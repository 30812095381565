import { EyeClosed, EyeOpen } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { useState } from "react";

interface usePasswordShownResult {
	passwordShown: boolean;
	PasswordVisibilityIcon: JSX.Element;
	togglePasswordVisibility: () => void;
}

interface usePasswordShowProps {
	hasError: boolean;
}

export const usePasswordShown = ({ hasError }: usePasswordShowProps): usePasswordShownResult => {
	const [passwordShown, setPasswordShown] = useState<usePasswordShownResult["passwordShown"]>(false);

	const togglePasswordVisibility: usePasswordShownResult["togglePasswordVisibility"] = () =>
		setPasswordShown(!passwordShown);

	const PasswordVisibilityIcon = passwordShown
		? EyeOpen({
				fill: hasError ? COLORS.GASPACHO[100] : COLORS.NIGHT[100],
				height: 20,
				width: 20
			})
		: EyeClosed({
				fill: hasError ? COLORS.GASPACHO[100] : COLORS.NIGHT[100],
				height: 20,
				width: 20
			});

	return { passwordShown, PasswordVisibilityIcon, togglePasswordVisibility };
};
