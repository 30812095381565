import { ArrowLeft } from "@hygo/shared/icons";
import { COLORS, emailRegEx } from "@hygo/shared/utils";
import {
	Button,
	ContainerWithBgImage,
	FormCard,
	FormHeader,
	InputTip,
	Loader,
	TextInput
} from "@hygo/web/ui-components";
import { FormProvider } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { EmailPasswordValidationScreenProps } from "./screen.types";

const Wrapper = styled.div`
	margin-top: 32px;
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const StyledButton = styled(Button)`
	margin-top: 32px;
`;

const InputWrapper = styled.div`
	margin-bottom: 8px;
`;

const GoBackButton = styled(Button)`
	@media (max-width: 740px) {
		display: none;
	}
`;

const EmailPasswordValidationScreen = ({
	emailSent,
	loading,
	methods,
	onGoBack,
	onSubmit
}: EmailPasswordValidationScreenProps): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState: { isValid }
	} = methods;
	return (
		<ContainerWithBgImage backgroundImage="/assets/images/bg.jpg">
			<FormCard>
				<FormHeader
					backIcon={<ArrowLeft />}
					onGoBack={onGoBack}
					title={t("screens.emailPasswordValidation.title")}
				/>
				{loading && <Loader />}
				{!loading && !emailSent && (
					<Wrapper>
						<FormProvider {...methods}>
							<form onSubmit={methods.handleSubmit(onSubmit)}>
								<InputWrapper>
									<TextInput
										autoCapitalize="off"
										autoComplete="email"
										label={t("inputs.email.label")}
										name="email"
										rules={{
											pattern: {
												message: t("inputs.email.errors.invalid"),
												value: emailRegEx
											},
											required: {
												message: t("inputs.email.errors.required"),
												value: true
											}
										}}
									/>
								</InputWrapper>
								<InputTip palette={COLORS.DISABLED} withBorder={false}>
									<ColoredCardText>{t("inputs.email.inputTip")}</ColoredCardText>
								</InputTip>
								<StyledButton
									color={COLORS.LAKE}
									disabled={!isValid}
									isSubmitBtn
									text={t("screens.emailPasswordValidation.submitBtn")}
								/>
							</form>
						</FormProvider>
					</Wrapper>
				)}
				{!loading && emailSent && (
					<Wrapper>
						<Trans i18nKey="screens.emailPasswordValidation.body">
							<p>
								Your pasword has been reset ! Check your email box and click on the link we sent you to
								set a new password.
								<br />
								If you didn&apos;t receive it yet, check your spam or ask a new link. If the problem
								persists, contact us at 06 74 82 99 04
							</p>
						</Trans>
						<GoBackButton color={COLORS.LAKE} onClick={onGoBack} text={t("button.understood")} />
						<StyledButton
							color={COLORS.LAKE}
							onClick={() => onSubmit({ email: methods.getValues("email") })}
							outlined
							text={t("screens.emailPasswordValidation.resendBtn")}
						/>
					</Wrapper>
				)}
			</FormCard>
		</ContainerWithBgImage>
	);
};

export default EmailPasswordValidationScreen;
