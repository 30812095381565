import { Chevron, Trash } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import styled, { css } from "styled-components";

const Label = styled.h5`
	text-overflow: ellipsis;
	flex: 1;
	overflow: hidden;
`;

const Wrapper = styled.button<{ $disabled: boolean; $hoverColor: string }>`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 8px 0;
	width: 100%;
	cursor: unset;
	${(props) =>
		!props.$disabled &&
		css`
			cursor: pointer;
			&:hover {
				${Label} {
					color: ${props.$hoverColor};
				}
			}
		`}
`;

const DeleteButtonWrapper = styled.div`
	cursor: pointer;
`;

const Content = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const LeftWrapper = styled.div`
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	flex-direction: row;
	flex: 1;
	gap: 4px;
`;

const Value = styled.h5<{ $color: string }>`
	color: ${(props) => props.$color};
	text-overflow: ellipsis;
	text-align: right;
	white-space: nowrap;
	flex: 1;
	overflow: hidden;
`;

const Error = styled.p`
	color: var(--gaspacho-100);
`;

const ChevronIcon = styled(Chevron)`
	transform: rotate(-90deg);
`;

interface ButtonClusterProps {
	chevronColor?: string;
	disabled?: boolean;
	error?: { message?: string };
	hoverColor?: string;
	Icon?: JSX.Element;
	label: string;
	onClear?: () => Promise<void>;
	onClick: () => void;
	placeholder?: string;
	value?: string;
}

const ButtonCluster = ({
	chevronColor,
	disabled,
	error,
	hoverColor,
	Icon,
	label,
	onClear,
	onClick,
	placeholder,
	value
}: ButtonClusterProps): JSX.Element => {
	const hasError = !!error?.message;

	const getValueColor = (): string => {
		if (hasError) return COLORS.GASPACHO[100];
		if (disabled) return COLORS.NIGHT[50];
		if (value) return COLORS.LAKE[100];
		return COLORS.TANGERINE[100];
	};

	const getRightPart = (): JSX.Element => {
		if (onClear)
			return (
				<DeleteButtonWrapper
					onClick={(e) => {
						e.stopPropagation();
						onClear();
					}}
				>
					<Trash fill={COLORS.GASPACHO[100]} height={16} onClick={onClear} width={16} />
				</DeleteButtonWrapper>
			);
		if (value || placeholder) return <Value $color={getValueColor()}>{value || placeholder}</Value>;
		return <ChevronIcon fill={chevronColor} height={16} width={16} />;
	};

	return (
		<Wrapper
			$disabled={disabled}
			$hoverColor={hoverColor || chevronColor || getValueColor()}
			onClick={!disabled ? onClick : null}
		>
			<Content>
				<LeftWrapper>
					{Icon}
					<Label>{label}</Label>
				</LeftWrapper>
				{getRightPart()}
			</Content>
			{hasError && <Error>{error?.message}</Error>}
		</Wrapper>
	);
};

export default ButtonCluster;
