import { ProductFamilyIcons, RecycleCrop, Smag, Warning, WaterFill } from "@hygo/shared/icons";
import { Condition, Country, Crop, DenormalizedField, ProductFamily, SmagStatus } from "@hygo/shared/models";
import {
	COLORS,
	computeColorFromConditions,
	formatDateToLocale,
	formatJSDateInHours,
	hexToRGBA,
	transformDate
} from "@hygo/shared/utils";
import { Checkbox, CropIcon, ParcelSVG, TooltipBubble } from "@hygo/web/ui-components";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface AccordionSummaryProps {
	checked: boolean;
	condition: Condition;
	countryCode: Country;
	crops: Crop[];
	endTime: string;
	exportMode: boolean;
	fields: DenormalizedField[];
	id: number;
	isTask: boolean;
	onClickCheckbox: (checked: boolean, taskId: number) => void;
	productFamily: ProductFamily;
	selectedFields: DenormalizedField[];
	smagStatus: SmagStatus;
	startTime: string;
}

const Icon = styled.svg<{ height: number; width: number }>`
	min-width: ${(props) => props.width}px;
	min-height: ${(props) => props.height}px;
`;

const CategoryWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const CropText = styled.h5`
	white-space: nowrap;
`;

const ProductFamilyText = styled.h4`
	white-space: nowrap;
`;

const CropItem = styled.div`
	padding: 8px 16px;
	display: flex;
	align-items: center;
	gap: 4px;
	justify-content: center;
	border-radius: 30px;
	background-color: var(--night-5);
`;

const StyledToolTipBubble = styled(TooltipBubble)`
	top: 42px;
	z-index: 1;
	left: -7px;
`;

const FieldsTooltipBubble = styled(StyledToolTipBubble)`
	top: 50px;
	left: -4px;
`;

const ExportTooltipBubble = styled(StyledToolTipBubble)``;

const CropTooltipBubble = styled(StyledToolTipBubble)`
	top: 50px;
	left: -2px;
`;

const CropIconWrapper = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0;
	background-color: var(--white-80);
	width: 16px;
	height: 16px;
	border-radius: 50%;
`;

const Tooltip = styled.div`
	position: relative;
	cursor: pointer;
	&:hover ${StyledToolTipBubble} {
		display: block;
	}
`;

const TooltipFieldsWrapper = styled(Tooltip)`
	position: relative;
	cursor: pointer;
	flex: 1;
	width: 0;
	&:hover ${FieldsTooltipBubble} {
		display: block;
	}
`;

const FieldsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	overflow: hidden;
`;

const ConditionWrapper = styled.div<{ $backgroundColor: string }>`
	border-radius: 8px;
	padding: 4px 8px;
	white-space: nowrap;
	background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

const FieldIconWrapper = styled.div`
	position: relative;
`;

const Slot = styled.h5`
	white-space: nowrap;
`;

const StyledCheckbox = styled(Checkbox)``;

const ConditionLabel = styled.h5<{ color: string }>`
	color: ${({ color }) => color};
`;

const AccordionSummary = ({
	checked,
	condition,
	countryCode,
	crops,
	endTime,
	exportMode,
	fields,
	id,
	isTask,
	onClickCheckbox,
	productFamily,
	selectedFields,
	smagStatus,
	startTime
}: AccordionSummaryProps): JSX.Element => {
	const { i18n, t } = useTranslation();

	const onClick = (e: MouseEvent<HTMLDivElement>): void => {
		e.stopPropagation();
		onClickCheckbox(checked, id);
	};
	const cropIds = [...new Set(fields?.map((f) => f.cropId))];
	const ProductIcon = productFamily ? ProductFamilyIcons[productFamily] : WaterFill;
	const backgroundConditionColor = computeColorFromConditions(condition, "BG_COLOR");
	const conditionColor = computeColorFromConditions(condition, "CONTRASTED_TEXT");
	const firstCrop = crops.find((c) => c.id === cropIds?.[0]);

	const getCropsList = (): string => {
		const cropsNames = cropIds.map((cId) => {
			const crop = crops.find((c) => c.id === cId);
			return crop?.name;
		});
		return cropsNames.join(", ");
	};

	return (
		<>
			{exportMode && <StyledCheckbox checked={checked} onClick={onClick} />}
			{!exportMode && smagStatus === SmagStatus.SUCCEEDED && (
				<Tooltip>
					<Icon as={Smag} backgroundFill={COLORS.TANGERINE[100]} height={20} width={20} />
					<ExportTooltipBubble text={t("components.taskCard.accordionSummary.tooltip.smagStatusSucceeded")} />
				</Tooltip>
			)}
			{!exportMode && smagStatus === SmagStatus.FAILED && (
				<Tooltip>
					<Icon as={Warning} fill={COLORS.GASPACHO[100]} height={20} width={20} />
					<ExportTooltipBubble text={t("components.taskCard.accordionSummary.tooltip.smagStatusFailed")} />
				</Tooltip>
			)}
			<CategoryWrapper>
				<Icon as={ProductIcon} fill={COLORS.NIGHT[100]} height={20} width={20} />
				<ProductFamilyText>
					{productFamily ? t(`products.${productFamily}`) : t("glossary.irrigation")}
				</ProductFamilyText>
			</CategoryWrapper>

			{cropIds?.length > 1 ? (
				<Tooltip>
					<CropItem>
						<Icon as={RecycleCrop} fill={COLORS.NIGHT[100]} height={20} width={20} />
						<CropText>{t("components.taskCard.accordionSummary.multiCropsLabel")}</CropText>
					</CropItem>
					<CropTooltipBubble text={getCropsList()} />
				</Tooltip>
			) : (
				<CropItem>
					<CropIcon crop={firstCrop} fill={COLORS.NIGHT[100]} height={20} width={20} />
					<CropText>{firstCrop?.name}</CropText>
				</CropItem>
			)}

			<TooltipFieldsWrapper>
				<FieldsWrapper>
					{fields?.map((field) => {
						const isSelected = selectedFields.map((f) => f.id).includes(field.id);
						const crop = crops?.find((c) => c.id === field.cropId);
						if (!field.svg) return null;
						return (
							<FieldIconWrapper key={field.id}>
								<Icon
									as={ParcelSVG}
									fill={
										selectedFields?.length === 0 || isSelected
											? computeColorFromConditions(
													field?.realCondition,
													"FIELD",
													hexToRGBA(crop?.color, 0.5, COLORS.LAKE[50])
												)
											: computeColorFromConditions(
													field?.realCondition,
													"BG_COLOR",
													hexToRGBA(crop?.color, 0.1, COLORS.LAKE[10])
												)
									}
									height={32}
									key={field.id}
									path={field.svg}
									stroke={
										selectedFields?.length === 0 || isSelected
											? computeColorFromConditions(
													field?.realCondition,
													"SLOT",
													hexToRGBA(crop?.color, 1, COLORS.LAKE[100])
												)
											: computeColorFromConditions(
													field?.realCondition,
													"FIELD",
													hexToRGBA(crop?.color, 0.25, COLORS.LAKE[25])
												)
									}
									width={32}
								/>
								<CropIconWrapper>
									<CropIcon crop={crop} fill={COLORS.NIGHT[100]} height={10} width={10} />
								</CropIconWrapper>
							</FieldIconWrapper>
						);
					})}
				</FieldsWrapper>
				<FieldsTooltipBubble text={t("components.taskCard.field", { count: fields?.length })} />
			</TooltipFieldsWrapper>
			{!isTask ? (
				<Slot>
					{formatDateToLocale(new Date(startTime), `${i18n.resolvedLanguage}-${countryCode}`, false)}{" "}
					{t("time.to")} {formatJSDateInHours(new Date(startTime))}h -{" "}
					{formatDateToLocale(new Date(endTime), `${i18n.resolvedLanguage}-${countryCode}`, false)}{" "}
					{t("time.to")} {formatJSDateInHours(new Date(endTime))}h
				</Slot>
			) : (
				<Slot>
					{transformDate(new Date(startTime), 5, Math.round)} -{" "}
					{transformDate(new Date(endTime), 5, Math.round)}
				</Slot>
			)}
			{condition && (
				<ConditionWrapper $backgroundColor={backgroundConditionColor}>
					<ConditionLabel color={conditionColor}>{t(`conditions.${condition}`)}</ConditionLabel>
				</ConditionWrapper>
			)}
		</>
	);
};

export default AccordionSummary;
