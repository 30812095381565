import { NozzleFamily, nozzleFamilyList } from "@hygo/shared/models";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface NozzleFamilyListProps {
	error?: { message?: string };
	onChange: (f: NozzleFamily) => void;
	value: NozzleFamily;
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	overflow: auto;
`;

const Label = styled.h5`
	white-space: nowrap;
`;

const ImageWrapper = styled.div`
	width: 60px;
	box-shadow:
		0px 0.8px 1.5px rgba(0, 83, 94, 0.1),
		0px 6px 12px rgba(0, 83, 94, 0.1);
	border-radius: 4px;
	height: 60px;
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;
`;

const NozzleFamilyWrapper = styled.div<{ selected: boolean }>`
	flex: 1;

	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	${Label} {
		color: ${(props) => (props.selected ? "var(--lake-100)" : "var(--night-100)")};
	}
	${ImageWrapper} {
		border: ${(props) => (props.selected ? "2px solid var(--lake-100)" : "none")};
	}
`;

const ErrorMessage = styled.p`
	color: var(--gaspacho-100);
`;

const NozzleFamilySelector = ({ error, onChange, value }: NozzleFamilyListProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<>
			<Wrapper>
				{nozzleFamilyList.map((nozzleFamily) => {
					return (
						<NozzleFamilyWrapper
							key={nozzleFamily}
							onClick={() => onChange(nozzleFamily)}
							selected={value === nozzleFamily}
						>
							<ImageWrapper>
								<img alt={nozzleFamily} src={`/assets/images/equipment/${nozzleFamily}.png`} />
							</ImageWrapper>
							<Label>{t(`nozzle.${nozzleFamily}`)}</Label>
						</NozzleFamilyWrapper>
					);
				})}
			</Wrapper>
			{error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
		</>
	);
};

export default NozzleFamilySelector;
