export enum NozzleFamily {
	CALIBRATE = "CALIBRATE",
	CLASSIC_LOW = "CLASSIC_LOW",
	CLASSIC_STD = "CLASSIC_STD",
	DOUBLE_FENTE_ANTI_DERIVE = "DOUBLE_FENTE_ANTI_DERIVE",
	FERTILIZERS = "FERTILIZERS",
	INJECTION = "INJECTION"
}

export const nozzleFamilyList: NozzleFamily[] = Object.values(NozzleFamily);
