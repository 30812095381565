// eslint-disable-next-line @nx/enforce-module-boundaries
import {
	Belgium,
	CzechRepublic,
	France,
	Germany,
	GreatBritain,
	Italy,
	Netherlands,
	Poland,
	Slovakia
} from "@hygo/shared/icons";
import { Country, CountryStatus, Language } from "@hygo/shared/models";
import { OAD } from "@hygo/shared/models";
import { getCountryCallingCode, getExampleNumber } from "libphonenumber-js/min";
import examples from "libphonenumber-js/mobile/examples";
import { FC } from "react";

interface CountryDetails {
	backofficeResources: string[];
	flag: FC<{ height: number; width: number }>;
	languages: Language[];
	phone: {
		callingCode: string;
		examplePhone: string;
	};
	planPresentation: {
		addons: OAD[];
		hve: boolean;
		oadList: OAD[];
		video: boolean;
	};
	status: CountryStatus;
	withCoops?: boolean;
}

export const countryMapping: {
	[key in Country]: CountryDetails;
} = {
	[Country.BE]: {
		backofficeResources: ["BE/authorizations", "BE/protections", "BE/nutritions"],
		flag: Belgium,
		languages: [Language.fr, Language.nl],
		phone: {
			callingCode: getCountryCallingCode(Country.BE),
			examplePhone: getExampleNumber(Country.BE, examples).formatNational()
		},
		planPresentation: {
			addons: null,
			hve: false,
			oadList: [OAD.SMAG, OAD.GEOFOLIA, OAD.JOHNDEERE],
			video: false
		},
		status: CountryStatus.FREE_ONLY
	},
	[Country.CZ]: {
		backofficeResources: ["CZ/authorizations", "CZ/protections", "CZ/nutritions"],
		flag: CzechRepublic,
		languages: [Language.cs],
		phone: {
			callingCode: getCountryCallingCode(Country.CZ),
			examplePhone: getExampleNumber(Country.CZ, examples).formatNational()
		},
		planPresentation: {
			addons: null,
			hve: false,
			oadList: [OAD.XARVIO, OAD.JOHNDEERE],
			video: false
		},
		status: CountryStatus.FREE_ONLY
	},
	[Country.DE]: {
		backofficeResources: ["DE/authorizations", "DE/protections", "DE/nutritions"],
		flag: Germany,
		languages: [Language.de],
		phone: {
			callingCode: getCountryCallingCode(Country.DE),
			examplePhone: getExampleNumber(Country.DE, examples).formatNational()
		},
		planPresentation: {
			addons: null,
			hve: false,
			oadList: [OAD.SKYFLD, OAD.JOHNDEERE],
			video: false
		},
		status: CountryStatus.FREE_ONLY
	},

	[Country.FR]: {
		backofficeResources: ["FR/authorizations", "FR/protections", "FR/nutritions", "FR/usages", "FR/targets"],
		flag: France,
		languages: [Language.fr, Language.en],
		phone: {
			callingCode: getCountryCallingCode(Country.FR),
			examplePhone: getExampleNumber(Country.FR, examples).formatNational()
		},
		planPresentation: {
			addons: [OAD.MILEOS],
			hve: true,
			oadList: [OAD.SMAG, OAD.GEOFOLIA],
			video: true
		},
		status: CountryStatus.OPEN,
		withCoops: true
	},
	[Country.GB]: {
		backofficeResources: ["GB/authorizations", "GB/protections", "GB/nutritions"],
		flag: GreatBritain,
		languages: [Language.en],
		phone: {
			callingCode: getCountryCallingCode(Country.GB),
			examplePhone: getExampleNumber(Country.GB, examples).formatNational()
		},
		planPresentation: {
			addons: null,
			hve: false,
			oadList: [OAD.XARVIO, OAD.JOHNDEERE],
			video: false
		},
		status: CountryStatus.FREE_ONLY
	},
	[Country.IT]: {
		backofficeResources: ["IT/authorizations", "IT/protections", "IT/nutritions"],
		flag: Italy,
		languages: [Language.it],
		phone: {
			callingCode: getCountryCallingCode(Country.IT),
			examplePhone: getExampleNumber(Country.IT, examples).formatNational()
		},
		planPresentation: {
			addons: null,
			hve: false,
			oadList: [OAD.GEOFOLIA, OAD.JOHNDEERE],
			video: false
		},
		status: CountryStatus.FREE_ONLY
	},
	[Country.NL]: {
		backofficeResources: ["NL/authorizations", "NL/protections", "NL/nutritions"],
		flag: Netherlands,
		languages: [Language.nl],
		phone: {
			callingCode: getCountryCallingCode(Country.NL),
			examplePhone: getExampleNumber(Country.NL, examples).formatNational()
		},
		planPresentation: {
			addons: null,
			hve: false,
			oadList: [OAD.XARVIO, OAD.JOHNDEERE],
			video: false
		},
		status: CountryStatus.FREE_ONLY
	},
	[Country.PL]: {
		backofficeResources: ["PL/authorizations", "PL/protections", "PL/nutritions"],
		flag: Poland,
		languages: [Language.pl],
		phone: {
			callingCode: getCountryCallingCode(Country.PL),
			examplePhone: getExampleNumber(Country.PL, examples).formatNational()
		},
		planPresentation: {
			addons: null,
			hve: false,
			oadList: [OAD.XARVIO, OAD.JOHNDEERE],
			video: false
		},
		status: CountryStatus.FREE_ONLY
	},
	[Country.SK]: {
		backofficeResources: ["SK/authorizations", "SK/protections", "SK/nutritions"],
		flag: Slovakia,
		languages: [Language.sk],
		phone: {
			callingCode: getCountryCallingCode(Country.SK),
			examplePhone: getExampleNumber(Country.SK, examples).formatNational()
		},
		planPresentation: {
			addons: null,
			hve: false,
			oadList: [OAD.XARVIO, OAD.JOHNDEERE],
			video: false
		},
		status: CountryStatus.FREE_ONLY
	}
};

export const availableCountries = Object.values(Country).filter((country) => !!countryMapping[country].status);
