import { FieldNameModalPropsType } from "@hygo/shared/modals";
import { COLORS } from "@hygo/shared/utils";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import TextInput from "../../TextInput";
import BaseModal from "../BaseModal";

const FieldNameModal = ({ field, hideModal, modalVisible, onSubmit }: FieldNameModalPropsType): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultValues = useMemo(() => ({ name: field?.name }), [field]);

	const methods = useForm({
		defaultValues,
		mode: "onChange"
	});
	const {
		formState: { isValid }
	} = methods;
	const onConfirm = async ({ name }: { name: string }): Promise<void> => {
		try {
			setLoading(true);
			await onSubmit({ id: field?.id, name });
			hideModal();
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		methods.reset(defaultValues);
	}, [methods, defaultValues]);

	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnDisabled={!isValid}
			confirmBtnLoading={loading}
			confirmBtnText={t("button.save")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={methods.handleSubmit(onConfirm)}
			title={t("inputs.fieldName.label")}
		>
			<FormProvider {...methods}>
				<TextInput
					name="name"
					placeholder="Les Plaines"
					rules={{
						required: {
							message: t("inputs.fieldName.errors.required"),
							value: true
						}
					}}
				/>
			</FormProvider>
		</BaseModal>
	);
};
export default FieldNameModal;
