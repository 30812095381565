import { UserContext } from "@hygo/shared/contexts";
import { useMileosSetup } from "@hygo/shared/feature-fields-manager";
import { Chevron } from "@hygo/shared/icons";
import { ArvalisDeviceModalPropsType } from "@hygo/shared/modals";
import { COLORS } from "@hygo/shared/utils";
import { useContext, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import InputTip from "../../InputTip";
import SelectInput from "../../SelectInput";
import TextInput from "../../TextInput";
import BaseModal from "../BaseModal";

const Wrapper = styled.div<{ $open: boolean }>`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: ${(props) => (props.$open ? "300px" : "unset")};
	gap: 16px;
`;

const ChevronIcon = styled(Chevron)`
	transform: rotate(-90deg);
`;

const StyleInputTip = styled(InputTip)`
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: space-between;
`;

const ArvalisDeviceModal = ({
	field,
	hideModal,
	modalVisible,
	refreshField
}: ArvalisDeviceModalPropsType): JSX.Element => {
	const { i18n, t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const { arvalisDevicesConstructors } = useContext(UserContext);
	const [open, setOpen] = useState<boolean>(false);
	const { arvalisDeviceRules, handleDeviceCreationErrors, methods, onAddDevice } = useMileosSetup({ field });

	const {
		formState: { isValid }
	} = methods;

	const onClickHelp = (): void => {
		window.open(
			`https://support.alvie.fr/${i18n.resolvedLanguage}/articles/9052261-mileos-configurez-vos-parcelles-de-pommes-de-terre#h_7b77f83e0a`,
			"_blank"
		);
	};

	const onConfirm = async ({
		constructorName,
		deviceCode
	}: {
		constructorName: string;
		deviceCode: string;
	}): Promise<void> => {
		try {
			setLoading(true);
			await onAddDevice({ constructorName, deviceCode }, async () => {
				await refreshField();
			});
			hideModal();
		} catch (e) {
			handleDeviceCreationErrors(e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnDisabled={!isValid}
			confirmBtnLoading={loading}
			confirmBtnText={t("button.save")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={methods.handleSubmit(onConfirm)}
			title={t("modals.arvalisDevice.title")}
		>
			<FormProvider {...methods}>
				<Wrapper $open={open}>
					<div>
						<SelectInput
							control={methods.control}
							initialOptions={arvalisDevicesConstructors?.map((v) => ({
								label: v,
								value: v
							}))}
							label={t("inputs.arvalisDeviceConstructor.label")}
							name="constructorName"
							onOpenCallback={setOpen}
							placeholder={t("inputs.arvalisDeviceConstructor.placeholder")}
							rules={arvalisDeviceRules.constructorName}
							searchable
							theme="light"
						/>
					</div>
					<TextInput
						label={t("inputs.arvalisDeviceCode.label")}
						name="deviceCode"
						placeholder={t("inputs.arvalisDeviceCode.placeholder")}
						rules={arvalisDeviceRules.deviceCode}
					/>
					<StyleInputTip onClick={onClickHelp} palette={COLORS.GRASS}>
						<h5>{t("modals.arvalisDevice.inputTip")}</h5>
						<ChevronIcon fill={COLORS.GRASS[100]} height={20} width={20} />
					</StyleInputTip>
				</Wrapper>
			</FormProvider>
		</BaseModal>
	);
};
export default ArvalisDeviceModal;
