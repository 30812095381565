import { SVGProps } from "react";

export const Germany = ({ className, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "20"} viewBox="0 0 20 20" width={width || "20"}>
			<mask height="18" id="mask0_6660_34158" maskUnits="userSpaceOnUse" width="18" x="1" y="1">
				<circle cx="10.0001" cy="10" fill="#D9D9D9" r="8.33333" />
			</mask>
			<g mask="url(#mask0_6660_34158)">
				<rect
					fill="#F8CE47"
					height="16.6667"
					transform="rotate(-90 1.66675 18.3334)"
					width="5.55555"
					x="1.66675"
					y="18.3334"
				/>
				<rect
					fill="#CC2A1D"
					height="16.6667"
					transform="rotate(-90 1.66675 12.7778)"
					width="5.55556"
					x="1.66675"
					y="12.7778"
				/>
				<rect
					fill="black"
					height="16.6667"
					transform="rotate(-90 1.66675 7.22223)"
					width="5.55556"
					x="1.66675"
					y="7.22223"
				/>
			</g>
		</svg>
	);
};

export const Belgium = ({ className, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "20"} viewBox="0 0 20 20" width={width || "20"}>
			<mask height="18" id="mask0_6660_34165" maskUnits="userSpaceOnUse" width="18" x="1" y="1">
				<circle cx="10.0001" cy="10" fill="#D9D9D9" r="8.33333" />
			</mask>
			<g mask="url(#mask0_6660_34165)">
				<rect fill="black" height="16.6667" width="5.55556" x="1.66675" y="1.66669" />
				<rect fill="#F8DA52" height="16.6667" width="5.55556" x="7.22241" y="1.66669" />
				<rect fill="#DD4648" height="16.6667" width="5.55555" x="12.7778" y="1.66669" />
			</g>
		</svg>
	);
};

export const France = ({ className, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "20"} viewBox="0 0 20 20" width={width || "20"}>
			<mask height="18" id="mask0_6660_34172" maskUnits="userSpaceOnUse" width="18" x="1" y="1">
				<circle cx="10.0001" cy="10" fill="#D9D9D9" r="8.33333" />
			</mask>
			<g mask="url(#mask0_6660_34172)">
				<rect fill="#000091" height="16.6667" width="5.55556" x="1.66675" y="1.66669" />
				<rect fill="white" height="16.6667" width="5.55556" x="7.22241" y="1.66669" />
				<rect fill="#E1000F" height="16.6667" width="5.55555" x="12.7778" y="1.66669" />
			</g>
		</svg>
	);
};

export const Italy = ({ className, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "20"} viewBox="0 0 20 20" width={width || "20"}>
			<mask height="18" id="mask0_6660_34179" maskUnits="userSpaceOnUse" width="18" x="1" y="1">
				<circle cx="10.0001" cy="10" fill="#D9D9D9" r="8.33333" />
			</mask>
			<g mask="url(#mask0_6660_34179)">
				<rect fill="#408F4E" height="16.6667" width="5.55556" x="1.66675" y="1.66669" />
				<rect fill="white" height="16.6667" width="5.55556" x="7.22241" y="1.66669" />
				<rect fill="#BE3B3D" height="16.6667" width="5.55555" x="12.7778" y="1.66669" />
			</g>
		</svg>
	);
};

export const Netherlands = ({ className, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "20"} viewBox="0 0 20 20" width={width || "20"}>
			<mask height="18" id="mask0_6660_34186" maskUnits="userSpaceOnUse" width="18" x="1" y="1">
				<circle cx="10.0001" cy="10" fill="#D9D9D9" r="8.33333" />
			</mask>
			<g mask="url(#mask0_6660_34186)">
				<rect
					fill="#284887"
					height="16.6667"
					transform="rotate(-90 1.66675 18.3334)"
					width="5.55555"
					x="1.66675"
					y="18.3334"
				/>
				<rect
					fill="white"
					height="16.6667"
					transform="rotate(-90 1.66675 12.7778)"
					width="5.55556"
					x="1.66675"
					y="12.7778"
				/>
				<rect
					fill="#A12C2E"
					height="16.6667"
					transform="rotate(-90 1.66675 7.22223)"
					width="5.55556"
					x="1.66675"
					y="7.22223"
				/>
			</g>
		</svg>
	);
};

export const CzechRepublic = ({ className, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "20"} viewBox="0 0 20 20" width={width || "20"}>
			<mask height="18" id="mask0_6660_34193" maskUnits="userSpaceOnUse" width="18" x="1" y="1">
				<circle cx="10.0001" cy="10" fill="#D9D9D9" r="8.33333" />
			</mask>
			<g mask="url(#mask0_6660_34193)">
				<path d="M10.9399 10L-1.38892 1.66669V18.3334L10.9399 10Z" fill="#1F467A" />
				<path d="M23.6111 1.66669H-1.38892L10.9399 10H23.6111V1.66669Z" fill="white" />
				<path d="M23.6111 18.3334H-1.38892L10.9399 10H23.6111V18.3334Z" fill="#C63028" />
			</g>
		</svg>
	);
};

export const Poland = ({ className, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "20"} viewBox="0 0 20 20" width={width || "20"}>
			<mask height="18" id="mask0_1914_63" maskUnits="userSpaceOnUse" width="18" x="1" y="1">
				<circle cx="10" cy="9.99999" fill="#D9D9D9" r="8.33333" />
			</mask>
			<g mask="url(#mask0_1914_63)">
				<path d="M1.66666 18.3334L1.66666 10L18.3333 10L18.3333 18.3334L1.66666 18.3334Z" fill="#DC143C" />
				<path d="M1.66666 10L1.66666 1.66667L18.3219 1.66667L18.3219 10L1.66666 10Z" fill="white" />
			</g>
		</svg>
	);
};

export const GreatBritain = ({ className, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "20"} viewBox="0 0 20 20" width={width || "20"}>
			<mask height="18" id="mask0_6660_34200" maskUnits="userSpaceOnUse" width="18" x="1" y="1">
				<path
					d="M10.0001 18.3334C14.6025 18.3334 18.3334 14.6024 18.3334 10C18.3334 5.39765 14.6025 1.66669 10.0001 1.66669C5.39771 1.66669 1.66675 5.39765 1.66675 10C1.66675 14.6024 5.39771 18.3334 10.0001 18.3334Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_6660_34200)">
				<path
					d="M1.66675 1.66669L1.92716 2.38283L1.66675 3.13153V3.88023L2.70841 5.63804L1.66675 7.39585V8.43752L2.70841 10L1.66675 11.5625V12.6042L2.70841 14.362L1.66675 16.1198V18.3334L2.38289 18.0729L3.13159 18.3334H3.88029L5.6381 17.2917L7.39592 18.3334H8.43758L10.0001 17.2917L11.5626 18.3334H12.6042L14.3621 17.2917L16.1199 18.3334H18.3334L18.073 17.6172L18.3334 16.8685V16.1198L17.2917 14.362L18.3334 12.6042V11.5625L17.2917 10L18.3334 8.43752V7.39585L17.2917 5.63804L18.3334 3.88023V1.66669L17.6173 1.9271L16.8686 1.66669H16.1199L14.3621 2.70835L12.6042 1.66669H11.5626L10.0001 2.70835L8.43758 1.66669H7.39592L5.6381 2.70835L3.88029 1.66669H1.66675Z"
					fill="#EEEEEE"
				/>
				<path
					d="M12.6042 1.66669V5.18231L16.1199 1.66669H12.6042ZM18.3334 3.88023L14.8178 7.39585H18.3334V3.88023ZM1.66675 7.39585H5.18237L1.66675 3.88023V7.39585ZM3.88029 1.66669L7.39592 5.18231V1.66669H3.88029ZM7.39592 18.3334V14.8177L3.88029 18.3334H7.39592ZM1.66675 16.1198L5.18237 12.6042H1.66675V16.1198ZM18.3334 12.6042H14.8178L18.3334 16.1198V12.6042ZM16.1199 18.3334L12.6042 14.8177V18.3334H16.1199Z"
					fill="#0052B4"
				/>
				<path
					d="M1.66675 1.66669V3.13153L5.93107 7.39585H7.39592L1.66675 1.66669ZM8.43758 1.66669V8.43752H1.66675V11.5625H8.43758V18.3334H11.5626V11.5625H18.3334V8.43752H11.5626V1.66669H8.43758ZM16.8686 1.66669L12.6042 5.93101V7.39585L18.3334 1.66669H16.8686ZM7.39592 12.6042L1.66675 18.3334H3.13159L7.39592 14.069V12.6042ZM12.6042 12.6042L18.3334 18.3334V16.8685L14.0691 12.6042H12.6042Z"
					fill="#D80027"
				/>
			</g>
		</svg>
	);
};
