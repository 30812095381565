import { ENV } from "@hygo/shared/models";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ChangelogScreen from "./ChangelogScreen";
import { ChangelogContainerProps, ChangelogScreenProps } from "./screen.types";

const ChangelogContainer = ({ path }: ChangelogContainerProps): JSX.Element => {
	const [changelog, setChangelog] = useState<ChangelogScreenProps["changelog"]>(null);
	const navigate = useNavigate();

	const goToChangelog: ChangelogScreenProps["goToChangelog"] = (newPath: string): void => {
		setChangelog(null);
		navigate(newPath);
	};

	useEffect(() => {
		const fetchChangelog = async (): Promise<void> => {
			const response = await axios.get(
				`https://gitlab.com/api/v4/projects/41069930/repository/files/${path.toUpperCase()}_CHANGELOG.md/raw?ref=${
					process.env.NX_PUBLIC_ENV === ENV.PRODUCTION ? "release" : "develop"
				}`,
				{
					headers: {
						"PRIVATE-TOKEN": process.env.NX_PUBLIC_GITLAB_TOKEN
					}
				}
			);
			setChangelog(response?.data);
		};
		if (path) fetchChangelog();
	}, [path]);

	return <ChangelogScreen changelog={changelog} goToChangelog={goToChangelog} path={path} />;
};

export default ChangelogContainer;
