export enum ProductFamily {
	ADJUVANT = "ADJUVANT",
	FERTILIZER = "FERTILIZER",
	FONGICIDE = "FONGICIDE",
	GROWTH_REGULATOR = "GROWTH_REGULATOR",
	HERBICIDE = "HERBICIDE",
	INSECTICIDE = "INSECTICIDE",
	MULTI_TREATMENTS = "MULTI_TREATMENTS",
	OTHERS = "OTHERS"
}
