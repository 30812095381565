import { FieldsLinkingGrid } from "@hygo/web/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ImportTelepacLinkingScreenProps } from "./screen.types";

const GeofoliaImg = styled.img`
	width: 16px;
	height: 16px;
`;

const ImportTelepacLinkingScreen = ({
	backFromError,
	crops,
	cropSynchronize,
	defaultFarm,
	error,
	fields,
	fieldsToImport,
	goBack,
	goToDashboard,
	isGeofoliaFile,
	linking,
	loading,
	loadingImportedFields,
	onChange,
	onClickCheckbox,
	onClickCropSynchronize,
	onSubmit,
	retryImport,
	success
}: ImportTelepacLinkingScreenProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<FieldsLinkingGrid
			backFromError={backFromError}
			crops={crops}
			cropSynchronize={cropSynchronize}
			defaultFarm={defaultFarm}
			error={error}
			fields={fields}
			fieldsToImport={fieldsToImport}
			goBack={goBack}
			goToDashboard={goToDashboard}
			greenInputTipText={
				isGeofoliaFile
					? t("screens.importTelepacLinking.geofolia.inputTip")
					: t("screens.importTelepacLinking.classic.inputTip")
			}
			importedFieldsLabel={
				isGeofoliaFile
					? t("screens.importTelepacLinking.grid.geofoliaLabel")
					: t("screens.importTelepacLinking.grid.classicLabel")
			}
			importFieldsLogo={isGeofoliaFile && <GeofoliaImg alt="geofolia" src={`/assets/images/oad/geofolia.png`} />}
			linking={linking}
			loading={loading}
			loadingImportedFields={loadingImportedFields}
			onChange={onChange}
			onClickCheckbox={onClickCheckbox}
			onClickCropSynchronize={onClickCropSynchronize}
			onSubmit={onSubmit}
			retryImport={retryImport}
			subtitle={
				isGeofoliaFile
					? t("screens.importTelepacLinking.geofolia.subtitle")
					: t("screens.importTelepacLinking.classic.subtitle")
			}
			success={success}
		/>
	);
};

export default ImportTelepacLinkingScreen;
