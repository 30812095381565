import { Smag } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { FieldsLinkingGrid } from "@hygo/web/ui-components";
import { useTranslation } from "react-i18next";

import { ImportSmagLinkingScreenProps } from "./screen.types";

const ImportSmagLinkingScreen = ({
	backFromError,
	crops,
	cropSynchronize,
	defaultFarm,
	error,
	fields,
	fieldsToImport,
	goBack,
	goToDashboard,
	linking,
	loading,
	loadingImportedFields,
	onChange,
	onClickCheckbox,
	onClickCropSynchronize,
	onSubmit,
	retryImport,
	success
}: ImportSmagLinkingScreenProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<FieldsLinkingGrid
			backFromError={backFromError}
			crops={crops}
			cropSynchronize={cropSynchronize}
			defaultFarm={defaultFarm}
			error={error}
			fields={fields}
			fieldsToImport={fieldsToImport}
			goBack={goBack}
			goToDashboard={goToDashboard}
			greenInputTipText={t("screens.importSmagLinking.inputTip.1")}
			importedFieldsLabel={t("screens.importSmagLinking.grid.smagLabel")}
			importFieldsLogo={<Smag backgroundFill={COLORS.TANGERINE[100]} height={16} width={16} />}
			linking={linking}
			loading={loading}
			loadingImportedFields={loadingImportedFields}
			onChange={onChange}
			onClickCheckbox={onClickCheckbox}
			onClickCropSynchronize={onClickCropSynchronize}
			onSubmit={onSubmit}
			retryImport={retryImport}
			subtitle={t("screens.importSmagLinking.subtitle")}
			success={success}
		/>
	);
};

export default ImportSmagLinkingScreen;
