import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useSignup } from "@hygo/shared/feature-authentication";
import { Country, Language, SignupEvents } from "@hygo/shared/models";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { SignupScreenProps } from "./screen.types";
import SignupScreen from "./SignupScreen";

const SignupContainer = (): JSX.Element => {
	const { i18n } = useTranslation();
	const {
		activeStep,
		businessSectors,
		coops,
		createNewUser,
		goNextStep,
		goPreviousStep,
		isButtonDisabled,
		loading,
		methods,
		onChangeCountry,
		onEditPhoneCountrySelector,
		passwordShown,
		PasswordVisibilityIcon,
		rules,
		togglePasswordVisibility
	} = useSignup({ defaultCountry: Country.FR, language: i18n.resolvedLanguage as Language });
	const navigate = useNavigate();
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const onGoBack: SignupScreenProps["onGoBack"] = () => {
		logAnalyticEvent(SignupEvents.goBack, { currentStep: 1 });
		navigate(-1);
	};

	const onClickConditions: SignupScreenProps["onGoBack"] = () => {
		logAnalyticEvent(SignupEvents.clickConditions);
	};

	const onLocationChange: SignupScreenProps["onLocationChange"] = (v) => {
		logAnalyticEvent(SignupEvents.selectLocation, { value: v?.label });
		methods.setValue("location", v, { shouldDirty: true, shouldValidate: true });
	};

	return (
		<SignupScreen
			activeStep={activeStep}
			businessSectors={businessSectors}
			coops={coops}
			goNextStep={goNextStep}
			goPreviousStep={goPreviousStep}
			isButtonDisabled={isButtonDisabled}
			loading={loading}
			methods={methods}
			onChangeCountry={onChangeCountry}
			onClickConditions={onClickConditions}
			onClickCreateAccount={createNewUser}
			onEditPhoneCountrySelector={onEditPhoneCountrySelector}
			onGoBack={onGoBack}
			onLocationChange={onLocationChange}
			passwordShown={passwordShown}
			PasswordVisibilityIcon={PasswordVisibilityIcon}
			rules={rules}
			togglePasswordVisibility={togglePasswordVisibility}
		/>
	);
};

export default SignupContainer;
