import {
	AmplitudeEvent,
	AmplitudeUser,
	BaseNozzle,
	BaseSprayer,
	Crop,
	GenericField,
	Nozzle,
	ProductUnit,
	Sprayer,
	Target,
	User
} from "@hygo/shared/models";
import { formatDateToLocale } from "@hygo/shared/utils";
import _ from "lodash";
import { createContext, useCallback, useMemo } from "react";

interface AmplitudeContextResult {
	formatFields: (fields: Omit<GenericField, "fieldArea">[], crops: Crop[]) => { crop: string; name: string }[];
	formatNozzle: (nozzle: Nozzle, sprayerName: string) => { sprayerName: string } & Omit<BaseNozzle, "sprayerId">;
	formatProducts: (
		products: {
			initialDosePerHA: number;
			isPerHA: boolean;
			name: string;
			realDosePerHA: number;
			unit: ProductUnit;
		}[]
	) => {
		initialDosePerHA: number;
		isPerHA: boolean;
		name: string;
		realDosePerHA: number;
		unit: ProductUnit;
	}[];
	formatSprayer: (sprayer: Sprayer) => Omit<BaseSprayer, "defaultNozzleId">;
	formatTargets: (targets: Target[]) => string[];
	logAnalyticEvent: (
		event: AmplitudeEvent,
		properties?: {
			[name: string]: unknown;
		}
	) => void;
	setUser: (user: { build: string; OTA: string; version: string } & User) => void;
	setUserId: (id: string) => void;
}

export const AmplitudeContext = createContext({} as AmplitudeContextResult);

interface AmplitudeProviderProps {
	amplitudeLogCallback: (event: string, properties?: Record<string, unknown>) => void;
	children: JSX.Element | JSX.Element[];
	setUserCallback: (amplitudeUser: AmplitudeUser) => void;
	setUserIdCallback: (id: string) => void;
}

const AmplitudeProvider = ({
	amplitudeLogCallback,
	children,
	setUserCallback,
	setUserIdCallback
}: AmplitudeProviderProps): JSX.Element => {
	const logAnalyticEvent: AmplitudeContextResult["logAnalyticEvent"] = useCallback(
		(event, properties = {}) => amplitudeLogCallback(event, properties),
		[amplitudeLogCallback]
	);

	const setUserId: AmplitudeContextResult["setUserId"] = useCallback(
		(id) => {
			setUserIdCallback(id);
		},
		[setUserIdCallback]
	);

	const formatProducts: AmplitudeContextResult["formatProducts"] = useCallback(
		(products) =>
			products?.map(({ initialDosePerHA, isPerHA, name, realDosePerHA, unit }) => ({
				initialDosePerHA,
				isPerHA,
				name,
				realDosePerHA,
				unit
			})),
		[]
	);

	const formatTargets: AmplitudeContextResult["formatTargets"] = useCallback(
		(targets) => targets?.map((t) => t.name),
		[]
	);

	const formatFields: AmplitudeContextResult["formatFields"] = useCallback(
		(fields, crops) =>
			fields?.map((f) => ({
				area: f.area,
				crop: crops?.find((c) => c.id === f.cropId)?.name,
				name: f.name,
				needCheck: f.needCheck
			})),
		[]
	);

	const setUser: AmplitudeContextResult["setUser"] = useCallback(
		({
			admin,
			build,
			coopId,
			countryCode,
			createdAt,
			email,
			equipments,
			firstName,
			hsContactId,
			language,
			lastName,
			location,
			OTA,
			plan,
			premiumRequestDate,
			setup,
			smagStatus,
			tester,
			version
		}) => {
			setUserCallback({
				build,
				coopId: coopId || admin?.coopId,
				createdAt,
				email: email || admin?.email,
				firstName: firstName || admin?.firstName,
				hsContactId,
				hveMode: setup?.hveMode,
				lastName: lastName || admin?.lastName,
				location: location?.label,
				OTA,
				planId: plan?.planId,
				planStatus: plan?.planStatus,
				premiumRequestDate: premiumRequestDate
					? formatDateToLocale(new Date(premiumRequestDate), `${language}-${countryCode}`)
					: null,
				smagStatus,
				soil: equipments?.soil,
				soilAcidity: equipments?.soilAcidity,
				tester,
				version,
				waterAcidity: equipments?.waterAcidity,
				waterHardness: equipments?.waterHardness,
				waterTankBottomPercentage: equipments?.waterTankBottomPercentage
			});
		},
		[setUserCallback]
	);

	const formatNozzle: AmplitudeContextResult["formatNozzle"] = useCallback((nozzle, sprayerName) => {
		const formattedNozzle = _.pick(nozzle, ["color", "speed", "pressure", "height", "family", "name"]);
		return { ...formattedNozzle, sprayerName };
	}, []);

	const formatSprayer: AmplitudeContextResult["formatSprayer"] = useCallback((sprayer) => {
		return _.pick(sprayer, ["gpsBarcode", "weatherBarcode", "name"]);
	}, []);

	const value = useMemo(
		() => ({
			formatFields,
			formatNozzle,
			formatProducts,
			formatSprayer,
			formatTargets,
			logAnalyticEvent,
			setUser,
			setUserId
		}),
		[logAnalyticEvent, formatNozzle, formatFields, formatProducts, formatTargets, setUser, setUserId, formatSprayer]
	);
	return <AmplitudeContext.Provider value={value}>{children}</AmplitudeContext.Provider>;
};

export default AmplitudeProvider;
