import styled from "styled-components";

const Subtitle = styled.h4`
	color: var(--night-50);
`;

const Wrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: auto;
	padding: 16px;
	text-align: center;
	gap: 16px;
`;

interface ManagerEmptyStateProps {
	asset: JSX.Element;
	subtitle: string;
	title: string;
}

const ManagerEmptyState = ({ asset, subtitle, title }: ManagerEmptyStateProps): JSX.Element => {
	return (
		<Wrapper>
			{asset}
			<div>
				<h2>{title}</h2>
				<Subtitle>{subtitle}</Subtitle>
			</div>
		</Wrapper>
	);
};

export default ManagerEmptyState;
