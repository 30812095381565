import { UserContext } from "@hygo/shared/contexts";
import { Smag } from "@hygo/shared/icons";
import { OnboardingCropsModalPropsType } from "@hygo/shared/modals";
import { COLORS, convertToHa, hexToRGBA } from "@hygo/shared/utils";
import { OADContext } from "@hygo/web/contexts";
import { OADKeyEnum, OADStepEnum } from "@hygo/web/models";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ParcelSVG from "../../ParcelSVG";
import BaseModal from "../BaseModal";

const Field = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
	gap: 16px;
`;

const GeofoliaImg = styled.img`
	width: 20px;
	display: block;
	height: 20px;
`;

const Area = styled.h4`
	color: var(--night-50);
`;

const Subline = styled.h4`
	text-align: center;
	color: var(--night-50);
`;

const assets: { [key in OADKeyEnum]: JSX.Element } = {
	[OADKeyEnum.GEOFOLIA_ONBOARDING_STEP]: <GeofoliaImg alt="geofolia" src={`/assets/images/oad/geofolia.png`} />,
	[OADKeyEnum.SMAG_ONBOARDING_STEP]: <Smag backgroundFill={COLORS.TANGERINE[100]} height={20} width={20} />
};

const OnboardingCropsModal = ({
	hideModal,
	modalVisible,
	onboardingKey,
	onConfirm
}: OnboardingCropsModalPropsType): JSX.Element => {
	const { t } = useTranslation();
	const { setStepCookie } = useContext(OADContext);
	const { activeFields, crops } = useContext(UserContext);
	const exampleField = activeFields?.filter((f) => !f.needCheck)?.[0];
	const crop = crops?.find((c) => c.id === exampleField?.cropId);

	const onDismiss = (): void => {
		setStepCookie({ key: onboardingKey, value: OADStepEnum.SHOW_ONBOARDING_TASKS });
		hideModal();
	};

	return (
		<BaseModal
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnText={t("button.understood")}
			hideModal={onDismiss}
			modalVisible={modalVisible}
			onConfirm={onConfirm}
			title={t(`modals.${onboardingKey}.crops.title`)}
		>
			{exampleField && (
				<Field>
					{assets[onboardingKey]}

					<ParcelSVG
						fill={hexToRGBA(crop?.color, 0.5, COLORS.LAKE[50])}
						height={32}
						path={exampleField.svg}
						stroke={hexToRGBA(crop?.color, 1, COLORS.LAKE[100])}
						width={32}
					/>
					<h4>{exampleField.name}</h4>

					<Area>
						{convertToHa(exampleField.area)} {t("units.hectare")}
					</Area>
				</Field>
			)}

			<Subline>{t(`modals.${onboardingKey}.crops.subline`)}</Subline>
		</BaseModal>
	);
};
export default OnboardingCropsModal;
