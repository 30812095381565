import { Clear, File } from "@hygo/shared/icons";
import { MouseEvent, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
	border: 1px solid var(--lake-100);
	border-radius: 4px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 80px;
	padding: 8px;
	background-color: var(--gradient-background-2);
	cursor: pointer;
`;

const Placeholder = styled.h4`
	text-align: center;
	color: var(--lake-100);
`;

const FileWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
`;

const FileName = styled.p`
	display: flex;
	align-items: center;
	color: var(--lake-100);
	gap: 10px;
`;

const Error = styled.p`
	margin-top: 8px;
	color: var(--gaspacho-100);
`;
interface ImportDropzoneProps {
	className?: string;
	file?: File;
	onChange: (file?: File) => void;
}
const ImportDropzone = ({ className, file, onChange }: ImportDropzoneProps): JSX.Element => {
	const { t } = useTranslation();
	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			onChange(acceptedFiles[0]);
		},
		[onChange]
	);
	const validator = (testedFile: File): { code: string; message: string } => {
		const acceptedExtensions = /^\S.+\S\.(edi|xml|dap)$/;
		return acceptedExtensions.test(testedFile.name)
			? null
			: {
					code: "fileType",
					message: t("screens.importTelepac.dropzone.fileTypeError")
				};
	};
	const { fileRejections, getInputProps, getRootProps } = useDropzone({
		multiple: false,
		onDrop,
		validator
	});
	const clearInput = (event: MouseEvent<SVGSVGElement>): void => {
		event.stopPropagation();
		event.preventDefault();
		onChange(undefined);
	};

	return (
		<div {...getRootProps({ style: { outline: "none" } })} className={className}>
			<Wrapper>
				<input {...getInputProps()} />
				{file ? (
					<FileWrapper>
						<FileName>
							<File height={20} width={20} /> {file?.name}
						</FileName>
						<Clear height={20} onClick={clearInput} width={20} />
					</FileWrapper>
				) : (
					<Placeholder>
						<Trans i18nKey="screens.importTelepac.dropzone.placeholder">
							Déposez votre fichier parcellaire ou cliquez ici ! <br />
							(fichier .xml ou .edi ou .dap)
						</Trans>
					</Placeholder>
				)}
			</Wrapper>
			{fileRejections.length > 0 && <Error>{fileRejections[0].errors[0].message}</Error>}
		</div>
	);
};

export default ImportDropzone;
