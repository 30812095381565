import { svgMinifier } from "@hygo/shared/utils";
import styled from "styled-components";

const SVGWrapper = styled.svg<{ height: number; width: number }>`
	min-width: ${(props) => props.width}px;
	min-height: ${(props) => props.height}px;
`;

interface ParcelSVGProps {
	className?: string;
	fill: string;
	height: number;
	path: string;
	stroke: string;
	width: number;
}
const ParcelSVG = ({ className, fill, height, path, stroke, width }: ParcelSVGProps): JSX.Element => {
	const { minifiedPath, viewport } = svgMinifier(path);
	return viewport !== "" ? (
		<SVGWrapper className={className} height={height} viewBox={viewport} width={width}>
			<path d={minifiedPath} fill={fill} stroke={stroke} strokeWidth={1} vectorEffect="non-scaling-stroke" />
		</SVGWrapper>
	) : null;
};

export default ParcelSVG;
