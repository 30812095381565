import { AmplitudeContext } from "@hygo/shared/amplitude";
import { Barn, Pencil, Plus, Trash } from "@hygo/shared/icons";
import { ModalsContext } from "@hygo/shared/modals";
import { Farm, FieldsManagerEvents, SnackbarType } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { COLORS } from "@hygo/shared/utils";
import { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ConfirmationModal from "../modals/ConfirmationModal/ConfirmationModal";
import FarmModal from "../modals/FarmModal";
import SelectInput from "../SelectInput";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	gap: 2px;
`;

const EditButton = styled.div`
	height: 40px;
	width: 40px;
	background-color: var(--night-100);
	display: flex;
	align-items: center;
	justify-content: center;
	&:last-child {
		border-radius: 0px 4px 4px 0px;
	}
`;

const AddFarmButton = styled.div`
	cursor: pointer;
	display: flex;
	border: 1px solid var(--night-100) !important;
	color: var(--white-100);
	border-radius: 4px;
	display: flex;
	width: 100%;
	align-items: center;
	background: var(--night-100);
	padding: 0 8px;
	height: 40px;
	gap: 8px;
`;

interface FarmSelectorProps {
	createFarm?: (data: { name: string }) => Promise<{ id: number }>;
	crudActions: boolean;
	defaultFarm: Farm;
	deleteFarm?: (farmId: number) => Promise<void>;
	farms: Farm[];
	loadFarms?: () => Promise<Farm[]>;
	updateDefaultFarm: (farmId: number, newFarms: Farm[], handleNewFields: boolean) => Promise<void>;
	updateFarm?: (farm: Farm) => Promise<void>;
}

const FarmSelector = ({
	createFarm,
	crudActions,
	defaultFarm,
	deleteFarm,
	farms,
	loadFarms,
	updateDefaultFarm,
	updateFarm
}: FarmSelectorProps): JSX.Element => {
	const { t } = useTranslation();

	const borderRadius = crudActions && defaultFarm ? "4px 0px 0px 4px" : "4px";
	const { showModal } = useContext(ModalsContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { showSnackbar } = useContext(SnackbarContext);
	const defaultValues = useMemo(() => ({ defaultFarm: defaultFarm?.id?.toString() }), [defaultFarm]);
	const methods = useForm({ defaultValues });

	useEffect(() => {
		methods.reset(defaultValues);
	}, [defaultValues, methods]);

	const onChange = async ({ value }: { value: string }): Promise<void> => {
		if (value) {
			await updateDefaultFarm(parseInt(value, 10), null, true);
		}
	};

	const openUpdateModal = (farm: Farm): void => {
		showModal(FarmModal, {
			farm,
			onSubmit: async ({ name }) => {
				await updateFarm({ ...farm, name });
				logAnalyticEvent(FieldsManagerEvents.updateFarm, { farm: name });
				const newFarms = await loadFarms();
				await updateDefaultFarm(farm?.id, newFarms, false);
			}
		});
	};

	const openCreateModal = (): void => {
		showModal(FarmModal, {
			onSubmit: async ({ name }) => {
				const createdFarm = await createFarm({ name });
				logAnalyticEvent(FieldsManagerEvents.createFarm, { farm: name });
				const newFarms = await loadFarms();
				await updateDefaultFarm(createdFarm?.id, newFarms, true);
			}
		});
	};

	const openDeleteModal = (farm: Farm): void => {
		showModal(ConfirmationModal, {
			btnColorPalette: COLORS.GASPACHO,
			confirmLabel: t("button.delete"),
			dismissLabel: t("button.cancel"),
			entity: {
				Icon: <Barn fill={COLORS.NIGHT[100]} height={42} width={42} />,
				name: farm?.name
			},
			handleConfirm: async () => {
				try {
					await deleteFarm(defaultFarm?.id);
					const newFarms = await loadFarms();
					await updateDefaultFarm(newFarms.find((f) => f.id !== farm?.id)?.id, newFarms, true);
					logAnalyticEvent(FieldsManagerEvents.deleteFarm, { farm: farm.name });
					showSnackbar(t("snackbar.deleteFarm.success"), SnackbarType.SUCCESS);
				} catch (e) {
					showSnackbar(t("snackbar.deleteFarm.error"), SnackbarType.ERROR);
					throw e;
				}
			},
			title: t("modals.farmDelete.title")
		});
	};

	return (
		<Wrapper>
			{defaultFarm ? (
				<SelectInput
					borderRadius={borderRadius}
					control={methods.control}
					ctaAction={
						crudActions && { label: t("components.farmSelector.createFarm"), onClick: openCreateModal }
					}
					initialOptions={farms?.map((f) => ({
						Icon: <Barn fill={COLORS.WHITE[100]} height={20} width={20} />,
						label: f.name,
						value: f.id.toString()
					}))}
					name="defaultFarm"
					onCustomChange={onChange}
					placeholder={t("components.farmSelector.placeholder")}
					theme="dark"
				/>
			) : (
				<AddFarmButton onClick={openCreateModal}>
					<Plus fill={COLORS.WHITE[100]} height={20} width={20} />
					<h4>{t("components.farmSelector.createFarm")}</h4>
				</AddFarmButton>
			)}

			{defaultFarm && crudActions && (
				<>
					<EditButton onClick={() => openUpdateModal(defaultFarm)}>
						<Pencil fill="white" width={17} />
					</EditButton>

					<EditButton onClick={() => openDeleteModal(defaultFarm)}>
						<Trash fill="white" width={17} />
					</EditButton>
				</>
			)}
		</Wrapper>
	);
};

export default FarmSelector;
