import { Crop, Field } from "@hygo/shared/models";
import { ImportedField } from "@hygo/web/models";
import { Fragment } from "react";
import styled from "styled-components";

import Checkbox from "../../Checkbox";
import FieldCard from "./FieldCard";
import FieldSelector from "./FieldSelector";
import Toast from "./Toast";

interface RowProps {
	crops: Crop[];
	cropSynchronize: boolean;
	field: ImportedField;
	fields: Field[];
	link: { fieldId: number; importedFieldId: string; selected: boolean };
	onChange: (data: { fieldId: number; importedFieldId: string }) => void;
	onClickCheckbox: (importedFieldId: string) => void;
}

const StyledCheckbox = styled(Checkbox)`
	width: 16px;
	align-self: center;
	height: 16px;
`;

const GreyCard = styled.div`
	padding: 8px;
	background-color: var(--smoke-100);
	border-radius: 8px;
	border: 1px solid var(--night-5);
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
`;

const FieldGreyCard = styled(GreyCard)`
	grid-column: span 2;
`;

const Row = ({ crops, cropSynchronize, field, fields, link, onChange, onClickCheckbox }: RowProps): JSX.Element => {
	const selectedField = fields?.find((f) => f.id === link?.fieldId);
	const isDifferentCrop = selectedField?.cropId !== field?.cropId;

	const getToastType = (): "fusion" | "info" | "neutral" | "rotation" => {
		if (!link.selected) return "neutral";
		if (link.fieldId && isDifferentCrop && cropSynchronize) return "rotation";
		return link.fieldId ? "fusion" : "info";
	};

	return (
		<Fragment>
			<StyledCheckbox checked={link.selected} onClick={() => onClickCheckbox(field.id)} />
			<FieldGreyCard>
				<FieldCard crops={crops} field={field} />
				<FieldSelector
					crops={crops}
					fields={fields}
					isSelected={link.selected}
					onChange={(fieldId) =>
						onChange({
							fieldId,
							importedFieldId: field.id
						})
					}
					selectedField={fields?.find((f) => f.id === link?.fieldId)}
				/>
			</FieldGreyCard>

			<Toast type={getToastType()} />
		</Fragment>
	);
};

export default Row;
