import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
	display: none;
	height: 100%;
	background: var(--gradient-background-1);
	flex-direction: column;

	@media (max-width: 740px) {
		display: flex;
	}
`;

const ImageWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 2;
	margin: 0 16px;
`;

const Steps = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	gap: 16px;
`;

const StepImage = styled.img`
	width: 40px;
	height: 40px;
`;

const LakeSpan = styled.span`
	color: var(--lake-100);
`;

const Content = styled.div`
	flex: 1;
	background-color: var(--white-100);
	border-radius: 16px 16px 0 0;
	padding: 16px;
`;

const Subtitle = styled.h4`
	color: var(--night-50);
	margin-bottom: 16px;
`;

const OnlyDesktop = (): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<ImageWrapper>
				<img alt="Application sur ordinateur" src="/assets/images/errors/desktop-only.png" />
			</ImageWrapper>
			<Content>
				<h2>{t("components.onlyDesktop.title")}</h2>
				<Subtitle>{t("components.onlyDesktop.subtitle")}</Subtitle>
				<Steps>
					<StepImage alt="Personne sur son ordinateur" src={"/assets/images/oad/mileos/computer.png"} />
					<h4>{t("components.onlyDesktop.steps.1")}</h4>
				</Steps>
				<Steps>
					<StepImage alt="app.alvie.fr" src={"/assets/images/home/plan-step-2.png"} />
					<Trans i18nKey="components.onlyDesktop.steps.2">
						<h4>
							Login from <LakeSpan>app.alvie.fr</LakeSpan> with your email and your password
						</h4>
					</Trans>
				</Steps>
			</Content>
		</Wrapper>
	);
};

export default OnlyDesktop;
