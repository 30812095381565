import { ExportTasksModalPropsType } from "@hygo/shared/modals";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import CardWithImage from "../../CardWithImage";
import SimpleButton from "../../SimpleButton";
import TooltipBubble from "../../TooltipBubble";
import BaseModal from "../BaseModal";

const Link = styled(SimpleButton)`
	text-align: center;
	width: unset !important;
`;

const CardsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	margin-bottom: 16px;
	text-decoration: none;
`;

const StyledToolTipBubble = styled(TooltipBubble)`
	bottom: -30px;
	right: 10px;
`;

const CardWithTooltip = styled(CardWithImage)<{ $isGeofoliaExportable: boolean }>`
	${(props) =>
		!props.$isGeofoliaExportable &&
		css`
			position: relative;
			pointer-events: unset;
			cursor: unset;
			&:hover {
				background-color: unset;
				${StyledToolTipBubble} {
					display: block;
				}
			}
		`}
`;

const ExportTasksModal = ({
	hideModal,
	isGeofoliaExportable,
	modalVisible,
	onConfirm
}: ExportTasksModalPropsType): JSX.Element => {
	const { i18n, t } = useTranslation();

	const onClick = (format: "dap" | "pdf" | "xlsx"): void => {
		onConfirm(format);
		hideModal();
	};

	const onClickHelp = (): void => {
		window.open(
			`https://support.alvie.fr/${i18n.resolvedLanguage}/articles/8703395-exportez-vos-interventions-vers-geofolia`,
			"_blank"
		);
	};

	return (
		<BaseModal hideModal={hideModal} modalVisible={modalVisible} title={t("modals.exportTasks.title")}>
			<CardsWrapper>
				<CardWithImage
					asset={{ image: "/assets/images/tasks/export-pdf.png" }}
					imageWidth={155}
					onClick={() => onClick("pdf")}
					title={t("modals.exportTasks.pdf.title")}
				/>
				<CardWithImage
					asset={{ image: "/assets/images/tasks/export-excel.png" }}
					imageWidth={155}
					onClick={() => onClick("xlsx")}
					title={t("modals.exportTasks.excel.title")}
				/>

				<CardWithTooltip
					$isGeofoliaExportable={isGeofoliaExportable}
					asset={{ image: "/assets/images/tasks/export-edi.png" }}
					disabled={!isGeofoliaExportable}
					imageWidth={155}
					onClick={() => onClick("dap")}
					title={t("modals.exportTasks.edi.title")}
				>
					{isGeofoliaExportable && <StyledToolTipBubble reverse text={t("modals.exportTasks.edi.tooltip")} />}
				</CardWithTooltip>
			</CardsWrapper>

			<Link onClick={onClickHelp} text={t("modals.exportTasks.linkToTutorial")} />
		</BaseModal>
	);
};
export default ExportTasksModal;
