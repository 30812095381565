import { UserContext } from "@hygo/shared/contexts";
import { DenormalizedField, DenormalizedTask, Tank } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { Accordion, AccordionContent, AccordionTitle } from "@hygo/web/ui-components";
import { useContext, useState } from "react";
import styled from "styled-components";

import AccordionDetails from "./AccordionDetails";
import AccordionSummary from "./AccordionSummary";

interface TaskCardProps {
	exportMode: boolean;
	onClickCheckbox: (checked: boolean, taskId: number) => void;
	onDeleteTask: (taskId: number) => void;
	onOpenTaskDetails: (data: { productIds: string[]; targetIds: number[] }) => Promise<Tank>;
	selectedFields: DenormalizedField[];
	selectedTaskIds: number[];
	task: DenormalizedTask;
}

const Wrapper = styled.div`
	margin-bottom: 16px;
`;

const StyledAccordion = styled(Accordion)<{ checked: boolean }>`
	border: 1px solid ${(props) => (props.checked ? "var(--lake-100)" : "var(--night-5)")};
	${AccordionContent}[aria-expanded="false"] {
		border-top: 1px solid var(--night-5);
	}
	${AccordionTitle} {
		gap: 16px;
	}
`;

const TaskCard = ({
	exportMode,
	onClickCheckbox,
	onDeleteTask,
	onOpenTaskDetails,
	selectedFields,
	selectedTaskIds,
	task
}: TaskCardProps): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);
	const [tankIndications, setTankIndications] = useState<Tank>();
	const { crops, user } = useContext(UserContext);

	const {
		debit,
		endTime,
		fields,
		id,
		notes,
		nozzle,
		productFamily,
		products,
		realCondition,
		smagStatus,
		sprayer,
		startTime,
		targets
	} = task;

	const checked = selectedTaskIds?.includes(id);

	const onOpenDetails = async (): Promise<void> => {
		if (tankIndications) return;
		setLoading(true);
		const fetchedTankIndications = await onOpenTaskDetails({
			productIds: products.map((p) => p.id),
			targetIds: targets?.map((t) => t.id)
		});
		setTankIndications(fetchedTankIndications);
		setLoading(false);
	};

	return (
		<Wrapper>
			<StyledAccordion
				checked={checked}
				chevronColor={COLORS.LAKE[100]}
				innerContentBackground="var(--gradient-light-grey)"
				onOpen={onOpenDetails}
				summary={
					<AccordionSummary
						checked={checked}
						condition={realCondition}
						countryCode={null}
						crops={crops}
						endTime={endTime}
						exportMode={exportMode}
						fields={fields}
						id={id}
						isTask
						onClickCheckbox={onClickCheckbox}
						productFamily={productFamily}
						selectedFields={selectedFields}
						smagStatus={smagStatus}
						startTime={startTime}
					/>
				}
			>
				<AccordionDetails
					crops={crops}
					debit={debit}
					endTime={null}
					fields={fields}
					id={id}
					isTask
					loading={loading}
					notes={notes}
					nozzle={nozzle}
					onDelete={onDeleteTask}
					products={products}
					quantity={null}
					selectedFields={selectedFields}
					sprayer={sprayer}
					startTime={null}
					tankIndications={tankIndications}
					targets={targets}
					user={user}
				/>
			</StyledAccordion>
		</Wrapper>
	);
};

export default TaskCard;
