import { AppStoreIcon, GooglePlayIcon } from "@hygo/shared/icons";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

const DownloadAppWrapper = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`;

const Picture = styled.img``;

const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
`;

const Title = styled.h1``;

const QRCode = styled(Picture)`
	margin: 16px 0;
	width: 150px;
`;

const Column = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const DownloadButton = styled.a`
	display: flex;
	cursor: pointer;
	text-decoration: none;
	align-items: center;
	justify-content: center;
	background-color: black;
	gap: 6px;
	border-radius: 10px;
	color: var(--white-100);
	height: 40px;
	border: solid 1px #a6a6a6;
`;

const GoogleButton = styled(DownloadButton)`
	width: 140px;
`;

const AppleButton = styled(DownloadButton)`
	width: 130px;
`;

const TextGoogleSmall = styled.p`
	font-family: "Google Sans";
	font-size: 9px;
	line-height: 9px;
`;

const TextGoogleBig = styled(TextGoogleSmall)`
	font-size: 17px;
	line-height: 17px;
`;

const TextAppleSmall = styled.p`
	font-family: "SF Pro";
	font-size: 10px;
	line-height: 10px;
`;

const TextAppleBig = styled(TextAppleSmall)`
	font-size: 18px;
	line-height: 18px;
`;

const TextWrapper = styled.div``;

const DownloadApp = (): JSX.Element => {
	const { i18n, t } = useTranslation();
	return (
		<DownloadAppWrapper>
			<Column>
				<img
					alt="AgroMeteo + - Aperçu"
					src={`assets/images/pricing/agrometeo/iphone-${i18n.resolvedLanguage}.png`}
				/>
			</Column>
			<Column>
				<Title>{t("screens.pricing.downloadApp")}</Title>

				<QRCode alt="QRCode - Téléchargement app" src="assets/images/pricing/agrometeo/qrcode.png" />

				<ButtonsWrapper>
					<AppleButton href="https://apps.apple.com/us/app/hygo/id1494676431?itsct=apps_box_badge&amp;itscg=30200">
						<AppStoreIcon height={24} width={24} />
						<TextWrapper>
							<Trans i18nKey={"screens.pricing.components.downloadApp.appStoreBtn"}>
								<TextAppleSmall>Download on</TextAppleSmall>
								<TextAppleBig>the App Store</TextAppleBig>
							</Trans>
						</TextWrapper>
					</AppleButton>
					<GoogleButton href="https://play.google.com/store/apps/details?id=com.alvie.hygo&hl=ln&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
						<GooglePlayIcon height={24} width={24} />
						<TextWrapper>
							<Trans i18nKey={"screens.pricing.components.downloadApp.googePlayBtn"}>
								<TextGoogleSmall>GET IT ON</TextGoogleSmall>
								<TextGoogleBig>Google Play</TextGoogleBig>
							</Trans>
						</TextWrapper>
					</GoogleButton>
				</ButtonsWrapper>
			</Column>
		</DownloadAppWrapper>
	);
};

export default DownloadApp;
