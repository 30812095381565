import { CheckboxChecked, CheckboxUndeterminate } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { MouseEvent } from "react";
import styled from "styled-components";

interface CheckboxProps {
	checked: boolean;
	className?: string;
	isIndeterminate?: boolean;
	onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const Wrapper = styled.div`
	cursor: pointer;
	width: 24px;
	overflow: hidden;
	height: 24px;
	border-radius: 4px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
	border: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const StyledCheckbox = styled.div<{ $isIndeterminate: boolean; checked: boolean }>`
	width: inherit;
	height: inherit;
	background-color: var(--${(props) => (props.$isIndeterminate || props.checked ? "none" : "white-100")});
	border: ${(props) => (props.$isIndeterminate || props.checked ? "none" : "2px solid var(--night-10)")};
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Checkbox = ({ checked, className, isIndeterminate, onClick }: CheckboxProps): JSX.Element => {
	const getCheckboxIcon = (): JSX.Element => {
		if (checked) return <CheckboxChecked fill={COLORS.LAKE[100]} height={24} width={24} />;
		if (isIndeterminate) return <CheckboxUndeterminate fill={COLORS.LAKE[100]} height={24} width={24} />;
		return null;
	};

	return (
		<Wrapper className={className} onClick={onClick}>
			<HiddenCheckbox defaultChecked={checked} />
			<StyledCheckbox $isIndeterminate={isIndeterminate} checked={checked}>
				{getCheckboxIcon()}
			</StyledCheckbox>
		</Wrapper>
	);
};

export default Checkbox;
