import styled from "styled-components";

interface FormHeaderProps {
	backIcon?: JSX.Element;
	onGoBack?: () => void;
	subTitle?: string;
	subtitleIcon?: JSX.Element;
	title: string;
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	&::after {
		flex: 1;
		content: "";
	}
`;

const Subtitle = styled.h4`
	display: flex;
	align-items: center;
	gap: 4px;
	justify-content: center;
`;

const Left = styled.div`
	flex: 1;
	cursor: pointer;
	@media (max-width: 740px) {
		* {
			display: none;
		}
	}
`;

const Center = styled.div`
	text-align: center;
`;

const FormHeader = ({ backIcon, onGoBack, subTitle, subtitleIcon, title }: FormHeaderProps): JSX.Element => {
	return (
		<Wrapper>
			{backIcon && onGoBack ? (
				<Left onClick={onGoBack} onKeyDown={onGoBack} role="button" tabIndex={0}>
					{backIcon}
				</Left>
			) : (
				<Left />
			)}
			<Center>
				<h2>{title}</h2>
				{subTitle && (
					<Subtitle>
						{subTitle}
						{subtitleIcon}
					</Subtitle>
				)}
			</Center>
		</Wrapper>
	);
};

export default FormHeader;
