import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import {
	ArvalisDevice,
	ArvalisDeviceEvents,
	ArvalisDeviceSelectorEvents,
	BaseArvalisDevice,
	Field,
	MileosInfos,
	MileosVariety,
	SnackbarType
} from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { addDays, getStartOfDayAsJSDate, substractDays } from "@hygo/shared/utils";
import { t } from "i18next";
import { useCallback, useContext, useState } from "react";
import { RegisterOptions, useForm, UseFormReturn } from "react-hook-form";

interface useMileosSetupProps {
	field: Field;
}

interface useMileosSetupResult {
	arvalisDevice: ArvalisDevice;
	arvalisDeviceRules: Record<keyof BaseArvalisDevice, RegisterOptions>;
	getValuesOfPhase: (d: { index: number; nextPhaseDate: string; previousPhaseDate: string }) => {
		disabled: boolean;
		maximumDate: Date;
		minimumDate: Date;
	};
	handleDeleteDevice: (deviceId: number, callback: () => Promise<void>) => Promise<void>;
	handleDeviceCreationErrors: (error: { response: { data: { code: string } } }) => void;
	handleSelectDevice: (d: { deviceId: number }, callback: () => Promise<void>) => Promise<void>;
	loading: boolean;
	loadMileosInfos: () => Promise<void>;
	methods: UseFormReturn<BaseArvalisDevice>;
	mileosFieldSettingsRules: { varietyId: RegisterOptions; yieldValue: RegisterOptions };
	mileosInfos: MileosInfos;
	mileosVariety: MileosVariety;
	onAddDevice: (values: BaseArvalisDevice, callback: (id: number) => Promise<void>) => Promise<{ id: number }>;
}

export const useMileosSetup = ({ field }: useMileosSetupProps): useMileosSetupResult => {
	const [loading, setLoading] = useState<boolean>(true);
	const { arvalisDevices, defaultFarm, loadArvalisDevices, mileosVarieties, user } = useContext(UserContext);
	const { createArvalisDevice, deleteArvalisDevice, getMileosInfos, patchMileosField } = useApi();
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const [mileosInfos, setMileosInfos] = useState<MileosInfos>(null);
	const mileosVariety = mileosVarieties?.find((v) => v.id === mileosInfos?.varietyId);
	const arvalisDevice = arvalisDevices?.find((d) => d.id === mileosInfos?.deviceId);
	const { showSnackbar } = useContext(SnackbarContext);
	const methods = useForm<BaseArvalisDevice>({
		mode: "all"
	});
	const loadMileosInfos: useMileosSetupResult["loadMileosInfos"] = useCallback(async () => {
		if (!defaultFarm || !field?.mileosStatus) return;
		setLoading(true);
		try {
			const fetchedMileosInfos = await getMileosInfos({ farmId: defaultFarm.id, fieldId: field?.id });
			setMileosInfos(fetchedMileosInfos);
		} finally {
			setLoading(false);
		}
	}, [field, defaultFarm, getMileosInfos]);

	const handleDeleteDevice: useMileosSetupResult["handleDeleteDevice"] = async (deviceId, callback) => {
		try {
			await deleteArvalisDevice(deviceId);
			logAnalyticEvent(ArvalisDeviceSelectorEvents.deleteStation);
			await callback();
			await loadArvalisDevices();
			showSnackbar(t("snackbar.deleteArvalisDevice.success"), SnackbarType.SUCCESS);
		} catch (e) {
			showSnackbar(t("snackbar.deleteArvalisDevice.error"), SnackbarType.ERROR);
			throw e;
		}
	};

	const handleDeviceCreationErrors: useMileosSetupResult["handleDeviceCreationErrors"] = (e) => {
		if (e?.response?.data?.code === "deviceAlreadyExists")
			methods.setError("deviceCode", {
				message: t("inputs.arvalisDeviceCode.errors.deviceAlreadyExists"),
				type: "custom"
			});
		else if (e?.response?.data?.code === "deviceUnknown")
			methods.setError("deviceCode", {
				message: t("inputs.arvalisDeviceCode.errors.deviceUnknown"),
				type: "custom"
			});
		else if (e?.response?.data?.code === "deviceNotConnected")
			methods.setError("deviceCode", {
				message: t("inputs.arvalisDeviceCode.errors.deviceNotConnected"),
				type: "custom"
			});
		else {
			showSnackbar(t("snackbar.createArvalisDevice.error"), SnackbarType.ERROR);
			throw e;
		}
	};

	const getValuesOfPhase: useMileosSetupResult["getValuesOfPhase"] = ({
		index,
		nextPhaseDate,
		previousPhaseDate
	}) => {
		return {
			disabled: !previousPhaseDate && index > 0,
			maximumDate: nextPhaseDate
				? substractDays(getStartOfDayAsJSDate(new Date(nextPhaseDate)), 1)
				: user?.setup?.mileosCampaignEnd,
			minimumDate: previousPhaseDate
				? addDays(getStartOfDayAsJSDate(new Date(previousPhaseDate)), 1)
				: user?.setup?.mileosCampaignStart
		};
	};

	const onAddDevice: useMileosSetupResult["onAddDevice"] = async ({ constructorName, deviceCode }, callback) => {
		const createdDevice = await createArvalisDevice({
			constructorName,
			deviceCode
		});
		logAnalyticEvent(ArvalisDeviceEvents.addStation);
		await patchMileosField({
			farmId: defaultFarm.id,
			fieldId: field?.id,
			mileosInfos: { deviceId: createdDevice?.id }
		});
		await loadArvalisDevices();
		await callback(createdDevice?.id);
		showSnackbar(t("snackbar.createArvalisDevice.success"), SnackbarType.SUCCESS);
		return createdDevice;
	};

	const handleSelectDevice: useMileosSetupResult["handleSelectDevice"] = async ({ deviceId }, callback) => {
		try {
			await patchMileosField({
				farmId: defaultFarm.id,
				fieldId: field?.id,
				mileosInfos: { deviceId: deviceId || null }
			});
			logAnalyticEvent(ArvalisDeviceSelectorEvents.updateStation);
			await callback();
			showSnackbar(t("snackbar.selectArvalisDevice.success"), SnackbarType.SUCCESS);
		} catch (e) {
			showSnackbar(t("snackbar.selectArvalisDevice.error"), SnackbarType.ERROR);
			throw e;
		}
	};

	const arvalisDeviceRules = {
		constructorName: {
			required: {
				message: t("inputs.arvalisDeviceConstructor.errors.required"),
				value: true
			}
		},
		deviceCode: {
			required: {
				message: t("inputs.arvalisDeviceCode.errors.required"),
				value: true
			}
		}
	};
	const mileosFieldSettingsRules = {
		varietyId: {
			required: {
				message: t("inputs.mileosVariety.errors.required"),
				value: true
			}
		},
		yieldValue: {
			required: {
				message: t("inputs.mileosYieldValue.errors.required"),
				value: true
			},
			setValueAs: (v: string) => Number(v) || v,
			validate: (v: number) => {
				return v >= 0 || t("inputs.mileosYieldValue.errors.invalid");
			}
		}
	};

	return {
		arvalisDevice,
		arvalisDeviceRules,
		getValuesOfPhase,
		handleDeleteDevice,
		handleDeviceCreationErrors,
		handleSelectDevice,
		loading,
		loadMileosInfos,
		methods,
		mileosFieldSettingsRules,
		mileosInfos,
		mileosVariety,
		onAddDevice
	};
};
