import { SetupError } from "@hygo/shared/models";

interface ErrorContent {
	image: string;
	onClick: () => void;
}

export const httpErrors: {
	[key: number]: ErrorContent;
} = {
	404: {
		image: "/assets/images/errors/error-background.png",
		onClick: () => {
			window.location.href = "/";
		}
	},
	449: {
		image: "/assets/images/errors/error-background.png",
		onClick: () => {
			window.location.href = "/";
		}
	},
	500: {
		image: "/assets/images/errors/error-background.png",
		onClick: () => window.location.reload()
	}
};

export const setupErrors: { [key in SetupError]: ErrorContent } = {
	[SetupError.defaultFarmWithoutFields]: null,
	[SetupError.equipmentMissing]: null,
	[SetupError.needUpdateVersion]: null,
	[SetupError.noDefaultFarm]: null,
	[SetupError.noNetwork]: null,
	[SetupError.noPlan]: null,
	[SetupError.noWeather]: null,
	[SetupError.ongoingMaintenance]: {
		image: "/assets/images/errors/error-maintenance.png",
		onClick: () => {
			window.location.href = "/";
		}
	}
};
