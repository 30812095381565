import { createGlobalStyle } from "styled-components";

const styled = { createGlobalStyle };

const Typography = styled.createGlobalStyle`
	@font-face {
		font-family: "Google Sans";
		src: url("/assets/fonts/Google-Sans.woff2") format("woff2");
		font-weight: normal;
		font-style: normal;
		font-display: fallback;
	}

	@font-face {
		font-family: "Nunito Bold";
		src: url("/assets/fonts/Nunito-Bold.woff2") format("woff2");
		font-weight: normal;
		font-style: normal;
		font-display: fallback;
	}

	@font-face {
		font-family: "Nunito Regular";
		src: url("/assets/fonts/Nunito-Regular.woff2") format("woff2");
		font-weight: normal;
		font-style: normal;
		font-display: fallback;
	}

	@font-face {
		font-family: "Nunito SemiBold";
		src: url("/assets/fonts/Nunito-SemiBold.woff2") format("woff2");
		font-weight: normal;
		font-style: normal;
		font-display: fallback;
	}

	@font-face {
		font-family: "Avenir Black";
		src: url("/assets/fonts/Avenir-Black.woff2") format("woff2");
		font-weight: normal;
		font-style: normal;
		font-display: fallback;
	}

	@font-face {
		font-family: "SF Pro";
		src: url("/assets/fonts/SF-pro.woff2") format("woff2");
		font-weight: normal;
		font-style: normal;
		font-display: fallback;
	}

	html {
		font-family:
			Nunito Regular,
			-apple-system,
			BlinkMacSystemFont,
			"Segoe UI",
			Roboto,
			Oxygen,
			Ubuntu,
			Cantarell,
			"Open Sans",
			"Helvetica Neue",
			sans-serif;
		color: var(--night-100);
	}

	h1 {
		font-family: "Avenir Black";
		font-size: var(--36px);
	}

	h2 {
		font-family: "Avenir Black";
		font-size: var(--24px);
	}

	h3 {
		font-family: "Avenir Black";
		font-size: var(--20px);
	}

	h4 {
		font-family: "Nunito Bold";
		font-size: var(--14px);
	}

	h5 {
		font-family: "Nunito SemiBold";
		font-size: var(--12px);
	}

	p {
		font-family: "Nunito Regular";
		font-size: var(--12px);
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: normal;
	}

	@media (max-width: 740px) {
		h1 {
			font-size: var(--24px);
		}
		h2 {
			font-size: var(--20px);
		}
		h3 {
			font-size: var(--16px);
		}
		h4 {
			font-size: var(--16px);
		}
		h5 {
			font-size: var(--14px);
		}
		h6 {
			font-size: var(--14px);
		}
		p {
			font-size: var(--14px);
		}
	}
`;

export default Typography;
