import { UserContext } from "@hygo/shared/contexts";
import { UserSmagStatus } from "@hygo/shared/models";
import { OADKeyEnum, OADStepEnum } from "@hygo/web/models";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

interface OADContextResult {
	geofoliaOnboardingStep: OADStepEnum;
	getOnboardingKeyCrops: () => OADKeyEnum;
	getOnboardingKeyTasks: () => OADKeyEnum;
	loggedInSmag: boolean;
	setStepCookie: (data: { key: OADKeyEnum; value?: OADStepEnum }) => void;
	smagOnboardingStep: OADStepEnum;
}

export const OADContext = createContext({} as OADContextResult);

interface OADProviderProps {
	children: JSX.Element | JSX.Element[];
}

const OADProvider = ({ children }: OADProviderProps): JSX.Element => {
	const [smagOnboardingStep, setSmagOnboardingStep] = useState<OADContextResult["smagOnboardingStep"]>();
	const [geofoliaOnboardingStep, setGeofoliaOnboardingStep] = useState<OADContextResult["geofoliaOnboardingStep"]>();
	const { activeFields, user } = useContext(UserContext);

	const loggedInSmag = user?.smagStatus !== UserSmagStatus.NO_TOKEN;
	const hasSmagFields = loggedInSmag && activeFields?.some((f) => f.smagCropZoneUid);
	const hasGeofoliaFields = activeFields?.some((f) => f.geofoliaReady);

	const smagStepCookie = localStorage.getItem(OADKeyEnum.SMAG_ONBOARDING_STEP) as OADStepEnum;
	const geofoliaStepCookie = localStorage.getItem(OADKeyEnum.GEOFOLIA_ONBOARDING_STEP) as OADStepEnum;

	const setStepCookie = useCallback(({ key, value }: { key: OADKeyEnum; value?: OADStepEnum }): void => {
		localStorage.setItem(key, value || "");
		if (key === OADKeyEnum.SMAG_ONBOARDING_STEP) setSmagOnboardingStep(value);
		if (key === OADKeyEnum.GEOFOLIA_ONBOARDING_STEP) setGeofoliaOnboardingStep(value);
	}, []);

	const getOnboardingKeyCrops: OADContextResult["getOnboardingKeyCrops"] = useCallback(() => {
		if (smagOnboardingStep === OADStepEnum.SHOW_ONBOARDING_CROPS && hasSmagFields)
			return OADKeyEnum.SMAG_ONBOARDING_STEP;
		if (hasGeofoliaFields && geofoliaOnboardingStep === OADStepEnum.SHOW_ONBOARDING_CROPS)
			return OADKeyEnum.GEOFOLIA_ONBOARDING_STEP;
	}, [geofoliaOnboardingStep, hasSmagFields, hasGeofoliaFields, smagOnboardingStep]);

	const getOnboardingKeyTasks: OADContextResult["getOnboardingKeyTasks"] = useCallback(() => {
		if (loggedInSmag && smagOnboardingStep === OADStepEnum.SHOW_ONBOARDING_TASKS)
			return OADKeyEnum.SMAG_ONBOARDING_STEP;
		if (geofoliaOnboardingStep === OADStepEnum.SHOW_ONBOARDING_TASKS) return OADKeyEnum.GEOFOLIA_ONBOARDING_STEP;
	}, [geofoliaOnboardingStep, smagOnboardingStep, loggedInSmag]);

	useEffect(() => {
		setSmagOnboardingStep(smagStepCookie);
		setGeofoliaOnboardingStep(geofoliaStepCookie);
	}, [geofoliaStepCookie, smagStepCookie]);

	const value = useMemo(
		() => ({
			geofoliaOnboardingStep,
			getOnboardingKeyCrops,
			getOnboardingKeyTasks,
			loggedInSmag,
			setStepCookie,
			smagOnboardingStep
		}),
		[
			smagOnboardingStep,
			setStepCookie,
			loggedInSmag,
			geofoliaOnboardingStep,
			getOnboardingKeyCrops,
			getOnboardingKeyTasks
		]
	);

	return <OADContext.Provider value={value}>{children}</OADContext.Provider>;
};

export default OADProvider;
