export enum Soil {
	ARGILE = "ARGILE",
	ARGILE_LIMONEUSE = "ARGILE_LIMONEUSE",
	ARGILE_SABLEUX = "ARGILE_SABLEUX",
	ARGILO_CALCAIRE = "ARGILO_CALCAIRE",
	CRAIE = "CRAIE",
	LIMON_ARGILO_SABLEUX = "LIMON_ARGILO_SABLEUX",
	LIMON_FIN = "LIMON_FIN",
	LIMON_SABLEUX = "LIMON_SABLEUX",
	SABLE = "SABLE",
	SABLE_TERREAU = "SABLE_TERREAU",
	TERREAU = "TERREAU",
	TERREAU_ARGILE = "TERREAU_ARGILE"
}

export const soilList: Soil[] = Object.values(Soil);
