import { Drop } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { Fragment } from "react";
import styled from "styled-components";

import Divider from "../Divider";

interface StepperProps {
	activeStep: number;
	numberOfSteps: number;
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	max-width: 30%;
	margin: 0 auto;
	gap: 3px;
`;

const Step = styled.div<{ $completed?: boolean }>`
	width: 12px;
	height: 12px;
	background-color: ${(props) => (props.$completed ? "var(--tangerine-100)" : "var(--night-25)")};
	border-radius: 50%;
`;

const Stepper = ({ activeStep, numberOfSteps }: StepperProps): JSX.Element => {
	const getStepStatus = (step: number): JSX.Element => {
		if (activeStep === step) return <Drop />;
		if (activeStep > step) return <Step $completed />;
		return <Step />;
	};
	return (
		<Wrapper>
			{Array.from({ length: numberOfSteps }).map((_, i) => {
				const key = i;
				const step = i + 1;
				return (
					<Fragment key={key}>
						{getStepStatus(step)}
						{step < numberOfSteps && <Divider color={COLORS.NIGHT[25]} />}
					</Fragment>
				);
			})}
		</Wrapper>
	);
};

export default Stepper;
