import { COLORS } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import InputTip from "../../InputTip";

interface FieldErrorsProps {
	hasArea: number;
	hasCrop: number;
	hasName: string;
	hasZone: { lat: number; lon: number }[];
}

const StyledInputTip = styled(InputTip)`
	margin-bottom: 8px;
	&:first-of-type {
		margin-top: 4px;
	}
	&:last-of-type {
		margin-bottom: 0;
	}
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const FieldErrors = ({ hasArea, hasCrop, hasName, hasZone }: FieldErrorsProps): JSX.Element => {
	const { t } = useTranslation();
	const errors = [!hasCrop && "noCrop", !hasName && "noName", !hasArea && "noArea", !hasZone && "noZone"]?.filter(
		(e) => e
	);
	return (
		<div>
			{errors?.map((e, i) => {
				const key = `${e}-${i}`;
				return (
					<StyledInputTip key={key} palette={COLORS.DISABLED} withBorder={false}>
						<ColoredCardText>{t(`components.fieldsListCard.fieldErrors.${e}`)}</ColoredCardText>
					</StyledInputTip>
				);
			})}
		</div>
	);
};

export default FieldErrors;
