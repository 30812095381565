import { CheckmarkRounded } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface PasswordStrengthProps {
	password: string;
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 740px) {
		flex-wrap: wrap;
		gap: 8px;
	}
`;

const Rule = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const IndicatorText = styled.h5<{ $success: boolean }>`
	color: ${(props) => (props.$success ? "var(--grass-100)" : "var(--night-50)")};
`;

const PasswordStrength = ({ password }: PasswordStrengthProps): JSX.Element => {
	const { t } = useTranslation();
	const [containsUppercase, setContainsUppercase] = useState<boolean>(false); // uppercase letter
	const [containsLowercase, setContainsLowercase] = useState<boolean>(false); // lowercase letter
	const [containsDigit, setContainsDigit] = useState<boolean>(false); // number
	const [contains8Chars, setContains8Chars] = useState<boolean>(false); // min 8 characters

	const rules = [
		[t("components.passwordStrength.uppercase"), containsUppercase],
		[t("components.passwordStrength.lowercase"), containsLowercase],
		[t("components.passwordStrength.digit"), containsDigit],
		[t("components.passwordStrength.minChars"), contains8Chars]
	];

	useEffect(() => {
		const validatePassword = (): void => {
			setContainsUppercase(password.toLowerCase() !== password);
			setContainsLowercase(password.toUpperCase() !== password);
			setContainsDigit(/\d/.test(password));
			setContains8Chars(password.length >= 8);
		};
		if (password) validatePassword();
		else {
			setContainsUppercase(false);
			setContainsLowercase(false);
			setContainsDigit(false);
			setContains8Chars(false);
		}
	}, [password, containsUppercase, containsLowercase, contains8Chars, containsDigit]);

	return (
		<Wrapper>
			{rules.map((data, i) => {
				const key = `${data[0]}-${i}`;
				return (
					<Rule key={key}>
						<CheckmarkRounded
							fill={data[1] ? COLORS.GRASS[100] : COLORS.NIGHT[50]}
							stroke={data[1] ? COLORS.GRASS[100] : COLORS.NIGHT[50]}
						/>
						<IndicatorText $success={data[1] ? true : null}>{data[0]}</IndicatorText>
					</Rule>
				);
			})}
		</Wrapper>
	);
};

export default PasswordStrength;
