import { ProductFamilyIcons, WaterFill } from "@hygo/shared/icons";
import { DenormalizedProduct, Feature, ProductStatus, ProductUnit } from "@hygo/shared/models";
import { COLORS, computeModulationAverage, computeTank, convertToFloat } from "@hygo/shared/utils";
import { InputTip, TooltipBubble } from "@hygo/web/ui-components";
import { useFeature } from "flagged";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Section from "./Section";

interface ProductsSectionProps {
	debit: number;
	isPelletMixture: boolean;
	products: DenormalizedProduct[];
	totalAreaHA: number;
	waterTankBottomPercentage: number;
}

const MiddleRightColumn = styled.h5<{ $appliedModulation?: number }>`
	text-align: right;
	flex: 1;
	color: var(--night-50);
`;

const Total = styled(MiddleRightColumn)`
	color: var(--night-100);
`;

const ProductsWrapper = styled.div`
	flex: 1;
	gap: 8px;
	display: flex;
	flex-direction: column;
`;

const StyledToolTipBubble = styled(TooltipBubble)`
	top: 42px;
	z-index: 1;
	left: 9px;
`;

const AppliedModulation = styled.h5<{ $appliedModulation: number }>`
	color: ${(props) => (props.$appliedModulation ? "var(--grass-200)" : "var(--night-100)")};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const MaxModulation = styled.h5`
	color: var(--night-50);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	position: relative;
	&:hover ${StyledToolTipBubble} {
		display: block;
	}
`;

const ProductNameWrapper = styled.div`
	display: flex;
	align-items: center;
	flex: 2;
	gap: 4px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ProductName = styled.h5`
	color: var(--night-100);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Icon = styled.svg`
	min-width: 20px;
`;

const DeletedText = styled.h5`
	color: var(--night-50);
`;

const BlueCircle = styled.div`
	width: 20px;
	height: 20px;
	background-color: var(--lake-10);
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	display: flex;
`;

const Content = styled.div`
	gap: 8px;
	display: flex;
	flex-direction: column;
`;

const ModulationCardsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const ProductNumberColor = styled.h5`
	color: var(--lake-100);
`;

const ProductsSection = ({
	debit,
	isPelletMixture,
	products,
	totalAreaHA,
	waterTankBottomPercentage
}: ProductsSectionProps): JSX.Element => {
	const { t } = useTranslation();
	const {
		products: tankProducts,
		volume,
		water
	} = computeTank({ debit, isPelletMixture, products, totalAreaHA, waterTankBottomPercentage });
	const hasOptimize = useFeature(Feature.OPTIMIZE);
	const appliedModulation = computeModulationAverage(products);
	const modulableProducts = products
		?.filter((p) => p.modulationStatus === ProductStatus.MODULABLE)
		?.map((p) => p.initialModulation);
	const maxModulation = modulableProducts?.length
		? modulableProducts?.reduce((prev, curr) => prev + curr, 0) / modulableProducts?.length
		: 0;

	return (
		<Section
			Icon={<ProductFamilyIcons.OTHERS fill={COLORS.LAKE[100]} height={20} width={20} />}
			title={t("components.productsReportCard.title")}
		>
			<Content>
				{hasOptimize && !!modulableProducts?.length && (
					<ModulationCardsWrapper>
						<InputTip palette={appliedModulation ? COLORS.GRASS : COLORS.NIGHT} withBorder={false}>
							<AppliedModulation $appliedModulation={appliedModulation}>
								{t("glossary.appliedModulation", {
									appliedModulation: appliedModulation?.toFixed(0)
								})}
							</AppliedModulation>
						</InputTip>
						<InputTip palette={COLORS.DISABLED} withBorder={false}>
							<MaxModulation>
								{t("glossary.maxModulation", { maxModulation: maxModulation?.toFixed(0) })}
							</MaxModulation>
						</InputTip>
					</ModulationCardsWrapper>
				)}
				<ProductsWrapper>
					{!isPelletMixture && (
						<Row>
							<ProductNameWrapper>
								<BlueCircle>
									<ProductNumberColor>1</ProductNumberColor>
								</BlueCircle>
								<Icon as={WaterFill} fill={COLORS.NIGHT[100]} height={20} width={20} />
								<ProductName>
									{t("glossary.water").toUpperCase()}
									{waterTankBottomPercentage && ` (+${waterTankBottomPercentage}%)`}
								</ProductName>
							</ProductNameWrapper>
							<MiddleRightColumn>
								{Number.isNaN(water) ? "..." : (water / totalAreaHA).toFixed(0)}{" "}
								{ProductUnit.LITER_PER_HA}
							</MiddleRightColumn>
							<MiddleRightColumn>
								{Number.isNaN(water) ? "..." : water.toFixed(0)} {t("units.l")}
							</MiddleRightColumn>
						</Row>
					)}
					{tankProducts
						?.sort((a, b) => a.formattedIncorporationOrder - b.formattedIncorporationOrder)
						.map((p) => {
							const totalUnit = p.unit.split("/")[0];
							const ProductIcon = ProductFamilyIcons[p.productFamily];
							return (
								<Row key={p.id}>
									<StyledToolTipBubble text={p?.name?.toUpperCase()} />
									<ProductNameWrapper>
										<BlueCircle>
											<ProductNumberColor>{p.formattedIncorporationOrder}</ProductNumberColor>
										</BlueCircle>
										<Icon as={ProductIcon} fill={COLORS.NIGHT[100]} height={20} width={20} />
										<ProductName>{p.name.toUpperCase()}</ProductName>
										{p?.deleted && <DeletedText>{t("glossary.deleted")}</DeletedText>}
									</ProductNameWrapper>
									{appliedModulation && p.initialDosePerHA ? (
										<InputTip palette={COLORS.GRASS} withBorder={false}>
											<AppliedModulation $appliedModulation={appliedModulation}>
												{Number.isNaN(p.realDosePerHA) || !p.realDosePerHA
													? "..."
													: convertToFloat(p.realDosePerHA?.toFixed(3))}{" "}
												{t(`units.${p.unit}`)}
											</AppliedModulation>
										</InputTip>
									) : (
										<MiddleRightColumn>
											{Number.isNaN(p.realDosePerHA) || !p.realDosePerHA
												? "..."
												: convertToFloat(p.realDosePerHA?.toFixed(3))}{" "}
											{t(`units.${p.unit}`)}
										</MiddleRightColumn>
									)}

									<MiddleRightColumn>
										{Number.isNaN(p.realDosePerHA) || !p.realDosePerHA
											? "..."
											: convertToFloat((p.realDosePerHA * totalAreaHA)?.toFixed(2))}{" "}
										{t(`units.${totalUnit}`)}
									</MiddleRightColumn>
								</Row>
							);
						})}
					<Row>
						<ProductNameWrapper />
						<Total>{t("glossary.total")}</Total>
						<Total>
							{volume.toFixed(0)} {isPelletMixture ? t("units.kg") : t("units.l")}
						</Total>
					</Row>
				</ProductsWrapper>
			</Content>
		</Section>
	);
};

export default ProductsSection;
