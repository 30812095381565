export enum OAD {
	AVIZIO = "AVIZIO",
	BSV = "BSV",
	CRISTALCERCOAD = "CRISTAL_UNION",
	GEOFOLIA = "GEOFOLIA",
	JOHNDEERE = "JOHNDEERE",
	MESPARCELLES = "MESPARCELLES",
	MILEOS = "MILEOS",
	SENCROP = "SENCROP",
	SKYFLD = "SKYFLD",
	SMAG = "SMAG",
	WEENAT = "WEENAT",
	WIUZ = "WIUZ",
	XARVIO = "XARVIO"
}
