import { COLORS } from "@hygo/shared/utils";
import { Accordion } from "@hygo/web/ui-components";
import styled from "styled-components";

export interface AccordionCardProps {
	body: string;
	image: string;
	title: string;
}

const AccordionSummary = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const StyledAccordion = styled(Accordion)`
	background-color: var(--smoke-100);
	height: fit-content;
`;

const Image = styled.img`
	width: 42px;
	height: 42px;
`;

const Title = styled.h4`
	flex: 1;
`;

const AccordionCard = ({ body, image, title }: AccordionCardProps): JSX.Element => {
	return (
		<StyledAccordion
			chevronColor={COLORS.LAKE[100]}
			summary={
				<AccordionSummary>
					<Image alt={title} src={image} />
					<Title>{title}</Title>
				</AccordionSummary>
			}
		>
			<h5>{body}</h5>
		</StyledAccordion>
	);
};

export default AccordionCard;
