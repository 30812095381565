import { UserContext } from "@hygo/shared/contexts";
import { useSprayer } from "@hygo/shared/feature-equipment";
import { Sprayer as SprayerIcon } from "@hygo/shared/icons";
import { ModalsContext } from "@hygo/shared/modals";
import { COLORS } from "@hygo/shared/utils";
import { ConfirmationModal, SprayerModal } from "@hygo/web/ui-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { SprayerManagementScreenProps } from "./screen.types";
import SprayerManagementScreen from "./SprayerManagementScreen";

const SprayerManagementContainer = (): JSX.Element => {
	const { t } = useTranslation();
	const { activeSprayers } = useContext(UserContext);

	const { showModal } = useContext(ModalsContext);
	const { onDelete } = useSprayer({});

	const onDeleteSprayer: SprayerManagementScreenProps["onDeleteSprayer"] = (sprayer) => {
		showModal(ConfirmationModal, {
			btnColorPalette: COLORS.GASPACHO,
			confirmLabel: t("button.delete"),
			dismissLabel: t("button.cancel"),
			entity: {
				Icon: <SprayerIcon height={42} width={42} />,
				name: sprayer.name
			},
			handleConfirm: async () => {
				await onDelete(sprayer);
			},
			subtitle: t("modals.sprayerDelete.subtitle"),
			title: t("modals.sprayerDelete.title")
		});
	};

	const onCreateSprayer: SprayerManagementScreenProps["onCreateSprayer"] = () => {
		showModal(SprayerModal);
	};

	const onUpdateSprayer: SprayerManagementScreenProps["onUpdateSprayer"] = (sprayer) => {
		showModal(SprayerModal, {
			sprayer
		});
	};

	return (
		<SprayerManagementScreen
			onCreateSprayer={onCreateSprayer}
			onDeleteSprayer={onDeleteSprayer}
			onUpdateSprayer={onUpdateSprayer}
			sprayers={activeSprayers}
		/>
	);
};

export default SprayerManagementContainer;
