import { AmplitudeContext } from "@hygo/shared/amplitude";
import { Chevron, Logout, Pencil, User as UserIcon } from "@hygo/shared/icons";
import { NavbarEvents, User } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
`;

const ChevronIcon = styled(Chevron)<{ open: boolean }>`
	transition: transform 0.1s ease-in;
	transform: rotate(${(props) => (props.open ? 0 : -90)}deg);
`;

const ButtonDropdown = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const Username = styled.h4`
	margin: 0 5px;
	@media (max-width: 1140px) {
		display: none;
	}
`;

const Dropdown = styled.div<{ open: boolean }>`
	position: absolute;
	top: 68px;
	background-color: var(--white-100);
	width: 250px;
	right: 0;
	display: ${(props) => (props.open ? "block" : "none")};
	cursor: pointer;
	box-shadow:
		0px 0.8px 1.5px rgba(0, 83, 94, 0.1),
		0px 6px 12px rgba(0, 83, 94, 0.1);
	border-radius: 0 0 4px 4px;
`;

const Item = styled.div`
	display: flex;
	align-items: center;
	padding: 10px;
	transition: background-color 0.1s ease-out;
	outline: none;
	gap: 8px;
	&:hover {
		background-color: var(--night-5);
	}
`;

interface UserMenuProps {
	admin: boolean;
	farmerSelected: boolean;
	logout: () => void;
	user: User;
}
const UserMenu = ({ admin, farmerSelected, logout, user }: UserMenuProps): JSX.Element => {
	const { t } = useTranslation();
	const [open, setOpen] = useState<boolean>(false);
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const navigate = useNavigate();

	const fullName = admin
		? `${user?.admin?.firstName} ${user?.admin?.lastName}`
		: `${user.firstName} ${user.lastName}`;

	const onClickDropdown = (): void => {
		setOpen(!open);
	};

	const onClickAccount = (): void => {
		logAnalyticEvent(NavbarEvents.goToAccount);
		navigate("/account");
	};

	return (
		<Wrapper>
			<ButtonDropdown onClick={onClickDropdown} onKeyDown={onClickDropdown} role="button" tabIndex={0}>
				<UserIcon fill={COLORS.NIGHT[100]} height={20} width={20} />
				<Username>{fullName}</Username>
				<ChevronIcon fill={COLORS.NIGHT[100]} height={20} open={open} width={20} />
			</ButtonDropdown>
			<Dropdown open={open}>
				{farmerSelected && (
					<Item onClick={onClickAccount} onKeyDown={onClickAccount} role="button" tabIndex={0}>
						<Pencil height={20} width={20} /> <h4>{t("components.userMenu.myAccount")}</h4>
					</Item>
				)}
				<Item onClick={logout} onKeyDown={logout} role="button" tabIndex={0}>
					<Logout height={20} width={20} /> <h4>{t("components.userMenu.logout")}</h4>
				</Item>
			</Dropdown>
		</Wrapper>
	);
};
export default UserMenu;
