import { ChangeEventHandler } from "react";
import styled from "styled-components";

const Wrapper = styled.label`
	--switch-width: 48px;
	--switch-cursor-size: 20px;
	--switch-offset: 2px;
	display: inline-block;
	position: relative;
	width: var(--switch-width);
	height: 24px;
	cursor: pointer;
	overflow: hidden;
`;

const Switch = styled.span`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: var(--lake-50);
	border-radius: 20px;
	&:before {
		content: "";
		display: inline-block;
		position: absolute;
		top: 50%;
		left: var(--switch-offset);
		width: var(--switch-cursor-size);
		height: var(--switch-cursor-size);
		background: var(--white-100);
		border-radius: 50%;
		transform: translateY(-50%);
		transition: all 0.5s;
	}
`;

const Input = styled.input`
	position: absolute;
	top: -30px;
	left: -30px;
	width: 0;
	height: 0;
	&:checked + ${Switch} {
		background: var(--lake-100);
		&:before {
			left: calc(var(--switch-width) - var(--switch-cursor-size) - var(--switch-offset));
		}
	}
`;

const Error = styled.p`
	color: var(--gaspacho-100);
`;

interface SwitchButtonProps {
	error?: { message?: string };
	name: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
	value: boolean;
}

const SwitchButton = ({ error, name, onChange, value }: SwitchButtonProps): JSX.Element => {
	return (
		<>
			<Wrapper>
				<Input checked={value} name={name} onChange={onChange} type="checkbox" />
				<Switch />
			</Wrapper>
			{error?.message && <Error>{error.message}</Error>}
		</>
	);
};

export default SwitchButton;
