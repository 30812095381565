// eslint-disable-next-line @nx/enforce-module-boundaries
import { ProductFamilyIcons, Warning } from "@hygo/shared/icons";
import { ProductFamily, RecommendationType, RecommendationTypeDetails } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";

export const recommendationMapping: {
	[key in RecommendationType]: RecommendationTypeDetails;
} = {
	[RecommendationType.DANGER]: {
		color: COLORS.GASPACHO,
		Icon: Warning
	},
	[RecommendationType.INFO]: {
		color: COLORS.GRASS,
		Icon: Warning
	},
	[RecommendationType.INFO_ADJ]: {
		color: COLORS.GRASS,
		Icon: ProductFamilyIcons[ProductFamily.ADJUVANT]
	},
	[RecommendationType.WARNING]: {
		color: COLORS.BEACH,
		Icon: Warning
	},
	[RecommendationType.WARNING_ADJ]: {
		color: COLORS.BEACH,
		Icon: ProductFamilyIcons[ProductFamily.ADJUVANT]
	}
};
