import { Parcelles } from "@hygo/shared/icons";
import { Crop, DenormalizedField, Tank, WindSpeedUnity } from "@hygo/shared/models";
import { COLORS, convertToHa } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import FieldCard from "./FieldCard";
import Section from "./Section";

interface FieldsSectionProps {
	crops: Crop[];
	fields: DenormalizedField[];
	isTask: boolean;
	onDelete: () => void;
	selectedFields: DenormalizedField[];
	tankIndications: Tank;
	windSpeedUnity: WindSpeedUnity;
}

const FieldCardWrapper = styled.div`
	overflow-x: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
	padding: 16px;
	margin: -16px;
`;

const FieldsSection = ({
	crops,
	fields,
	isTask,
	onDelete,
	selectedFields,
	tankIndications,
	windSpeedUnity
}: FieldsSectionProps): JSX.Element => {
	const { t } = useTranslation();
	const totalAreaHA = convertToHa(
		fields?.reduce((current, nextField) => current + (nextField.fieldArea || nextField.area), 0)
	);

	return (
		<Section
			Icon={<Parcelles fill={COLORS.LAKE[100]} height={20} width={20} />}
			onDelete={onDelete}
			standalone
			title={`${t("components.fieldsReportCard.title")} (${totalAreaHA} ${t("units.hectare")})`}
		>
			{!!fields?.length && (
				<FieldCardWrapper>
					{fields.map((field) => {
						const isSelected = selectedFields?.map((f) => f.id).includes(field.id);
						return (
							<FieldCard
								crops={crops}
								field={field}
								isSelected={isSelected || selectedFields?.length === 0}
								isTask={isTask}
								key={field?.id}
								tankIndications={tankIndications}
								windSpeedUnity={windSpeedUnity}
							/>
						);
					})}
				</FieldCardWrapper>
			)}
		</Section>
	);
};

export default FieldsSection;
