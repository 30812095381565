import { FarmModalPropsType } from "@hygo/shared/modals";
import { SnackbarType } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { COLORS } from "@hygo/shared/utils";
import { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import TextInput from "../../TextInput";
import BaseModal from "../BaseModal";

const FarmModal = ({ farm, hideModal, modalVisible, onSubmit }: FarmModalPropsType): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const { showSnackbar } = useContext(SnackbarContext);
	const defaultValues = useMemo(() => ({ name: farm?.name }), [farm]);

	const methods = useForm({
		defaultValues,
		mode: "onChange"
	});
	const {
		formState: { isValid }
	} = methods;
	useEffect(() => {
		methods.reset(defaultValues);
	}, [defaultValues, methods]);

	const onConfirm = async ({ name }: { name: string }): Promise<void> => {
		try {
			setLoading(true);
			await onSubmit({ name });
			hideModal();
			showSnackbar(t("snackbar.farm.success"), SnackbarType.SUCCESS);
		} catch (e) {
			showSnackbar(t("snackbar.farm.error"), SnackbarType.ERROR);
			throw e;
		} finally {
			setLoading(false);
		}
	};

	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnDisabled={!isValid}
			confirmBtnLoading={loading}
			confirmBtnText={t("button.save")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={methods.handleSubmit(onConfirm)}
			title={t("modals.farm.title")}
		>
			<FormProvider {...methods}>
				<TextInput
					name="name"
					placeholder={t("inputs.farmName.placeholder")}
					rules={{
						required: {
							message: t("inputs.farmName.errors.required"),
							value: true
						}
					}}
				/>
			</FormProvider>
		</BaseModal>
	);
};
export default FarmModal;
