import { UserContext } from "@hygo/shared/contexts";
import { useMileosSetup } from "@hygo/shared/feature-fields-manager";
import { MileosPhasesModalPropsType } from "@hygo/shared/modals";
import { COLORS, getStartOfDayAsJSDate } from "@hygo/shared/utils";
import { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import DatePicker from "../../DatePicker";
import BaseModal from "../BaseModal";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const MileosPhasesModal = ({
	field,
	hideModal,
	mileosInfos,
	modalVisible,
	onSubmit
}: MileosPhasesModalPropsType): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const { user } = useContext(UserContext);
	const { getValuesOfPhase } = useMileosSetup({ field });

	const defaultValues = useMemo(() => {
		return mileosInfos?.phases?.reduce((obj, item) => {
			return Object.assign(obj, {
				[item.name]: item?.date ? getStartOfDayAsJSDate(new Date(item?.date)) : null
			});
		}, {});
	}, [mileosInfos]);

	const methods = useForm<Record<string, Date>>({
		defaultValues,
		mode: "all"
	});

	const onConfirm = async (phases: Record<string, Date>): Promise<void> => {
		try {
			setLoading(true);
			const newValues = Object.keys(phases).map((phase) => ({
				date: phases[phase]?.toISOString(),
				name: phase
			}));
			await onSubmit({ phases: newValues });
			hideModal();
		} finally {
			setLoading(false);
		}
	};
	const {
		formState: { isDirty, isValid }
	} = methods;

	useEffect(() => {
		methods.reset(defaultValues);
	}, [methods, defaultValues]);

	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnDisabled={!isValid || !isDirty}
			confirmBtnLoading={loading}
			confirmBtnText={t("button.save")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={methods.handleSubmit(onConfirm)}
			title={t("modals.mileosPhases.title")}
		>
			<FormProvider {...methods}>
				<Wrapper>
					{mileosInfos?.phases?.map((phase, i) => {
						const { disabled, maximumDate, minimumDate } = getValuesOfPhase({
							index: i,
							nextPhaseDate:
								mileosInfos?.phases[i + 1]?.name &&
								methods.watch(mileosInfos?.phases[i + 1]?.name)?.toString(),
							previousPhaseDate:
								mileosInfos?.phases[i - 1]?.name &&
								methods.watch(mileosInfos?.phases[i - 1]?.name)?.toString()
						});
						return (
							<DatePicker
								control={methods.control}
								countryCode={user?.countryCode}
								dateFormat="dd/MM/yyyy"
								disabled={disabled}
								key={phase.name}
								label={t(`inputs.fieldPhases.${phase.name}.label`)}
								maxDate={maximumDate}
								minDate={minimumDate}
								name={phase?.name}
								placeholder={t("inputs.day.placeholder")}
							/>
						);
					})}
				</Wrapper>
			</FormProvider>
		</BaseModal>
	);
};
export default MileosPhasesModal;
