import styled from "styled-components";

import BaseSkeleton from "../../BaseSkeleton";

const TwoColumns = styled(BaseSkeleton)`
	grid-column: span 2;
	height: 68px;
`;

const OneColumn = styled(BaseSkeleton)`
	height: 26px;
	align-self: center;
`;

const Skeleton = (): JSX.Element => {
	return (
		<>
			<TwoColumns />
			<OneColumn />
		</>
	);
};

export default Skeleton;
