export enum Language {
	cs = "cs",
	de = "de",
	en = "en",
	fr = "fr",
	it = "it",
	nl = "nl",
	pl = "pl",
	sk = "sk"
}
