import { ModulationStatus } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";

export const modulationStatusMapping: {
	[key in ModulationStatus]: Record<number, string>;
} = {
	[ModulationStatus.ENABLE_EXPERT_MODE]: COLORS.GRASS,
	[ModulationStatus.MAX_MODULATION_REACHED]: COLORS.GRASS,
	[ModulationStatus.MODULATION_ACTIVE]: null,
	[ModulationStatus.NO_HOMOLOGATED_CROP]: COLORS.DISABLED,
	[ModulationStatus.NO_MODULATION_AVAILABLE]: COLORS.DISABLED
};
