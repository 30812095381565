import { SmagLoginModalPropsType } from "@hygo/shared/modals";
import { COLORS } from "@hygo/shared/utils";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import InputTip from "../../InputTip";
import BaseModal from "../BaseModal";

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const Subtitle = styled.h4`
	margin-bottom: 8px;
`;

const Description = styled.h5`
	color: var(--night-50);
	margin-bottom: 8px;
`;

const SmagLoginModal = ({ hideModal, loginUri, modalVisible }: SmagLoginModalPropsType): JSX.Element => {
	const { t } = useTranslation();

	const onConfirm = async (): Promise<void> => {
		window.location.replace(loginUri);
	};

	return (
		<BaseModal
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnText={t("button.letsGo")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onConfirm={onConfirm}
			title={t("modals.smagLogin.title")}
		>
			<Trans i18nKey="modals.smagLogin.mainInfos">
				<Description>
					You will be able to communicate directly between your HYGO tasks and your Smag Farmer Account
				</Description>
				<Subtitle>
					<span aria-label="Pourquoi" role="img">
						🤔
					</span>
					How will it work ?
				</Subtitle>
				<Description>
					We&apos;ll ask you to login with your smag credentials to link your account. We&apos;ll then invite
					you to import your Smag fields in HYGO
				</Description>
			</Trans>

			<InputTip palette={COLORS.DISABLED} withBorder={false}>
				<Trans i18nKey="modals.smagLogin.inputTip">
					<ColoredCardText>
						<span aria-label="Ampoule" role="img">
							💡
						</span>{" "}
						Why is it important to import my Smag fields ?
					</ColoredCardText>
					<ColoredCardText>
						This will enable us to make the link between each field to show you the corresponding tasks.
					</ColoredCardText>
				</Trans>
			</InputTip>
		</BaseModal>
	);
};
export default SmagLoginModal;
