import { UserContext } from "@hygo/shared/contexts";
import { SimpleArrowRight } from "@hygo/shared/icons";
import { SmagImportModalPropsType } from "@hygo/shared/modals";
import { COLORS, convertToHa, hexToRGBA } from "@hygo/shared/utils";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CropIcon from "../../CropIcon";
import ParcelSVG from "../../ParcelSVG";
import BaseModal from "../BaseModal";

const TextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const AreasWrapper = styled(TextWrapper)`
	gap: 8px;
`;

const FieldInfosWrapper = styled(TextWrapper)`
	gap: 4px;
	white-space: nowrap;
	overflow: hidden;
	flex: 1;
`;

const Area = styled.h4`
	color: var(--night-50);
`;

const SmagArea = styled.h4`
	color: var(--grass-100);
`;

const Card = styled.label`
	display: flex;
	border-radius: 4px;
	padding: 4px 8px;
	gap: 8px;
	justify-content: space-between;
	background-color: var(--smoke-100);
`;

const Wrapper = styled.div`
	gap: 16px;
	display: flex;
	flex-direction: column;
`;

const FieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const FieldName = styled.h4`
	overflow: hidden;
	text-overflow: ellipsis;
`;

const CropWrapper = styled.div`
	background-color: var(--white-80);
	border-radius: 50%;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	height: 12px;
	width: 12px;
`;

const FieldCropWrapper = styled.div`
	position: relative;
`;

const SmagImportModal = ({ fields, hideModal, modalVisible, onSubmit }: SmagImportModalPropsType): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const { crops } = useContext(UserContext);

	const onSend = async (): Promise<void> => {
		try {
			setLoading(true);
			await onSubmit();
			hideModal();
		} finally {
			setLoading(false);
		}
	};

	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnLoading={loading}
			confirmBtnText={t("button.send")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={onSend}
			title={t("modals.smagImport.title")}
		>
			<Wrapper>
				<h4>{t("modals.smagImport.subtitle")}</h4>
				<FieldWrapper>
					{fields.map((field) => {
						const crop = crops.find((c) => c.id === field.cropId);
						return (
							<Card key={field.id}>
								<FieldInfosWrapper>
									<FieldCropWrapper>
										<ParcelSVG
											fill={hexToRGBA(crop.color, 0.5, COLORS.LAKE[50])}
											height={24}
											path={field.svg}
											stroke={hexToRGBA(crop.color, 1, COLORS.LAKE[100])}
											width={24}
										/>
										<CropWrapper>
											<CropIcon crop={crop} fill={COLORS.NIGHT[100]} height={8} width={8} />
										</CropWrapper>
									</FieldCropWrapper>
									<FieldName>{field.name}</FieldName>
								</FieldInfosWrapper>
								<AreasWrapper>
									<Area>
										{convertToHa(field.fieldArea || field.area)} {t("units.hectare")}
									</Area>
									<SimpleArrowRight color={COLORS.NIGHT[100]} height={16} width={16} />
									<SmagArea>
										{convertToHa(field.area)} {t("units.hectare")}
									</SmagArea>
								</AreasWrapper>
							</Card>
						);
					})}
				</FieldWrapper>
			</Wrapper>
		</BaseModal>
	);
};
export default SmagImportModal;
