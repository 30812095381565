import { Warning } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { TooltipBubble } from "@hygo/web/ui-components";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

interface SeasonTabsProps {
	onChangeSeason: (v: number) => void;
	selectedSeason: { startAfter: Date; startBefore: Date; year: number };
	stepSeasons: { startAfter: Date; startBefore: Date; year: number }[];
}

const Wrapper = styled.div`
	margin-right: auto;
	display: flex;
	align-items: center;
	gap: 16px;
`;

const SeasonsList = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;
`;

const SeasonLabelWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const SeasonButton = styled.div<{ $isSelected: boolean }>`
	padding: 8px 16px;
	border-radius: 2px;
	background-color: ${(props) => (props.$isSelected ? "var(--lake-100)" : "var(--night-5)")};
	color: ${(props) => (props.$isSelected ? "var(--white-100)" : "var(--night-100)")};
	transition: background-color 0.1s ease-out;
	cursor: pointer;
	${(props) =>
		!props.$isSelected &&
		css`
			&:hover {
				background-color: var(--night-10);
			}
		`}

	&:first-of-type {
		border-radius: 8px 2px 2px 8px;
	}
	&:last-of-type {
		border-radius: 2px 8px 8px 2px;
	}
	&:only-child {
		border-radius: 8px;
	}
`;

const StyledToolTipBubble = styled(TooltipBubble)`
	top: 35px;
	z-index: 1;
	left: -11px;
`;

const Tooltip = styled.div`
	position: relative;
	cursor: pointer;
	&:hover ${StyledToolTipBubble} {
		display: block;
	}
`;

const Season = styled.h4``;

const SeasonTabs = ({ onChangeSeason, selectedSeason, stepSeasons }: SeasonTabsProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<SeasonLabelWrapper>
				<h2>{t("inputs.season.label")}</h2>
				<Tooltip>
					<Warning fill={COLORS.NIGHT[100]} height={16} width={16} />
					<StyledToolTipBubble text={t("screens.tasks.components.seasonTabs.tooltip")} />
				</Tooltip>
			</SeasonLabelWrapper>
			<SeasonsList>
				{stepSeasons?.map((season, i) => {
					const isSelected = season.year === selectedSeason.year;
					return (
						<SeasonButton $isSelected={isSelected} key={season.year} onClick={() => onChangeSeason(i)}>
							<Season>{season.year}</Season>
						</SeasonButton>
					);
				})}
			</SeasonsList>
		</Wrapper>
	);
};

export default SeasonTabs;
