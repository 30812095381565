import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { AccountDeleteModalPropsType } from "@hygo/shared/modals";
import { AuthEvents, SnackbarType } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { COLORS } from "@hygo/shared/utils";
import { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import TextInput from "../../TextInput";
import { Input } from "../../TextInput/TextInput";
import BaseModal from "../BaseModal";

const Wrapper = styled.div`
	${Input} {
		text-transform: uppercase;
	}
`;

const Subline = styled.h4`
	color: var(--night-50);
	text-align: center;
	margin-bottom: 16px;
`;

const Description = styled.h5`
	color: var(--lake-100);
	text-align: center;
	margin-bottom: 16px;
`;

const AccountDeleteModal = ({ hideModal, modalVisible }: AccountDeleteModalPropsType): JSX.Element => {
	const { deleteUser } = useApi();

	const { logout } = useContext(UserContext);
	const { showSnackbar } = useContext(SnackbarContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);

	const methods = useForm({ mode: "onChange" });
	const {
		formState: { isValid }
	} = methods;
	const deleteWord = t("button.delete").toUpperCase();

	const deleteAccount = async (): Promise<void> => {
		try {
			setLoading(true);
			await deleteUser();
			logAnalyticEvent(AuthEvents.deletion);
			await logout({ canDeletePushToken: false });
			showSnackbar(t("snackbar.accountDeletion.success"), SnackbarType.SUCCESS);
			hideModal();
		} catch (e) {
			showSnackbar(t("snackbar.accountDeletion.error"), SnackbarType.ERROR);
			throw e;
		} finally {
			setLoading(false);
		}
	};

	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorConfirmBtn={COLORS.GASPACHO}
			confirmBtnDisabled={!isValid}
			confirmBtnLoading={loading}
			confirmBtnText={t("button.delete")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={methods.handleSubmit(deleteAccount)}
			title={t("modals.accountDelete.title")}
		>
			<Wrapper>
				<Subline>{t("modals.accountDelete.body")}</Subline>
				<Description>{t("modals.accountDelete.subline", { deleteWord })}</Description>
				<FormProvider {...methods}>
					<TextInput
						name="account-delete"
						placeholder={deleteWord}
						rules={{
							required: true,
							validate: (value: string) => value.toUpperCase() === deleteWord
						}}
					/>
				</FormProvider>
			</Wrapper>
		</BaseModal>
	);
};
export default AccountDeleteModal;
