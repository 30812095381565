import { SetupError } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Button from "../Button";
import { httpErrors, setupErrors } from "./errors.constants";

interface ErrorProps {
	type: 404 | 449 | 500 | SetupError;
}

const Wrapper = styled.div`
	height: 100%;
	display: flex;
`;

const Content = styled.div`
	flex: 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 120px;
`;

const ImageWrapper = styled.div`
	flex: 2;
`;

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const Title = styled.h1`
	margin-bottom: 16px;
`;

const Description = styled.h2`
	margin-bottom: 16px;
`;

const ErrorType = styled(Description)`
	color: var(--night-50);
`;

const StyledButton = styled(Button)`
	width: 50%;
`;

const Error = ({ type }: ErrorProps): JSX.Element => {
	const { t } = useTranslation();
	const isHttpError = typeof type === "number";
	const error = isHttpError ? httpErrors?.[type] : setupErrors?.[type];

	return (
		<Wrapper>
			<Content>
				{isHttpError && (
					<ErrorType>
						{t("components.error.errorLabel")} {type}
					</ErrorType>
				)}
				<Title>{t(`components.error.${type}.title`)}</Title>
				<Description>{t(`components.error.${type}.description`)}</Description>
				<StyledButton color={COLORS.LAKE} onClick={error?.onClick} text={t(`components.error.${type}.btn`)} />
			</Content>
			<ImageWrapper>
				<Image alt={t("components.error.errorLabel")} src={error?.image} />
			</ImageWrapper>
		</Wrapper>
	);
};

export default Error;
