import { countryMapping } from "@hygo/shared/constants";
import { CountryStatus, Feature } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { Button } from "@hygo/web/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import PlanCard from "./components/PlanCard";
import PlanDetails from "./components/PlanDetails";
import Skeleton from "./components/Skeleton";
import { PricingScreenProps } from "./screen.types";

const Wrapper = styled.div`
	background-color: var(--white-100);
	height: 100%;
	display: flex;
	padding: 40px 100px;
	flex-direction: column;
`;

const PricingWrapper = styled.div`
	flex: 1;
	display: flex;
	gap: 16px;
	overflow: hidden;
`;

const LeftColumn = styled.div`
	flex-basis: 400px;
	display: flex;
	flex-direction: column;
`;

const RightColumn = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

const SubscribeButtonWrapper = styled.div`
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const SectionWrapper = styled.div`
	background-color: var(--smoke-100);
	padding: 16px;
	display: flex;
	overflow: auto;
	flex-direction: column;
	gap: 16px;
`;

const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const PricingScreen = ({
	actualPlan,
	alreadyInterested,
	countryCode,
	getTryHygoLabel,
	loading,
	onClick,
	onSubmit,
	openChargebeePortal,
	otherPlans,
	planDetailsRef,
	recommendedPlan,
	selectedPlan
}: PricingScreenProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<PricingWrapper>
				{otherPlans?.length > 0 ? (
					<>
						<LeftColumn>
							<SectionWrapper>
								{actualPlan && (
									<Section>
										<h4>{t("plans.types.actualPlan")}</h4>
										<PlanCard
											highlight={selectedPlan?.id === actualPlan?.id}
											onClick={onClick}
											plan={actualPlan}
										/>
									</Section>
								)}
								{recommendedPlan && (
									<Section>
										<h4>{t("plans.types.recommendedPlan")}</h4>
										<PlanCard
											highlight={selectedPlan?.id === recommendedPlan?.id}
											onClick={onClick}
											plan={recommendedPlan}
										/>
									</Section>
								)}

								<Section>
									<h4>{t("plans.types.otherPlans")}</h4>
									{otherPlans?.map((plan) => {
										return (
											<PlanCard
												highlight={selectedPlan?.id === plan.id}
												key={plan.id}
												onClick={onClick}
												plan={plan}
											/>
										);
									})}
								</Section>
							</SectionWrapper>
							{countryMapping[countryCode].status === CountryStatus.OPEN && (
								<SubscribeButtonWrapper>
									<Button
										color={COLORS.LAKE}
										onClick={openChargebeePortal}
										text={t("screens.pricing.cta")}
									/>
									{!actualPlan.features.includes(Feature.FARM_WEATHER) && (
										<Button
											color={COLORS.SMOKE}
											disabled={alreadyInterested}
											inverted
											loading={loading}
											onClick={onSubmit}
											text={getTryHygoLabel()}
										/>
									)}
								</SubscribeButtonWrapper>
							)}
						</LeftColumn>
						{selectedPlan && (
							<RightColumn>
								<PlanDetails
									actualPlan={actualPlan}
									countryCode={countryCode}
									plan={selectedPlan}
									planDetailsRef={planDetailsRef}
								/>
							</RightColumn>
						)}
					</>
				) : (
					<Skeleton />
				)}
			</PricingWrapper>
		</Wrapper>
	);
};

export default PricingScreen;
