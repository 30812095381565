import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useSignin } from "@hygo/shared/feature-authentication";
import { SignInEvents } from "@hygo/shared/models";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { SigninContainerProps, SigninScreenProps } from "./screen.types";
import SignInScreen from "./SigninScreen";

const SigninContainer = ({ version }: SigninContainerProps): JSX.Element => {
	const { loading, methods, onSubmit, passwordShown, PasswordVisibilityIcon, rules, togglePasswordVisibility } =
		useSignin();
	const { logAnalyticEvent } = useContext(AmplitudeContext);

	const navigate = useNavigate();

	const onClickSignUp: SigninScreenProps["onClickSignUp"] = () => navigate("/signup");

	const onClickReset: SigninScreenProps["onClickReset"] = () => {
		logAnalyticEvent(SignInEvents.forgotPassword);
		navigate("/reset-password");
	};

	return (
		<SignInScreen
			loading={loading}
			methods={methods}
			onClickReset={onClickReset}
			onClickSignUp={onClickSignUp}
			onSubmit={onSubmit}
			passwordShown={passwordShown}
			PasswordVisibilityIcon={PasswordVisibilityIcon}
			rules={rules}
			togglePasswordVisibility={togglePasswordVisibility}
			version={version}
		/>
	);
};

export default SigninContainer;
