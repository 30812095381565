import { planDetails } from "@hygo/shared/constants";
import { Plan } from "@hygo/shared/models";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface PlanCardProps {
	highlight?: boolean;
	onClick: (plan: Plan) => void;
	plan: Plan;
}

const Wrapper = styled.button<{ $highlightColor: string }>`
	padding: var(--padding-molecule-cta-16, 16px);
	border: ${(props) => (props.$highlightColor ? `3px solid ${props.$highlightColor}` : "1px solid var(--night-10)")};
	background-color: var(--white-100);
	border-radius: var(--corner-radius-molecule-cta-8, 8px);
	display: flex;
	cursor: pointer;
	gap: 8px;
	align-items: center;
	transition: background-color 0.1s ease-out;
	&:hover {
		background-color: var(--night-5);
	}
`;

const Title = styled.h2<{ $color: string }>`
	span {
		color: ${(props) => props.$color};
	}
`;

const Image = styled.img`
	width: 42px;
	height: 42px;
`;

const PlanCard = ({ highlight, onClick, plan }: PlanCardProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Wrapper $highlightColor={highlight ? plan.color : null} onClick={() => onClick(plan)}>
			{planDetails[plan.id] && <Image alt={t(`plans.${plan?.i18nId}.name`)} src={planDetails[plan.id]} />}
			<div>
				<Title $color={plan.color}>
					HYGO <span>{t(`plans.${plan?.i18nId}.name`)}</span>
				</Title>
				<h5>{t(`plans.${plan?.i18nId}.description`)}</h5>
			</div>
		</Wrapper>
	);
};

export default PlanCard;
