import { UserContext } from "@hygo/shared/contexts";
import { Feature } from "@hygo/shared/models";
import { CropsScreenProvider, OADContext } from "@hygo/web/contexts";
import { AccountScreen, OADScreen } from "@hygo/web/feature-account-settings";
import { ChangelogScreen } from "@hygo/web/feature-admin";
import {
	EmailPasswordValidationScreen,
	HygoRedirectScreen,
	ResetPasswordScreen,
	SigninScreen,
	SignupScreen
} from "@hygo/web/feature-authentication";
import { TasksScreen } from "@hygo/web/feature-done-task";
import { ExportLandingScreen } from "@hygo/web/feature-done-task";
import { SprayerManagementScreen } from "@hygo/web/feature-equipment";
import { CropsScreen } from "@hygo/web/feature-fields-manager";
import {
	ImportLandingScreen,
	ImportSmagLinkingScreen,
	ImportSmagScreen,
	ImportTelepacLinkingScreen,
	ImportTelepacScreen
} from "@hygo/web/feature-import";
import { PricingScreen } from "@hygo/web/feature-pricing";
import { Error, Loader } from "@hygo/web/ui-components";
import * as Sentry from "@sentry/react";
import { useContext, useEffect, useMemo } from "react";
import {
	createBrowserRouter,
	createRoutesFromElements,
	Navigate,
	Outlet,
	Route,
	RouterProvider,
	useRouteError
} from "react-router-dom";

import AdminRoutes from "./components/AdminRoutes";
import FarmerRoutes from "./components/FarmerRoutes";
import ProtectedRoutes from "./components/ProtectedRoutes";
import PublicRoutes from "./components/PublicRoutes";

const SentryRouteErrorFallback = (): JSX.Element => {
	const routeError = useRouteError();

	useEffect(() => {
		Sentry.captureException(routeError, { level: "fatal" });
	}, [routeError]);

	return <Error type={500} />;
};

export const AppRouter = ({ version }: { version: string }): JSX.Element => {
	const { authLoading } = useContext(UserContext);
	const { loggedInSmag } = useContext(OADContext);

	const router = useMemo(() => {
		return createBrowserRouter(
			createRoutesFromElements(
				<Route errorElement={<SentryRouteErrorFallback />}>
					{/* Routes always accessible */}
					<Route element={<HygoRedirectScreen />} path="hygo/*" />
					<Route element={<Outlet />} path="changelog">
						<Route element={<ChangelogScreen path={null} />} index />
						{["mobile", "web"].map((path) => (
							<Route element={<ChangelogScreen path={path} />} key={path} path={path} />
						))}
					</Route>
					{/* Routes when user is not authenticated */}
					<Route element={<PublicRoutes allowedMobilePaths={["reset-password"]} />}>
						<Route element={<SigninScreen version={version} />} path="/" />
						<Route path="reset-password">
							<Route element={<EmailPasswordValidationScreen />} index />
							<Route element={<ResetPasswordScreen />} path="new/:token" />
						</Route>
						<Route element={<SignupScreen />} path="signup" />
					</Route>
					{/* Routes only accessible for authenticated users */}
					<Route element={<ProtectedRoutes />} path="pricing">
						<Route element={<PricingScreen />} index />
					</Route>
					<Route element={<ProtectedRoutes />} path="account">
						<Route element={<AccountScreen />} index />
					</Route>
					{/* Routes only accessible to premium users */}
					<Route element={<FarmerRoutes feature={Feature.FARM_WEATHER} />} path="dashboard">
						<Route
							element={
								<CropsScreenProvider>
									<CropsScreen version={version} />
								</CropsScreenProvider>
							}
							index
						/>
					</Route>
					<Route element={<FarmerRoutes feature={Feature.FARM_WEATHER} />} path="import">
						<Route element={<ImportLandingScreen />} index />
						<Route element={<Outlet />} path="telepac">
							<Route element={<ImportTelepacScreen />} index />
							<Route element={<ImportTelepacLinkingScreen />} path="linking" />
						</Route>
						<Route element={loggedInSmag ? <Outlet /> : <Navigate to="/" />} path="smag">
							<Route element={<ImportSmagScreen />} index />
							<Route element={<ImportSmagLinkingScreen />} path="linking" />
						</Route>
					</Route>
					<Route element={<FarmerRoutes feature={Feature.FARM_WEATHER} />} path="oad">
						<Route element={<OADScreen />} index />
					</Route>
					<Route element={<FarmerRoutes feature={Feature.TASKS} />} path="interventions">
						<Route element={<TasksScreen />} index />
					</Route>
					<Route element={<FarmerRoutes feature={Feature.TASKS} />} path="sprayers">
						<Route element={<SprayerManagementScreen />} index />
					</Route>
					<Route element={<FarmerRoutes feature={Feature.TASKS} />} path="export">
						<Route element={<ExportLandingScreen />} index />
					</Route>
					{/* Routes only accessible to admins  */}
					<Route element={<AdminRoutes />} path="admin"></Route>
					<Route element={<Error type={404} />} path="*" />
				</Route>
			)
		);
	}, [loggedInSmag, version]);

	return authLoading ? <Loader /> : <RouterProvider router={router} />;
};
