import { ModalsContext, SmagTokenErrorModalPropsType } from "@hygo/shared/modals";
import { FC, useCallback, useContext } from "react";

interface useSmagRequestResult {
	requestToSmag: <T>(request: () => Promise<T>) => Promise<T>;
}

export const useSmagRequest = (Modal: FC<SmagTokenErrorModalPropsType>): useSmagRequestResult => {
	const { showModal } = useContext(ModalsContext);

	const requestToSmag: useSmagRequestResult["requestToSmag"] = useCallback(
		async (request) => {
			try {
				const response = await request();
				return response;
			} catch (e) {
				const errorCode = e?.response?.data?.code;
				if (errorCode === "invalidRefreshToken" || errorCode === "invalidSmagTokenError") {
					// To let the modal appear if another modal was present before
					setTimeout(
						() =>
							showModal(Modal, {
								errorCode: errorCode
							}),
						0
					);
					return null;
				}
				throw e;
			}
		},
		[Modal, showModal]
	);

	return { requestToSmag };
};
