import { availableLanguages } from "@hygo/shared/constants";
import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import i18n, { InitOptions, Module, Newable, NewableModule } from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

axiosRetry(axios, { retries: 10, retryDelay: axiosRetry.exponentialDelay });

export const getI18nSetup = (languageDetector: Module | Newable<Module> | NewableModule<Module>): typeof i18n =>
	i18n.use(Backend).use(languageDetector);

export const getI18nOptions = ({
	escapeValue,
	loadPath,
	namespace
}: {
	escapeValue: boolean;
	loadPath: string;
	namespace: string;
}): InitOptions => {
	return {
		backend: {
			loadPath,
			request: async (
				_options: unknown,
				url: string,
				_payload: unknown,
				cb: (e: Event, response: AxiosResponse<string, string>) => void
			) => {
				try {
					const response = await axios.get(url);
					cb(null, response);
				} catch (e) {
					cb(e, null);
				}
			}
		},
		defaultNS: namespace,
		fallbackLng: "en",
		fallbackNS: "common",
		interpolation: { escapeValue },
		load: "languageOnly",
		ns: [namespace, "common"],
		react: { useSuspense: false },
		supportedLngs: availableLanguages
	};
};

export const initTranslations = async ({
	callback,
	callbackError,
	escapeValue,
	languageDetector,
	loadPath,
	namespace
}: {
	callback: () => void;
	callbackError: (error: string) => void;
	escapeValue: boolean;
	languageDetector: Module | Newable<Module> | NewableModule<Module>;
	loadPath: string;
	namespace: string;
}): Promise<void> => {
	await getI18nSetup(languageDetector)
		.use(initReactI18next)
		.init(getI18nOptions({ escapeValue, loadPath, namespace }), (error: string) =>
			error ? callbackError(error) : callback()
		);
};
