import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { useMileosSetup } from "@hygo/shared/feature-fields-manager";
import { Checkmark, Plus, Refresh } from "@hygo/shared/icons";
import { ModalsContext } from "@hygo/shared/modals";
import { Field, MileosConfStatus } from "@hygo/shared/models";
import { COLORS, formatDateToLocale } from "@hygo/shared/utils";
import { CropsScreenContext } from "@hygo/web/contexts";
import {
	ArvalisDeviceModal,
	ButtonCluster,
	ConfirmationModal,
	CropIcon,
	InputTip,
	MileosFieldSettingsModal,
	MileosPhasesModal,
	MultiActionsButton,
	SimpleButton
} from "@hygo/web/ui-components";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { InlineWrapper, LabelWrapper, Link, Value } from "./FieldDescriptionCard";

interface MileosInputsProps {
	field: Field;
}

const LoadingText = styled.p`
	color: var(--night-50);
`;

const StyledInputTip = styled(InputTip)`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
`;

const SimpleButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 4px;
`;

const ErrorText = styled.p`
	color: var(--gaspacho-100);
`;

const ClusterWrapper = styled.div`
	background-color: var(--white-100);
	padding: 16px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const ErrorClusterWrapper = styled(ClusterWrapper)`
	cursor: pointer;
	&:hover {
		${ErrorText} {
			color: var(--gaspacho-hover);
		}
		path {
			fill: var(--gaspacho-hover);
		}
	}
`;

const MileosInputs = ({ field }: MileosInputsProps): JSX.Element => {
	const { arvalisDevices, crops, defaultFarm, loadFields, user } = useContext(UserContext);
	const { handleDeleteDevice, handleSelectDevice, loading, loadMileosInfos, mileosInfos, mileosVariety } =
		useMileosSetup({ field });
	const { handleFieldSelection } = useContext(CropsScreenContext);
	const { i18n, t } = useTranslation();
	const { showModal } = useContext(ModalsContext);
	const { patchMileosField } = useApi();
	const crop = crops?.find((c) => c.id === field?.cropId);

	const refreshField = async (): Promise<void> => {
		const fetchedFields = await loadFields(false, defaultFarm?.id);
		const newField = fetchedFields?.find((f) => f.id === field?.id);
		handleFieldSelection({
			centerMap: true,
			field: null,
			overrideActiveFields: fetchedFields,
			overrideNewSelectedFields: [newField],
			selection: true
		});
	};

	const onEditMileosSettings = (): void => {
		showModal(MileosFieldSettingsModal, {
			field,
			mileosInfos,
			onSubmit: async ({ varietyId, yieldValue }) => {
				await patchMileosField({
					farmId: defaultFarm.id,
					fieldId: field?.id,
					mileosInfos: { varietyId, yieldValue }
				});
				await refreshField();
			}
		});
	};

	const onDeleteDevice = async (deviceId: number): Promise<void> => {
		const device = arvalisDevices?.find((d) => d.id === deviceId);
		showModal(ConfirmationModal, {
			btnColorPalette: COLORS.GASPACHO,
			confirmLabel: t("button.delete"),
			dismissLabel: t("button.cancel"),
			entity: {
				Icon: null,
				name: `${device?.constructorName} - ${device?.deviceCode}`
			},
			handleConfirm: async () => {
				await handleDeleteDevice(deviceId, async () => {
					await refreshField();
				});
			},
			title: t("modals.deleteArvalisDevice.title")
		});
	};

	const onSelectDevice = async ({ deviceId }: { deviceId: number }): Promise<void> => {
		await handleSelectDevice({ deviceId }, async () => {
			await refreshField();
		});
	};

	const onAddDevice = (): void => {
		showModal(ArvalisDeviceModal, {
			field,
			refreshField
		});
	};

	const onEditMileosPhases = (): void => {
		showModal(MileosPhasesModal, {
			field,
			mileosInfos,
			onSubmit: async ({ phases }) => {
				await patchMileosField({
					farmId: defaultFarm.id,
					fieldId: field?.id,
					mileosInfos: { phases }
				});
				await loadMileosInfos();
			}
		});
	};

	useEffect(() => {
		loadMileosInfos();
	}, [loadMileosInfos]);

	if (loading)
		return (
			<ClusterWrapper>
				<InlineWrapper>
					<h4>{t("oad.MILEOS")}</h4>
				</InlineWrapper>
				<LabelWrapper>
					<img alt="loading" height={20} src={"/assets/images/spinner.gif"} width={20} />
					<LoadingText>{t("screens.dashboard.mileosInputs.loading")}</LoadingText>
				</LabelWrapper>
			</ClusterWrapper>
		);

	if (!mileosInfos)
		return (
			<ErrorClusterWrapper onClick={loadMileosInfos}>
				<InlineWrapper>
					<h4>{t("oad.MILEOS")}</h4>
				</InlineWrapper>
				<LabelWrapper>
					<Refresh fill={COLORS.GASPACHO[100]} height={20} width={20} />
					<ErrorText>{t("screens.dashboard.mileosInputs.error")}</ErrorText>
				</LabelWrapper>
			</ErrorClusterWrapper>
		);

	return (
		<>
			<ClusterWrapper>
				<InlineWrapper>
					<h4>{t("oad.MILEOS")}</h4>
					<Link
						$highlight={!mileosInfos?.varietyId || !mileosInfos?.yieldValue}
						onClick={onEditMileosSettings}
						text={t("button.edit")}
					/>
				</InlineWrapper>
				<InlineWrapper>
					<LabelWrapper>
						<CropIcon
							crop={crop}
							fill={mileosInfos?.varietyId ? COLORS.LAKE[100] : COLORS.NIGHT[50]}
							height={20}
							width={20}
						/>

						<h5>{t("inputs.mileosVariety.label")}</h5>
					</LabelWrapper>
					<Value>{mileosInfos?.varietyId ? mileosVariety?.name : "-"}</Value>
				</InlineWrapper>
				<InlineWrapper>
					<LabelWrapper>
						<Plus
							fill={mileosInfos?.yieldValue ? COLORS.LAKE[100] : COLORS.NIGHT[50]}
							height={20}
							width={20}
						/>
						<h5>{t("inputs.mileosYieldValue.label")}</h5>
					</LabelWrapper>
					<Value>
						{mileosInfos?.yieldValue
							? `${mileosInfos?.yieldValue} ${t("units.ton")}/${t("units.hectare")}`
							: "-"}
					</Value>
				</InlineWrapper>
			</ClusterWrapper>
			<StyledInputTip
				palette={field?.mileosStatus === MileosConfStatus.COMPATIBLE ? COLORS.GASPACHO : COLORS.GRASS}
			>
				{field?.mileosStatus === MileosConfStatus.READY && (
					<Checkmark fill={COLORS.GRASS[100]} height={20} width={20} />
				)}
				<h5>
					{field?.mileosStatus === MileosConfStatus.READY
						? t("screens.dashboard.mileosInputs.setupFinished.label")
						: t("screens.dashboard.mileosInputs.needSetup.label")}
				</h5>
			</StyledInputTip>
			{!!mileosInfos?.varietyId && (
				<>
					<ClusterWrapper>
						<InlineWrapper>
							<h4>{t("screens.dashboard.mileosInputs.arvalisDevice.title")}</h4>
						</InlineWrapper>
						<MultiActionsButton>
							<ButtonCluster
								disabled={mileosInfos?.virtualWeatherDeviceActivated}
								hoverColor={COLORS.LAKE[100]}
								Icon={
									<Checkmark
										fill={
											mileosInfos?.virtualWeatherDeviceActivated
												? COLORS.LAKE[100]
												: "transparent"
										}
										height={20}
										width={20}
									/>
								}
								label={t("glossary.hygoWeatherDevice")}
								onClick={() => onSelectDevice({ deviceId: null })}
							/>
							<>
								{/* // eslint-disable-next-line react/jsx-no-useless-fragment */}
								{arvalisDevices?.map((item) => {
									const isSelectedDevice = item.id === mileosInfos?.deviceId;
									return (
										<ButtonCluster
											disabled={isSelectedDevice}
											hoverColor={COLORS.LAKE[100]}
											Icon={
												<Checkmark
													fill={isSelectedDevice ? COLORS.LAKE[100] : "transparent"}
													height={20}
													width={20}
												/>
											}
											key={item.id}
											label={`${item.constructorName} - ${item.deviceCode}`}
											onClear={() => onDeleteDevice(item.id)}
											onClick={() => onSelectDevice({ deviceId: item.id })}
										/>
									);
								})}
							</>
						</MultiActionsButton>
						<SimpleButtonWrapper onClick={onAddDevice}>
							<Plus fill={COLORS.LAKE[100]} height={16} width={16} />
							<SimpleButton text={t("screens.dashboard.mileosInputs.arvalisDevice.addDeviceBtn")} />
						</SimpleButtonWrapper>
					</ClusterWrapper>
					<ClusterWrapper>
						<InlineWrapper>
							<h4>{t("screens.dashboard.mileosInputs.phases.title")}</h4>
							<Link $highlight={false} onClick={onEditMileosPhases} text={t("button.edit")} />
						</InlineWrapper>
						{mileosInfos?.phases?.map((phase) => {
							return (
								<InlineWrapper key={phase.name}>
									<LabelWrapper>
										<h5>{t(`inputs.fieldPhases.${phase?.name}.label`)}</h5>
									</LabelWrapper>
									<Value>
										{phase.date
											? formatDateToLocale(
													new Date(phase?.date),
													`${i18n.resolvedLanguage}-${user?.countryCode}`
												)
											: "-"}
									</Value>
								</InlineWrapper>
							);
						})}
					</ClusterWrapper>
				</>
			)}
		</>
	);
};

export default MileosInputs;
