import { Nozzle as NozzleIcon, Sprayer as SprayerIcon } from "@hygo/shared/icons";
import { Nozzle, ProductUnit, Sprayer } from "@hygo/shared/models";
import { COLORS, NOZZLE_COLORS } from "@hygo/shared/utils";
import { InputTip } from "@hygo/web/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Section from "./Section";

interface EquipmentSectionProps {
	debit: number;
	isPelletMixture: boolean;
	nozzle: Nozzle;
	sprayer: Sprayer;
}

const Debit = styled.h4`
	color: var(--lake-100);
`;

const NozzleCircle = styled.div`
	width: 52px;
	height: 52px;
	border: 1px solid var(--grass-100);
	border-radius: 50%;
	background-color: var(--white-100);
	display: flex;
	align-items: center;
	justify-content: center;
`;

const NozzleWrapper = styled.div`
	background-color: var(--smoke-100);
	padding: 8px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const DebitWrapper = styled(NozzleWrapper)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Content = styled.div`
	gap: 16px;
	display: flex;
	flex-direction: column;
`;

const EquipmentSection = ({ debit, isPelletMixture, nozzle, sprayer }: EquipmentSectionProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Section
			Icon={<SprayerIcon fill={COLORS.LAKE[100]} glassesColor="transparent" height={20} width={20} />}
			title={isPelletMixture ? t("glossary.pellet") : sprayer?.name}
		>
			<Content>
				{isPelletMixture ? (
					<InputTip palette={COLORS.GRASS}>
						<h5>{t("components.pelletReportCard.label")}</h5>
					</InputTip>
				) : (
					<>
						<NozzleWrapper>
							<div>
								<h4>{t("glossary.usedNozzle")}</h4>
								<h5>{nozzle?.name}</h5>
							</div>
							<NozzleCircle>
								<NozzleIcon
									fill={nozzle?.color ? NOZZLE_COLORS[nozzle.color] : COLORS.WHITE[100]}
									height={35}
									width={25}
								/>
							</NozzleCircle>
						</NozzleWrapper>
						<DebitWrapper>
							<h4>{t("equipment.pressure")}</h4>
							<Debit>
								{debit?.toFixed(0)} {ProductUnit.LITER_PER_HA}
							</Debit>
						</DebitWrapper>
					</>
				)}
			</Content>
		</Section>
	);
};

export default EquipmentSection;
