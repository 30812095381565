export enum MileosAdvice {
	CROP_EMERGENCE_NOT_ACHIEVED = "CROP_EMERGENCE_NOT_ACHIEVED",
	CURATIVE_FUNGICIDE = "CURATIVE_FUNGICIDE",
	END_OF_CAMPAIGN = "END_OF_CAMPAIGN",
	ERADICATING_FUNGICIDE = "ERADICATING_FUNGICIDE",
	NO_PROVISIONAL_ADVICE = "NO_PROVISIONAL_ADVICE",
	NO_TREATMENT_NEEDED = "NO_TREATMENT_NEEDED",
	PROTECTED = "PROTECTED",
	TREATMENT_NEEDED = "TREATMENT_NEEDED"
}
