import { COLORS } from "@hygo/shared/utils";
import { CropsScreenContext } from "@hygo/web/contexts";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useContext } from "react";
import { ControlPosition, useControl } from "react-map-gl";

type DrawControlProps = {
	onCreate?: (evt: { features: object[] }) => void;
	onUpdate?: (evt: { action: string; features: object[] }) => void;
	position?: ControlPosition;
} & ConstructorParameters<typeof MapboxDraw>[0];

export const DrawControl = (props: DrawControlProps): JSX.Element => {
	const { drawRef } = useContext(CropsScreenContext);

	useControl<MapboxDraw>(
		() => {
			const draw = new MapboxDraw({
				...props,
				styles: [
					{
						filter: ["all", ["==", "$type", "LineString"]],
						id: "gl-draw-line",
						layout: {
							"line-cap": "round",
							"line-join": "round"
						},
						paint: {
							"line-color": COLORS.LAKE[100],
							"line-dasharray": [0.2, 2],
							"line-width": 2
						},
						type: "line"
					},
					{
						filter: ["all", ["==", "$type", "Polygon"]],
						id: "gl-draw-polygon-fill",
						paint: {
							"fill-color": COLORS.LAKE[50],
							"fill-outline-color": COLORS.LAKE[100]
						},
						type: "fill"
					},
					{
						filter: ["all", ["==", "$type", "Polygon"]],
						id: "gl-draw-polygon-stroke-active",
						layout: {
							"line-cap": "round",
							"line-join": "round"
						},
						paint: {
							"line-color": COLORS.LAKE[100],
							"line-dasharray": [0.2, 2],
							"line-width": 2
						},
						type: "line"
					},
					{
						filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
						id: "gl-draw-polygon-and-line-vertex-active",
						paint: {
							"circle-color": COLORS.WHITE[100],
							"circle-radius": 4
						},
						type: "circle"
					},
					{
						filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
						id: "gl-draw-polygon-midpoint",
						paint: {
							"circle-color": COLORS.WHITE[50],
							"circle-radius": 3
						},
						type: "circle"
					}
				]
			});
			drawRef.current = draw;
			return draw;
		},
		({ map }) => {
			map.on("draw.create", props.onCreate);
			map.on("draw.update", props.onUpdate);
		},
		({ map }) => {
			map.off("draw.create", props.onCreate);
			map.off("draw.update", props.onUpdate);
		}
	);

	return null;
};
