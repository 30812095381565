import * as turf from "@turf/turf";
import { MutableRefObject } from "react";
import { LngLatBoundsLike, MapRef } from "react-map-gl";

const DEFAULT_COORDS = { lat: 47.17544, lon: 2.22327 };

export const fitMapBounds = ({
	fields,
	mapRef,
	userLocation
}: {
	fields: { coordinates: { lat: number; lon: number }[] }[];
	mapRef: MutableRefObject<MapRef>;
	userLocation: { lat: number; lon: number };
}): void => {
	let bounds: LngLatBoundsLike = [
		[DEFAULT_COORDS.lon + 3, DEFAULT_COORDS.lat + 3],
		[DEFAULT_COORDS.lon - 3, DEFAULT_COORDS.lat - 3]
	];
	if (userLocation)
		bounds = [
			[userLocation.lon + 0.005, userLocation.lat + 0.005],
			[userLocation.lon - 0.005, userLocation.lat - 0.005]
		];
	const coordinates = fields?.flatMap((f) => f.coordinates)?.filter((f) => f);
	if (coordinates?.length) {
		bounds = turf.bbox(turf.lineString(coordinates?.map((c) => [c.lon, c.lat]))) as [
			number,
			number,
			number,
			number
		];
	}

	mapRef.current.fitBounds(bounds, { duration: 500 });
};
