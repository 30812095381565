import { ArrowLeft, Idea, Parcelles, ParcelleSuccess, RecycleCrop, SadDrop } from "@hygo/shared/icons";
import { Crop, Farm, Field } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { ImportedField } from "@hygo/web/models";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Button from "../Button";
import Checkbox from "../Checkbox";
import EmptyState from "../EmptyState";
import FormCard from "../FormCard";
import FormHeader from "../FormHeader";
import ImportState from "../ImportState";
import InputTip from "../InputTip";
import Loader from "../Loader";
import SwitchButton from "../SwitchButton";
import Row from "./components/Row";
import Skeleton from "./components/Skeleton";

interface FieldsLinkingGridProps {
	backFromError: () => void;
	crops: Crop[];
	cropSynchronize: boolean;
	defaultFarm: Farm;
	error: boolean;
	fields: Field[];
	fieldsToImport: ImportedField[];
	goBack: () => void;
	goToDashboard: () => void;
	greenInputTipText: string;
	importedFieldsLabel: string;
	importFieldsLogo?: JSX.Element;
	linking: { fieldId: number; importedFieldId: string; selected: boolean }[];
	loading: boolean;
	loadingImportedFields: boolean;
	onChange: (data: { fieldId: number; importedFieldId: string }) => void;
	onClickCheckbox: (importedFieldId?: string) => void;
	onClickCropSynchronize: (checked: boolean) => void;
	onSubmit: () => void;
	retryImport: () => void;
	subtitle: string;
	success: boolean;
}

const Wrapper = styled.div`
	padding: 16px 0;
	background: var(--gradient-background-2);
	height: 100%;
	display: flex;
`;

const Card = styled(FormCard)`
	overflow: hidden;
	margin: auto;
	max-height: 100%;
	box-shadow: 0px 6px 60px rgba(0, 83, 94, 0.05);
	display: flex;
	flex-direction: column;
`;
const FlowWrapper = styled.div`
	margin-top: 32px;
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
`;

const InputTipText = styled.h5`
	flex: 1;
`;

const InputTipsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 16px 0;
`;

const Grid = styled.div`
	display: grid;
	padding-bottom: 80px;
	gap: 8px;
	grid-template-columns: 16px 1fr 1fr auto;
	overflow: auto;
	grid-template-rows: 37px;
	flex: 1;
`;

const SkeletonGrid = styled(Grid)`
	padding-bottom: 0;
	grid-template-columns: 1fr 1fr 0.5fr;
	grid-template-rows: unset;
`;

const GreyCard = styled.div`
	padding: 8px;
	background-color: var(--smoke-100);
	border-radius: 8px;
	border: 1px solid var(--night-5);
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
`;

const SwitchWrapper = styled.div`
	margin-top: 16px;
	background: var(--smoke-100);
	border: 0.5px solid var(--night-10);
	padding: 8px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const SwitchLabelWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const StyledCheckbox = styled(Checkbox)`
	width: 16px;
	align-self: center;
	height: 16px;
`;

const NoFieldsText = styled.p`
	margin-bottom: 32px;
`;

const ValidateButton = styled(Button)`
	flex-shrink: 0;
`;

const StyledInputTip = styled(InputTip)`
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
`;

const FieldsLinkingGrid = ({
	backFromError,
	crops,
	cropSynchronize,
	defaultFarm,
	error,
	fields,
	fieldsToImport,
	goBack,
	goToDashboard,
	greenInputTipText,
	importedFieldsLabel,
	importFieldsLogo,
	linking,
	loading,
	loadingImportedFields,
	onChange,
	onClickCheckbox,
	onClickCropSynchronize,
	onSubmit,
	retryImport,
	subtitle,
	success
}: FieldsLinkingGridProps): JSX.Element => {
	const { t } = useTranslation();

	const showWithFieldsStatus = fieldsToImport?.length > 0 || loadingImportedFields;

	return (
		<Wrapper>
			{defaultFarm ? (
				<Card>
					<FormHeader
						backIcon={!loading && !error && !success && showWithFieldsStatus && <ArrowLeft />}
						onGoBack={!loading && !error && !success && showWithFieldsStatus && goBack}
						subTitle={!loading && !error && !success && showWithFieldsStatus && subtitle}
						subtitleIcon={importFieldsLogo}
						title={
							!error &&
							!success &&
							!loading &&
							(showWithFieldsStatus
								? t("components.fieldsLinkingGrid.title.withFields")
								: t("components.fieldsLinkingGrid.title.noFields"))
						}
					/>
					{loading ? (
						<div>
							<Loader message={t("components.fieldsLinkingGrid.loaderMessage")} />
						</div>
					) : (
						<>
							{success && (
								<ImportState
									Icon={<ParcelleSuccess />}
									onClick={goToDashboard}
									primaryBtnText={t("button.continue")}
									title={t("components.fieldsLinkingGrid.success.title")}
								/>
							)}
							{error && (
								<ImportState
									Icon={<SadDrop />}
									onCancel={backFromError}
									onClick={retryImport}
									primaryBtnText={t("button.retry")}
									secondaryBtnText={t("button.cancel")}
									subtitle={t("components.fieldsLinkingGrid.error.description")}
									title={t("components.fieldsLinkingGrid.error.title")}
								/>
							)}
							{!success && !error && (
								<FlowWrapper>
									{loadingImportedFields ? (
										<SkeletonGrid>
											{Array.from({ length: 4 }).map((_, i) => (
												<Skeleton key={i} />
											))}
										</SkeletonGrid>
									) : (
										<>
											{fieldsToImport?.length > 0 && (
												<>
													<Grid>
														<StyledCheckbox
															checked={linking?.every((l) => l.selected)}
															isIndeterminate={linking?.some((l) => l.selected)}
															onClick={() => onClickCheckbox()}
														/>

														<GreyCard>
															{importFieldsLogo}

															<h4>{importedFieldsLabel}</h4>
														</GreyCard>
														<GreyCard>
															<h4>{t("components.fieldsLinkingGrid.hygoLabel")}</h4>
														</GreyCard>
														<GreyCard>
															<h5>
																{t("components.fieldsLinkingGrid.field", {
																	count: fieldsToImport?.length
																})}
															</h5>
														</GreyCard>
														{fieldsToImport?.map((f) => {
															const link = linking?.find(
																(l) => l.importedFieldId === f.id
															);
															return (
																<Row
																	crops={crops}
																	cropSynchronize={cropSynchronize}
																	field={f}
																	fields={fields}
																	key={f.id}
																	link={link}
																	onChange={onChange}
																	onClickCheckbox={onClickCheckbox}
																/>
															);
														})}
													</Grid>
													<InputTipsWrapper>
														<SwitchWrapper>
															<SwitchLabelWrapper>
																<RecycleCrop
																	fill={COLORS.LAKE[100]}
																	height={20}
																	width={20}
																/>
																<h5>
																	{t(
																		"components.fieldsLinkingGrid.switchButton.label"
																	)}
																</h5>
															</SwitchLabelWrapper>
															<SwitchButton
																name="updateCrops"
																onChange={(e) =>
																	onClickCropSynchronize(e.target.checked)
																}
																value={cropSynchronize}
															/>
														</SwitchWrapper>
														<StyledInputTip palette={COLORS.GRASS}>
															<Idea fill={COLORS.GRASS[100]} height={20} width={20} />
															<InputTipText>{greenInputTipText}</InputTipText>
														</StyledInputTip>
													</InputTipsWrapper>
													<ValidateButton
														color={COLORS.LAKE}
														disabled={!linking?.some((l) => l.selected)}
														onClick={onSubmit}
														text={t("button.validate")}
													/>
												</>
											)}
											{!(fieldsToImport?.length > 0) && (
												<>
													<NoFieldsText>
														{t("components.fieldsLinkingGrid.noFields")}
													</NoFieldsText>
													<ValidateButton
														color={COLORS.LAKE}
														onClick={goBack}
														text={t("components.fieldsLinkingGrid.backToImportBtn")}
													/>
												</>
											)}
										</>
									)}
								</FlowWrapper>
							)}
						</>
					)}
				</Card>
			) : (
				<EmptyState
					btnIcon={Parcelles}
					btnText={t("components.emptyState.noDefaultFarm.btn")}
					description={t("components.emptyState.noDefaultFarm.description")}
					illustration={<img alt="ferme" src={"/assets/images/coop.png"} width={100} />}
					onClick={goToDashboard}
					title={t("components.emptyState.noDefaultFarm.title")}
				/>
			)}
		</Wrapper>
	);
};

export default FieldsLinkingGrid;
