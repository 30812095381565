import { AmplitudeContext } from "@hygo/shared/amplitude";
import { AxiosContext, useApi } from "@hygo/shared/api";
import {
	ApplicationState,
	ArvalisDevice,
	Auth,
	AuthEvents,
	Chargebee,
	CoopUser,
	Crop,
	Farm,
	Feature,
	Field,
	MileosAdvisor,
	MileosVariety,
	Nozzle,
	Plan,
	Product,
	SetupError,
	SnackbarType,
	Sprayer,
	Target,
	User,
	UserLevel,
	Weather,
	WeatherError
} from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { getStartOfDayAsJSDate } from "@hygo/shared/utils";
import { FlagsProvider } from "flagged";
import i18next from "i18next";
import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface UserContextResult {
	activeFields: Field[];
	activeNozzles: Nozzle[];
	activeSprayers: Sprayer[];
	admin: boolean;
	appState: string;
	arvalisDevices: ArvalisDevice[];
	arvalisDevicesConstructors: string[];
	authError: SetupError;
	authLoading: boolean;
	authLoadingStep: string;
	chargebee: Chargebee;
	checkSetupAndFetchWeather: (data: { farmId: number; hasFarmWeather: boolean }) => Promise<boolean>;
	coopUsers: CoopUser[];
	crops: Crop[];
	defaultFarm: Farm;
	farmerSelected: boolean;
	farms: Farm[];
	fetchUser: () => Promise<User>;
	fetchWeather: (farmId: number, hasFarmWeather: boolean) => Promise<void>;
	fields: Field[];
	hasConnection: boolean;
	loadArvalisDevices: () => Promise<void>;
	loadFarms: () => Promise<Farm[]>;
	loadFields: (onlyChecked: boolean, farmId: number) => Promise<Field[]>;
	loading: boolean;
	loadMileosAdvisors: () => Promise<void>;
	loadMileosVarieties: () => Promise<void>;
	loadNozzles: () => Promise<void>;
	loadSprayers: () => Promise<void>;
	login: (token: string, withToken?: boolean) => Promise<void>;
	loginWithLocalToken: (token: string) => Promise<void>;
	logout: (data?: { canDeletePushToken: boolean }) => Promise<void>;
	meteo: Weather;
	meteoError: WeatherError;
	mileosAdvisors: MileosAdvisor[];
	mileosVarieties: MileosVariety[];
	nozzles: Nozzle[];
	planId: string;
	plans: Plan[];
	products: Product[];
	pushToken: string;
	setAppState: Dispatch<SetStateAction<string>>;
	setAuthLoading: Dispatch<SetStateAction<boolean>>;
	setChargebee: Dispatch<SetStateAction<Chargebee>>;
	setFields: Dispatch<SetStateAction<Field[]>>;
	setHasConnection: Dispatch<SetStateAction<boolean>>;
	setPushToken: Dispatch<SetStateAction<string>>;
	signInAsUser: (userId: number) => Promise<void>;
	sprayers: Sprayer[];
	status: Auth;
	superAdmin: boolean;
	targets: Target[];
	updateDefaultFarm: (farmId: number, newFarms?: Farm[]) => Promise<Field[]>;
	user: User;
	userToMonitor: User;
}

export const UserContext = createContext({} as UserContextResult);

interface UserProviderProps {
	captureSentryException: (e: Error) => void;
	children: JSX.Element;
	deleteAuthCookie: () => Promise<void>;
	getPushToken?: () => Promise<string>;
	initIntercomMobile?: (user: User) => Promise<void>;
	isMobile: boolean;
	storeAuthCookie: (token: string) => Promise<void>;
	versioning: { build?: string; OTA?: string; version: string };
}

const UserProvider = ({
	captureSentryException,
	children,
	deleteAuthCookie,
	getPushToken,
	initIntercomMobile,
	isMobile,
	storeAuthCookie,
	versioning
}: UserProviderProps): JSX.Element => {
	const { logAnalyticEvent, setUser: setAmplitudeUser, setUserId } = useContext(AmplitudeContext);

	const { updateAuthToken } = useContext(AxiosContext);
	const { showSnackbar } = useContext(SnackbarContext);
	const {
		checkSetup,
		createPushToken,
		deletePushToken,
		getAbout,
		getAdminToken,
		getArvalisDevices,
		getArvalisDevicesConstructors,
		getCoopUsers,
		getCrops,
		getFarms,
		getFarmWeather,
		getFields,
		getLocaleWeather,
		getMileosAdvisors,
		getMileosVarieties,
		getNozzles,
		getPlans,
		getProducts,
		getSprayers,
		getTargets,
		getUser,
		patchUser
	} = useApi();
	const { i18n } = useTranslation();

	const [chargebee, setChargebee] = useState<UserContextResult["chargebee"]>(null);
	const [userToMonitor, setUserToMonitor] = useState<UserContextResult["userToMonitor"]>(null);
	const [pushToken, setPushToken] = useState<UserContextResult["pushToken"]>(null);
	const [authLoading, setAuthLoading] = useState<UserContextResult["authLoading"]>(true);
	const [status, setStatus] = useState<UserContextResult["status"]>(Auth.LOGGED_OUT);
	const [user, setUser] = useState<UserContextResult["user"]>(null);
	const [admin, setAdmin] = useState<UserContextResult["admin"]>(false);
	const [authLoadingStep, setAuthLoadingStep] = useState<UserContextResult["authLoadingStep"]>(null);
	const [superAdmin, setSuperAdmin] = useState<UserContextResult["superAdmin"]>(false);
	const [coopUsers, setCoopUsers] = useState<UserContextResult["coopUsers"]>([]);
	const [fields, setFields] = useState<UserContextResult["fields"]>([]);
	const [sprayers, setSprayers] = useState<UserContextResult["sprayers"]>([]);
	const [farms, setFarms] = useState<UserContextResult["farms"]>([]);
	const [mileosAdvisors, setMileosAdvisors] = useState<UserContextResult["mileosAdvisors"]>([]);
	const [defaultFarm, setDefaultFarm] = useState<UserContextResult["defaultFarm"]>();
	const [crops, setCrops] = useState<UserContextResult["crops"]>([]);
	const [plans, setPlans] = useState<UserContextResult["plans"]>([]);
	const [products, setProducts] = useState<UserContextResult["products"]>([]);
	const [targets, setTargets] = useState<UserContextResult["targets"]>([]);
	const [mileosVarieties, setMileosVarieties] = useState<UserContextResult["mileosVarieties"]>([]);
	const [arvalisDevices, setArvalisDevices] = useState<UserContextResult["arvalisDevices"]>([]);
	const [arvalisDevicesConstructors, setArvalisDevicesConstructors] = useState<
		UserContextResult["arvalisDevicesConstructors"]
	>([]);

	const [nozzles, setNozzles] = useState<UserContextResult["nozzles"]>([]);
	const [meteoError, setMeteoError] = useState<UserContextResult["meteoError"]>();
	const [meteo, setMeteo] = useState<UserContextResult["meteo"]>();
	const [authError, setAuthError] = useState<UserContextResult["authError"]>();
	const [hasConnection, setHasConnection] = useState<UserContextResult["hasConnection"]>(true);
	const [loading, setLoading] = useState<UserContextResult["loading"]>(false);
	const [appState, setAppState] = useState<UserContextResult["appState"]>(ApplicationState.ACTIVE);
	const farmerSelected = !!user?.id;
	const planId = user?.plan?.planId;
	const activeFields = useMemo(() => fields?.filter((f) => !f.deleted), [fields]);
	const activeNozzles = useMemo(() => nozzles?.filter((f) => !f.deleted), [nozzles]);
	const activeSprayers = useMemo(() => sprayers?.filter((f) => !f.deleted), [sprayers]);
	const resetState = (): void => {
		setUser(null);
		setAdmin(false);
		setSuperAdmin(false);
		setDefaultFarm(null);
		setUserToMonitor(null);
		setFields([]);
		setFarms([]);
		setPushToken(null);
		setSprayers([]);
		setNozzles([]);
		setProducts([]);
		setTargets([]);
		setMileosVarieties([]);
		setMeteoError(null);
		setMeteo(null);
		setCrops([]);
	};

	const fetchUser: UserContextResult["fetchUser"] = useCallback(async () => {
		const { build, OTA, version } = versioning;
		const userProps = await getUser();

		setUser(userProps);
		setAmplitudeUser({ ...userProps, build, OTA, version });
		return userProps;
	}, [getUser, setAmplitudeUser, versioning]);

	const fetchCoopUsers = useCallback(async (): Promise<void> => {
		const fetchedUsers = await getCoopUsers();
		const collator = new Intl.Collator(i18n.resolvedLanguage, { numeric: true, sensitivity: "base" });
		const fetchedUsersSorted = fetchedUsers?.sort((a, b) => collator.compare(a.firstName, b.firstName));
		setCoopUsers(fetchedUsersSorted);
	}, [getCoopUsers, i18n]);

	const fetchFarmWeather = useCallback(
		async (farmId: number): Promise<void> => {
			if (!farmId) return;
			const fetchedMeteo = await getFarmWeather({
				farmId,
				startAt: getStartOfDayAsJSDate(new Date())
			});

			setMeteo(fetchedMeteo);
		},
		[getFarmWeather]
	);

	const fetchLocaleWeather = useCallback(async (): Promise<void> => {
		const fetchedMeteo = await getLocaleWeather(getStartOfDayAsJSDate(new Date()));
		setMeteo(fetchedMeteo);
	}, [getLocaleWeather]);

	const loadFields: UserContextResult["loadFields"] = useCallback(
		async (onlyChecked, farmId) => {
			const fetchedFields = await getFields({ farmId, onlyChecked });
			setFields(fetchedFields);
			return fetchedFields;
		},
		[getFields]
	);

	const updateDefaultFarm: UserContextResult["updateDefaultFarm"] = useCallback(
		async (farmId, newFarms) => {
			await patchUser({ defaultFarmId: farmId || null });
			await fetchUser();
			const farm = (newFarms || farms)?.find((f) => f.id === farmId);
			setDefaultFarm(farm);
			if (farmId && user?.plan?.features?.includes(Feature.FARM_WEATHER)) {
				const fetchedFields = await loadFields(isMobile, farmId);
				return fetchedFields;
			}
			if (!farmId) setFields([]);
		},
		[fetchUser, patchUser, isMobile, loadFields, farms, user]
	);

	const _logout = useCallback(
		async ({
			canDeletePushToken,
			userPushToken,
			withStatus
		}: {
			canDeletePushToken: boolean;
			userPushToken: string;
			withStatus: boolean;
		}): Promise<void> => {
			try {
				if (userPushToken && canDeletePushToken) await deletePushToken(userPushToken);
				if (chargebee) chargebee.logout();
				await deleteAuthCookie();
				updateAuthToken(null);
				if (withStatus) setStatus(Auth.LOGGED_OUT);
				resetState();
			} catch (error) {
				showSnackbar(i18next.t("snackbar.logout.error"), SnackbarType.ERROR);
				throw error;
			}
		},
		[showSnackbar, deletePushToken, deleteAuthCookie, updateAuthToken, chargebee]
	);

	const logout: UserContextResult["logout"] = useCallback(
		async (params: { canDeletePushToken: boolean }) => {
			logAnalyticEvent(AuthEvents.logout);
			await _logout({
				canDeletePushToken: params?.canDeletePushToken,
				userPushToken: pushToken,
				withStatus: true
			});
		},
		[_logout, pushToken, logAnalyticEvent]
	);

	const fetchWeather: UserContextResult["fetchWeather"] = useCallback(
		async (farmId, hasFarmWeather) => {
			try {
				setMeteoError(null);
				hasFarmWeather ? await fetchFarmWeather(farmId) : await fetchLocaleWeather();
			} catch (e) {
				if (e?.response?.data?.code === "someFieldsDontHaveWeatherYet")
					setMeteoError(WeatherError.SOME_FIELDS_DONT_HAVE_WEATHER_YET);
				else if (!e?.response?.status) setMeteoError(WeatherError.NETWORK_ERROR);
				else throw e;
			}
		},
		[fetchFarmWeather, fetchLocaleWeather]
	);

	const checkSetupAndFetchWeather: UserContextResult["checkSetupAndFetchWeather"] = useCallback(
		async ({ farmId, hasFarmWeather }) => {
			try {
				await checkSetup();
				if (farmId) await fetchWeather(farmId, hasFarmWeather);
				setStatus(Auth.LOGGED_IN);
				return true;
			} catch (e) {
				if (e?.response?.data?.code === SetupError.defaultFarmWithoutFields) {
					setStatus(Auth.NEED_FIELDS);
				} else if (e?.response?.data?.code === SetupError.equipmentMissing) {
					setStatus(Auth.NEED_EQUIPMENT);
				} else {
					setAuthError(
						Object.values(SetupError).includes(e?.response?.data?.code)
							? e?.response?.data?.code
							: SetupError.noNetwork
					);
					setStatus(Auth.NEED_SETUP);
				}
			}
		},
		[checkSetup, fetchWeather]
	);

	const checkAbout = useCallback(async (): Promise<boolean> => {
		let fetchedAbout;
		try {
			fetchedAbout = await getAbout();
		} catch (e) {
			setAuthError(SetupError.noNetwork);
			setStatus(Auth.NEED_SETUP);
			throw e;
		}
		const maintenance = fetchedAbout.maintenance;
		const newBuildAvailable = parseInt(versioning.build, 10) < fetchedAbout.build;
		if (maintenance) {
			setAuthError(SetupError.ongoingMaintenance);
			setStatus(Auth.NEED_SETUP);
			return;
		}
		if (newBuildAvailable) {
			setAuthError(SetupError.needUpdateVersion);
			setStatus(Auth.NEED_SETUP);
			return;
		}

		return !maintenance && !newBuildAvailable;
	}, [getAbout, versioning]);

	const loadFarms: UserContextResult["loadFarms"] = useCallback(async () => {
		const fetchedFarms = await getFarms();
		setFarms(fetchedFarms);
		return fetchedFarms;
	}, [getFarms]);

	const loadPlans = useCallback(async (): Promise<Plan[]> => {
		const fetchedPlans = await getPlans();
		setPlans(fetchedPlans);
		return fetchedPlans;
	}, [getPlans]);

	const loadCrops = useCallback(async (): Promise<void> => {
		const fetchedCrops = await getCrops();
		const collator = new Intl.Collator(i18n.resolvedLanguage, { numeric: true, sensitivity: "base" });
		setCrops(fetchedCrops.sort((a, b) => collator.compare(a.name, b.name)));
	}, [getCrops, i18n]);

	const loadSprayers: UserContextResult["loadSprayers"] = useCallback(async () => {
		const fetchedSprayers = await getSprayers();
		setSprayers(fetchedSprayers);
	}, [getSprayers]);

	const loadNozzles: UserContextResult["loadNozzles"] = useCallback(async () => {
		const fetchedNozzles = await getNozzles();
		setNozzles(fetchedNozzles);
	}, [getNozzles]);

	const loadMileosAdvisors = useCallback(async (): Promise<void> => {
		const fetchedMileosAdvisors = await getMileosAdvisors();
		setMileosAdvisors(fetchedMileosAdvisors);
	}, [getMileosAdvisors]);

	const loadProducts = useCallback(async (): Promise<void> => {
		const fetchedProducts = await getProducts();
		const collator = new Intl.Collator(i18n.resolvedLanguage, { numeric: true, sensitivity: "base" });
		setProducts(fetchedProducts.sort((a, b) => collator.compare(a.name, b.name)));
	}, [getProducts, i18n]);

	const loadTargets = useCallback(async (): Promise<void> => {
		const fetchedTargets = await getTargets();
		const collator = new Intl.Collator(i18n.resolvedLanguage, { numeric: true, sensitivity: "base" });
		setTargets(fetchedTargets?.sort((a, b) => collator.compare(a.name, b.name)));
	}, [getTargets, i18n]);

	const loadMileosVarieties = useCallback(async (): Promise<void> => {
		const fetchedVarieties = await getMileosVarieties();
		const collator = new Intl.Collator(i18n.resolvedLanguage, { numeric: true, sensitivity: "base" });
		setMileosVarieties(fetchedVarieties?.sort((a, b) => collator.compare(a.name, b.name)));
	}, [getMileosVarieties, i18n]);

	const loadArvalisDevices: UserContextResult["loadArvalisDevices"] = useCallback(async () => {
		const fetchedArvalisDevices = await getArvalisDevices();
		const collator = new Intl.Collator(i18n.resolvedLanguage, { numeric: true, sensitivity: "base" });
		setArvalisDevices(fetchedArvalisDevices?.sort((a, b) => collator.compare(a.deviceCode, b.deviceCode)));
	}, [getArvalisDevices, i18n]);

	const loadArvalisDevicesConstructors = useCallback(async (): Promise<void> => {
		const fetchedConstructors = await getArvalisDevicesConstructors();
		const collator = new Intl.Collator(i18n.resolvedLanguage, { numeric: true, sensitivity: "base" });
		setArvalisDevicesConstructors(fetchedConstructors?.sort((a, b) => collator.compare(a, b)));
	}, [getArvalisDevicesConstructors, i18n]);

	const initData = useCallback(
		async ({
			defaultFarmId,
			features,
			onlyChecked
		}: {
			defaultFarmId: number;
			features: Feature[];
			onlyChecked: boolean;
		}): Promise<void> => {
			setAuthLoadingStep("plans");
			await loadPlans();
			const hasFarmWeather = features?.includes(Feature.FARM_WEATHER);
			const hasMileos = features?.includes(Feature.MILEOS);
			const hasTasks = features?.includes(Feature.TASKS);
			setAuthLoadingStep("products");
			await loadProducts();
			await loadTargets();
			await loadCrops();
			if (hasMileos) {
				setAuthLoadingStep("mileos");
				try {
					await loadMileosVarieties();
					await loadMileosAdvisors();
				} catch (e) {
					if (e?.response?.data?.code !== "mileosErrors" && e.code !== "ECONNABORTED") throw e;
				}

				await loadArvalisDevicesConstructors();
				await loadArvalisDevices();
			}
			if (hasTasks) {
				setAuthLoadingStep("sprayers");
				await loadSprayers();
				setAuthLoadingStep("nozzles");
				await loadNozzles();
			}
			setAuthLoadingStep("farms");
			const fetchedFarms = await loadFarms();
			if (fetchedFarms?.length > 0) {
				const userDefaultFarm = fetchedFarms?.find((f) => f.id === defaultFarmId);
				setDefaultFarm(userDefaultFarm);
				if (userDefaultFarm && hasFarmWeather) {
					setAuthLoadingStep("fields");
					await loadFields(onlyChecked, userDefaultFarm?.id);
				}
			}
		},
		[
			loadFarms,
			loadTargets,
			loadMileosAdvisors,
			loadArvalisDevices,
			loadArvalisDevicesConstructors,
			loadMileosVarieties,
			loadFields,
			loadCrops,
			loadSprayers,
			loadNozzles,
			loadProducts,
			loadPlans
		]
	);

	const initPushToken = useCallback(async (): Promise<string> => {
		let fetchedPushToken;
		try {
			fetchedPushToken = await getPushToken();
			setPushToken(fetchedPushToken);
			if (fetchedPushToken) await createPushToken(fetchedPushToken);
			return fetchedPushToken;
		} catch (e) {
			captureSentryException(e);
		}
	}, [createPushToken, getPushToken, captureSentryException]);

	const login: UserContextResult["login"] = useCallback(
		async (token, withToken) => {
			let fetchedPushToken: string;
			try {
				updateAuthToken(token);
				setAuthLoadingStep("user");
				await storeAuthCookie(token);
				const fetchedUser = await fetchUser();
				if (fetchedUser?.language) await i18next.changeLanguage(fetchedUser?.language);
				setUserToMonitor(fetchedUser);
				setUserId(fetchedUser?.id?.toString() || fetchedUser?.admin?.userId?.toString());
				setAuthLoadingStep("setup");
				if (fetchedUser?.admin?.userId) {
					setAdmin(true);
					await fetchCoopUsers();
					if (fetchedUser?.admin?.level === UserLevel.ADMIN) setSuperAdmin(true);
				}
				const isAdmin = !fetchedUser?.id;
				if (!isMobile) setStatus(Auth.LOGGED_IN);
				if (isAdmin && isMobile) setStatus(Auth.ADMIN);
				if (isAdmin) return;
				setLoading(true);
				await initData({
					defaultFarmId: fetchedUser.setup.defaultFarmId,
					features: fetchedUser?.plan?.features,
					onlyChecked: !!isMobile
				});
				logAnalyticEvent(AuthEvents.login, { withToken });
				setLoading(false);
				if (isMobile) {
					await initIntercomMobile(fetchedUser);
					fetchedPushToken = await initPushToken();
					await checkSetupAndFetchWeather({
						farmId: fetchedUser?.setup.defaultFarmId,
						hasFarmWeather: fetchedUser?.plan?.features?.includes(Feature.FARM_WEATHER)
					});
				}
			} catch (e) {
				if (!e?.response?.status) {
					setAuthError(SetupError.noNetwork);
					setStatus(Auth.NEED_SETUP);
					return;
				}
				if (e?.response?.status !== 401) logAnalyticEvent(AuthEvents.logoutFromError, { error: e });
				_logout({ canDeletePushToken: true, userPushToken: fetchedPushToken, withStatus: true });
				throw e;
			}
		},
		[
			_logout,
			initData,
			checkSetupAndFetchWeather,
			logAnalyticEvent,
			isMobile,
			initPushToken,
			initIntercomMobile,
			fetchCoopUsers,
			fetchUser,
			storeAuthCookie,
			updateAuthToken,
			setUserId
		]
	);

	const signInAsUser: UserContextResult["signInAsUser"] = useCallback(
		async (userId) => {
			setAuthLoading(true);
			const token = await getAdminToken(userId);
			await _logout({ canDeletePushToken: true, userPushToken: pushToken, withStatus: false });
			await login(token);
			setAuthLoading(false);
		},
		[_logout, login, getAdminToken, pushToken]
	);

	const loginWithLocalToken: UserContextResult["loginWithLocalToken"] = useCallback(
		async (token) => {
			try {
				const isCompatible = await checkAbout();
				if (!token || !isCompatible) return;
				setAuthLoading(true);
				await login(token, true);
			} catch (e) {
				if (e?.response?.status !== 401) {
					showSnackbar(i18next.t("snackbar.login.error"), SnackbarType.ERROR);
					throw e;
				}
			} finally {
				const timeout = token ? 0 : 1000;
				setTimeout(() => {
					setAuthLoading(false);
				}, timeout);
			}
		},
		[login, showSnackbar, checkAbout]
	);

	useEffect(() => {
		const checkSetupFromBackground = async (): Promise<void> => {
			const isCompatible = await checkAbout();
			if (isCompatible && status === Auth.LOGGED_IN && user && authError !== SetupError.noNetwork) {
				await checkSetupAndFetchWeather({
					farmId: user?.setup?.defaultFarmId,
					hasFarmWeather: user?.plan?.features?.includes(Feature.FARM_WEATHER)
				});
			}
		};
		if (appState === ApplicationState.BACKGROUND && isMobile) checkSetupFromBackground();
	}, [appState, status, user, checkSetupAndFetchWeather, checkAbout, isMobile, authError]);

	const value = useMemo(
		() => ({
			activeFields,
			activeNozzles,
			activeSprayers,
			admin,
			appState,
			arvalisDevices,
			arvalisDevicesConstructors,
			authError,
			authLoading,
			authLoadingStep,
			chargebee,
			checkSetupAndFetchWeather,
			coopUsers,
			crops,
			defaultFarm,
			farmerSelected,
			farms,
			fetchUser,
			fetchWeather,
			fields,
			hasConnection,
			loadArvalisDevices,
			loadFarms,
			loadFields,
			loading,
			loadMileosAdvisors,
			loadMileosVarieties,
			loadNozzles,
			loadSprayers,
			login,
			loginWithLocalToken,
			logout,
			meteo,
			meteoError,
			mileosAdvisors,
			mileosVarieties,
			nozzles,
			planId,
			plans,
			products,
			pushToken,
			setAppState,
			setAuthLoading,
			setChargebee,
			setFields,
			setHasConnection,
			setPushToken,
			signInAsUser,
			sprayers,
			status,
			superAdmin,
			targets,
			updateDefaultFarm,
			user,
			userToMonitor
		}),
		[
			logout,
			setPushToken,
			loading,
			mileosVarieties,
			planId,
			farmerSelected,
			activeNozzles,
			arvalisDevices,
			arvalisDevicesConstructors,
			loadArvalisDevices,
			setFields,
			loadSprayers,
			loadFarms,
			activeFields,
			setAuthLoading,
			coopUsers,
			pushToken,
			mileosAdvisors,
			loginWithLocalToken,
			fetchUser,
			defaultFarm,
			updateDefaultFarm,
			authLoadingStep,
			plans,
			loadFields,
			loadNozzles,
			fetchWeather,
			userToMonitor,
			targets,
			loadMileosAdvisors,
			loadMileosVarieties,
			farms,
			nozzles,
			setAppState,
			sprayers,
			appState,
			products,
			fields,
			meteo,
			crops,
			meteoError,
			superAdmin,
			hasConnection,
			user,
			authLoading,
			login,
			authError,
			chargebee,
			status,
			activeSprayers,
			signInAsUser,
			admin,
			checkSetupAndFetchWeather
		]
	);

	return (
		<UserContext.Provider value={value}>
			<FlagsProvider features={user?.plan?.features}>{children}</FlagsProvider>
		</UserContext.Provider>
	);
};

export default UserProvider;
