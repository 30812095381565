import { Country } from "@hygo/shared/models";
import { Autocomplete, Libraries, LoadScriptNext } from "@react-google-maps/api";
import { useCallback, useState } from "react";
import { RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";

import TextInput from "../TextInput";

interface GooglePlacesAutocompleteProps {
	countryCode: Country;
	flex?: boolean;
	hint?: string;
	label?: string;
	name: string;
	onChange: (details: { label: string; lat: number; lon: number }) => void;
	placeholder?: string;
	rules?: Omit<RegisterOptions, "deps">;
}

const mapLibs: Libraries = ["drawing", "places"];

const GooglePlacesAutocompleteComponent = ({
	countryCode,
	flex,
	hint,
	label,
	name,
	onChange,
	placeholder,
	rules
}: GooglePlacesAutocompleteProps): JSX.Element => {
	const { i18n } = useTranslation();
	const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
	const [loaded, setLoaded] = useState<boolean>(false);

	const onLoad = useCallback((autocompleteComp: google.maps.places.Autocomplete) => {
		setAutocomplete(autocompleteComp);
	}, []);

	const onPlaceChanged = async (): Promise<void> => {
		if (!autocomplete) return;
		const place = autocomplete.getPlace();
		onChange({
			label: place.formatted_address,
			lat: place.geometry.location.lat(),
			lon: place.geometry.location.lng()
		});
	};

	return (
		<LoadScriptNext
			googleMapsApiKey={`${process.env.NX_PUBLIC_GOOGLE_MAPS_API}&loading=async`}
			key={`map-${i18n.resolvedLanguage}`}
			language={i18n.resolvedLanguage}
			libraries={mapLibs}
			onLoad={() => setLoaded(true)}
		>
			{loaded && (
				<Autocomplete
					onLoad={onLoad}
					onPlaceChanged={onPlaceChanged}
					restrictions={{ country: countryCode.toLowerCase() }}
					types={["postal_code", "sublocality", "locality"]}
				>
					<TextInput
						autoComplete="postal-code"
						flex={flex}
						hint={hint}
						label={label}
						name={name}
						placeholder={placeholder}
						rules={rules}
					/>
				</Autocomplete>
			)}
		</LoadScriptNext>
	);
};

export default GooglePlacesAutocompleteComponent;
