export enum Feature {
	CONDITIONS = "CONDITIONS",
	FARM_WEATHER = "FARM_WEATHER",
	LOCAL_WEATHER = "LOCAL_WEATHER",
	MILEOS = "MILEOS",
	NETATMO = "NETATMO",
	NETATMO_FULL = "NETATMO_FULL",
	OPTIMIZE = "OPTIMIZE",
	POTATOES_ONLY = "POTATOES_ONLY",
	REALTIME = "REALTIME",
	TASKS = "TASKS",
	TRACABILITY = "TRACABILITY",
	WEATHER_DEVICE = "WEATHER_DEVICE"
}
