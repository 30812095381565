import { BaseSkeleton } from "@hygo/web/ui-components";
import styled from "styled-components";

const SmallItem = styled(BaseSkeleton)`
	height: 16px;
	width: 180px;
`;

const Item = styled(BaseSkeleton)`
	flex: 1;
`;

const BottomWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
	height: 300px;
`;

const TaskSkeleton = (): JSX.Element => {
	return (
		<>
			<SmallItem />
			<BottomWrapper>
				<Item />
				<Item />
				<Item />
			</BottomWrapper>
			<BottomWrapper>
				<Item />
				<Item />
				<Item />
			</BottomWrapper>
		</>
	);
};

export default TaskSkeleton;
