// eslint-disable-next-line @nx/enforce-module-boundaries
import { Rain, SoilHumi, SoilTemp, Temperature, Volatility, WaterFill, Wind } from "@hygo/shared/icons";
import { WeatherMetrics } from "@hygo/shared/models";
import { FC } from "react";

export const productMetricsMapping: {
	[key in keyof WeatherMetrics]: FC<{
		fill: string;
		height: number;
		width: number;
	}>;
} = {
	avghumi: WaterFill,
	avgtemp: Temperature,
	maxgust: Wind,
	maxhumi: WaterFill,
	maxsoilhumi: SoilHumi,
	maxtemp: Temperature,
	minhumi: WaterFill,
	minsoiltemp: SoilTemp,
	mintemp: Temperature,
	pictocode: null,
	precipitation: Rain,
	r2: Rain,
	r24: Rain,
	r72: Rain,
	rmax: Rain,
	rmin: Rain,
	t1: Temperature,
	t24: Temperature,
	t3: Temperature,
	t72: Temperature,
	t72max: Temperature,
	timestamps: null,
	volaLoss: Volatility,
	wind: Wind,
	winddir: null
};
