import { BaseSkeleton } from "@hygo/web/ui-components";
import styled from "styled-components";

const WithMarginItem = styled(BaseSkeleton)`
	margin-bottom: 16px;
`;

const SmallItem = styled(WithMarginItem)`
	height: 14px;
	width: 10%;
`;

const BigItem = styled(WithMarginItem)`
	height: 38px;
	width: 100%;
	border-radius: 8px;
`;

const Wrapper = styled.div``;

const Skeleton = (): JSX.Element => {
	return (
		<Wrapper>
			<SmallItem />
			<BigItem />
			<BigItem />
			<SmallItem />
			<BigItem />
			<BigItem />
			<BigItem />
			<SmallItem />
			<BigItem />
		</Wrapper>
	);
};

export default Skeleton;
