import { Crop, DenormalizedField, Tank, WindSpeedUnity } from "@hygo/shared/models";
import { COLORS, computeColorFromConditions, hexToRGBA, transformDate } from "@hygo/shared/utils";
import { CropIcon, InputTip, ParcelSVG } from "@hygo/web/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import MetricsProductList from "./MetricsProductList";
import WeatherDetails from "./WeatherDetails";

interface FieldCardProps {
	crops: Crop[];
	field: DenormalizedField;
	isSelected: boolean;
	isTask: boolean;
	tankIndications: Tank;
	windSpeedUnity: WindSpeedUnity;
}

const Wrapper = styled.div<{ $isSelectedField: boolean }>`
	box-shadow:
		0px 0.8px 1.5px rgba(0, 83, 94, 0.1),
		0px 6px 12px rgba(0, 83, 94, 0.1);
	border-radius: 4px;
	opacity: ${(props) => (props.$isSelectedField ? 1 : 0.25)};
`;

const MetricsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const CropIconWrapper = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0;
	background-color: var(--white-80);
	width: 16px;
	height: 16px;
	border-radius: 50%;
`;
const FieldIconWrapper = styled.div`
	position: relative;
`;

const Content = styled.div`
	background-color: var(--white-100);
	padding: 16px;
	display: flex;
	border-radius: 4px;
	flex-direction: column;
	gap: 16px;
`;

const FieldDetailsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const ConditionWrapper = styled.div<{ $backgroundColor: string }>`
	border-radius: 8px;
	padding: 4px 8px;
	background-color: ${({ $backgroundColor }) => $backgroundColor};
`;
const ConditionLabel = styled.h5<{ color: string }>`
	color: ${({ color }) => color};
	white-space: nowrap;
`;

const Name = styled.h5`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

const NameAreaWrapper = styled.div`
	flex: 1;
`;

const Area = styled(Name)`
	color: var(--night-50);
`;

const FieldCard = ({
	crops,
	field,
	isSelected,
	isTask,
	tankIndications,
	windSpeedUnity
}: FieldCardProps): JSX.Element => {
	const { t } = useTranslation();
	const crop = crops.find((c) => c.id === field?.cropId);
	const backgroundConditionColor = computeColorFromConditions(field?.realCondition, "BG_COLOR");
	const conditionColor = computeColorFromConditions(field?.realCondition, "CONTRASTED_TEXT");

	return (
		<Wrapper $isSelectedField={isSelected}>
			<Content>
				<FieldDetailsWrapper>
					{field.svg && (
						<FieldIconWrapper>
							<ParcelSVG
								fill={computeColorFromConditions(
									field?.realCondition,
									"FIELD",
									hexToRGBA(crop.color, 0.5, COLORS.LAKE[50])
								)}
								height={32}
								path={field.svg}
								stroke={computeColorFromConditions(
									field?.realCondition,
									"SLOT",
									hexToRGBA(crop.color, 1, COLORS.LAKE[100])
								)}
								width={32}
							/>
							<CropIconWrapper>
								<CropIcon crop={crop} fill={COLORS.NIGHT[100]} height={10} width={10} />
							</CropIconWrapper>
						</FieldIconWrapper>
					)}

					<NameAreaWrapper>
						<Name>{field.name}</Name>
						<Area>{field.town}</Area>
					</NameAreaWrapper>

					{!!field?.detectedStartTime && (
						<h5>
							{transformDate(new Date(field?.detectedStartTime), 5, Math.round)} -
							{transformDate(new Date(field?.detectedEndTime), 5, Math.round)}
						</h5>
					)}
					{field?.realCondition && isTask && (
						<ConditionWrapper $backgroundColor={backgroundConditionColor}>
							<ConditionLabel color={conditionColor}>
								{t(`conditions.${field?.realCondition}`)}
							</ConditionLabel>
						</ConditionWrapper>
					)}
				</FieldDetailsWrapper>
				{isTask &&
					(field?.realMetrics ? (
						<MetricsWrapper>
							<WeatherDetails metrics={field?.realMetrics} windSpeedUnity={windSpeedUnity} />
							<MetricsProductList
								metrics={field?.realMetrics}
								productMetrics={tankIndications?.productMetrics}
							/>
						</MetricsWrapper>
					) : (
						<InputTip palette={COLORS.DISABLED} withBorder={false}>
							<ColoredCardText>{t("components.fieldsReportCard.unavailableMeteo")}</ColoredCardText>
						</InputTip>
					))}
			</Content>
		</Wrapper>
	);
};

export default FieldCard;
