import { productMetricsMapping } from "@hygo/shared/constants";
import { WeatherMetrics } from "@hygo/shared/models";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface MetricsProductListProps {
	metrics: WeatherMetrics;
	productMetrics: (keyof WeatherMetrics)[];
}

const Wrapper = styled.div`
	background-color: var(--smoke-100);
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	border-radius: 8px;
`;

const CategoryWrapper = styled.div`
	display: flex;
	gap: 4px;
`;

const MetricWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Category = styled.h5`
	color: var(--night-50);
`;

const MetricsProductList = ({ metrics, productMetrics }: MetricsProductListProps): JSX.Element => {
	const { t } = useTranslation();

	if (!productMetrics || productMetrics.length === 0) return null;

	return (
		<Wrapper>
			{productMetrics?.map((metric) => {
				if (metrics?.[metric] || metrics?.[metric] === 0)
					return (
						<MetricWrapper key={metric}>
							<CategoryWrapper>
								{productMetricsMapping[metric]({ fill: null, height: 20, width: 20 })}
								<Category>{t(`tank.productMetrics.${metric}.label`)}</Category>
							</CategoryWrapper>
							<h5>
								{(metrics?.[metric as keyof WeatherMetrics] as number)?.toFixed(0)}{" "}
								{t(`tank.productMetrics.${metric}.unit`)}
							</h5>
						</MetricWrapper>
					);
				return null;
			})}
		</Wrapper>
	);
};

export default MetricsProductList;
