import { Calendar } from "@hygo/shared/icons";
import { Country } from "@hygo/shared/models";
import { COLORS, validateDates } from "@hygo/shared/utils";
import { cs } from "date-fns/locale/cs";
import { de } from "date-fns/locale/de";
import { enGB } from "date-fns/locale/en-GB";
import { fr } from "date-fns/locale/fr";
import { it } from "date-fns/locale/it";
import { nl } from "date-fns/locale/nl";
import { pl } from "date-fns/locale/pl";
import { sk } from "date-fns/locale/sk";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Control, FieldPath, FieldValues, RegisterOptions, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

registerLocale("fr", fr);
registerLocale("sk", sk);
registerLocale("cs", cs);
registerLocale("de", de);
registerLocale("nl", nl);
registerLocale("it", it);
registerLocale("pl", pl);
registerLocale("en", enGB);

const Wrapper = styled.div<{ $error: boolean }>`
	display: flex;
	font-size: var(--14px);
	color: ${(props) => (props.$error ? "var(--gaspacho-100)" : "inherit")};
	font-family: "Nunito Bold";
	flex-direction: column;

	.react-datepicker-wrapper {
		width: 100%;
	}
	.react-datepicker__calendar-icon {
		width: 20px;
		height: 20px;
		padding: 0 0 0 8px !important;
	}
	.react-datepicker__input-container {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
	}
	.react-datepicker__navigation {
		top: 18px;
		width: 12px;
		height: 18px;
		&:hover {
			.react-datepicker__navigation-icon::before {
				border-color: var(--lake-hover);
			}
		}
	}
	.react-datepicker__month-dropdown {
		left: 8px;
	}
	.react-datepicker__year-dropdown {
		left: 116px;
	}
	.react-datepicker__month-dropdown,
	.react-datepicker__year-dropdown {
		overflow: auto;
		max-height: 200px;
		margin-top: 20px;
		border-radius: 4px;
		background: var(--white-100);
		border: 1px solid var(--night-10);
		width: 100px;
		box-shadow:
			0px 0.8px 1.5px rgba(0, 83, 94, 0.1),
			0px 6px 12px rgba(0, 83, 94, 0.1);
		.react-datepicker__navigation--years-upcoming,
		.react-datepicker__navigation--years-previous {
			display: none;
		}
		.react-datepicker__month-option,
		.react-datepicker__year-option {
			border-radius: 0;
			height: 40px;
			display: flex;
			align-items: center;
			padding: 0 8px;
			font-family: "Nunito Regular";
			color: var(--night-100);
			text-transform: capitalize;
			font-size: var(--12px);
			&:has(> .react-datepicker__navigation--years-upcoming, .react-datepicker__navigation--years-previous) {
				display: none;
			}
			.react-datepicker__month-option--selected,
			.react-datepicker__year-option--selected {
				display: none;
			}
			&:hover {
				background: var(--night-5);
			}
		}
	}
	.react-datepicker__navigation--next {
		right: 12px;
	}
	.react-datepicker__navigation--previous {
		left: unset;
		right: 40px;
	}
	.react-datepicker__header__dropdown {
		display: flex;
		flex-direction: row;
		margin-left: 8px;
		gap: 8px;
	}
	.react-datepicker__month-dropdown-container,
	.react-datepicker__year-dropdown-container {
		margin: 0;
		border: 1px solid var(--night-10);
		border-radius: 4px;
		background: var(--white-100);
		height: 40px;
		width: 100px;
		color: var(--night-100);
		.react-datepicker__year-read-view,
		.react-datepicker__month-read-view {
			display: flex;
			flex-direction: row-reverse;
			padding: 0 8px;
			height: 100%;

			visibility: visible !important;
			align-items: center;
			justify-content: space-between;
			.react-datepicker__month-read-view--selected-month,
			.react-datepicker__year-read-view--selected-year {
				font-size: var(--12px);
				font-family: "Nunito Regular";
				text-transform: capitalize;
			}
		}

		.react-datepicker__year-read-view--down-arrow,
		.react-datepicker__month-read-view--down-arrow {
			position: relative;
			border-color: var(--lake-100);
			border-width: 2.5px 2.5px 0 0;
			right: unset;
			top: -2px;
		}
	}
	.react-datepicker__navigation-icon {
		font-size: unset;
		&::before {
			border-width: 2.5px 2.5px 0 0;
			border-color: var(--lake-100);
			width: 10px;
			height: 10px;
		}
	}

	.react-datepicker__day-name {
		color: var(--night-50);
		text-transform: capitalize;
		margin: 7px;
		margin-bottom: 0;
		font-family: "Nunito Regular";
	}
	.react-datepicker__day {
		color: var(--night-100);
		margin: 7px;
		margin-top: 0;
		&:hover {
			background-color: var(--night-5);
		}
	}
	.react-datepicker__day--today {
		font-family: "Nunito Bold";
		font-weight: normal;
	}
	.react-datepicker__day--selected,
	.react-datepicker__day--keyboard-selected {
		background-color: var(--lake-25);
	}
	.react-datepicker__day--disabled {
		color: var(--night-50);
	}
	.react-datepicker__header {
		background: var(--white-100);
		font-family: "Nunito Bold";
		border: none;
	}

	.react-datepicker {
		border: none;
		border-radius: 8px;
		font-family: "Nunito Regular";
		font-size: var(--12px);
		font-weight: normal;
		box-shadow: 0px 6px 12px 0px hsla(187, 100%, 18%, 0.1);
	}
	.react-datepicker__current-month {
		font-weight: normal;
		color: var(--night-100);
		font-size: var(--14px);
		text-transform: capitalize;
	}
`;

const StyledDatePicker = styled(ReactDatePicker)<{ $error: boolean; disabled: boolean }>`
	cursor: pointer;
	width: 100%;
	caret-color: transparent;
	height: 40px;
	padding: 0 0 0 32px !important;
	border: 1px solid var(${(props) => (props.$error ? "--gaspacho-100" : "--night-10")});
	color: ${(props) => {
		if (props?.disabled) return "var(--night-50)";
		return props?.$error ? "var(--gaspacho-100)" : "var(--night-100)";
	}};
	background-color: ${(props) => (props?.disabled ? "var(--night-5)" : "unset")};
	border-radius: 4px;
	outline: none;
	&:focus {
		border: 1px solid var(--lake-100);
	}
	&::placeholder {
		color: var(--night-50);
	}
`;

const Label = styled.label`
	display: block;
	font-size: var(--14px);
`;

const Error = styled.p`
	color: var(--gaspacho-100);
`;

interface DatePickerProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> {
	control: Control<TFieldValues>;
	countryCode: Country;
	dateFormat: string;
	disabled?: boolean;
	label: string;
	maxDate?: Date;
	minDate?: Date;
	name: TName;
	onCalendarClose?: () => void;
	onCalendarOpen?: () => void;
	placeholder: string;
	rules?: Omit<RegisterOptions, "deps">;
}

const DatePicker = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
	control,
	countryCode,
	dateFormat,
	disabled,
	label,
	maxDate,
	minDate,
	name,
	onCalendarClose,
	onCalendarOpen,
	placeholder,
	rules
}: DatePickerProps<TFieldValues, TName>): JSX.Element => {
	const { i18n } = useTranslation();
	const {
		field,
		fieldState: { error }
	} = useController<TFieldValues, TName>({
		control,
		name,
		rules: {
			...rules,
			validate: (v) =>
				validateDates({
					locale: `${i18n.resolvedLanguage}-${countryCode}`,
					maximumDate: maxDate,
					minimumDate: minDate,
					mode: "date",
					value: v
				})
		}
	});
	const hasError = !!error?.message;

	return (
		<Wrapper $error={hasError}>
			<Label htmlFor={name}>{label}</Label>
			<StyledDatePicker
				$error={hasError}
				dateFormat={dateFormat}
				dateFormatCalendar=" "
				disabled={disabled}
				disabledKeyboardNavigation
				formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3)}
				icon={<Calendar fill={hasError ? COLORS.GASPACHO[100] : COLORS.NIGHT[50]} height={20} width={20} />}
				locale={i18n.resolvedLanguage}
				maxDate={maxDate}
				minDate={minDate}
				onCalendarClose={onCalendarClose}
				onCalendarOpen={onCalendarOpen}
				onChange={field.onChange}
				onKeyDown={(e) => e.preventDefault()}
				placeholderText={placeholder}
				popperPlacement="bottom-start"
				selected={field.value}
				showIcon
				showMonthDropdown
				showPopperArrow={false}
				showYearDropdown
			/>
			{error?.message && <Error>{error.message}</Error>}
		</Wrapper>
	);
};

export default DatePicker;
