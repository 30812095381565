import { ProductFamily } from "@hygo/shared/models";
import { SVGProps } from "react";

const Product = ({ className, fill, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} height={height || 24} viewBox="0 0 24 24" width={width || 24}>
			<path
				d="M19.328 12.184a4.47 4.47 0 00.092-.78v-2.5c0-2.11-1.739-3.808-3.849-3.808h-2.55c-.094 0-.21 0-.302.023l-.65-.688h-1.275v-.757h.487a.743.743 0 00.742-.734v-.206A.743.743 0 0011.281 2H6.736a.743.743 0 00-.742.734v.206c0 .39.325.734.742.734h.487V4.5C5.391 4.798 4 6.358 4 8.261v9.932C4 20.303 5.74 22 7.85 22h8.3c2.134 0 3.85-1.72 3.85-3.807V12.94l-.672-.756zm-3.78-5.78c1.391 0 2.527 1.124 2.527 2.5v1.95l-.742-.803-3.385-3.647h1.6zm-7.676-.688h3.641l5.403 5.825 1.785 1.927v.183c-.904.528-2.898.987-6.26-1.651-3.455-2.706-5.96-.688-7.073.688V8.216c-.023-1.377 1.113-2.5 2.504-2.5z"
				fill={fill || "#00343A"}
			/>
		</svg>
	);
};

const Fongicide = ({ className, fill, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "24"} viewBox="0 0 24 24" width={width || "24"}>
			<path
				d="M14.7924 11.7671C14.6659 11.7671 14.5184 11.7671 14.392 11.7882C14.392 11.8303 14.392 11.8935 14.392 11.9357C14.392 12.3572 14.392 13.6006 13.3593 14.3593C13.1275 14.5279 12.8535 14.6543 12.5796 14.7386L12.3688 18.2791C12.3267 19.1854 13.0011 19.9651 13.9073 20.0283C14.1812 20.0494 14.4763 20.0494 14.7713 20.0494C15.0664 20.0494 15.3614 20.0494 15.6354 20.0283C16.5416 19.9651 17.2371 19.1854 17.1738 18.2791L16.7945 12.0411C16.1622 11.8935 15.4879 11.7671 14.7924 11.7671Z"
				fill={fill || "#00343A"}
			/>
			<path
				d="M14.7923 3C12.3477 3 10.177 4.09588 8.87036 5.78185C11.5047 6.24549 13.6121 8.14221 14.2233 10.5236C14.413 10.5026 14.6027 10.5026 14.7923 10.5026C16.0779 10.5026 17.2791 10.8608 18.3329 11.1769C19.1126 11.4088 19.8081 11.6195 20.3982 11.6195C20.7564 11.6195 21.0726 11.5352 21.3465 11.3245C21.9366 10.8819 21.9998 10.06 21.9998 9.49098C22.0209 5.92937 18.7754 3 14.7923 3Z"
				fill={fill || "#00343A"}
			/>
			<path
				d="M7.56372 13.959C7.03685 13.959 6.48891 14.0644 5.98312 14.2119L5.70915 18.7008C5.667 19.3962 6.19387 20.0074 6.88933 20.0495C7.10007 20.0706 7.33189 20.0706 7.54264 20.0706C7.77446 20.0706 7.98521 20.0706 8.21703 20.0495C8.91249 20.0074 9.43936 19.3962 9.39721 18.7008L9.14431 14.2119C8.63852 14.0854 8.09058 13.959 7.56372 13.959Z"
				fill={fill || "#00343A"}
			/>
			<path
				d="M13.1274 11.9357C13.1274 9.17491 10.6406 6.91992 7.5637 6.91992C4.4868 6.91992 2 9.17491 2 11.9357C2 12.3782 2.06322 13.0105 2.50579 13.3477C3.05373 13.7481 3.83349 13.5163 4.824 13.2212C5.64591 12.9683 6.55212 12.6944 7.54262 12.6944C8.53313 12.6944 9.46041 12.9683 10.2612 13.2212C10.8724 13.4109 11.3993 13.5584 11.8629 13.5584C12.1369 13.5584 12.3898 13.4952 12.6005 13.3477C13.0852 13.0105 13.1274 12.3782 13.1274 11.9357Z"
				fill={fill || "#00343A"}
			/>
		</svg>
	);
};

const Herbicide = ({ className, fill, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "24"} viewBox="0 0 24 24" width={width || "24"}>
			<path
				d="M2.03508 9.13843C2.03508 9.13843 1.09628 16.6279 8.93162 16.6279C8.93162 16.6279 10.8844 9.13843 2.03508 9.13843Z"
				fill={fill || "#00343A"}
			/>
			<path
				d="M21.9441 6C11.2856 6 10.2996 12.7036 10.4952 16.1455C12.2959 10.9517 17.2047 9.80531 17.2047 9.80531C12.5186 12.6384 11.5574 17.7528 11.4949 18.1138C23.3971 17.6544 21.9441 6 21.9441 6Z"
				fill={fill || "#00343A"}
			/>
		</svg>
	);
};

const GrowthRegulator = ({ className, fill, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "24"} viewBox="0 0 24 24" width={width || "24"}>
			<path
				d="M18.5711 21.6429C18.5711 21.7376 18.5334 21.8284 18.4665 21.8954C18.3995 21.9624 18.3086 22 18.2139 22H5.35713C5.15991 22 5 21.8401 5 21.6429C5 19.1884 7.80046 17.1781 11.3288 17.0161C10.851 15.1722 10.1531 13.3923 9.25042 11.715L9.24978 11.7154C8.85279 10.972 8.30834 10.3172 7.64954 9.79138C7.48914 9.68264 7.2712 9.72171 7.15865 9.87938C7.04593 10.0372 7.07973 10.256 7.23454 10.3725C7.78363 10.8202 8.23913 11.3715 8.57524 11.9951C8.49186 12.0107 8.4072 12.0193 8.32238 12.0211C8.23357 12.0209 8.14477 12.0137 8.05692 11.9997C7.5453 11.8953 7.07911 11.6332 6.72422 11.2502C5.81861 10.2909 5.32773 9.01334 5.35773 7.69451C5.36793 7.51324 5.5127 7.36847 5.69398 7.35843C7.0128 7.32829 8.29036 7.81935 9.24968 8.7248C9.63264 9.0797 9.89476 9.54571 9.99917 10.0573C10.0655 10.4495 9.98051 10.8524 9.76129 11.1842C10.4317 12.335 10.9821 13.5516 11.4036 14.8148C11.4755 12.8095 11.7853 10.0117 12.8325 7.82809C11.9629 6.65881 12.1554 5.08995 13.3725 3.87284C15.2445 2.00028 18.7731 1.99441 18.9303 2.00061C19.1259 2.00157 19.2844 2.16004 19.2853 2.35567C19.2864 2.50522 19.2864 6.04035 17.4128 7.91348C16.8007 8.57322 15.9506 8.96145 15.0511 8.99206C14.6748 8.99158 14.3032 8.91011 13.9614 8.75323C14.4572 7.59812 15.1616 6.64965 16.1628 6.18762C16.342 6.10504 16.4203 5.89283 16.3376 5.71364C16.255 5.53459 16.0428 5.45631 15.8638 5.5389C15.726 5.60283 15.5922 5.67458 15.4629 5.75381C15.3646 5.81408 15.1243 5.98579 15.0986 6.00556C11.963 8.43342 12.0619 15.4384 12.1256 17.0118C15.7088 17.1344 18.5712 19.1615 18.5712 21.6427L18.5711 21.6429Z"
				fill={fill || "#00343A"}
			/>
		</svg>
	);
};

const Fertilizer = ({ className, fill, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "24"} viewBox="0 0 24 24" width={width || "24"}>
			<path
				d="M12.3251 11.666V13.5765C13.4306 13.6635 14.2814 14.1826 14.2814 14.8123L9.71676 14.8125C9.71676 14.1828 10.5673 13.6637 11.6731 13.5767V11.6662C10.3868 11.5121 9.3909 10.4627 9.3909 9.18809C10.8203 9.18809 11.9799 10.2905 11.9976 11.6564H12.0008C12.0183 10.2903 13.178 9.18809 14.6074 9.18809C14.6074 10.4625 13.6113 11.5121 12.3252 11.666L12.3251 11.666ZM19.4187 20.1747C19.6309 20.6361 19.7653 21.13 19.8083 21.6383C19.8254 21.8348 19.6573 22.0005 19.4517 21.9841C18.9034 21.9414 18.3714 21.8056 17.8763 21.5905C14.1947 22.1363 9.8051 22.1367 6.12145 21.5905C5.62611 21.8054 5.09414 21.9414 4.54604 21.9841C4.34068 22.0001 4.17283 21.8344 4.18944 21.6383C4.23287 21.13 4.36683 20.6361 4.57902 20.1747C4.03934 15.0115 4.03819 9.00173 4.57902 3.8256C4.36683 3.36442 4.23288 2.87033 4.18944 2.36226C4.17238 2.16545 4.34045 1.99981 4.54604 2.01615C5.09414 2.05865 5.62634 2.19465 6.12145 2.40956C9.81256 1.86272 14.2008 1.86425 17.8763 2.40956C18.3716 2.19465 18.9034 2.05865 19.4517 2.01615C19.6569 2.00024 19.8249 2.16545 19.8083 2.36226C19.7649 2.8703 19.6309 3.3644 19.4187 3.8256C19.9589 8.98862 19.9589 15.0114 19.4187 20.1747ZM17.2154 12.0002C17.2154 9.23898 14.88 7.0008 11.9987 7.0008C9.11737 7.0008 6.78194 9.23892 6.78194 12.0002C6.78194 14.7614 9.11737 16.9995 11.9987 16.9995C14.88 16.9995 17.2154 14.7614 17.2154 12.0002Z"
				fill={fill || "#00343A"}
			/>
		</svg>
	);
};

const Insecticide = ({ className, fill, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "24"} viewBox="0 0 24 24" width={width || "24"}>
			<path
				d="M16.8999 2.65906C17.0471 2.4928 17.0307 2.2434 16.859 2.10089C16.6873 1.95838 16.4298 1.97421 16.2827 2.14048C15.8003 2.6749 15.7349 3.30829 15.6613 3.98126C15.5796 4.76112 15.4896 5.56473 14.7825 6.45148C14.5822 6.29709 14.3655 6.15062 14.1243 6.02394C13.8505 5.88143 13.6665 5.61224 13.642 5.30742C13.5643 4.42859 12.8163 3.76353 11.9047 3.76353H11.1239C10.2205 3.76353 9.47657 4.42067 9.39072 5.29158C9.36211 5.58849 9.18225 5.84976 8.91655 5.98831C8.66311 6.11895 8.43419 6.2773 8.21754 6.44752C7.51036 5.56077 7.42043 4.75716 7.33868 3.98126C7.2651 3.30829 7.19969 2.6749 6.71734 2.13652C6.57018 1.97025 6.30856 1.95442 6.14097 2.09693C5.96928 2.23944 5.95293 2.48884 6.10009 2.6551C6.41485 3.00347 6.4639 3.47059 6.52522 4.05648C6.61106 4.8878 6.72143 5.90914 7.63299 7.00569C7.55124 7.10466 7.4654 7.20759 7.3959 7.31447C7.14246 7.70242 6.97896 8.126 6.91764 8.56145C8.7653 8.69605 10.4331 9.60259 11.5 11C12.5751 9.59863 14.2429 8.69209 16.0864 8.56145C16.0251 8.12996 15.8616 7.69846 15.6041 7.31447C15.5346 7.20759 15.4528 7.10466 15.3711 7.00569C16.2827 5.90914 16.3889 4.8878 16.4789 4.05648C16.5361 3.47059 16.5852 3.00743 16.8999 2.65906Z"
				fill={fill || "#00343A"}
			/>
			<path
				d="M19.4665 11.5281C19.4345 11.4496 19.3825 11.3877 19.3105 11.3422L17.3385 10.074C17.3185 9.83444 17.2945 9.59898 17.2665 9.36352C17.2425 9.15697 17.0705 9 16.8705 9C16.8665 9 16.8625 9 16.8625 9L16.7505 9.00413C14.5864 9.08262 12.7703 10.4623 11.9983 12.4039C11.2263 10.4665 9.42221 9.08675 7.27414 9.00413H7.18214C6.98613 8.99174 6.79813 9.14871 6.77013 9.36352C6.74213 9.59072 6.71813 9.81792 6.70213 10.0493L4.68606 11.3422C4.61806 11.3877 4.56605 11.4496 4.53405 11.5281L3.034 15.0518C2.946 15.2625 3.034 15.5062 3.23801 15.5971C3.29001 15.6219 3.34601 15.6301 3.39802 15.6301C3.55402 15.6301 3.69803 15.5392 3.76603 15.3864L5.21408 11.9743L6.65812 11.0531C6.65412 11.115 6.65412 11.1811 6.65412 11.2431C6.65412 13.3746 7.07014 15.3162 7.77416 16.8487L5.66609 17.456C5.55409 17.489 5.45808 17.5717 5.41008 17.6832L3.74603 21.4134C3.65402 21.62 3.74203 21.8678 3.94203 21.9628C3.99804 21.9876 4.05404 22 4.11004 22C4.26204 22 4.40605 21.9091 4.47405 21.7604L6.0581 18.1996L8.15417 17.5964C9.1062 19.2571 10.4382 20.2857 11.9383 20.3228C11.9623 20.3228 11.9903 20.327 12.0183 20.327C13.0103 20.327 13.9784 19.8726 14.8104 19.0175C15.2024 18.6168 15.5584 18.1376 15.8664 17.6006L17.9385 18.1996L19.5265 21.7604C19.5945 21.9091 19.7386 22 19.8906 22C19.9466 22 20.0026 21.9876 20.0586 21.9628C20.2586 21.8678 20.3466 21.62 20.2546 21.4134L18.5905 17.6832C18.5385 17.5717 18.4465 17.489 18.3345 17.456L16.2504 16.857C16.9785 15.2873 17.3825 13.3292 17.3825 11.2431C17.3825 11.1853 17.3825 11.1316 17.3785 11.0737L18.7825 11.9743L20.2346 15.3864C20.2986 15.5392 20.4466 15.6301 20.5986 15.6301C20.6546 15.6301 20.7106 15.6219 20.7626 15.5971C20.9626 15.5062 21.0546 15.2625 20.9666 15.0518L19.4665 11.5281Z"
				fill={fill || "#00343A"}
			/>
		</svg>
	);
};

const Adjuvant = ({ className, fill, height, width }: SVGProps<SVGSVGElement>): JSX.Element => {
	return (
		<svg className={className} fill="none" height={height || "24"} viewBox="0 0 24 24" width={width || "24"}>
			<path
				clipRule="evenodd"
				d="M7.05275 19.8886C8.36502 21.2383 10.1442 21.9977 12 22C13.8558 21.9977 15.635 21.2383 16.9472 19.8886C18.2595 18.5388 18.9977 16.7087 19 14.7999C18.9555 13.3805 18.5469 11.9986 17.8154 10.7942L12.6588 2.34922C12.584 2.24123 12.4852 2.15319 12.3706 2.09244C12.256 2.03169 12.1289 2 12 2C11.8711 2 11.744 2.03169 11.6294 2.09244C11.5148 2.15319 11.416 2.24123 11.3412 2.34922L6.162 10.8358C5.44266 12.0291 5.04186 13.3964 5 14.7999C5.00226 16.7087 5.74049 18.5388 7.05275 19.8886ZM12.4286 14.4286H15V13.5714H12.4286V11H11.5714V13.5714H9V14.4286H11.5714V17H12.4286V14.4286Z"
				fill={fill || "#00343A"}
				fillRule="evenodd"
			/>
		</svg>
	);
};

export const ProductFamilyIcons = {
	[ProductFamily.ADJUVANT]: Adjuvant,
	[ProductFamily.FERTILIZER]: Fertilizer,
	[ProductFamily.FONGICIDE]: Fongicide,
	[ProductFamily.GROWTH_REGULATOR]: GrowthRegulator,
	[ProductFamily.HERBICIDE]: Herbicide,
	[ProductFamily.INSECTICIDE]: Insecticide,
	[ProductFamily.MULTI_TREATMENTS]: Product,
	[ProductFamily.OTHERS]: Product
};
