import styled, { css } from "styled-components";

const Wrapper = styled.div<{ $reverse: boolean }>`
	display: none;
	position: absolute;
	background-color: var(--smoke-100);
	border-radius: 4px;
	padding: 8px;
	max-width: 300px;
	border: 1px solid var(--night-25);
	width: max-content;
	white-space: pre-wrap;
	&:before,
	&:after {
		content: "";
		position: absolute;
		top: -16px;
		left: 10px;
		border-left: 8px solid transparent;
		border-bottom: 16px solid var(--smoke-100);
		border-right: 8px solid transparent;
		width: 0px;
		height: 0px;
		z-index: 2;
	}
	&:after {
		border-left: 9px solid transparent;
		border-bottom: 18px solid var(--night-25);
		border-right: 9px solid transparent;
		top: -19px;
		left: 9px;
		z-index: 1;
	}
	${(props) =>
		props.$reverse &&
		css`
			transform: scale(-1, 1);
			h5 {
				transform: scale(-1, 1);
			}
		`}
`;

interface TooltipBubbleProps {
	className?: string;
	reverse?: boolean;
	text: string;
}

const TooltipBubble = ({ className, reverse, text }: TooltipBubbleProps): JSX.Element => {
	return (
		<Wrapper $reverse={reverse} className={className}>
			<h5>{text}</h5>
		</Wrapper>
	);
};

export default TooltipBubble;
