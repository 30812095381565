import { ThreeDotsLoading } from "@hygo/shared/icons";
import { getBtnVariant, Properties } from "@hygo/shared/utils";
import { FC, SVGProps } from "react";
import styled from "styled-components";

interface ButtonProps {
	className?: string;
	color: Record<number, string>;
	disabled?: boolean;
	fillIcon?: boolean;
	Icon?: FC<{ backgroundFill: string } & SVGProps<SVGSVGElement>>;
	inverted?: boolean;
	isSubmitBtn?: boolean;
	loading?: boolean;
	onClick?: () => void;
	outlined?: boolean;
	strokeIcon?: boolean;
	strokeTranslucid?: boolean;
	text: string;
	tiny?: boolean;
	translucid?: boolean;
	width?: string;
}

const Wrapper = styled.button<{
	$btnProperties: Properties;
	$loading: boolean;
	width: string;
}>`
	width: ${(props) => props.width};
	height: 40px;
	display: flex;
	padding: 0 16px;
	justify-content: center;
	align-items: center;
	color: ${(props) => props.$btnProperties.color};
	border-color: ${(props) => props.$btnProperties.borderColor};
	border-width: ${(props) => props.$btnProperties.borderWidth}px;
	border-style: solid;
	gap: 8px;
	background-color: ${(props) => props.$btnProperties.backgroundColor};
	border-radius: 4px;
	transition: background-color 0.1s ease-out;
	outline: none;
	cursor: pointer;

	&:hover {
		background-color: ${(props) => props.$btnProperties.hoverColor};
	}

	&:disabled {
		cursor: unset;
	}
`;

const StyledIcon = styled.svg<{ width: number }>`
	min-width: ${(props) => props.width}px;
`;

const ThreeDotsSvg = styled(ThreeDotsLoading)<{ height: number; width: number }>`
	min-width: ${(props) => props.width}px;
	min-height: ${(props) => props.height}px;
`;

const Button = ({
	className,
	color,
	disabled,
	fillIcon,
	Icon,
	inverted,
	isSubmitBtn,
	loading,
	onClick,
	outlined,
	strokeIcon,
	strokeTranslucid,
	text,
	tiny,
	translucid,
	width = "100%"
}: ButtonProps): JSX.Element => {
	const state = (loading && "loading") || (disabled && "disabled") || "active";
	const variant =
		(translucid && "translucid") ||
		(strokeTranslucid && "strokeTranslucid") ||
		(outlined && "outlined") ||
		(inverted && "inverted") ||
		"filled";

	return (
		<Wrapper
			$btnProperties={getBtnVariant({ color, state, variant })}
			$loading={loading}
			className={className}
			disabled={loading || disabled}
			onClick={onClick}
			type={isSubmitBtn ? "submit" : "button"}
			width={width}
		>
			{Icon && (
				<StyledIcon
					as={Icon}
					backgroundFill="transparent"
					fill={fillIcon && getBtnVariant({ color, state, variant })?.color}
					height={tiny ? 16 : 20}
					stroke={strokeIcon && getBtnVariant({ color, state, variant })?.color}
					width={tiny ? 16 : 20}
				/>
			)}
			{tiny ? <h5>{text}</h5> : <h4>{text}</h4>}
			{loading && (
				<ThreeDotsSvg
					fill={getBtnVariant({ color, state, variant })?.color}
					height={tiny ? 16 : 24}
					width={tiny ? 16 : 24}
				/>
			)}
		</Wrapper>
	);
};

export default Button;
