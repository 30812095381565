import { COLORS } from "@hygo/shared/utils";
import styled from "styled-components";

import Button from "../Button";

const Wrapper = styled.div`
	text-align: center;
	svg {
		margin: 0 auto 32px auto;
	}
`;

const Subtitle = styled.h5`
	color: var(--night-50);
`;

const StyledButton = styled(Button)`
	margin-top: 16px;
`;

interface ImportStateProps {
	Icon: JSX.Element;
	onCancel?: () => void;
	onClick: () => void;
	primaryBtnText: string;
	secondaryBtnText?: string;
	subtitle?: string;
	title: string;
}
const ImportState = ({
	Icon,
	onCancel,
	onClick,
	primaryBtnText,
	secondaryBtnText,
	subtitle,
	title
}: ImportStateProps): JSX.Element => {
	return (
		<Wrapper>
			{Icon}
			<h4>{title}</h4>
			{subtitle && <Subtitle>{subtitle}</Subtitle>}
			<StyledButton color={COLORS.LAKE} onClick={onClick} text={primaryBtnText} />
			{secondaryBtnText && onCancel && (
				<StyledButton color={COLORS.SMOKE} inverted onClick={onCancel} text={secondaryBtnText} />
			)}
		</Wrapper>
	);
};

export default ImportState;
