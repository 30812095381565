import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { useSmagRequest } from "@hygo/shared/hooks";
import { ImportEvents } from "@hygo/shared/models";
import { OADContext } from "@hygo/web/contexts";
import { useImport } from "@hygo/web/hooks";
import { ImportedField, OADKeyEnum, OADStepEnum } from "@hygo/web/models";
import { AmbiguousCropModal, ConfirmationModal, SmagTokenErrorModal } from "@hygo/web/ui-components";
import { useCallback, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ImportSmagLinkingScreen from "./ImportSmagLinkingScreen";

const ImportSmagLinkingContainer = (): JSX.Element => {
	const { getSmagFields, importSmagFields } = useApi();
	const { activeFields, crops, defaultFarm } = useContext(UserContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { requestToSmag } = useSmagRequest(SmagTokenErrorModal);
	const { setStepCookie, smagOnboardingStep } = useContext(OADContext);
	const navigate = useNavigate();
	const location = useLocation();

	const importCallback = async (): Promise<{
		createdFieldIds: number[];
		updatedFieldIds: number[];
	}> => {
		logAnalyticEvent(ImportEvents.importFromFieldsLinking, { importCrops: cropSynchronize, isSmag: true });
		const response = await requestToSmag(() =>
			importSmagFields({
				cropSynchronize,
				cropYear: location?.state?.year,
				farmId: defaultFarm.id,
				fields: linking
					?.filter((l) => l.selected)
					?.map((l) => ({ fieldId: l.fieldId, smagCropZoneUid: l.importedFieldId })),
				smagFarmUid: location?.state?.smagFarmId
			})
		);

		if (!smagOnboardingStep)
			setStepCookie({ key: OADKeyEnum.SMAG_ONBOARDING_STEP, value: OADStepEnum.SHOW_ONBOARDING_CROPS });
		return response;
	};

	const fetchImportCallback = useCallback(async (): Promise<ImportedField[]> => {
		const fetchedSmagFields = await requestToSmag(() =>
			getSmagFields({
				cropYear: location?.state?.year,
				farmUid: location?.state?.smagFarmId
			})
		);
		if (!fetchedSmagFields) return;
		return fetchedSmagFields?.map((sf) => ({ ...sf, id: sf.smagCropZoneUid }));
	}, [getSmagFields, location, requestToSmag]);

	const {
		backFromError,
		cropSynchronize,
		error,
		fieldsToImport,
		goBack,
		goToDashboard,
		importFields,
		initImportLinking,
		linking,
		loading,
		loadingImportedFields,
		onChange,
		onClickCheckbox,
		retryImport,
		setCropSynchronize,
		success
	} = useImport({
		AmbiguousCropModal,
		ConfirmationModal,
		fetchImportCallback,
		importCallback
	});

	useEffect(() => {
		if (linking) return;
		if (location?.state?.year && location?.state?.smagFarmId) initImportLinking();
		else navigate("/import/smag");
	}, [location, navigate, initImportLinking, linking]);

	return (
		<ImportSmagLinkingScreen
			backFromError={backFromError}
			crops={crops}
			cropSynchronize={cropSynchronize}
			defaultFarm={defaultFarm}
			error={error}
			fields={activeFields}
			fieldsToImport={fieldsToImport}
			goBack={goBack}
			goToDashboard={goToDashboard}
			linking={linking}
			loading={loading}
			loadingImportedFields={loadingImportedFields}
			onChange={onChange}
			onClickCheckbox={onClickCheckbox}
			onClickCropSynchronize={setCropSynchronize}
			onSubmit={importFields}
			retryImport={retryImport}
			success={success}
		/>
	);
};

export default ImportSmagLinkingContainer;
