import { ProductUnit } from "@hygo/shared/models";

const conversionCoeff: Record<string, number> = {
	[ProductUnit.GRAMS_PER_HA]: 0.001,
	[ProductUnit.KILOGRAMS_PER_HA]: 1,
	[ProductUnit.LITER_PER_HA]: 1
};

export const computeDosesSum = (
	products: { realDosePerHA: number; unit: ProductUnit }[],
	isPellet: boolean
): number => {
	let result = products;
	if (!isPellet) result = products.filter((p) => p.unit === ProductUnit.LITER_PER_HA);
	return result.reduce((sum, { realDosePerHA, unit }) => {
		const dose = realDosePerHA;
		return dose ? sum + conversionCoeff[unit] * dose : 0;
	}, 0);
};
