import { useResetPassword } from "@hygo/shared/feature-account-settings";
import { PasswordUpdateModalPropsType } from "@hygo/shared/modals";
import { COLORS } from "@hygo/shared/utils";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import PasswordStrength from "../../PasswordStrength";
import TextInput from "../../TextInput";
import BaseModal from "../BaseModal";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const PasswordStrengthWrapper = styled.div`
	margin-top: 8px;
`;

const PasswordUpdateModal = ({ hideModal, modalVisible }: PasswordUpdateModalPropsType): JSX.Element => {
	const { t } = useTranslation();

	const {
		loading,
		methods,
		newPasswordShown,
		NewPasswordVisibilityIcon,
		oldPasswordShown,
		OldPasswordVisibilityIcon,
		onSubmit,
		rules,
		toggleNewPasswordVisibility,
		toggleOldPasswordVisibility
	} = useResetPassword();
	const {
		formState: { isValid }
	} = methods;

	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnDisabled={!isValid}
			confirmBtnLoading={loading}
			confirmBtnText={t("button.save")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={methods.handleSubmit(onSubmit)}
			title={t("modals.passwordUpdate.title")}
		>
			<Wrapper>
				<FormProvider {...methods}>
					<TextInput
						autoComplete="current-password"
						label={t("inputs.oldPassword.label")}
						name="oldPassword"
						placeholder="*****"
						placeholderIcon={{
							icon: <div onClick={toggleOldPasswordVisibility}>{OldPasswordVisibilityIcon}</div>,
							position: "right"
						}}
						rules={rules.oldPassword}
						type={oldPasswordShown ? "text" : "password"}
					/>
					<div>
						<TextInput
							autoComplete="new-password"
							label={t("inputs.newPassword.label")}
							name="newPassword"
							placeholder="*****"
							placeholderIcon={{
								icon: <div onClick={toggleNewPasswordVisibility}>{NewPasswordVisibilityIcon}</div>,
								position: "right"
							}}
							rules={rules.newPassword}
							type={newPasswordShown ? "text" : "password"}
						/>
						<PasswordStrengthWrapper>
							<PasswordStrength password={methods.watch().newPassword} />
						</PasswordStrengthWrapper>
					</div>
				</FormProvider>
			</Wrapper>
		</BaseModal>
	);
};

export default PasswordUpdateModal;
