import { UserContext } from "@hygo/shared/contexts";
import { Idea } from "@hygo/shared/icons";
import { FieldPhasesModalPropsType } from "@hygo/shared/modals";
import { addDays, COLORS, getStartOfDayAsJSDate, substractDays } from "@hygo/shared/utils";
import { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import DatePicker from "../../DatePicker";
import InputTip from "../../InputTip";
import BaseModal from "../BaseModal";

const Wrapper = styled.div<{ $open: boolean }>`
	display: flex;
	overflow: hidden;
	height: ${(props) => (props.$open ? "400px" : "unset")};
	flex-direction: column;
	gap: 16px;
`;

const InputTipText = styled.h5`
	flex: 1;
`;

const StyledInputTip = styled(InputTip)`
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
`;

const FieldPhasesModal = ({ fields, hideModal, modalVisible, onSubmit }: FieldPhasesModalPropsType): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const { user } = useContext(UserContext);
	const [open, setOpen] = useState<boolean>(false);

	const defaultValues = useMemo(
		() => ({
			harvestDate:
				fields?.length === 1 && fields[0]?.harvestDate
					? getStartOfDayAsJSDate(new Date(fields[0]?.harvestDate))
					: null,
			sowingDate:
				fields?.length === 1 && fields[0]?.sowingDate
					? getStartOfDayAsJSDate(new Date(fields[0]?.sowingDate))
					: null
		}),
		[fields]
	);

	const methods = useForm({
		defaultValues,
		mode: "all"
	});
	const minimumHarvestDate = methods.watch()?.sowingDate
		? addDays(getStartOfDayAsJSDate(new Date(methods.watch()?.sowingDate)), 1)
		: null;
	const maximumSowingDateDate = methods.watch()?.harvestDate
		? substractDays(getStartOfDayAsJSDate(new Date(methods.watch()?.harvestDate)), 1)
		: null;

	const onConfirm = async ({ harvestDate, sowingDate }: { harvestDate: Date; sowingDate: Date }): Promise<void> => {
		try {
			setLoading(true);
			await onSubmit({
				harvestDate,
				ids: fields?.map((f) => f.id),
				sowingDate
			});
			hideModal();
		} finally {
			setLoading(false);
		}
	};
	const {
		formState: { isDirty, isValid }
	} = methods;

	useEffect(() => {
		methods.reset(defaultValues);
	}, [methods, defaultValues]);

	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnDisabled={!isValid || !isDirty}
			confirmBtnLoading={loading}
			confirmBtnText={t("button.save")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={methods.handleSubmit(onConfirm)}
			title={t("modals.fieldPhases.title")}
		>
			<FormProvider {...methods}>
				<Wrapper $open={open}>
					<DatePicker
						control={methods.control}
						countryCode={user?.countryCode}
						dateFormat="dd/MM/yyyy"
						label={t("inputs.fieldPhases.sowingDate.label")}
						maxDate={maximumSowingDateDate}
						name="sowingDate"
						onCalendarClose={() => setOpen(false)}
						onCalendarOpen={() => setOpen(true)}
						placeholder={t("inputs.day.placeholder")}
					/>

					<DatePicker
						control={methods.control}
						countryCode={user?.countryCode}
						dateFormat="dd/MM/yyyy"
						label={t("inputs.fieldPhases.harvestDate.label")}
						minDate={minimumHarvestDate}
						name="harvestDate"
						onCalendarClose={() => setOpen(false)}
						onCalendarOpen={() => setOpen(true)}
						placeholder={t("inputs.day.placeholder")}
					/>
					<StyledInputTip palette={COLORS.GRASS}>
						<Idea fill={COLORS.GRASS[100]} height={20} width={20} />
						<InputTipText>{t("modals.fieldPhases.inputTip")}</InputTipText>
					</StyledInputTip>
				</Wrapper>
			</FormProvider>
		</BaseModal>
	);
};
export default FieldPhasesModal;
