import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { usePasswordShown } from "@hygo/shared/feature-authentication";
import { AccountEvents, SnackbarType } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { t } from "i18next";
import { useContext, useState } from "react";
import { RegisterOptions, useForm, UseFormReturn } from "react-hook-form";

export interface PasswordFormInputs {
	newPassword: string;
	oldPassword: string;
}

interface useResetPasswordResult {
	loading: boolean;
	methods: UseFormReturn<PasswordFormInputs>;
	newPasswordShown: boolean;
	NewPasswordVisibilityIcon: JSX.Element;
	oldPasswordShown: boolean;
	OldPasswordVisibilityIcon: JSX.Element;
	onSubmit: (data: PasswordFormInputs) => Promise<void>;
	rules: Record<keyof PasswordFormInputs, RegisterOptions>;
	toggleNewPasswordVisibility: () => void;
	toggleOldPasswordVisibility: () => void;
}

export const useResetPassword = (): useResetPasswordResult => {
	const [loading, setLoading] = useState<boolean>(false);
	const { showSnackbar } = useContext(SnackbarContext);
	const methods = useForm<PasswordFormInputs>({ mode: "all" });
	const { logAnalyticEvent } = useContext(AmplitudeContext);

	const rules: useResetPasswordResult["rules"] = {
		newPassword: {
			required: {
				message: t("inputs.password.errors.required"),
				value: true
			},
			validate: (value) => {
				return (
					(value.toLowerCase() !== value &&
						value.toUpperCase() !== value &&
						/\d/.test(value) &&
						value.length >= 8 &&
						value.length <= 40) ||
					t("inputs.password.errors.invalid")
				);
			}
		},
		oldPassword: {
			required: {
				message: t("inputs.password.errors.required"),
				value: true
			}
		}
	};

	const {
		passwordShown: oldPasswordShown,
		PasswordVisibilityIcon: OldPasswordVisibilityIcon,
		togglePasswordVisibility: toggleOldPasswordVisibility
	} = usePasswordShown({
		hasError: !!methods.formState.errors?.oldPassword
	});
	const {
		passwordShown: newPasswordShown,
		PasswordVisibilityIcon: NewPasswordVisibilityIcon,
		togglePasswordVisibility: toggleNewPasswordVisibility
	} = usePasswordShown({
		hasError: !!methods.formState.errors?.newPassword
	});

	const { patchUser } = useApi();
	const onSubmit: useResetPasswordResult["onSubmit"] = async (formValues): Promise<void> => {
		setLoading(true);
		try {
			logAnalyticEvent(AccountEvents.updatePassword);
			await patchUser({
				password: {
					newPassword: formValues.newPassword,
					oldPassword: formValues.oldPassword
				}
			});
			methods.reset({ newPassword: null, oldPassword: null });
			showSnackbar(t("snackbar.passwordUpdate.success"), SnackbarType.SUCCESS);
		} catch (e) {
			if (e?.response?.data?.code === "incorrectUserPassword") {
				methods.setError("oldPassword", {
					message: t("inputs.password.errors.incorrectUserPassword"),
					type: "custom"
				});
				return;
			}
			showSnackbar(t("snackbar.passwordUpdate.error"), SnackbarType.ERROR);
			throw e;
		} finally {
			setLoading(false);
		}
	};

	return {
		loading,
		methods,
		newPasswordShown,
		NewPasswordVisibilityIcon,
		oldPasswordShown,
		OldPasswordVisibilityIcon,
		onSubmit,
		rules,
		toggleNewPasswordVisibility,
		toggleOldPasswordVisibility
	};
};
