import { UserContext } from "@hygo/shared/contexts";
import { Auth } from "@hygo/shared/models";
import { AppWrapper, Navbar } from "@hygo/web/ui-components";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import styled from "styled-components";

import routes from "../../navbar.routes";

const Wrapper = styled.main`
	height: calc(100% - 68px);
`;

const ProtectedRoutes = (): JSX.Element => {
	const { admin, coopUsers, farmerSelected, logout, signInAsUser, status, superAdmin, user } =
		useContext(UserContext);

	if (status !== Auth.LOGGED_IN || !farmerSelected) {
		return <Navigate replace to="/" />;
	}

	return (
		<AppWrapper>
			<Navbar
				admin={admin}
				coopUsers={coopUsers}
				farmerSelected={farmerSelected}
				logout={logout}
				routes={routes}
				signInAsUser={signInAsUser}
				superAdmin={superAdmin}
				user={user}
			/>
			<Wrapper>
				<Outlet />
			</Wrapper>
		</AppWrapper>
	);
};
export default ProtectedRoutes;
