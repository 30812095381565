import { EquipmentPlan, Feature } from "@hygo/shared/models";
import { OAD, PlanName } from "@hygo/shared/models";

export const planDetails = {
	[PlanName.OPTIMISE]: require("@hygo/shared/assets/images/pricing/features/optimize.png"),
	[PlanName.OPTIMISE_PLUS]: require("@hygo/shared/assets/images/pricing/features/weather_device.png"),
	[PlanName.TRACE]: require("@hygo/shared/assets/images/pricing/features/tasks.png"),
	[PlanName.VERIFY]: require("@hygo/shared/assets/images/pricing/features/conditions.png")
} as { [key in PlanName]: string };

export const oadDetails = {
	[OAD.AVIZIO]: require("@hygo/shared/assets/images/oad/avizio.png"),
	[OAD.BSV]: require("@hygo/shared/assets/images/oad/bsv.png"),
	[OAD.CRISTALCERCOAD]: require("@hygo/shared/assets/images/oad/cristal_union.png"),
	[OAD.GEOFOLIA]: require("@hygo/shared/assets/images/oad/geofolia.png"),
	[OAD.JOHNDEERE]: require("@hygo/shared/assets/images/oad/johndeere.png"),
	[OAD.MESPARCELLES]: require("@hygo/shared/assets/images/oad/mesparcelles.png"),
	[OAD.MILEOS]: require("@hygo/shared/assets/images/oad/mileos.png"),
	[OAD.SENCROP]: require("@hygo/shared/assets/images/oad/sencrop.png"),
	[OAD.SKYFLD]: require("@hygo/shared/assets/images/oad/skyfld.png"),
	[OAD.SMAG]: require("@hygo/shared/assets/images/oad/smag.png"),
	[OAD.WEENAT]: require("@hygo/shared/assets/images/oad/weenat.png"),
	[OAD.WIUZ]: require("@hygo/shared/assets/images/oad/wiuz.png"),
	[OAD.XARVIO]: require("@hygo/shared/assets/images/oad/xarvio.png")
} as { [key in OAD]: string };

export const addonDetails = {
	[OAD.MILEOS]: require("@hygo/shared/assets/images/oad/mileos.png")
} as { [key in OAD]: string };

export const featureDetails = {
	[Feature.CONDITIONS]: require("@hygo/shared/assets/images/pricing/features/conditions.png"),
	[Feature.FARM_WEATHER]: require("@hygo/shared/assets/images/pricing/features/farm_weather.png"),
	[Feature.LOCAL_WEATHER]: require("@hygo/shared/assets/images/pricing/features/local_weather.png"),
	[Feature.OPTIMIZE]: require("@hygo/shared/assets/images/pricing/features/optimize.png"),
	[Feature.REALTIME]: require("@hygo/shared/assets/images/pricing/features/realtime.png"),
	[Feature.TASKS]: require("@hygo/shared/assets/images/pricing/features/tasks.png"),
	[Feature.TRACABILITY]: require("@hygo/shared/assets/images/pricing/features/tracability.png"),
	[Feature.WEATHER_DEVICE]: require("@hygo/shared/assets/images/pricing/features/weather_device.png")
} as { [key in Feature]: string };

export const equipmentPackDetails = {
	[EquipmentPlan.GPS_TRACKER_ONLY]: require("@hygo/shared/assets/images/pricing/equipments/gps_tracker.png"),
	[EquipmentPlan.GPS_TRACKER_WITH_WEATHER_DEVICE]: require("@hygo/shared/assets/images/pricing/equipments/gps_tracker_with_weather_device.png")
} as { [key in EquipmentPlan]: string };
