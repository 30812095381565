import { ConfirmationModalWebPropsType } from "@hygo/shared/modals";
import { useState } from "react";
import styled from "styled-components";

import BaseModal from "../BaseModal";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const EntityWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
`;

const Subtitle = styled.h4`
	text-align: center;
`;

const ConfirmationModal = ({
	body,
	btnColorPalette,
	confirmLabel,
	dismissLabel,
	entity,
	handleConfirm,
	handleDismiss,
	hideModal,
	modalVisible,
	subtitle,
	title
}: ConfirmationModalWebPropsType): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);

	const onConfirm = async (): Promise<void> => {
		try {
			setLoading(true);
			await handleConfirm();
			hideModal();
		} finally {
			setLoading(false);
		}
	};

	const onDismiss = (): void => {
		handleDismiss && handleDismiss();
		hideModal();
	};

	return (
		<BaseModal
			cancelBtnText={dismissLabel}
			closeAfterConfirm={false}
			colorCancelBtn={btnColorPalette}
			colorConfirmBtn={btnColorPalette}
			confirmBtnLoading={loading}
			confirmBtnText={confirmLabel}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={onDismiss}
			onConfirm={onConfirm}
			title={title}
		>
			<Wrapper>
				{subtitle && <Subtitle>{subtitle}</Subtitle>}
				{entity && (
					<EntityWrapper>
						{entity.Icon}
						<h4>{entity.name}</h4>
					</EntityWrapper>
				)}
				{body && <p>{body}</p>}
			</Wrapper>
		</BaseModal>
	);
};
export default ConfirmationModal;
