import { HappyDrop } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import {
	Button,
	ContainerWithBgImage,
	FormCard,
	FormHeader,
	Loader,
	PasswordStrength,
	TextInput
} from "@hygo/web/ui-components";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ResetPasswordScreenProps } from "./screen.types";

const GoBackButton = styled(Button)`
	@media (max-width: 740px) {
		display: none;
	}
`;

const InputWrapper = styled.div`
	margin-bottom: 8px;
`;

const StyledButton = styled(Button)`
	margin-top: 32px;
`;

const Paragraph = styled.p`
	text-align: center;
`;

const HappyDropIcon = styled(HappyDrop)`
	margin: 0 auto 32px auto;
`;
const Wrapper = styled.div`
	margin-top: 32px;
`;

const ResetPasswordScreen = ({
	loading,
	methods,
	onGoBack,
	onSubmit,
	passwordUpdated,
	rules
}: ResetPasswordScreenProps): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState: { isValid }
	} = methods;
	return (
		<ContainerWithBgImage backgroundImage={"/assets/images/bg.jpg"}>
			<FormCard>
				<FormHeader
					title={
						passwordUpdated
							? t("screens.resetPassword.updatedTitle")
							: t("screens.resetPassword.toUpdateTitle")
					}
				/>
				{loading && <Loader />}
				{!loading && !passwordUpdated && (
					<Wrapper>
						<FormProvider {...methods}>
							<form onSubmit={methods.handleSubmit(onSubmit)}>
								<InputWrapper>
									<TextInput
										autoCapitalize="off"
										autoComplete="new-password"
										label={t("inputs.newPassword.label")}
										name="password"
										rules={rules.newPassword}
										type="password"
									/>
								</InputWrapper>
								<PasswordStrength password={methods.watch().password} />
								<StyledButton
									color={COLORS.LAKE}
									disabled={!isValid}
									isSubmitBtn
									text={t("button.next")}
								/>
							</form>
						</FormProvider>
					</Wrapper>
				)}
				{!loading && passwordUpdated && (
					<Wrapper>
						<HappyDropIcon height={117} width={81} />
						<Paragraph>{t("screens.resetPassword.body")}</Paragraph>
						<GoBackButton color={COLORS.LAKE} onClick={onGoBack} text={t("button.understood")} />
					</Wrapper>
				)}
			</FormCard>
		</ContainerWithBgImage>
	);
};

export default ResetPasswordScreen;
