import { COLORS } from "@hygo/shared/utils";
import { Button } from "@hygo/web/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import SprayerCard from "./components/SprayerCard";
import { SprayerManagementScreenProps } from "./screen.types";

const StyledSprayerManagement = styled.div`
	padding: 20px 120px;
	background: var(--gradient-background-2);
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const SprayersList = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto;
	gap: 16px;
`;

const SprayerManagementScreen = ({
	onCreateSprayer,
	onDeleteSprayer,
	onUpdateSprayer,
	sprayers
}: SprayerManagementScreenProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<StyledSprayerManagement>
			<SprayersList>
				{sprayers?.map((sprayer) => {
					return (
						<SprayerCard
							key={sprayer.id}
							onDeleteSprayer={onDeleteSprayer}
							onEditSprayer={onUpdateSprayer}
							sprayer={sprayer}
							withDeleteBtn={sprayers?.length > 1}
						/>
					);
				})}
			</SprayersList>
			<Button
				color={COLORS.LAKE}
				onClick={onCreateSprayer}
				text={t("screens.sprayerManagement.addSprayerBtn")}
				width="fit-content"
			/>
		</StyledSprayerManagement>
	);
};

export default SprayerManagementScreen;
