import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { countryMapping } from "@hygo/shared/constants";
import { UserContext } from "@hygo/shared/contexts";
import { CountryStatus, Plan, PlanPresentationEvents, SnackbarType } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface usePlanResult {
	actualPlan: Plan;
	getTryHygoLabel: () => string;
	loading: boolean;
	onSubmit: () => Promise<void>;
	otherPlans: Plan[];
	recommendedPlan: Plan;
}

export const usePlan = (): usePlanResult => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<usePlanResult["loading"]>(false);
	const { patchUser } = useApi();
	const { showSnackbar } = useContext(SnackbarContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { fetchUser, plans, user } = useContext(UserContext);
	const plansEnabledInPortal = plans?.filter((p) => p.enabledInPortal);

	const actualPlan = useMemo(() => plans?.find((p) => p.id === user?.plan?.planId), [user, plans]);

	const premiumPlansSortedByPrice = plansEnabledInPortal?.map((p) => p)?.sort((a, b) => b?.price - a?.price);
	const actualPlanIndex = premiumPlansSortedByPrice?.findIndex((p) => actualPlan?.id === p.id);

	const recommendedPlan = premiumPlansSortedByPrice?.slice(0, actualPlanIndex >= 0 ? actualPlanIndex : null)?.[0];

	const onSubmit: usePlanResult["onSubmit"] = async () => {
		setLoading(true);
		try {
			logAnalyticEvent(PlanPresentationEvents.clickTryHygoPremium);
			await patchUser({ premiumRequestDate: new Date() });
			await fetchUser();
			showSnackbar(
				countryMapping[user?.countryCode].status === CountryStatus.OPEN
					? t("snackbar.tryHygoPremium.success")
					: t("snackbar.tryHygoPremium.waitingList"),
				SnackbarType.SUCCESS
			);
		} catch (e) {
			showSnackbar(t("snackbar.tryHygoPremium.error"), SnackbarType.ERROR);
			throw e;
		} finally {
			setLoading(false);
		}
	};

	const getTryHygoLabel: usePlanResult["getTryHygoLabel"] = () => {
		if (user?.premiumRequestDate) return t("button.alreadyInterested");
		return countryMapping[user?.countryCode].status === CountryStatus.OPEN
			? t("button.interested")
			: t("button.waitingList");
	};

	const otherPlans = plansEnabledInPortal
		?.filter((p) => p.id !== actualPlan?.id && p.id !== recommendedPlan?.id)
		?.sort((a, b) => a.price - b.price);

	return { actualPlan, getTryHygoLabel, loading, onSubmit, otherPlans, recommendedPlan };
};
