import { useNozzle } from "@hygo/shared/feature-equipment";
import { Nozzle, NozzleCharacteristics, NozzleHeight, NozzlePressure, Speed } from "@hygo/shared/icons";
import { NozzleModalPropsType } from "@hygo/shared/modals";
import { BaseNozzle, WindSpeedUnity } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { useRef, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import TextInput from "../../TextInput";
import BaseModal from "../BaseModal";
import ColorSelector from "./components/ColorSelector";
import NozzleFamilySelector from "./components/NozzleFamilySelector";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-right: 10px;
`;

const LabelWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 8px;
`;

const NozzleModal = ({ hideModal, modalVisible, nozzle, sprayerId }: NozzleModalPropsType): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const { methods, onCreateOrUpdate, rules } = useNozzle({ nozzle: { ...nozzle, sprayerId } });
	const scrollRef = useRef<HTMLDivElement>();

	const onConfirm = async (values: BaseNozzle): Promise<void> => {
		try {
			setLoading(true);
			await onCreateOrUpdate({ ...nozzle, ...values });
			hideModal();
		} finally {
			setLoading(false);
		}
	};
	const {
		formState: { isValid }
	} = methods;

	const tryToScrollDown = (): void => {
		const values = methods.watch();
		if (values?.color && values?.name && values?.family && !values?.pressure && !values?.speed && !values?.height) {
			scrollRef.current.scrollIntoView({
				behavior: "smooth",
				block: "end"
			});
		}
	};

	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnDisabled={!isValid}
			confirmBtnLoading={loading}
			confirmBtnText={t("button.save")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={methods.handleSubmit(onConfirm)}
			title={t("modals.nozzle.title")}
		>
			<FormProvider {...methods}>
				<Wrapper ref={scrollRef}>
					<TextInput
						label={t("inputs.nozzleName.label")}
						name="name"
						placeholder={t("inputs.nozzleName.placeholder")}
						rules={rules.name}
					/>

					<LabelWrapper>
						<Nozzle fill={COLORS.LAKE[100]} height={20} width={20} />
						<h4>{t("inputs.nozzleType.label")}</h4>
					</LabelWrapper>
					<Controller<BaseNozzle, "family">
						control={methods.control}
						name="family"
						render={({ field: { onChange, value }, fieldState: { error } }) => {
							return <NozzleFamilySelector error={error} onChange={onChange} value={value} />;
						}}
						rules={rules.family}
					/>

					<LabelWrapper>
						<NozzleCharacteristics fill={COLORS.LAKE[100]} height={20} width={20} />
						<h4>{t("inputs.nozzleCharacteristics.label")}</h4>
					</LabelWrapper>
					<Controller<BaseNozzle, "color">
						control={methods.control}
						name="color"
						render={({ field: { onChange, value }, fieldState: { error } }) => {
							return (
								<ColorSelector
									error={error}
									onChange={(v) => {
										onChange(v);
										tryToScrollDown();
									}}
									value={value}
								/>
							);
						}}
						rules={rules.color}
					/>

					<TextInput
						iconLabel={<NozzleHeight fill={COLORS.LAKE[100]} height={20} width={20} />}
						label={t("inputs.nozzleHeight.label")}
						name="height"
						placeholder="Ex: 70"
						rules={rules.height}
						unit={t("units.centimeters")}
					/>

					<TextInput
						iconLabel={<Speed fill={COLORS.LAKE[100]} height={20} width={20} />}
						label={t("inputs.nozzleSpeed.label")}
						name="speed"
						placeholder="Ex: 10"
						rules={rules.speed}
						unit={t(`units.${WindSpeedUnity.KMPH}`)}
					/>

					<TextInput
						iconLabel={<NozzlePressure fill={COLORS.LAKE[100]} height={20} width={20} />}
						label={t("inputs.nozzlePressure.label")}
						name="pressure"
						placeholder="Ex: 3"
						rules={rules.pressure}
						unit={t("units.bar")}
					/>
				</Wrapper>
			</FormProvider>
		</BaseModal>
	);
};
export default NozzleModal;
