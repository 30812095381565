import { useNozzle } from "@hygo/shared/feature-equipment";
import { Nozzle as NozzleIcon, Plus, Trash } from "@hygo/shared/icons";
import { ModalsContext } from "@hygo/shared/modals";
import { Nozzle } from "@hygo/shared/models";
import { COLORS, NOZZLE_COLORS } from "@hygo/shared/utils";
import { ConfirmationModal, NozzleModal } from "@hygo/web/ui-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface NozzleListProps {
	className?: string;
	nozzles: Nozzle[];
	sprayerId: number;
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	overflow: auto;
	gap: 8px;
`;

const CircularButton = styled.div`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: var(--white-100);
	filter: drop-shadow(0px 0.8px 1.5px rgba(0, 83, 94, 0.1)) drop-shadow(0px 6px 12px rgba(0, 83, 94, 0.1));
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 200ms;
`;

const CircularButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
	gap: 4px;
	width: 100px;
	flex-shrink: 0;

	&:hover {
		${CircularButton} {
			background-color: var(--smoke-100);
		}
	}
`;

const TrashIconWrapper = styled.div`
	background-color: var(--gaspacho-100);
	border-radius: 50%;
	width: 20px;
	height: 20px;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 15px;
	display: none;
	z-index: 2;
`;

const NozzleButtonWrapper = styled(CircularButtonWrapper)`
	&:hover {
		${TrashIconWrapper} {
			display: flex;
		}
	}
`;

const NozzleName = styled.h5`
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
`;

const NozzleList = ({ className, nozzles, sprayerId }: NozzleListProps): JSX.Element => {
	const { t } = useTranslation();
	const { onDelete } = useNozzle({});
	const { showModal } = useContext(ModalsContext);
	const onCreateNozzle = (): void => {
		showModal(NozzleModal, {
			sprayerId
		});
	};

	const onUpdateNozzle = (nozzle: Nozzle): void => {
		showModal(NozzleModal, {
			nozzle,
			sprayerId
		});
	};

	const onDeleteNozzle = (nozzle: Nozzle): void => {
		showModal(ConfirmationModal, {
			btnColorPalette: COLORS.GASPACHO,
			confirmLabel: t("button.delete"),
			dismissLabel: t("button.cancel"),
			entity: {
				Icon: <NozzleIcon fill={NOZZLE_COLORS[nozzle?.color]} height={24} width={24} />,
				name: nozzle.name
			},
			handleConfirm: async () => {
				await onDelete(nozzle);
			},
			title: t("modals.nozzleDelete.title")
		});
	};
	return (
		<Wrapper className={className}>
			<CircularButtonWrapper onClick={onCreateNozzle}>
				<CircularButton>
					<Plus fill={COLORS.LAKE[100]} height={24} width={24} />
				</CircularButton>
				<NozzleName>{t("glossary.newNozzle")}</NozzleName>
			</CircularButtonWrapper>
			{nozzles?.map((nozzle) => {
				return (
					<NozzleButtonWrapper key={nozzle?.id} onClick={() => onUpdateNozzle(nozzle)}>
						<TrashIconWrapper
							onClick={(e) => {
								e.stopPropagation();
								onDeleteNozzle(nozzle);
							}}
						>
							<Trash fill={COLORS.WHITE[100]} height={16} width={16} />
						</TrashIconWrapper>
						<CircularButton>
							<NozzleIcon fill={NOZZLE_COLORS[nozzle.color]} height={24} width={24} />
						</CircularButton>
						<NozzleName>{nozzle?.name}</NozzleName>
					</NozzleButtonWrapper>
				);
			})}
		</Wrapper>
	);
};

export default NozzleList;
