export enum Country {
	BE = "BE",
	CZ = "CZ",
	DE = "DE",
	FR = "FR",
	GB = "GB",
	IT = "IT",
	NL = "NL",
	PL = "PL",
	SK = "SK"
}
