import styled from "styled-components";

interface CardWithImageProps {
	asset: { Icon?: JSX.Element; image?: string };
	children?: JSX.Element;
	className?: string;
	disabled?: boolean;
	imageWidth: number;
	onClick: () => void;
	title: string;
}

const Wrapper = styled.div<{ $disabled: boolean }>`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-decoration: none;
	border: 1px solid ${(props) => (props.$disabled ? "var(--night-10)" : "var(--lake-100)")};
	border-radius: 8px;
	padding: 16px;
	height: 180px;
	justify-content: center;
	gap: 16px;
	cursor: pointer;
	pointer-events: ${(props) => (props.$disabled ? "none" : "unset")};
	transition: background-color 0.1s ease-out;
	&:hover {
		background-color: var(--lake-25);
	}
`;

const Title = styled.h4<{ $disabled: boolean }>`
	text-align: center;
	color: ${(props) => (props.$disabled ? "var(--night-50)" : "var(--night-100)")};
`;

const Image = styled.img<{ $disabled: boolean }>`
	opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
`;

const CardWithImage = ({
	asset,
	children,
	className,
	disabled,
	imageWidth,
	onClick,
	title
}: CardWithImageProps): JSX.Element => {
	const onClickCard = (): void => {
		if (disabled) return;
		onClick();
	};
	return (
		<Wrapper $disabled={disabled} className={className} onClick={onClickCard}>
			{asset.image && <Image $disabled={disabled} alt={title} src={asset.image} width={imageWidth} />}
			{asset.Icon && asset.Icon}
			<Title $disabled={disabled}>{title}</Title>
			{children}
		</Wrapper>
	);
};

export default CardWithImage;
