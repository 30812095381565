import { parsePhoneNumberWithError } from "libphonenumber-js/max";

export const validatePhone = (principalPhone: string): boolean => {
	try {
		const phoneNumber = parsePhoneNumberWithError(principalPhone, {
			extract: false
		});
		return phoneNumber.isValid();
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
	} catch (e) {
		return false;
	}
};
