import { Aim } from "@hygo/shared/icons";
import { Target } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Section from "./Section";

interface TargetSectionProps {
	targets: Target[];
}

const TargetsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const TargetSection = ({ targets }: TargetSectionProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Section
			Icon={<Aim fill={COLORS.LAKE[100]} height={20} width={20} />}
			title={t("components.targetsReportCard.title")}
		>
			<TargetsWrapper>
				{targets?.map((target) => {
					return (
						<Row key={target.id}>
							<h5>{target.name}</h5>
						</Row>
					);
				})}
			</TargetsWrapper>
		</Section>
	);
};

export default TargetSection;
