import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { SmagErrorToken, SmagLogin } from "@hygo/shared/icons";
import { SmagTokenErrorModalPropsType } from "@hygo/shared/modals";
import { COLORS } from "@hygo/shared/utils";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import BaseModal from "../BaseModal";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const Body = styled.h4`
	text-align: center;
`;

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const SmagTokenErrorModal = ({ errorCode, hideModal, modalVisible }: SmagTokenErrorModalPropsType): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation();
	const { deleteSmagToken, getSmagToken } = useApi();
	const { fetchUser } = useContext(UserContext);
	const isRefreshTokenError = errorCode === "invalidRefreshToken";

	const onConfirm = async (): Promise<void> => {
		setLoading(true);
		try {
			await deleteSmagToken();
			await fetchUser();
			if (isRefreshTokenError) {
				const loginUri = await getSmagToken();
				window.location.replace(loginUri);
			}
			hideModal();
		} finally {
			setLoading(false);
		}
	};
	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorCancelBtn={COLORS.SMOKE}
			colorConfirmBtn={isRefreshTokenError ? COLORS.LAKE : COLORS.GASPACHO}
			confirmBtnLoading={loading}
			confirmBtnText={t(`modals.smagTokenError.${errorCode}.cta`)}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={onConfirm}
			title={t(`modals.smagTokenError.${errorCode}.title`)}
		>
			<Wrapper>
				<Body>{t(`modals.smagTokenError.${errorCode}.body`)}</Body>
				<IconWrapper>
					{isRefreshTokenError ? (
						<SmagLogin height={160} width={200} />
					) : (
						<SmagErrorToken height={100} width={100} />
					)}
				</IconWrapper>
			</Wrapper>
		</BaseModal>
	);
};
export default SmagTokenErrorModal;
