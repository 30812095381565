import { createContext, FC, useCallback, useReducer } from "react";

import { ModalListTypes } from "./modals.types";

interface ModalsContextResult {
	component: FC<ModalListTypes>;
	hideModal: () => void;
	modalProps: {
		props: ModalListTypes;
		visibility: boolean;
	};
	showModal: <T extends ModalListTypes>(component: FC<T>, props?: T) => void;
}

export const ModalsContext = createContext({} as ModalsContextResult);

const reducer = (
	state: ModalsContextResult,
	{ component, modalProps, type }: { component?: FC; modalProps?: ModalListTypes; type: string }
): ModalsContextResult => {
	switch (type) {
		case "hideModal":
			return { ...state, component: null, modalProps: { props: {}, visibility: false } };
		case "openModal":
			return { ...state, component, modalProps: { props: modalProps, visibility: true } };
		default:
			throw new Error("Unspecified reducer action");
	}
};

interface ModalsProviderProps {
	children: JSX.Element[];
}

const ModalsProvider = ({ children }: ModalsProviderProps): JSX.Element => {
	const initialState: ModalsContextResult = {
		component: null,
		hideModal: () => {
			dispatch({ type: "hideModal" });
		},
		modalProps: { props: {}, visibility: true },
		showModal: useCallback((component, modalProps): void => {
			dispatch({ component, modalProps, type: "openModal" });
		}, [])
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	return <ModalsContext.Provider value={state}>{children}</ModalsContext.Provider>;
};

export default ModalsProvider;
