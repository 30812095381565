import { oadDetails } from "@hygo/shared/constants";
import { ThreeDotsLoading } from "@hygo/shared/icons";
import { OAD } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { SimpleButton } from "@hygo/web/ui-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Action } from "../screen.types";

interface OADCardProps {
	id: OAD;
	isLoggedIn: boolean;
	primaryAction: Action;
	secondaryAction: Action;
}

const StyledIcon = styled.svg<{ height: number; width: number }>`
	min-width: ${(props) => props.width}px;
	min-height: ${(props) => props.height}px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
`;

const StyledSimpleButton = styled(SimpleButton)<{ $disabled: boolean }>`
	opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
	cursor: ${(props) => (props.$disabled ? "unset" : "pointer")};
`;

const Description = styled.h5`
	color: var(--night-50);
`;

const TitleWrapper = styled.div`
	justify-content: space-between;
	display: flex;
	gap: 4px;
	align-items: center;
`;

const Title = styled.h3``;

const ActionWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	justify-content: space-between;
`;

const Wrapper = styled.div<{ $disabled: boolean }>`
	background: var(--white-100);
	cursor: ${(props) => (props.$disabled ? "unset" : "pointer")};
	border: 1px solid var(--night-10);
	border-radius: 8px;
	padding: 16px;
	height: 140px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	${(props) =>
		!props.$disabled &&
		css`
			&:hover {
				background: var(--smoke-100);
				${StyledSimpleButton} {
					color: var(--lake-hover);
				}
				${StyledIcon} path {
					fill: var(--lake-hover);
				}
			}
		`}
`;

const OADCard = ({ id, isLoggedIn, primaryAction, secondaryAction }: OADCardProps): JSX.Element => {
	const action = isLoggedIn ? secondaryAction : primaryAction;
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);

	const onSubmit = async (): Promise<void> => {
		setLoading(true);
		await action.onClick(id);
		setLoading(false);
	};

	const linkText = isLoggedIn
		? t(`screens.oad.components.oadCard.${id}.secondaryAction`)
		: t(`screens.oad.components.oadCard.${id}.primaryAction${action.disabled ? ".disabled" : ""}`);

	const isDisabled = loading || action?.disabled;

	return (
		<Wrapper $disabled={isDisabled} onClick={isDisabled ? null : onSubmit}>
			<ActionWrapper>
				<TitleWrapper>
					<img alt={id} height={24} src={oadDetails[id]} width={24} />
				</TitleWrapper>
				<ButtonWrapper>
					<StyledSimpleButton $disabled={isDisabled} text={linkText} />
					{loading ? (
						<ThreeDotsLoading fill={COLORS.LAKE[50]} height={20} width={20} />
					) : (
						<StyledIcon
							as={action?.icon}
							fill={isDisabled ? COLORS.LAKE[50] : COLORS.LAKE[100]}
							height={16}
							width={16}
						/>
					)}
				</ButtonWrapper>
			</ActionWrapper>
			<Title>{t(`oad.${id}`)}</Title>
			<Description>{t(`oad.${id}.description`)}</Description>
		</Wrapper>
	);
};

export default OADCard;
