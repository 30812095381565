import { Pencil } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { TextInput } from "@hygo/web/ui-components";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Section from "./Section";

interface NotesSectionProps {
	notes: string;
}

const NotesSection = ({ notes }: NotesSectionProps): JSX.Element => {
	const { t } = useTranslation();
	const methods = useForm({
		defaultValues: {
			notes
		}
	});
	return (
		<Section
			Icon={<Pencil fill={COLORS.LAKE[100]} height={20} width={20} />}
			title={t("components.notesReportCard.title")}
		>
			<FormProvider {...methods}>
				<TextInput disabled name="notes" placeholder={t("components.notesReportCard.empty")} />
			</FormProvider>
		</Section>
	);
};

export default NotesSection;
