import { XY } from "@hygo/shared/models";

import { convertToFloat } from "./convertToFloat";

export const smoothData = (data: XY[]): XY[] => {
	return data.map((d, index, datas) => {
		const span = data.length > 5 ? Math.round(data.length / 5) : 1;
		const y: number = datas
			.slice(index < span ? 0 : index - span, index + span)
			.map((el: XY) => el.y)
			.reduce((prev: number, cur: number, _i, arr) => prev + cur / arr.length, 0);
		return { x: d.x, y: convertToFloat(y?.toFixed(2)) };
	});
};

export const valueIsBetween = (value: number, minValue: number, maxValue: number): boolean =>
	value < maxValue && value > minValue;

export const countDecimals = (value: number): number => {
	if (value % 1 !== 0 && typeof value === "number") return value.toString().split(".")[1]?.length;
	return 0;
};

export const smallerThan = (value: number, limitValue: number): string => {
	if (value || value === 0)
		return value && value < limitValue ? `< ${limitValue}` : convertToFloat(value?.toFixed(1))?.toString();
};
