import { AmplitudeContext } from "@hygo/shared/amplitude";
import { UserContext } from "@hygo/shared/contexts";
import { ImportEvents } from "@hygo/shared/models";
import { OADContext } from "@hygo/web/contexts";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import ImportLandingScreen from "./ImportLandingScreen";
import { ImportLandingScreenProps } from "./screen.types";

const ImportLandingContainer = (): JSX.Element => {
	const { defaultFarm } = useContext(UserContext);
	const { loggedInSmag } = useContext(OADContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const navigate = useNavigate();

	const goToDashboard: ImportLandingScreenProps["goToDashboard"] = () => navigate("/");

	const goToSmag: ImportLandingScreenProps["goToSmag"] = () => {
		logAnalyticEvent(ImportEvents.clickImportSmag);
		navigate(loggedInSmag ? "smag" : "/oad");
	};

	const goToTelepac: ImportLandingScreenProps["goToTelepac"] = () => {
		logAnalyticEvent(ImportEvents.clickImportTelepac);
		navigate("telepac");
	};

	return (
		<ImportLandingScreen
			defaultFarm={defaultFarm}
			goToDashboard={goToDashboard}
			goToSmag={goToSmag}
			goToTelepac={goToTelepac}
			loggedInSmag={loggedInSmag}
		/>
	);
};

export default ImportLandingContainer;
