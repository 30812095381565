import { COLORS } from "./palette";

export interface Properties {
	backgroundColor: string;
	borderColor: string;
	borderWidth: number;
	color: string;
	hoverColor: string;
}

interface States {
	active: Properties;
	disabled: Properties;
	loading: Properties;
}

interface Variants {
	filled: States;
	inverted: States;
	outlined: States;
	strokeTranslucid: States;
	translucid: States;
}

export const getBtnVariant = ({
	color,
	state,
	variant
}: {
	color: Record<number, string>;
	state: keyof States;
	variant: keyof Variants;
}): Properties => {
	const variants: Variants = {
		filled: {
			active: {
				backgroundColor: color[100],
				borderColor: null,
				borderWidth: 0,
				color: COLORS.WHITE[100],
				hoverColor: color[200]
			},
			disabled: {
				backgroundColor: color[25],
				borderColor: null,
				borderWidth: 0,
				color: COLORS.WHITE[100],
				hoverColor: null
			},
			loading: {
				backgroundColor: color[25],
				borderColor: null,
				borderWidth: 0,
				color: COLORS.WHITE[100],
				hoverColor: null
			}
		},
		inverted: {
			active: {
				backgroundColor: COLORS.SMOKE[100],
				borderColor: null,
				borderWidth: 0,
				color: COLORS.NIGHT[100],
				hoverColor: COLORS.SMOKE[200]
			},
			disabled: {
				backgroundColor: COLORS.SMOKE[100],
				borderColor: null,
				borderWidth: 0,
				color: COLORS.NIGHT[50],
				hoverColor: null
			},
			loading: {
				backgroundColor: COLORS.SMOKE[100],
				borderColor: null,
				borderWidth: 0,
				color: COLORS.NIGHT[50],
				hoverColor: null
			}
		},
		outlined: {
			active: {
				backgroundColor: COLORS.WHITE[100],
				borderColor: color[100],
				borderWidth: 1,
				color: color[100],
				hoverColor: color[25]
			},
			disabled: {
				backgroundColor: COLORS.WHITE[100],
				borderColor: COLORS.NIGHT[50],
				borderWidth: 1,
				color: COLORS.NIGHT[50],
				hoverColor: null
			},
			loading: {
				backgroundColor: COLORS.WHITE[100],
				borderColor: color[50],
				borderWidth: 1,
				color: color[50],
				hoverColor: null
			}
		},
		strokeTranslucid: {
			active: {
				backgroundColor: color[10],
				borderColor: color[100],
				borderWidth: 1,
				color: color[100],
				hoverColor: null
			},
			disabled: {
				backgroundColor: null,
				borderColor: null,
				borderWidth: 0,
				color: COLORS.NIGHT[50],
				hoverColor: null
			},
			loading: null
		},
		translucid: {
			active: {
				backgroundColor: color[10],
				borderColor: null,
				borderWidth: 0,
				color: color[100],
				hoverColor: null
			},
			disabled: {
				backgroundColor: color[10],
				borderColor: null,
				borderWidth: 0,
				color: color[25],
				hoverColor: null
			},
			loading: null
		}
	};
	return variants?.[variant]?.[state];
};
