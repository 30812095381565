import { NozzleColor } from "@hygo/shared/models";
import { NOZZLE_COLORS } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface ColorSelectorProps {
	error?: { message?: string };
	onChange: (value: string) => void;
	value: string;
}

const Wrapper = styled.div`
	display: flex;
	gap: 16px 0;
	align-items: center;
	flex-wrap: wrap;
`;

const Label = styled.h5``;

const Color = styled.div<{ color: string }>`
	width: 60px;
	border-radius: 50%;
	background-color: ${(props) => props.color};
	height: 60px;
	cursor: pointer;
`;

const ColorWrapper = styled.div<{ selected: boolean }>`
	width: 25%;
	display: flex;

	flex-direction: column;
	align-items: center;
	gap: 4px;
	${Label} {
		color: ${(props) => (props.selected ? "var(--lake-100)" : "var(--night-100)")};
	}
	${Color} {
		border: ${(props) => (props.selected ? "4px solid var(--lake-100)" : "none")};
	}
`;

const ErrorMessage = styled.p`
	color: var(--gaspacho-100);
`;

const ColorSelector = ({ error, onChange, value }: ColorSelectorProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<>
			<Wrapper>
				{Object.values(NozzleColor).map((color) => {
					return (
						<ColorWrapper key={color} onClick={() => onChange(color)} selected={color === value}>
							<Color color={NOZZLE_COLORS[color]} />
							<Label>{t(`colors.${color}`)}</Label>
						</ColorWrapper>
					);
				})}
			</Wrapper>
			{error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
		</>
	);
};

export default ColorSelector;
