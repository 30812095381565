import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { useSeasons, useSmagRequest } from "@hygo/shared/hooks";
import { ModalsContext } from "@hygo/shared/modals";
import {
	DenormalizedIrrigation,
	DenormalizedTask,
	Feature,
	SnackbarType,
	TasksEvents,
	TasksListEvents
} from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { COLORS, convertObjectToSortedArray, denormalizeData } from "@hygo/shared/utils";
import { OADContext } from "@hygo/web/contexts";
import { OADStepEnum } from "@hygo/web/models";
import { ConfirmationModal, OnboardingTasksModal, SmagImportModal, SmagTokenErrorModal } from "@hygo/web/ui-components";
import { useFeature } from "flagged";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { TasksScreenProps } from "./screen.types";
import TasksScreen from "./TasksScreen";

const TasksContainer = (): JSX.Element => {
	const { checkTank, createFarm, deleteDoneTask, deleteIrrigation, exportSmagTasks, getDoneTasks, getIrrigations } =
		useApi();
	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const {
		admin,
		crops,
		defaultFarm,
		farmerSelected,
		farms,
		fields: allFields,
		loadFarms,
		nozzles: allNozzles,
		products: allProducts,
		sprayers: allSprayers,
		targets: allTargets,
		updateDefaultFarm,
		user
	} = useContext(UserContext);
	const hasMileos = useFeature(Feature.MILEOS);
	const { requestToSmag } = useSmagRequest(SmagTokenErrorModal);
	const { getOnboardingKeyTasks, loggedInSmag, setStepCookie } = useContext(OADContext);
	const { showModal } = useContext(ModalsContext);
	const { showSnackbar } = useContext(SnackbarContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { stepSeasons } = useSeasons({ startDate: new Date(user?.createdAt), withNextSeason: false });
	const [selectedFields, setSelectedFields] = useState<TasksScreenProps["selectedFields"]>([]);
	const [tasks, setTasks] = useState<DenormalizedTask[]>([]);
	const [irrigations, setIrrigations] = useState<DenormalizedIrrigation[]>([]);
	const [loading, setLoading] = useState<TasksScreenProps["loading"]>(false);
	const [smagExporting, setSmagExporting] = useState<TasksScreenProps["smagExporting"]>(false);
	const [selectedTaskIds, setSelectedTaskIds] = useState<TasksScreenProps["selectedTaskIds"]>([]);
	const [selectedSeason, setSelectedSeason] = useState(stepSeasons?.find((f) => f.isCurrent));
	const [exportMode, setExportMode] = useState<TasksScreenProps["exportMode"]>(false);

	const onUpdateDefaultFarm: TasksScreenProps["updateDefaultFarm"] = async (farmId, newFarms) => {
		resetSelection();
		logAnalyticEvent(TasksEvents.updateDefaultFarmFromTasks, { farm: farms?.find((f) => f.id === farmId) });
		await updateDefaultFarm(farmId, newFarms);
	};

	const goToDashboard: TasksScreenProps["goToDashboard"] = () => navigate("/");

	const resetSelection: TasksScreenProps["resetSelection"] = () => setSelectedFields([]);

	const onDeleteIrrigation: TasksScreenProps["onDeleteIrrigation"] = (irrigationId) => {
		showModal(ConfirmationModal, {
			btnColorPalette: COLORS.GASPACHO,
			confirmLabel: t("button.delete"),
			dismissLabel: t("button.cancel"),
			handleConfirm: async () => {
				try {
					await deleteIrrigation({ farmId: defaultFarm.id, irrigationId });
					await loadList();
					showSnackbar(t("snackbar.deleteIrrigation.success"), SnackbarType.SUCCESS);
				} catch (e) {
					showSnackbar(t("snackbar.deleteIrrigation.error"), SnackbarType.ERROR);
					throw e;
				}
			},
			subtitle: t("modals.irrigationDelete.body"),
			title: t("modals.irrigationDelete.title")
		});
	};

	const onDeleteTask: TasksScreenProps["onDeleteTask"] = (taskId) => {
		showModal(ConfirmationModal, {
			btnColorPalette: COLORS.GASPACHO,
			confirmLabel: t("button.delete"),
			dismissLabel: t("button.cancel"),
			handleConfirm: async () => {
				try {
					await deleteDoneTask({ farmId: defaultFarm.id, taskId });
					await loadList();
					logAnalyticEvent(TasksEvents.deleteTask);
					showSnackbar(t("snackbar.deleteTask.success"), SnackbarType.SUCCESS);
				} catch (e) {
					showSnackbar(t("snackbar.deleteTask.error"), SnackbarType.ERROR);
					throw e;
				}
			},
			subtitle: t("modals.taskDelete.body"),
			title: t("modals.taskDelete.title")
		});
	};

	const onCancelExport: TasksScreenProps["onCancelExport"] = () => {
		setExportMode(null);
		setSelectedTaskIds([]);
	};

	const startSmagExport = async (): Promise<void> => {
		onCancelExport();
		setSmagExporting(true);

		try {
			const response = await requestToSmag(() =>
				exportSmagTasks({
					farmId: defaultFarm.id,
					taskIds: selectedTaskIds
				})
			);
			if (!response) return;

			const succeededExportsMsg = t("snackbar.exportSmag.success.succeededExports", {
				count: response?.succeededExports
			});
			const failedExportsMsg = t("snackbar.exportSmag.success.failedExports", {
				count: response?.failedExports
			});
			const message = succeededExportsMsg + (response?.failedExports ? `\n${failedExportsMsg}` : "");
			showSnackbar(message, SnackbarType.INFO, 10000);
		} catch (e) {
			showSnackbar(t("snackbar.exportSmag.error"), SnackbarType.ERROR);
			throw e;
		} finally {
			logAnalyticEvent(TasksListEvents.exportSmagTask, {
				tasks: selectedTaskIds.map((taskId) => {
					const currentTask = tasks.find((task) => task.id === taskId);
					return { productFamily: currentTask?.productFamily, taskId: currentTask?.id };
				})
			});
			await loadList();
			setSmagExporting(false);
		}
	};

	const onUnselectAll: TasksScreenProps["onUnselectAll"] = () => {
		setSelectedTaskIds([]);
	};

	const onSelectAll: TasksScreenProps["onSelectAll"] = () => {
		setSelectedTaskIds(filteredList?.flatMap((taskGroup) => taskGroup?.data?.map((task) => task.id)));
	};

	const checkSmagTasksBeforeExport = async (): Promise<void> => {
		const smagFieldsWithAreaCheck = selectedTaskIds
			?.flatMap((taskId) => tasks?.find((task) => task.id === taskId)?.fields)
			?.filter((v, i, a) => v.fieldArea > v.area && a.findIndex((v2) => v2.id === v.id) === i);

		smagFieldsWithAreaCheck.length > 0
			? showModal(SmagImportModal, {
					fields: smagFieldsWithAreaCheck,
					onSubmit: async () => await startSmagExport()
				})
			: await startSmagExport();
	};

	const onClickExport: TasksScreenProps["onClickExport"] = async () => {
		exportMode ? checkSmagTasksBeforeExport() : setExportMode(true);
	};

	const updateSelectedTaskIds: TasksScreenProps["updateSelectedTaskIds"] = (checked, taskId) => {
		if (checked) {
			setSelectedTaskIds((prev) => prev?.filter((selectedTaskId) => selectedTaskId !== taskId));
		} else {
			setSelectedTaskIds((prev) => [...prev, taskId]);
		}
	};

	const onOpenTaskDetails: TasksScreenProps["onOpenTaskDetails"] = async ({ productIds, targetIds }) => {
		const fetchedTankIndications = await checkTank({ productIds, targetIds });
		logAnalyticEvent(TasksEvents.clickTaskDetails);
		return fetchedTankIndications;
	};

	const loadList = useCallback(async (): Promise<void> => {
		setLoading(true);
		const fetchedTasks = await getDoneTasks({
			farmId: defaultFarm.id,
			startAfter: selectedSeason.startAfter,
			startBefore: selectedSeason.startBefore
		});
		if (hasMileos) {
			const fetchedIrrigations = await getIrrigations({
				farmId: defaultFarm.id,
				startAfter: selectedSeason.startAfter,
				startBefore: selectedSeason.startBefore
			});
			const denormalizedFetchedIrrigations = fetchedIrrigations?.map((irrigation) => {
				return {
					...irrigation,
					fields: denormalizeData({
						data: allFields,
						fieldIdName: "fieldId",
						usages: irrigation?.fieldIds?.map((id) => ({
							detectedEndTime: null,
							detectedStartTime: null,
							fieldArea: null,
							fieldId: id,
							realCondition: null,
							realMetrics: null
						}))
					})
				};
			});
			setIrrigations(denormalizedFetchedIrrigations);
		}

		const denormalizedFetchedTasks = fetchedTasks?.map((task) => {
			return {
				...task,
				fields: denormalizeData({
					data: allFields,
					fieldIdName: "fieldId",
					usages: task?.fields
				})?.sort((a, b) => new Date(a.detectedStartTime).getTime() - new Date(b.detectedStartTime).getTime()),
				nozzle: allNozzles.find((n) => n.id === task?.nozzleId),
				products: denormalizeData({
					data: allProducts,
					fieldIdName: "productId",
					usages: task?.products
				}),
				sprayer: allSprayers.find((s) => s.id === task?.sprayerId),
				targets: denormalizeData({ data: allTargets, usages: task?.targetIds })
			};
		});
		setTasks(denormalizedFetchedTasks);
		setLoading(false);
	}, [
		defaultFarm,
		selectedSeason,
		getDoneTasks,
		allFields,
		allProducts,
		hasMileos,
		allTargets,
		allNozzles,
		allSprayers,
		getIrrigations
	]);

	const smagExportableTaskIds = useMemo(
		() =>
			tasks
				?.filter((task) => {
					return !task.smagStatus && task?.fields?.some((field) => field.smagCropZoneUid);
				})
				?.map((task) => task.id),
		[tasks]
	);

	const filteredList: TasksScreenProps["filteredList"] = useMemo(() => {
		let result;
		const filteredTasks = tasks.filter((task) => {
			return task.fields.find(
				(tf) => selectedFields?.length === 0 || selectedFields?.find((f) => f.id === tf.id)
			);
		});
		const filteredIrrigations = irrigations.filter((irrigation) => {
			return irrigation.fieldIds.find(
				(id) => selectedFields?.length === 0 || selectedFields?.find((f) => f.id === id)
			);
		});
		if (exportMode) {
			result = filteredTasks.filter((task) => smagExportableTaskIds?.includes(task.id));
		} else result = [...filteredTasks, ...filteredIrrigations];

		return convertObjectToSortedArray(result, (task) => new Date(task.startTime));
	}, [smagExportableTaskIds, exportMode, selectedFields, tasks, irrigations]);

	const fields = useMemo(() => {
		// We are using all fields included in tasks of the selectedseason and if we are
		// in Smag mode export, we are only showing the fields of exportable tasks

		// We are using all fields included in irrigations of the selectedSeason and if we are in any export mode,
		// we are not showing them
		const fieldsInTask = tasks
			?.filter((task) => !exportMode || smagExportableTaskIds?.includes(task?.id))
			?.flatMap((task) => task.fields);
		const fieldsInIrrigation = irrigations
			?.filter(() => !exportMode)
			?.flatMap((irrigation) =>
				denormalizeData({
					data: allFields?.filter((f) => !f?.deleted),
					fieldIdName: "fieldId",
					usages: irrigation?.fieldIds?.map((id) => ({
						detectedEndTime: null,
						detectedStartTime: null,
						fieldArea: null,
						fieldId: id,
						realCondition: null,
						realMetrics: null
					}))
				})
			);
		return [...fieldsInTask, ...fieldsInIrrigation].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
	}, [tasks, smagExportableTaskIds, exportMode, allFields, irrigations]);

	const onChangeSeason: TasksScreenProps["onChangeSeason"] = (i) => {
		const newSeason = stepSeasons[i];
		resetSelection();
		onCancelExport();
		setSelectedSeason(newSeason);
	};

	const handleSelectField: TasksScreenProps["handleSelectField"] = (f) => {
		setSelectedFields([
			{
				...f,
				detectedEndTime: null,
				detectedStartTime: null,
				fieldArea: null,
				realCondition: null,
				realMetrics: null
			}
		]);
	};

	useEffect(() => {
		if (defaultFarm && selectedSeason) loadList();
	}, [defaultFarm, loadList, selectedSeason]);

	useEffect(() => {
		const selectedField = fields?.find((f) => f.id === location.state?.selectedFieldId);
		if (!selectedField) return;
		setSelectedFields([selectedField]);
	}, [location, fields]);

	useEffect(() => {
		if (getOnboardingKeyTasks()) {
			showModal(OnboardingTasksModal, {
				onboardingKey: getOnboardingKeyTasks(),
				onConfirm: (): void => {
					setStepCookie({ key: getOnboardingKeyTasks(), value: OADStepEnum.DONE });
					navigate("/");
				}
			});
		}
	}, [navigate, setStepCookie, showModal, getOnboardingKeyTasks]);

	return (
		<TasksScreen
			adminWithoutUserSelected={admin && !farmerSelected}
			createFarm={createFarm}
			crops={crops}
			defaultFarm={defaultFarm}
			exportMode={exportMode}
			farms={farms}
			fields={fields}
			filteredList={filteredList}
			goToDashboard={goToDashboard}
			handleSelectField={handleSelectField}
			loadFarms={loadFarms}
			loading={loading}
			loggedInSmag={loggedInSmag}
			onCancelExport={onCancelExport}
			onChangeSeason={onChangeSeason}
			onClickExport={onClickExport}
			onDeleteIrrigation={onDeleteIrrigation}
			onDeleteTask={onDeleteTask}
			onOpenTaskDetails={onOpenTaskDetails}
			onSelectAll={onSelectAll}
			onUnselectAll={onUnselectAll}
			resetSelection={resetSelection}
			selectedFields={selectedFields}
			selectedSeason={selectedSeason}
			selectedTaskIds={selectedTaskIds}
			smagExportableTaskIds={smagExportableTaskIds}
			smagExporting={smagExporting}
			stepSeasons={stepSeasons}
			updateDefaultFarm={onUpdateDefaultFarm}
			updateSelectedTaskIds={updateSelectedTaskIds}
			user={user}
		/>
	);
};

export default TasksContainer;
