import { UserContext } from "@hygo/shared/contexts";
import { DenormalizedField, DenormalizedIrrigation } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { Accordion, AccordionContent, AccordionTitle } from "@hygo/web/ui-components";
import { useContext } from "react";
import styled from "styled-components";

import AccordionDetails from "./AccordionDetails";
import AccordionSummary from "./AccordionSummary";

interface IrrigationCardProps {
	irrigation: DenormalizedIrrigation;
	onDeleteIrrigation: (irrigationId: number) => void;
	selectedFields: DenormalizedField[];
}

const Wrapper = styled.div`
	margin-bottom: 16px;
`;

const StyledAccordion = styled(Accordion)`
	border: 1px solid var(--night-5);
	${AccordionContent}[aria-expanded="false"] {
		border-top: 1px solid var(--night-5);
	}
	${AccordionTitle} {
		gap: 16px;
	}
`;

const IrrigationCard = ({ irrigation, onDeleteIrrigation, selectedFields }: IrrigationCardProps): JSX.Element => {
	const { endTime, id, quantity, startTime } = irrigation;
	const { crops, user } = useContext(UserContext);

	return (
		<Wrapper>
			<StyledAccordion
				chevronColor={COLORS.LAKE[100]}
				innerContentBackground="var(--gradient-light-grey)"
				summary={
					<AccordionSummary
						checked={false}
						condition={null}
						countryCode={user?.countryCode}
						crops={crops}
						endTime={endTime}
						exportMode={null}
						fields={irrigation?.fields}
						id={null}
						isTask={false}
						onClickCheckbox={null}
						productFamily={null}
						selectedFields={selectedFields}
						smagStatus={null}
						startTime={startTime}
					/>
				}
			>
				<AccordionDetails
					crops={crops}
					debit={null}
					endTime={endTime}
					fields={irrigation?.fields}
					id={id}
					isTask={false}
					loading={false}
					notes={null}
					nozzle={null}
					onDelete={onDeleteIrrigation}
					products={null}
					quantity={quantity}
					selectedFields={selectedFields}
					sprayer={null}
					startTime={startTime}
					tankIndications={null}
					targets={null}
					user={user}
				/>
			</StyledAccordion>
		</Wrapper>
	);
};

export default IrrigationCard;
