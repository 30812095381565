import { Trash } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import styled from "styled-components";

interface SectionProps {
	children: JSX.Element;
	Icon: JSX.Element;
	onDelete?: () => void;
	standalone?: boolean;
	title: string;
}

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const TitleLabelWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const IconWrapper = styled.div`
	padding: 8px;
	background-color: var(--lake-10);
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const DeleteIconWrapper = styled(IconWrapper)`
	background-color: var(--gaspacho-10);
	cursor: pointer;
`;

const Wrapper = styled.section<{ $standalone: boolean }>`
	background-color: ${(props) => (props.$standalone ? "transparent" : "var(--white-100)")};
	padding: ${(props) => (props.$standalone ? 0 : "16px")};
	border-radius: ${(props) => (props.$standalone ? 0 : "4px")};
	gap: 8px;
	flex: 1;
	display: flex;
	flex-direction: column;
`;

const Section = ({ children, Icon, onDelete, standalone, title }: SectionProps): JSX.Element => {
	return (
		<Wrapper $standalone={standalone}>
			<TitleWrapper>
				<TitleLabelWrapper>
					<IconWrapper>{Icon}</IconWrapper>
					<h4>{title}</h4>
				</TitleLabelWrapper>
				{onDelete && (
					<DeleteIconWrapper onClick={onDelete}>
						<Trash fill={COLORS.GASPACHO[100]} height={20} width={20} />
					</DeleteIconWrapper>
				)}
			</TitleWrapper>
			{children}
		</Wrapper>
	);
};

export default Section;
