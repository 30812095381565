import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { useCoops, useSmagRequest } from "@hygo/shared/hooks";
import { UserSmagStatus } from "@hygo/shared/models";
import { useImport } from "@hygo/web/hooks";
import { SmagTokenErrorModal } from "@hygo/web/ui-components";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import ImportSmagScreen from "./ImportSmagScreen";
import { FormInputs, ImportSmagScreenProps } from "./screen.types";

const ImportSmagContainer = (): JSX.Element => {
	const { getSmagFarms } = useApi();
	const { defaultFarm, farms, updateDefaultFarm, user } = useContext(UserContext);
	const { requestToSmag } = useSmagRequest(SmagTokenErrorModal);
	const { matchingCoop: userCoop } = useCoops({ coopId: user?.coopId });
	const navigate = useNavigate();
	const methods = useForm<FormInputs>({ mode: "all" });
	const [loadingSmagFarms, setLoadingSmagFarms] = useState<ImportSmagScreenProps["loadingSmagFarms"]>(false);
	const [smagFarms, setSmagFarms] = useState<ImportSmagScreenProps["smagFarms"]>();
	const { goBackToImportLanding, goToDashboard } = useImport({});

	const onSubmit: ImportSmagScreenProps["onSubmit"] = ({ smagFarmId, year }) =>
		navigate("linking", { state: { smagFarmId, year: parseInt(year, 10) } });

	const onChangeYear: ImportSmagScreenProps["onChangeYear"] = async (year) => {
		setLoadingSmagFarms(true);
		setSmagFarms(null);
		methods.setValue("year", year);
		methods.setValue("smagFarmId", null, { shouldDirty: true, shouldValidate: true });
		if (user.smagStatus === UserSmagStatus.USER) {
			const fetchedSmagFarms = await requestToSmag(() => getSmagFarms(parseInt(year, 10)));
			if (!fetchedSmagFarms) {
				setLoadingSmagFarms(false);
				return;
			}
			setSmagFarms(fetchedSmagFarms);
		}
		setLoadingSmagFarms(false);
	};

	const onSearchFarms: ImportSmagScreenProps["onSearchFarms"] = async (inputValue) => {
		if (!inputValue) return;
		const fetchedSmagFarms = await requestToSmag(() =>
			getSmagFarms(parseInt(methods.watch().year, 10), inputValue)
		);
		if (!fetchedSmagFarms) return;
		return fetchedSmagFarms?.map((f) => ({ label: f.name, value: f.uid }));
	};

	const onUpdateDefaultFarm: ImportSmagScreenProps["updateDefaultFarm"] = async (farmId) => {
		await updateDefaultFarm(farmId);
	};

	return (
		<ImportSmagScreen
			defaultFarm={defaultFarm}
			farms={farms}
			goBack={goBackToImportLanding}
			goToDashboard={goToDashboard}
			loadingSmagFarms={loadingSmagFarms}
			methods={methods}
			onChangeYear={onChangeYear}
			onSearchFarms={onSearchFarms}
			onSubmit={onSubmit}
			smagFarms={smagFarms}
			updateDefaultFarm={onUpdateDefaultFarm}
			userCoop={userCoop}
			userSmagStatus={user.smagStatus}
		/>
	);
};

export default ImportSmagContainer;
