import { SnackbarType } from "@hygo/shared/models";
import { SNACKBAR_COLORS } from "@hygo/shared/utils";
import { createContext, FC, useCallback, useMemo, useState } from "react";

interface SnackbarContextResult {
	hideSnackbar: () => void;
	showSnackbar: (text: string, type: SnackbarType, durationValue?: number) => void;
}

export const SnackbarContext = createContext({} as SnackbarContextResult);

interface SnackbarProviderProps {
	children: JSX.Element;
	Component: FC<{
		backgroundColor: string;
		duration: number;
		message: string;
		onDismiss?: () => void;
		show: boolean;
	}>;
	hideManually: boolean;
}

const SnackbarProvider = ({ children, Component, hideManually }: SnackbarProviderProps): JSX.Element => {
	const [show, setShow] = useState<boolean>(false);
	const [text, setText] = useState<string>("");
	const [type, setType] = useState<SnackbarType>();
	const [duration, setDuration] = useState<number>();
	const showSnackbar: SnackbarContextResult["showSnackbar"] = useCallback(
		(message, snackType, durationValue = 3000) => {
			setShow(false);
			setText(message);
			setType(snackType);
			setDuration(durationValue);
			setShow(true);
			hideManually &&
				setTimeout(() => {
					setShow(false);
				}, durationValue);
		},
		[hideManually]
	);

	const hideSnackbar: SnackbarContextResult["hideSnackbar"] = useCallback(() => {
		setShow(false);
		setText(null);
		setType(null);
		setDuration(null);
	}, []);

	const value = useMemo(
		() => ({
			hideSnackbar,
			showSnackbar
		}),
		[showSnackbar, hideSnackbar]
	);

	return (
		<SnackbarContext.Provider value={value}>
			{children}
			<Component
				backgroundColor={SNACKBAR_COLORS[type]}
				duration={duration}
				message={text}
				onDismiss={() => setShow(false)}
				show={show}
			/>
		</SnackbarContext.Provider>
	);
};

export default SnackbarProvider;
