import { Crop } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

import CropIcon from "../CropIcon";
import SelectInput from "../SelectInput";

interface CropSelectorProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any>;
	crops: Crop[];
	disabled?: boolean;
	hasPotatoesOnly?: boolean;
	onOpenCallback?: (v: boolean) => void;
}

const CropSelector = ({
	control,
	crops,
	disabled,
	hasPotatoesOnly,
	onOpenCallback
}: CropSelectorProps): JSX.Element => {
	const { t } = useTranslation();
	const finalCrops = hasPotatoesOnly ? crops?.filter((c) => c.mileosCompatible) : crops;
	return (
		<SelectInput
			control={control}
			disabled={disabled}
			initialOptions={finalCrops.map((c) => ({
				Icon: (
					<CropIcon crop={c} fill={disabled ? COLORS.NIGHT[50] : COLORS.NIGHT[100]} height={20} width={20} />
				),
				label: c.name,
				value: c.id.toString()
			}))}
			label={t("inputs.crop.label")}
			name="crop"
			onOpenCallback={onOpenCallback}
			placeholder={t("inputs.crop.placeholder")}
			rules={{
				required: {
					message: t("inputs.crop.errors.required"),
					value: true
				}
			}}
			searchable
			theme="light"
		/>
	);
};

export default CropSelector;
