import { SimpleButton } from "@hygo/web/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface SmagToolTipProps {
	onClick: () => void;
}

const Wrapper = styled.div`
	background-color: var(--white-100);
	border-radius: 8px;
	z-index: 1;
	position: absolute;
	width: 385px;
	bottom: 16px;
	left: 16px;
	padding: 8px 16px;
`;

const Paragraph = styled.p`
	margin: 8px 0;
`;

const SmagTooltip = ({ onClick }: SmagToolTipProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<h4>{t("screens.dashboard.importSmagTooltip.title")}</h4>
			<Paragraph>{t("screens.dashboard.importSmagTooltip.body")}</Paragraph>
			{<SimpleButton onClick={onClick} text={t("screens.dashboard.importSmagTooltip.cta")} />}
		</Wrapper>
	);
};

export default SmagTooltip;
