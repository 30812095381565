import { UserContext } from "@hygo/shared/contexts";
import { FieldCharacteristicsModalPropsType } from "@hygo/shared/modals";
import { Feature } from "@hygo/shared/models";
import { COLORS, convertToHa } from "@hygo/shared/utils";
import { useFeature } from "flagged";
import { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CropSelector from "../../CropSelector";
import InputTip from "../../InputTip";
import TextInput from "../../TextInput";
import BaseModal from "../BaseModal";

const Wrapper = styled.div<{ $open: boolean }>`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: ${(props) => (props.$open ? "500px" : "unset")};
	gap: 16px;
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const AreaInputWrapper = styled.div`
	gap: 8px;
	display: flex;
	flex-direction: column;
`;

const FieldCharacteristicsModal = ({
	field,
	hideModal,
	modalVisible,
	onSubmit
}: FieldCharacteristicsModalPropsType): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const { crops } = useContext(UserContext);
	const hasPotatoesOnly = useFeature(Feature.POTATOES_ONLY);
	const [open, setOpen] = useState<boolean>(false);
	const isSmagField = !!field?.smagCropZoneUid;

	const defaultValues = useMemo(
		() => ({
			area: field.area ? convertToHa(field.area) : null,
			crop: field?.cropId?.toString()
		}),
		[field]
	);

	const methods = useForm({
		defaultValues,
		mode: "onChange"
	});
	const {
		formState: { isValid }
	} = methods;
	useEffect(() => {
		methods.reset(defaultValues);
	}, [methods, defaultValues]);

	const onConfirm = async ({ area, crop }: { area: number; crop: string }): Promise<void> => {
		try {
			setLoading(true);
			await onSubmit({
				area: isSmagField ? undefined : area * 10000,
				cropId: parseInt(crop, 10),
				id: field?.id
			});
			hideModal();
		} finally {
			setLoading(false);
		}
	};

	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnDisabled={!isValid}
			confirmBtnLoading={loading}
			confirmBtnText={t("button.save")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={methods.handleSubmit(onConfirm)}
			title={t("modals.fieldCharacteristics.title")}
		>
			<FormProvider {...methods}>
				<Wrapper $open={open}>
					<AreaInputWrapper>
						<TextInput
							disabled={isSmagField}
							label={t("inputs.area.label")}
							name="area"
							rules={{
								required: {
									message: t("inputs.area.errors.required"),
									value: true
								},
								validate: (value: number) => value > 0 || t("inputs.area.errors.invalid")
							}}
							unit={t("units.hectare")}
						/>
						{isSmagField && (
							<InputTip palette={COLORS.DISABLED} withBorder={false}>
								<ColoredCardText>{t("modals.fieldCharacteristics.area.smagInputTip")}</ColoredCardText>
							</InputTip>
						)}
					</AreaInputWrapper>

					<div>
						<CropSelector
							control={methods.control}
							crops={crops}
							disabled={!!methods.watch().crop && !!hasPotatoesOnly}
							hasPotatoesOnly={!!hasPotatoesOnly}
							onOpenCallback={setOpen}
						/>
					</div>
				</Wrapper>
			</FormProvider>
		</BaseModal>
	);
};
export default FieldCharacteristicsModal;
