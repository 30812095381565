import { Parcelles } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import {
	Button,
	DatePicker,
	EmptyState,
	FarmSelector,
	FormCard,
	FormHeader,
	InputTip,
	SwitchButton
} from "@hygo/web/ui-components";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ExportLandingScreenProps } from "./screen.types";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	background: var(--gradient-background-2);
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const ButtonWrapper = styled.div`
	gap: 16px;
	display: flex;
	flex-direction: column;
`;

const DateWrapper = styled.div`
	display: flex;
	flex: 1;
	gap: 8px;
	width: 100%;
`;

const Card = styled(FormCard)`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const InputTipWrapper = styled(InputTip)`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;

const DatePickerWrapper = styled.div`
	flex: 1;
`;

const ExportLandingScreen = ({
	defaultFarm,
	error,
	exporting,
	farms,
	goToDashboard,
	methods,
	onExportCampaign,
	onWithMeteoPress,
	updateDefaultFarm,
	user
}: ExportLandingScreenProps): JSX.Element => {
	const { isValid } = methods.formState;

	const { t } = useTranslation();
	return (
		<Wrapper>
			{defaultFarm ? (
				<Card>
					<FormHeader title={t("screens.exportLanding.title")} />
					<FormProvider {...methods}>
						<FarmSelector
							crudActions={false}
							defaultFarm={defaultFarm}
							farms={farms}
							updateDefaultFarm={updateDefaultFarm}
						/>
						<DateWrapper>
							<DatePickerWrapper>
								<DatePicker
									control={methods.control}
									countryCode={user?.countryCode}
									dateFormat="dd/MM/yyyy"
									label={t("glossary.from")}
									maxDate={methods.watch()?.startBefore}
									name="startAfter"
									placeholder={t("inputs.day.placeholder")}
								/>
							</DatePickerWrapper>
							<DatePickerWrapper>
								<DatePicker
									control={methods.control}
									countryCode={user?.countryCode}
									dateFormat="dd/MM/yyyy"
									label={t("glossary.to")}
									maxDate={new Date()}
									minDate={methods.watch()?.startAfter}
									name="startBefore"
									placeholder={t("inputs.day.placeholder")}
								/>
							</DatePickerWrapper>
						</DateWrapper>
						<InputTipWrapper palette={COLORS.DISABLED} withBorder={false}>
							<h5>{t("screens.exportLanding.withMeteo")}</h5>
							<SwitchButton
								name="withMeteo"
								onChange={(e) => onWithMeteoPress(e.target.checked)}
								value={methods.watch()?.withMeteo}
							/>
						</InputTipWrapper>
						{methods.watch().withMeteo && (
							<InputTip palette={COLORS.DISABLED} withBorder={false}>
								<ColoredCardText>
									{t("screens.exportLanding.inputTip.noWeatherOnEdiExport")}
								</ColoredCardText>
							</InputTip>
						)}

						<ButtonWrapper>
							<Button
								color={COLORS.LAKE}
								disabled={!isValid}
								loading={exporting}
								onClick={methods.handleSubmit(onExportCampaign)}
								text={t("screens.exportLanding.cta")}
							/>
							{error === "noTaskOnThisPeriod" && (
								<InputTip palette={COLORS.BEACH}>
									<h5>{t("screens.exportLanding.inputTip.noTaskOnThisPeriod")}</h5>
								</InputTip>
							)}
						</ButtonWrapper>
					</FormProvider>
				</Card>
			) : (
				<EmptyState
					btnIcon={Parcelles}
					btnText={t("components.emptyState.noDefaultFarm.btn")}
					description={t("components.emptyState.noDefaultFarm.description")}
					illustration={<img alt="ferme" src={"/assets/images/coop.png"} width={100} />}
					onClick={goToDashboard}
					title={t("components.emptyState.noDefaultFarm.title")}
				/>
			)}
		</Wrapper>
	);
};

export default ExportLandingScreen;
