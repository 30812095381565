import { countryMapping } from "@hygo/shared/constants";
import { Chevron, Plus } from "@hygo/shared/icons";
import { AccountEvents, CountryStatus, WindSpeedUnity } from "@hygo/shared/models";
import { Feature as FeatureEnum } from "@hygo/shared/models";
import { COLORS, PLAN_STATUS_COLORS } from "@hygo/shared/utils";
import {
	Button,
	FormCard,
	GooglePlacesAutocomplete,
	InputTip,
	PhoneNumberInput,
	SelectInput,
	TextInput
} from "@hygo/web/ui-components";
import { Feature } from "flagged";
import { capitalize } from "lodash";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AccountScreenProps } from "./screen.types";

const Wrapper = styled.div`
	background: var(--gradient-background-1);
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 32px;
`;

const Title = styled.h4`
	color: var(--lake-100);
`;

const PlanDetails = styled.div`
	flex: 1;
`;

const PlanStatus = styled.h4<{ $planStatusColor: string }>`
	color: ${(props) => props.$planStatusColor};
`;

const Border = styled.div`
	background-color: var(--night-10);
	height: 1px;
`;

const SubscriptionButtonWrapper = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`;

const Card = styled(FormCard)`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const FormContainer = styled.div`
	width: 700px;
	margin: 0 auto;
	margin-bottom: 16px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const FormHeader = styled.h2`
	padding-left: 64px;
`;

const FormWrapper = styled.div`
	display: grid;
	gap: 16px;
`;

const LeftWrapper = styled.div`
	flex: 1;
`;

const FooterWrapper = styled.div`
	background: var(--white-100);
	width: 100%;
	padding: 30px 0;
`;

const Footer = styled.div`
	margin: 0 auto;
	width: 700px;
	display: flex;
	gap: 16px;
	align-items: center;
`;

const OADImage = styled.img`
	overflow: hidden;
`;

const InlineWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
	align-items: center;
`;

const Hint = styled.p`
	color: var(--night-50);
`;

const ChevronIcon = styled(Chevron)`
	transform: rotate(-90deg);
`;

const StyleInputTip = styled(InputTip)`
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: space-between;
	width: fit-content;
`;

const AccountScreen = ({
	coops,
	countryCode,
	hasFarmWeather,
	hasMileosAddon,
	isFarmer,
	languages,
	loading,
	logAnalyticEvent,
	methods,
	onCancel,
	onClickAddMorePlots,
	onClickManageSubscription,
	onClickPasswordModal,
	onClickPricing,
	onEditPhoneCountrySelector,
	onSubmit,
	openAccountDeleteModal,
	plan,
	planStatus,
	redirectToOAD,
	rules
}: AccountScreenProps): JSX.Element => {
	const { t } = useTranslation();
	// Read all formState values to subscribe to changes. NEEDS TO BE KEPT to subscripe to RHF's proxy
	const { isDirty, isValid } = methods.formState;
	return (
		<Wrapper>
			<FormContainer>
				<FormHeader>{t("screens.account.title")}</FormHeader>
				{plan && (
					<Card>
						<Title>{t("screens.account.subscription.title")}</Title>
						<PlanDetails>
							<h2>HYGO {t(`plans.${plan.i18nId}.name`)}</h2>
							<PlanStatus $planStatusColor={PLAN_STATUS_COLORS[planStatus]}>
								{t(`plans.status.${planStatus}`)}
							</PlanStatus>
							<p>{t(`plans.${plan.i18nId}.description`)}</p>
						</PlanDetails>
						{hasMileosAddon && <h4>{t("screens.account.subscription.hasMileosAddonLabel")}</h4>}
						<SubscriptionButtonWrapper>
							{countryMapping[countryCode].status === CountryStatus.OPEN && (
								<Button
									color={COLORS.LAKE}
									onClick={onClickManageSubscription}
									text={t("screens.account.subscription.editSubscriptionBtn")}
								/>
							)}
							<Button
								color={COLORS.LAKE}
								onClick={onClickPricing}
								outlined
								text={t("screens.account.subscription.pricingBtn")}
							/>
						</SubscriptionButtonWrapper>
					</Card>
				)}
				<FormProvider {...methods}>
					<FormWrapper>
						<Card>
							<Title>{t("screens.account.myDetails.title")}</Title>
							<TextInput
								autoCapitalize="words"
								autoComplete="given-name"
								flex
								label={t("inputs.firstName.label")}
								name="firstName"
								placeholder={t("inputs.firstName.placeholder")}
								rules={rules.firstName}
							/>
							<Border />
							<TextInput
								autoCapitalize="words"
								autoComplete="family-name"
								flex
								label={t("inputs.lastName.label")}
								name="lastName"
								placeholder={t("inputs.lastName.placeholder")}
								rules={rules.lastName}
							/>
							<Border />
							<PhoneNumberInput
								control={methods.control}
								error={methods.formState.errors.principalPhone}
								flex
								onChange={onEditPhoneCountrySelector}
								phoneCountrySelector={methods.watch().phoneCountrySelector}
							/>
							<Border />
							<TextInput
								autoComplete="email"
								flex
								label={t("inputs.email.label")}
								name="email"
								placeholder={t("inputs.email.placeholder")}
								rules={rules.email}
							/>
							<Border />
							<InlineWrapper>
								<LeftWrapper>
									<h4>{t("inputs.password.label")}</h4>
								</LeftWrapper>
								<Button
									color={COLORS.SMOKE}
									inverted
									onClick={onClickPasswordModal}
									text={t("button.edit")}
									width="fit-content"
								/>
							</InlineWrapper>
							<Border />

							<SelectInput
								control={methods.control}
								disabled={loading}
								flex
								initialOptions={languages.map((c) => ({
									Icon: c?.Icon,
									label: c?.label,
									value: c?.value
								}))}
								label={t("inputs.language.label")}
								name="language"
								placeholder={t("inputs.language.placeholder")}
								rules={{
									required: {
										message: t("inputs.language.errors.required"),
										value: true
									}
								}}
								theme="light"
							/>
							<Border />
							<SelectInput
								control={methods.control}
								disabled={loading}
								flex
								initialOptions={Object.keys(WindSpeedUnity)?.map((l) => {
									return {
										label: capitalize(t(`units.${l}`)),
										value: l
									};
								})}
								label={t("inputs.windSpeedUnity.label")}
								name="windSpeedUnity"
								placeholder={t("inputs.windSpeedUnity.placeholder")}
								rules={{
									required: {
										message: t("inputs.windSpeedUnity.errors.required"),
										value: true
									}
								}}
								theme="light"
							/>
						</Card>
						<Card>
							<Title>{t("screens.account.myFarm.title")}</Title>
							<GooglePlacesAutocomplete
								countryCode={countryCode}
								flex
								hint={t("inputs.location.hint")}
								label={t("inputs.location.label")}
								name="googleAutocomplete"
								onChange={(v) => {
									logAnalyticEvent(AccountEvents.updateZipCode, { location: v.label });
									methods.setValue("location", v);
								}}
								placeholder={t("inputs.location.placeholder")}
								rules={rules.googleAutocomplete}
							/>

							<Feature name={FeatureEnum.FARM_WEATHER}>
								{(enabled) =>
									!enabled &&
									countryMapping[countryCode].status === CountryStatus.OPEN && (
										<StyleInputTip onClick={onClickAddMorePlots} palette={COLORS.GRASS}>
											<Plus fill={COLORS.GRASS[100]} height={20} width={20} />
											<p>{t("screens.account.inputTip.addPlot")}</p>
											<ChevronIcon fill={COLORS.GRASS[100]} height={20} width={20} />
										</StyleInputTip>
									)
								}
							</Feature>
							{isFarmer && countryMapping[countryCode].withCoops && (
								<>
									<Border />

									<SelectInput
										clearable
										control={methods.control}
										disabled={loading}
										event={AccountEvents.updateCoop}
										flex
										hint={t("inputs.coop.hint")}
										initialOptions={coops.map((c) => ({
											label: c.name,
											value: c.id.toString()
										}))}
										label={t("inputs.coop.label")}
										name="coop"
										placeholder={t("inputs.coop.placeholder")}
										rules={rules.coop}
										searchable
										theme="light"
									/>
								</>
							)}
						</Card>
					</FormWrapper>
				</FormProvider>
				{hasFarmWeather && (
					<Card>
						<Title>{t("screens.account.myOad.title")}</Title>
						<OADImage alt={t("screens.account.myOad.title")} src="/assets/images/account/oad.png" />
						<InlineWrapper>
							<LeftWrapper>
								<h4>{t("screens.account.myOad.title")}</h4>
								<Hint>{t("screens.account.myOad.hint")}</Hint>
							</LeftWrapper>
							<Button
								color={COLORS.SMOKE}
								inverted
								onClick={redirectToOAD}
								text={t("screens.account.myOad.cta")}
								width="fit-content"
							/>
						</InlineWrapper>
					</Card>
				)}
				<Card>
					<InlineWrapper>
						<LeftWrapper>
							<h4>{t("screens.account.delete.title")}</h4>
							<Hint>{t("screens.account.delete.description")}</Hint>
						</LeftWrapper>
						<Button
							color={COLORS.GASPACHO}
							onClick={openAccountDeleteModal}
							text={t("button.delete")}
							width="fit-content"
						/>
					</InlineWrapper>
				</Card>
			</FormContainer>
			<FooterWrapper>
				<Footer>
					<Button
						color={COLORS.SMOKE}
						disabled={!isDirty}
						inverted
						onClick={onCancel}
						text={t("button.cancel")}
					/>
					<Button
						color={COLORS.LAKE}
						disabled={!isDirty || !isValid}
						loading={loading}
						onClick={methods.handleSubmit(onSubmit)}
						text={t("button.save")}
					/>
				</Footer>
			</FooterWrapper>
		</Wrapper>
	);
};

export default AccountScreen;
