import styled from "styled-components";

interface DividerProps {
	color?: string;
	text?: string;
}

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	align-items: center;
	margin: 40px 0;
`;

const StyledDivider = styled.div<{ color: string }>`
	height: 1px;
	width: 100%;
	background-color: ${(props) => props.color || "var(--night-50)"};
	flex: 1;
`;

const Text = styled.h5`
	margin: 0 16px;
`;

const Divider = ({ color, text }: DividerProps): JSX.Element => {
	return (
		<Wrapper>
			<StyledDivider color={color} />
			{text ? <Text>{text}</Text> : <StyledDivider color={color} />}
			<StyledDivider color={color} />
		</Wrapper>
	);
};

export default Divider;
