import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { BaseSprayer, SnackbarType, Sprayer, SprayerEvents } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { countDecimals } from "@hygo/shared/utils";
import { useContext, useEffect, useMemo } from "react";
import { RegisterOptions, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type SprayerFormInputs = Omit<BaseSprayer, "defaultNozzleId">;

interface useSprayerResult {
	handleSprayerErrors: (error: { response: { data: { code: string } } }) => void;
	methods: UseFormReturn<SprayerFormInputs>;
	onCreateOrUpdate: (sprayer: Sprayer) => Promise<number>;
	onDelete: (sprayer: Sprayer) => Promise<void>;
	rules: Record<keyof SprayerFormInputs, RegisterOptions>;
}

interface useSprayerProps {
	sprayer?: Sprayer;
}

export const useSprayer = ({ sprayer }: useSprayerProps): useSprayerResult => {
	const { t } = useTranslation();
	const { createSprayer, deleteSprayer, updateSprayer } = useApi();
	const { fetchUser, loadSprayers } = useContext(UserContext);
	const { formatSprayer, logAnalyticEvent } = useContext(AmplitudeContext);
	const { showSnackbar } = useContext(SnackbarContext);
	const defaultValues = useMemo(
		() => ({ gpsBarcode: sprayer?.gpsBarcode, name: sprayer?.name, weatherBarcode: sprayer?.weatherBarcode }),
		[sprayer]
	);

	const methods = useForm({ defaultValues, mode: "onChange" });

	const handleSprayerErrors: useSprayerResult["handleSprayerErrors"] = (e) => {
		if (e?.response?.data?.code === "gpsDeviceNotFound") {
			methods.setError("gpsBarcode", {
				message: t("inputs.gpsBarcode.errors.invalid"),
				type: "custom"
			});
		} else if (e?.response?.data?.code === "weatherDeviceNotFound") {
			methods.setError("weatherBarcode", {
				message: t("inputs.weatherBarcode.errors.invalid"),
				type: "custom"
			});
		} else {
			showSnackbar(t("snackbar.sprayer.error"), SnackbarType.ERROR);
			throw e;
		}
	};

	const onCreateOrUpdate: useSprayerResult["onCreateOrUpdate"] = async (values) => {
		const { gpsBarcode, name, weatherBarcode } = values;
		let createdId;
		if (!values?.id) {
			const { id } = await createSprayer({
				defaultNozzleId: null,
				gpsBarcode,
				name,
				weatherBarcode
			});
			logAnalyticEvent(SprayerEvents.createSprayer, formatSprayer(values));
			createdId = id;
		} else {
			await updateSprayer(values);
			logAnalyticEvent(SprayerEvents.updateSprayer, formatSprayer(values));
		}
		methods.reset({
			gpsBarcode,
			name,
			weatherBarcode
		});
		await loadSprayers();
		showSnackbar(t("snackbar.sprayer.success"), SnackbarType.SUCCESS);
		return createdId;
	};

	const onDelete: useSprayerResult["onDelete"] = async (sprayerParams) => {
		try {
			await deleteSprayer(sprayerParams.id);
			await loadSprayers();
			await fetchUser();
			logAnalyticEvent(SprayerEvents.deleteSprayer, formatSprayer(sprayerParams));
			showSnackbar(t("snackbar.deleteSprayer.success"), SnackbarType.SUCCESS);
		} catch (e) {
			showSnackbar(t("snackbar.deleteSprayer.error"), SnackbarType.ERROR);
			throw e;
		}
	};

	const rules: useSprayerResult["rules"] = {
		gpsBarcode: {
			setValueAs: (v: string) => Number(v) || v,
			validate: (v) => {
				return !v || (v > 0 && !countDecimals(v)) || t("inputs.gpsBarcode.errors.invalid");
			}
		},
		name: {
			required: {
				message: t("inputs.sprayerName.errors.required"),
				value: true
			}
		},
		weatherBarcode: undefined
	};

	useEffect(() => {
		methods.reset(defaultValues);
	}, [defaultValues, methods]);

	return {
		handleSprayerErrors,
		methods,
		onCreateOrUpdate,
		onDelete,
		rules
	};
};
