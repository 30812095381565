import { COLORS } from "@hygo/shared/utils";
import { Button, FormCard, TextInput } from "@hygo/web/ui-components";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import OADCard from "./components/OADCard";
import { OADScreenProps } from "./screen.types";

const Wrapper = styled.div`
	background: var(--gradient-background-1);
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 32px;
`;

const Title = styled.h4`
	color: var(--lake-100);
`;

const FormHeader = styled.h2`
	padding-left: 64px;
`;

const Grid = styled.div`
	display: grid;
	flex: 1;
	gap: 16px;
	grid-template-columns: repeat(2, 1fr);
`;

const Card = styled(FormCard)`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const Container = styled.div`
	overflow: auto;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	width: 700px;
	gap: 16px;
	margin-bottom: 16px;
`;

const InlineWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
	align-items: center;
`;

const OADScreen = ({
	availableOADList,
	loading,
	methods,
	onSubmit,
	unavailableOADList
}: OADScreenProps): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState: { isValid }
	} = methods;
	return (
		<Wrapper>
			<Container>
				<FormHeader>{t("screens.oad.title")}</FormHeader>
				<Card>
					<Title>{t("screens.oad.available.title")}</Title>
					<Grid>
						{availableOADList.map(({ id, isLoggedIn, primaryAction, secondaryAction }) => {
							return (
								<OADCard
									id={id}
									isLoggedIn={isLoggedIn}
									key={id}
									primaryAction={primaryAction}
									secondaryAction={secondaryAction}
								/>
							);
						})}
					</Grid>
				</Card>
				<Card>
					<Title>{t("screens.oad.unavailable.title")}</Title>
					<Grid>
						{unavailableOADList.map(({ id, isLoggedIn, primaryAction, secondaryAction }) => {
							return (
								<OADCard
									id={id}
									isLoggedIn={isLoggedIn}
									key={id}
									primaryAction={primaryAction}
									secondaryAction={secondaryAction}
								/>
							);
						})}
					</Grid>
				</Card>
				<FormProvider {...methods}>
					<Card>
						<InlineWrapper>
							<TextInput
								disabled={loading}
								flex
								hint={t("screens.oad.footer.hint")}
								label={t("screens.oad.footer")}
								name="suggestion"
								placeholder={t("inputs.suggestion.placeholder")}
								rules={{
									required: {
										message: t("inputs.suggestion.errors.invalid"),
										value: true
									}
								}}
							/>
							<Button
								color={COLORS.LAKE}
								disabled={!isValid}
								isSubmitBtn
								loading={loading}
								onClick={methods.handleSubmit(onSubmit)}
								text={t("button.send")}
								width="fit-content"
							/>
						</InlineWrapper>
					</Card>
				</FormProvider>
			</Container>
		</Wrapper>
	);
};

export default OADScreen;
