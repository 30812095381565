import { Condition, MileosAdvice, MileosStatus, NozzleColor, PlanStatus, SnackbarType } from "@hygo/shared/models";

export const COLORS: { [key: string]: { [key: number]: string } } = {
	ABSENT_TARGET: {
		100: "hsla(192, 11%, 91%, 1)"
	},
	BEACH: {
		10: "hsla(43, 100%, 75%, 0.1)",
		100: "hsla(43, 100%, 75%, 1)",
		200: "hsla(43, 100%, 50%, 1)",
		50: "hsla(43, 100%, 75%, 0.5)"
	},
	BLACK: {
		100: "hsla(0, 0%, 0%, 1)",
		50: "hsla(0, 0%, 0%, 0.5)"
	},
	DISABLED: {
		10: "hsla(186, 13%, 55%, 0.1)",
		100: "hsla(186, 13%, 55%, 1)",
		20: "hsla(186, 13%, 55%, 0.2)",
		50: "hsla(186, 13%, 55%, 0.5)"
	},
	GASPACHO: {
		10: "hsla(0, 85%, 65%, 0.1)",
		100: "hsla(0, 85%, 65%, 1)",
		200: "hsla(360, 84%, 55%, 1)",
		25: "hsla(0, 85%, 65%, 0.25)",
		50: "hsla(0, 85%, 65%, 0.5)"
	},
	GRASS: {
		10: "hsla(143, 94%, 37%, 0.1)",
		100: "hsla(143, 94%, 37%, 1)",
		200: "hsla(143, 94%, 28%, 1)",
		50: "hsla(143, 94%, 37%, 0.5)"
	},
	GREY: {
		100: "hsla(180, 3%, 81%, 1)"
	},
	LAKE: {
		10: "hsla(187, 100%, 35%, 0.1)",
		100: "hsla(187, 100%, 35%, 1)",
		200: "hsla(187, 100%, 28%, 1)",
		25: "hsla(187, 100%, 35%, 0.25)",
		50: "hsla(187, 100%, 35%, 0.5)"
	},
	LEAF: {
		10: "hsla(104, 79%, 63%, 0.1)",
		100: "hsla(104, 79%, 63%, 1)",
		200: "hsla(105, 51%, 54%, 1)",
		50: "hsla(104, 79%, 63%, 0.5)"
	},
	NIGHT: {
		0: "hsla(186, 100%, 11%, 0)",
		10: "hsla(186, 100%, 11%, 0.1)",
		100: "hsla(186, 100%, 11%, 1)",
		20: "hsla(186, 100%, 11%, 0.2)",
		25: "hsla(186, 100%, 11%, 0.25)",
		5: "hsla(186, 100%, 11%, 0.05)",
		50: "hsla(186, 100%, 11%, 0.5)",
		80: "hsla(186, 100%, 11%, 0.8)",
		90: "hsla(186, 100%, 11%, 0.9)"
	},
	SKY: {
		100: "hsla(182, 67%, 64%, 1)",
		25: "hsla(182, 67%, 64%, 0.25)",
		50: "hsla(182, 67%, 64%, 0.5)"
	},
	SMOKE: {
		100: "hsla(0, 0%, 98%, 1)",
		200: "hsla(0, 0%, 95%, 1)",
		25: "hsla(0, 0%, 98%, 0.25)",
		50: "hsla(0, 0%, 98%, 0.5)"
	},
	SUN: {
		100: "hsla(28, 96%, 69%, 1)",
		25: "hsla(28, 96%, 69%, 0.25)",
		50: "hsla(28, 96%, 69%, 0.5)"
	},
	TANGERINE: {
		10: "hsla(18, 84%, 58%, 0.1)",
		100: "hsla(18, 84%, 58%, 1)",
		200: "hsla(18, 84%, 48%, 1)",
		25: "hsla(18, 84%, 58%, 0.25)",
		50: "hsla(18, 84%, 58%, 0.5)"
	},
	WHITE: {
		10: "hsla(0, 0%, 100%, 0.1)",
		100: "hsla(0, 0%, 100%, 1)",
		25: "hsla(0, 0%, 100%, 0.25)",
		50: "hsla(0, 0%, 100%, 0.5)",
		80: "hsla(0, 0%, 100%, 0.8)"
	}
};

export const SNACKBAR_COLORS: Record<SnackbarType, string> = {
	[SnackbarType.ERROR]: COLORS.GASPACHO[100],
	[SnackbarType.INFO]: COLORS.LAKE[100],
	[SnackbarType.NEUTRAL]: COLORS.NIGHT[90],
	[SnackbarType.SUCCESS]: COLORS.GRASS[100]
};

export const GRADIENTS = {
	ALUMINIMUM: ["hsla(180, 33%, 99%, 1)", "hsla(0, 0%, 96%, 1)"],
	BACKGROUND_1: [COLORS.SMOKE[100], "hsla(180, 100%, 96%, 1)"],
	BACKGROUND_2: [COLORS.WHITE[100], "hsla(180, 60%, 98%, 1)"],
	BLACK: [COLORS.NIGHT[50], "transparent"],
	LAKE_GRAD: [COLORS.LAKE[100], COLORS.SKY[100]],
	LIGHT_GREY: ["hsla(180, 20%, 99%, 1)", "hsla(200, 27%, 98%, 1)"],
	TANGERINE_GRAD: [COLORS.TANGERINE[100], COLORS.SUN[100]]
};

export const NOZZLE_COLORS: Record<string, string> = {
	[NozzleColor.BLUE]: "hsla(207, 51%, 53%, 1)",
	[NozzleColor.BROWN]: "hsla(33, 52%, 40%, 1)",
	[NozzleColor.GREEN]: "hsla(125, 31%, 46%, 1)",
	[NozzleColor.GREY]: COLORS.GREY[100],
	[NozzleColor.MAUVE]: "hsla(340, 48%, 37%, 1)",
	[NozzleColor.ORANGE]: "hsla(18, 84%, 58%, 1)",
	[NozzleColor.PURPLE]: "hsla(277, 100%, 62%, 1)",
	[NozzleColor.RED]: "hsla(357, 65%, 50%, 1)",
	[NozzleColor.YELLOW]: "hsla(54, 93%, 68%, 1)"
};

export const MILEOS_INDICE_COLORS: {
	MAIN: Record<string, string>;
	PALETTE: Record<string, Record<number, string>>;
	TEXT: Record<string, string>;
	TRANSPARENT_BG: Record<string, string>;
} = {
	MAIN: {
		[MileosStatus.C_RISK]: COLORS.GASPACHO[100],
		[MileosStatus.D_PROTECTED]: COLORS.LAKE[100],
		[MileosStatus.E_VIGILANCE]: COLORS.BEACH[100],
		[MileosStatus.F_NO_RISK]: COLORS.LEAF[100],
		[MileosStatus.H_END_OF_CAMPAIGN]: COLORS.GRASS[100]
	},
	PALETTE: {
		[MileosStatus.C_RISK]: COLORS.GASPACHO,
		[MileosStatus.D_PROTECTED]: COLORS.LAKE,
		[MileosStatus.E_VIGILANCE]: COLORS.BEACH,
		[MileosStatus.F_NO_RISK]: COLORS.LEAF,
		[MileosStatus.H_END_OF_CAMPAIGN]: COLORS.GRASS
	},
	TEXT: {
		[MileosStatus.C_RISK]: COLORS.GASPACHO[100],
		[MileosStatus.D_PROTECTED]: COLORS.LAKE[100],
		[MileosStatus.E_VIGILANCE]: COLORS.BEACH[200],
		[MileosStatus.F_NO_RISK]: COLORS.LEAF[200],
		[MileosStatus.H_END_OF_CAMPAIGN]: COLORS.GRASS[200]
	},
	TRANSPARENT_BG: {
		[MileosStatus.C_RISK]: COLORS.GASPACHO[10],
		[MileosStatus.D_PROTECTED]: COLORS.LAKE[10],
		[MileosStatus.E_VIGILANCE]: COLORS.BEACH[10],
		[MileosStatus.F_NO_RISK]: COLORS.LEAF[10],
		[MileosStatus.H_END_OF_CAMPAIGN]: COLORS.GRASS[10]
	}
};

export const MILEOS_ADVICES_COLORS: Record<MileosAdvice, Record<number, string>> = {
	[MileosAdvice.CROP_EMERGENCE_NOT_ACHIEVED]: COLORS.DISABLED,
	[MileosAdvice.CURATIVE_FUNGICIDE]: COLORS.GASPACHO,
	[MileosAdvice.END_OF_CAMPAIGN]: COLORS.GRASS,
	[MileosAdvice.ERADICATING_FUNGICIDE]: COLORS.GASPACHO,
	[MileosAdvice.NO_PROVISIONAL_ADVICE]: COLORS.NIGHT,
	[MileosAdvice.NO_TREATMENT_NEEDED]: COLORS.GRASS,
	[MileosAdvice.PROTECTED]: COLORS.LAKE,
	[MileosAdvice.TREATMENT_NEEDED]: COLORS.GASPACHO
};

export const CONDITIONS_COLORS: {
	BG_COLOR: Record<string, string>;
	CARD: Record<string, Record<number, string>>;
	CONTRASTED_TEXT: Record<string, string>;
	FIELD: Record<string, string>;
	SLOT: Record<string, string>;
	TEXT: Record<string, string>;
} = {
	BG_COLOR: {
		[Condition.ABSENT_TARGET]: COLORS.NIGHT[5],
		[Condition.BAD]: COLORS.GASPACHO[10],
		[Condition.CORRECT]: COLORS.BEACH[10],
		[Condition.EXCELLENT]: COLORS.GRASS[10],
		[Condition.GOOD]: COLORS.LEAF[10]
	},
	CARD: {
		[Condition.ABSENT_TARGET]: COLORS.NIGHT,
		[Condition.BAD]: COLORS.GASPACHO,
		[Condition.CORRECT]: COLORS.BEACH,
		[Condition.EXCELLENT]: COLORS.GRASS,
		[Condition.GOOD]: COLORS.LEAF
	},
	CONTRASTED_TEXT: {
		[Condition.ABSENT_TARGET]: COLORS.NIGHT[50],
		[Condition.BAD]: COLORS.GASPACHO[200],
		[Condition.CORRECT]: COLORS.BEACH[200],
		[Condition.EXCELLENT]: COLORS.GRASS[200],
		[Condition.GOOD]: COLORS.GRASS[200]
	},
	FIELD: {
		[Condition.ABSENT_TARGET]: COLORS.WHITE[25],
		[Condition.BAD]: COLORS.GASPACHO[50],
		[Condition.CORRECT]: COLORS.BEACH[50],
		[Condition.EXCELLENT]: COLORS.GRASS[50],
		[Condition.GOOD]: COLORS.LEAF[50]
	},
	SLOT: {
		[Condition.ABSENT_TARGET]: COLORS.ABSENT_TARGET[100],
		[Condition.BAD]: COLORS.GASPACHO[100],
		[Condition.CORRECT]: COLORS.BEACH[100],
		[Condition.EXCELLENT]: COLORS.GRASS[100],
		[Condition.GOOD]: COLORS.LEAF[100]
	},
	TEXT: {
		[Condition.ABSENT_TARGET]: COLORS.NIGHT[50],
		[Condition.BAD]: COLORS.GASPACHO[100],
		[Condition.CORRECT]: COLORS.BEACH[200],
		[Condition.EXCELLENT]: COLORS.GRASS[100],
		[Condition.GOOD]: COLORS.LEAF[200]
	}
};

export const PLAN_STATUS_COLORS = {
	[PlanStatus.ACTIVE]: COLORS.GRASS[100],
	[PlanStatus.CANCELLED]: COLORS.GASPACHO[100],
	[PlanStatus.IN_TRIAL]: COLORS.LAKE[100],
	[PlanStatus.INACTIVE_PLAN]: COLORS.GASPACHO[100],
	[PlanStatus.NON_RENEWING]: COLORS.GASPACHO[100]
} as { [key in PlanStatus]: string };

export const computeColorFromConditions = (
	value: string,
	type: "BG_COLOR" | "CONTRASTED_TEXT" | "FIELD" | "SLOT" | "TEXT",
	fallbackColor?: string
): string => CONDITIONS_COLORS[type]?.[value] || fallbackColor || COLORS.NIGHT[25];

export const computeColorFromMileosConditions = (
	value: string,
	type: "MAIN" | "TEXT" | "TRANSPARENT_BG",
	fallbackColor?: string
): string => MILEOS_INDICE_COLORS[type]?.[value] || fallbackColor || COLORS.NIGHT[25];

export const VERTICAL_PADDING = 40;

export const HORIZONTAL_PADDING = 16;
