import { availableCountries, countryMapping } from "@hygo/shared/constants";
import { Country } from "@hygo/shared/models";
import { validatePhone } from "@hygo/shared/utils";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import SelectInput from "../SelectInput/SelectInput";
import TextInput from "../TextInput/TextInput";

interface PhoneNumberInputProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any>;
	error?: { message?: string };
	flex?: boolean;
	onChange: (v: Country) => void;
	phoneCountrySelector: Country;
}
const Label = styled.label<{ $error: boolean }>`
	display: block;
	flex: 1;
	font-size: var(--14px);
	color: ${(props) => (props.$error ? "var(--gaspacho-100)" : "inherit")};
	font-family: "Nunito Bold";
`;

const PhoneInputWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	flex: 1;
`;

const SelectorWrapper = styled.div`
	min-width: 110px;
`;

const Wrapper = styled.div<{ $flex: boolean }>`
	display: ${(props) => (props.$flex ? "flex" : "unset")};
	justify-content: space-between;
	align-items: center;
	gap: 16px;
`;

const PhoneNumberInput = ({
	control,
	error,
	flex,
	onChange,
	phoneCountrySelector
}: PhoneNumberInputProps): JSX.Element => {
	const hasError = !!error?.message;
	const countryCallingCode = countryMapping[phoneCountrySelector].phone.callingCode;

	const { t } = useTranslation();
	return (
		<Wrapper $flex={flex}>
			<Label $error={hasError} htmlFor="phone">
				{t("inputs.principalPhone.label")}
			</Label>
			<PhoneInputWrapper>
				<SelectorWrapper>
					<SelectInput
						control={control}
						initialOptions={availableCountries.map((c) => {
							return {
								Icon: countryMapping[c].flag({
									height: 20,
									width: 20
								}) as JSX.Element,
								label: `+${countryMapping[c].phone.callingCode}`,
								value: c
							};
						})}
						name="phoneCountrySelector"
						onCustomChange={(d) => onChange(d.value as Country)}
						placeholder={null}
						theme="light"
					/>
				</SelectorWrapper>

				<TextInput
					autoComplete="tel-national"
					name="principalPhone"
					placeholder={countryMapping[phoneCountrySelector].phone.examplePhone}
					rules={{
						required: {
							message: t("inputs.principalPhone.errors.required"),
							value: true
						},
						validate: (value: string) =>
							validatePhone(`+${countryCallingCode}${value}`) || t("inputs.principalPhone.errors.invalid")
					}}
				/>
			</PhoneInputWrapper>
		</Wrapper>
	);
};

export default PhoneNumberInput;
