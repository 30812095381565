import { useEffect, useState } from "react";

import HygoRedirectScreen from "./HygoRedirectScreen";
import { HygoRedirectScreenProps } from "./screen.types";

const HygoRedirectContainer = (): JSX.Element => {
	const [loading, setLoading] = useState<HygoRedirectScreenProps["loading"]>(true);
	const [isMobile, setisMobile] = useState<HygoRedirectScreenProps["isMobile"]>(false);

	const checkIfMobile = (): void => {
		const isAndroid = /android/i.test(navigator.userAgent);
		const isIphone = /iPhone|iPod/.test(navigator.userAgent);
		if (isAndroid || isIphone) {
			window.location.replace(
				isIphone
					? "https://apps.apple.com/fr/app/hygo/id1494676431"
					: "https://play.google.com/store/apps/details?id=com.alvie.hygo&hl=fr&gl=FR"
			);
		}
		setisMobile(isAndroid || isIphone);
	};

	useEffect(() => {
		setLoading(true);
		checkIfMobile();
		setLoading(false);
	}, []);

	return <HygoRedirectScreen isMobile={isMobile} loading={loading} />;
};

export default HygoRedirectContainer;
