// eslint-disable-next-line @nx/enforce-module-boundaries
import { CzechRepublic, France, Germany, GreatBritain, Italy, Netherlands, Poland, Slovakia } from "@hygo/shared/icons";
import { Language } from "@hygo/shared/models";
import { FC } from "react";

import { availableCountries, countryMapping } from "./countryMapping";

export const langueMapping: {
	[key in Language]: FC<{ height: number; width: number }>;
} = {
	[Language.cs]: CzechRepublic,
	[Language.de]: Germany,
	[Language.en]: GreatBritain,
	[Language.fr]: France,
	[Language.it]: Italy,
	[Language.nl]: Netherlands,
	[Language.pl]: Poland,
	[Language.sk]: Slovakia
};

export const availableLanguages = [...new Set(availableCountries?.flatMap((c) => countryMapping[c].languages))];
