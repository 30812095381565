import { OnboardingTasksModalPropsType } from "@hygo/shared/modals";
import { COLORS } from "@hygo/shared/utils";
import { OADContext } from "@hygo/web/contexts";
import { OADStepEnum } from "@hygo/web/models";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import BaseModal from "../BaseModal";

const Subline = styled.h4`
	color: var(--night-50);
	text-align: center;
`;

const OnboardingTasksModal = ({
	hideModal,
	modalVisible,
	onboardingKey,
	onConfirm
}: OnboardingTasksModalPropsType): JSX.Element => {
	const { t } = useTranslation();
	const { setStepCookie } = useContext(OADContext);

	const onDismiss = (): void => {
		setStepCookie({ key: onboardingKey, value: OADStepEnum.DONE });
		hideModal();
	};

	return (
		<BaseModal
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnText={t("button.understood")}
			hideModal={onDismiss}
			modalVisible={modalVisible}
			onConfirm={onConfirm}
			title={t(`modals.${onboardingKey}.tasks.title`)}
		>
			<Subline>{t(`modals.${onboardingKey}.tasks.subline`)}</Subline>
		</BaseModal>
	);
};
export default OnboardingTasksModal;
