import { NoRain, Rain, Temperature, WaterFill, Wind, WindDirection } from "@hygo/shared/icons";
import { WeatherMetrics, WindSpeedUnity } from "@hygo/shared/models";
import { COLORS, convertMsToWindSpeedUnity, smallerThan } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface WeatherDetailsProps {
	metrics: WeatherMetrics;
	windSpeedUnity: WindSpeedUnity;
}

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 8px;
	align-items: center;
`;

const MetricWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 90px;
	align-items: center;
	padding: 4px;
	background-color: var(--smoke-100);
	border-radius: 8px;
`;

const PrimaryText = styled.h5``;

const SecondaryText = styled.h5``;

const WindDirectionIcon = styled(WindDirection)<{ degrees: number }>`
	transform: rotate(${(props) => props.degrees}deg);
`;

const WeatherDetails = ({ metrics, windSpeedUnity }: WeatherDetailsProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<MetricWrapper>
				<Temperature height={20} width={20} />
				<PrimaryText>
					{metrics?.maxtemp?.toFixed(0) ?? "..."} {t("units.degrees")}
				</PrimaryText>
				<SecondaryText>
					{metrics?.mintemp?.toFixed(0) ?? "..."} {t("units.degrees")}
				</SecondaryText>
			</MetricWrapper>

			<MetricWrapper>
				{!metrics?.rmax && !metrics?.rmin ? (
					<NoRain fill={COLORS.NIGHT[10]} height={20} width={20} />
				) : (
					<Rain height={20} width={20} />
				)}

				<PrimaryText>
					{smallerThan(metrics?.rmin, 0.1) ?? "..."} {t("units.millimeters")}
				</PrimaryText>
				<SecondaryText>
					{smallerThan(metrics?.rmax, 0.1) ?? "..."} {t("units.millimeters")}
				</SecondaryText>
			</MetricWrapper>
			<MetricWrapper>
				<WaterFill fill={COLORS.LAKE[100]} height={20} width={20} />

				<PrimaryText>
					{metrics?.maxhumi?.toFixed(0) ?? "..."} {t("units.percentage")}
				</PrimaryText>
				<SecondaryText>
					{metrics?.minhumi?.toFixed(0) ?? "..."} {t("units.percentage")}
				</SecondaryText>
			</MetricWrapper>
			<MetricWrapper>
				{metrics?.winddir || metrics?.winddir === 0 ? (
					<WindDirectionIcon
						degrees={metrics?.winddir + 180}
						fill={COLORS.LAKE[100]}
						height={20}
						width={20}
					/>
				) : (
					<Wind fill={COLORS.LAKE[100]} height={20} width={20} />
				)}

				<PrimaryText>
					{convertMsToWindSpeedUnity(metrics?.wind, windSpeedUnity)?.toFixed(0) ?? "..."}{" "}
					{t(`units.${windSpeedUnity}`)}
				</PrimaryText>
				<SecondaryText>
					{t("glossary.gust")} {convertMsToWindSpeedUnity(metrics?.maxgust, windSpeedUnity)?.toFixed(0)}
				</SecondaryText>
			</MetricWrapper>
		</Wrapper>
	);
};

export default WeatherDetails;
