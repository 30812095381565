import { Children, Fragment } from "react";
import styled, { css } from "styled-components";

interface MultiActionsButtonProps {
	children: JSX.Element | JSX.Element[];
	className?: string;
}

const GreyCard = styled.div`
	border-radius: 8px;
	padding-left: 8px;
	padding-right: 8px;
	border: 0.5px solid var(--night-10);
	width: 100%;
	background-color: var(--smoke-100);
`;

const Divider = styled.div`
	height: 0.5px;
	background-color: var(--night-10);
`;

const ChildButton = styled.div<{ color: string }>`
	display: flex;
	flex-direction: row;
	padding-top: 8px;
	align-items: center;
	padding-bottom: 8px;
	justify-content: space-between;
	width: 100%;
	${(props) =>
		props.color &&
		css`
			&:hover {
				color: var(--${props.color}-100);
			}
		`}
`;

const MultiActionsButton = ({ children, className }: MultiActionsButtonProps): JSX.Element => {
	const newChildren = Children.toArray(children);

	const childrenWithoutFragment = newChildren
		?.filter((c) => c)
		?.flatMap((c: JSX.Element) => (c.type === Fragment ? c.props.children : c));

	return (
		<GreyCard className={className}>
			{childrenWithoutFragment?.map((c, i) => {
				const isLast = i === childrenWithoutFragment?.length - 1;

				return (
					<Fragment key={`child-${i}`}>
						<ChildButton as={c.type} {...c.props} />
						{!isLast && <Divider />}
					</Fragment>
				);
			})}
		</GreyCard>
	);
};

export default MultiActionsButton;
