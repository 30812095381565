import { COLORS } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import TooltipBubble from "../../TooltipBubble";

interface ToastProps {
	type: "fusion" | "info" | "neutral" | "rotation";
}

const ToastTooltip = styled(TooltipBubble)`
	top: 43px;
	z-index: 2;
	color: var(--night-100);
	text-align: left;
	right: 0;
	&:before {
		right: 10px;
		left: unset;
	}
	&:after {
		right: 9px;
		left: unset;
	}
`;

const ToastWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const BaseToast = styled.h5<{ $backgroundColor: string; color: string }>`
	text-align: center;
	width: 100%;
	border-radius: 8px;
	padding: 4px 8px;
	position: relative;
	background-color: ${(props) => props.$backgroundColor};
	color: ${(props) => props.color};
	&:hover ${ToastTooltip} {
		display: block;
	}
`;

const Toast = ({ type }: ToastProps): JSX.Element => {
	const { t } = useTranslation();

	const getColor = (): Record<number, string> => {
		if (type === "neutral") return COLORS.DISABLED;
		if (type === "info") return COLORS.LAKE;
		return COLORS.GRASS;
	};

	return (
		<ToastWrapper>
			<BaseToast $backgroundColor={getColor()[10]} color={getColor()[100]}>
				<ToastTooltip text={t(`components.fieldsLinkingGrid.toasts.${type}.tooltip`)} />
				{t(`components.fieldsLinkingGrid.toasts.${type}.title`)}
			</BaseToast>
		</ToastWrapper>
	);
};

export default Toast;
