import { UserContext } from "@hygo/shared/contexts";
import { CheckmarkRounded, Pencil, QRCode, Sprayer as SprayerIcon, Tracker, Trash, Warning } from "@hygo/shared/icons";
import { Feature, Sprayer } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { Button, TooltipBubble } from "@hygo/web/ui-components";
import { useFeature } from "flagged";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import NozzleList from "./NozzleList";

const CTAWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const StyledToolTipBubble = styled(TooltipBubble)`
	top: 43px;
	left: -7px;
	z-index: 2;
`;

const SprayerName = styled.h4`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const Checker = styled.div`
	display: flex;
	align-items: baseline;
	position: relative;
	cursor: pointer;
	gap: 2px;
	&:hover ${StyledToolTipBubble} {
		display: block;
	}
`;

const EquipmentWrapper = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`;

const SprayerDetailsWrapper = styled.div`
	background: var(--gradient-light-grey);
	height: 100%;
	width: 300px;
	display: flex;
	padding-left: 40px;
	align-items: center;
	gap: 16px;
`;

const DetailsWrapper = styled.div`
	display: flex;
	flex: 1;
	// Replacing overflow hidden with max width for the tooltip by substracting the width of the container to the width of the sprayer icon (80) and the flex gap (16)
	max-width: calc(100% - 80px - 16px);
	flex-direction: column;
	gap: 4px;
`;

const Wrapper = styled.div`
	width: 100%;
	background-color: var(--white-100);
	border-radius: 4px;
	display: flex;
	border: 1px solid var(--night-5);
	height: 100px;

	align-items: center;
	justify-content: space-between;
	padding-right: 8px;
`;

const StyledSprayerIcon = styled(SprayerIcon)`
	min-width: 80px;
`;

const StyledNozzleList = styled(NozzleList)`
	flex: 1;
`;

interface SprayerCardProps {
	onDeleteSprayer: (sprayer: Sprayer) => void;
	onEditSprayer: (sprayer: Sprayer) => void;
	sprayer: Sprayer;
	withDeleteBtn: boolean;
}

const SprayerCard = ({ onDeleteSprayer, onEditSprayer, sprayer, withDeleteBtn }: SprayerCardProps): JSX.Element => {
	const { t } = useTranslation();
	const { activeNozzles } = useContext(UserContext);
	const hasWeatherDevice = useFeature(Feature.WEATHER_DEVICE);
	const nozzles = activeNozzles?.filter((n) => n.sprayerId === sprayer?.id);

	return (
		<Wrapper>
			<SprayerDetailsWrapper>
				<StyledSprayerIcon fill={COLORS.NIGHT[100]} height={80} width={80} />
				<DetailsWrapper>
					<SprayerName>{sprayer.name}</SprayerName>
					<EquipmentWrapper>
						<Checker>
							<Tracker height={20} width={14} />
							{sprayer?.gpsBarcode ? (
								<>
									<StyledToolTipBubble
										text={t("screens.sprayerManagement.components.sprayerCard.gpsBarcode.code", {
											gpsBarcode: sprayer?.gpsBarcode
										})}
									/>
									<CheckmarkRounded
										backgroundFill={COLORS.GRASS[100]}
										fill={COLORS.WHITE[100]}
										height={8}
										width={8}
									/>
								</>
							) : (
								<>
									<StyledToolTipBubble
										text={t("screens.sprayerManagement.components.sprayerCard.gpsBarcode.empty")}
									/>
									<Warning fill={COLORS.GASPACHO[100]} height={8} width={8} />
								</>
							)}
						</Checker>
						{hasWeatherDevice && (
							<Checker>
								<QRCode height={20} width={20} />
								{sprayer?.weatherBarcode ? (
									<>
										<StyledToolTipBubble
											text={t(
												"screens.sprayerManagement.components.sprayerCard.weatherBarcode.code",
												{ weatherBarcode: sprayer?.weatherBarcode }
											)}
										/>
										<CheckmarkRounded
											backgroundFill={COLORS.GRASS[100]}
											fill={COLORS.WHITE[100]}
											height={8}
											width={8}
										/>
									</>
								) : (
									<>
										<StyledToolTipBubble
											text={t(
												"screens.sprayerManagement.components.sprayerCard.weatherBarcode.empty"
											)}
										/>
										<Warning fill={COLORS.GASPACHO[100]} height={8} width={8} />
									</>
								)}
							</Checker>
						)}
					</EquipmentWrapper>
				</DetailsWrapper>
			</SprayerDetailsWrapper>
			<StyledNozzleList nozzles={nozzles} sprayerId={sprayer?.id} />
			<CTAWrapper>
				<Button
					color={COLORS.LAKE}
					fillIcon
					Icon={Pencil}
					onClick={() => onEditSprayer(sprayer)}
					text={t("button.edit")}
					tiny
				/>
				{withDeleteBtn && (
					<Button
						color={COLORS.GASPACHO}
						fillIcon
						Icon={Trash}
						onClick={() => onDeleteSprayer(sprayer)}
						text={t("button.delete")}
						tiny
					/>
				)}
			</CTAWrapper>
		</Wrapper>
	);
};

export default SprayerCard;
