import styled from "styled-components";

const Wrapper = styled.div<{ $backgroundImage: string; $centered: boolean }>`
	display: ${(props) => (props.$centered ? "flex" : "block")};
	align-items: center;
	justify-content: center;
	background-image: url(${(props) => props.$backgroundImage});
	background-position: center;
	background-size: 100% 100vh;
	height: 100%;
`;

interface ContainerWithBgImageProps {
	backgroundImage: string;
	centered?: boolean;
	children?: JSX.Element | JSX.Element[];
	className?: string;
}
const ContainerWithBgImage = ({
	backgroundImage,
	centered = true,
	children,
	className
}: ContainerWithBgImageProps): JSX.Element => {
	return (
		<Wrapper $backgroundImage={backgroundImage} $centered={centered} className={className}>
			{children}
		</Wrapper>
	);
};

export default ContainerWithBgImage;
