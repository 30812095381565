export const denormalizeData = <T extends { id: number | string }, U, K extends keyof U>({
	data = [],
	fieldIdName,
	usages = []
}: {
	data: T[];
	fieldIdName?: K;
	usages: U[];
}): U extends number ? T[] : (Omit<U, K> & T)[] => {
	return usages
		?.map((u) => {
			const backData = data.find((b) => b.id === (u[fieldIdName] || u));
			if (!backData) return null;
			const result = { ...u, ...backData };
			delete result[fieldIdName];
			return result;
		})
		.filter((d) => d) as U extends number ? T[] : (T & U)[];
};
