import { UserContext } from "@hygo/shared/contexts";
import { useMileosSetup } from "@hygo/shared/feature-fields-manager";
import { MileosFieldSettingsModalPropsType } from "@hygo/shared/modals";
import { COLORS } from "@hygo/shared/utils";
import { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import SelectInput from "../../SelectInput";
import TextInput from "../../TextInput";
import BaseModal from "../BaseModal";

const Wrapper = styled.div<{ $open: boolean }>`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: ${(props) => (props.$open ? "300px" : "unset")};
	gap: 16px;
`;

const MileosFieldSettingsModal = ({
	field,
	hideModal,
	mileosInfos,
	modalVisible,
	onSubmit
}: MileosFieldSettingsModalPropsType): JSX.Element => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const { mileosVarieties } = useContext(UserContext);
	const [open, setOpen] = useState<boolean>(false);
	const { mileosFieldSettingsRules } = useMileosSetup({ field });

	const defaultValues = useMemo(
		() => ({
			varietyId: mileosInfos?.varietyId?.toString(),
			yieldValue: mileosInfos?.yieldValue
		}),
		[mileosInfos]
	);

	const methods = useForm({
		defaultValues,
		mode: "onChange"
	});
	const {
		formState: { isValid }
	} = methods;

	useEffect(() => {
		methods.reset(defaultValues);
	}, [methods, defaultValues]);

	const onConfirm = async ({ varietyId, yieldValue }: { varietyId: string; yieldValue: number }): Promise<void> => {
		try {
			setLoading(true);
			await onSubmit({
				varietyId: parseInt(varietyId, 10),
				yieldValue
			});
			hideModal();
		} finally {
			setLoading(false);
		}
	};

	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnDisabled={!isValid}
			confirmBtnLoading={loading}
			confirmBtnText={t("button.save")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={methods.handleSubmit(onConfirm)}
			title={t("oad.MILEOS")}
		>
			<FormProvider {...methods}>
				<Wrapper $open={open}>
					{mileosVarieties?.length > 0 && (
						<div>
							<SelectInput
								control={methods.control}
								initialOptions={mileosVarieties?.map((v) => ({
									label: v?.name,
									value: v?.id?.toString()
								}))}
								label={t("inputs.mileosVariety.label")}
								name="varietyId"
								onOpenCallback={setOpen}
								placeholder={t("inputs.mileosVariety.placeholder")}
								rules={mileosFieldSettingsRules.varietyId}
								searchable
								theme="light"
							/>
						</div>
					)}
					<TextInput
						label={t("inputs.mileosYieldValue.label")}
						name="yieldValue"
						placeholder={t("inputs.mileosYieldValue.placeholder")}
						rules={mileosFieldSettingsRules.yieldValue}
						unit={`${t("units.ton")}/${t("units.hectare")}`}
					/>
				</Wrapper>
			</FormProvider>
		</BaseModal>
	);
};
export default MileosFieldSettingsModal;
