import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import * as Sentry from "@sentry/react";
import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
declare global {
	interface Window {
		// eslint-disable-next-line
		Chargebee: any;
	}
}

interface useChargebeeReturnType {
	openChargebeePortal: () => void;
}

const useChargebee = (): useChargebeeReturnType => {
	const { getChargebeePortalSession } = useApi();
	const { chargebee, login, logout, setAuthLoading, user } = useContext(UserContext);

	const navigate = useNavigate();

	const waitAndRefetchPlan = useCallback(async (): Promise<void> => {
		setAuthLoading(true);
		chargebee.closeAll();
		setTimeout(async () => {
			try {
				const token = localStorage.getItem("hygoCookie");
				await login(token);
				navigate("/");
			} catch (e) {
				Sentry.captureException(e);
				logout();
			} finally {
				setAuthLoading(false);
			}
		}, 15000);
	}, [navigate, setAuthLoading, login, logout, chargebee]);

	const openChargebeePortal: useChargebeeReturnType["openChargebeePortal"] = useCallback(() => {
		chargebee.setPortalSession(getChargebeePortalSession);
		chargebee.setCheckoutCallbacks(() => ({
			success: () => waitAndRefetchPlan()
		}));
		const cart = chargebee.getCart();
		cart.setCustomer({
			billing_address: {
				country: user?.countryCode,
				email: user.email,
				first_name: user.firstName,
				last_name: user.lastName
			},
			email: user.email,
			first_name: user.firstName,
			last_name: user.lastName
		});
		const portal = chargebee.createChargebeePortal();
		portal.open({
			close: () => chargebee.logout(),
			scheduledCancellationRemoved: () => waitAndRefetchPlan(),
			subscriptionCancelled: () => waitAndRefetchPlan(),
			subscriptionChanged: () => waitAndRefetchPlan(),
			subscriptionReactivated: () => waitAndRefetchPlan()
		});
	}, [user, waitAndRefetchPlan, chargebee, getChargebeePortalSession]);

	return { openChargebeePortal };
};

export default useChargebee;
