import { NoZone } from "@hygo/shared/icons";
import { Crop, Field, Item } from "@hygo/shared/models";
import { COLORS, convertToHa, hexToRGBA } from "@hygo/shared/utils";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CropIcon from "../../CropIcon";
import ParcelSVG from "../../ParcelSVG";
import SelectInput from "../../SelectInput";
import TooltipBubble from "../../TooltipBubble";

const CropIconWrapper = styled.div`
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: var(--white-80);
	bottom: 0;
	right: 0;
	position: absolute;
`;

const SelectorTooltip = styled(TooltipBubble)`
	top: 58px;
	z-index: 2;
`;

const Wrapper = styled.div`
	position: relative;
	width: 50%;
	&:hover ${SelectorTooltip} {
		display: block;
	}
`;

const SvgWrapper = styled.div`
	position: relative;
`;

interface FieldSelectorProps {
	crops: Crop[];
	fields: Field[];
	isSelected: boolean;
	onChange: (fieldId: number) => void;
	selectedField: Field;
}

const Icon = ({ crop, field }: { crop: Crop; field: Field }): JSX.Element => {
	return (
		<SvgWrapper>
			{field?.svg ? (
				<ParcelSVG
					fill={hexToRGBA(crop?.color, 0.5, COLORS.LAKE[50])}
					height={32}
					path={field.svg}
					stroke={hexToRGBA(crop?.color, 1, COLORS.LAKE[100])}
					width={32}
				/>
			) : (
				<NoZone fill={COLORS.NIGHT[100]} height={32} width={32} />
			)}
			{crop && (
				<CropIconWrapper>
					<CropIcon crop={crop} fill={COLORS.NIGHT[100]} height={12} width={12} />
				</CropIconWrapper>
			)}
		</SvgWrapper>
	);
};

const FieldSelector = ({ crops, fields, isSelected, onChange, selectedField }: FieldSelectorProps): JSX.Element => {
	const { t } = useTranslation();
	const defaultValues = useMemo(() => ({ selectedFieldId: selectedField?.id?.toString() || null }), [selectedField]);
	const methods = useForm({ defaultValues });
	const onSelect = async (v: Item): Promise<void> => {
		onChange(v ? parseInt(v?.value, 10) : null);
	};
	const selectedCrop = crops?.find((c) => c.id === selectedField?.cropId);

	useEffect(() => {
		methods.reset(defaultValues);
	}, [methods, defaultValues]);

	return (
		<Wrapper>
			<SelectInput
				clearable
				control={methods.control}
				disabled={!isSelected}
				height={52}
				initialOptions={fields?.map((f) => {
					const crop = crops?.find((c) => c.id === f.cropId);
					return {
						Icon: <Icon crop={crop} field={f} />,
						label: f.name.toString(),
						subtitle: f?.area
							? `${convertToHa(f?.area)} ${t("units.hectare")}${f?.town ? ` - ${f.town}` : ""}`
							: f?.town,
						value: f.id.toString()
					};
				})}
				name="selectedFieldId"
				onCustomChange={onSelect}
				placeholder={t("components.fieldsLinkingGrid.fieldSelector.placeholder")}
				searchable
				theme="light"
			/>
			{selectedCrop && <SelectorTooltip text={selectedCrop?.name} />}
		</Wrapper>
	);
};

export default FieldSelector;
