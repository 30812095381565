import { DenormalizedProduct } from "@hygo/shared/models";

import { computeDosesSum } from "./computeDosesSum";

export const computeTank = ({
	debit,
	isPelletMixture,
	products,
	totalAreaHA,
	waterTankBottomPercentage
}: {
	debit: number;
	isPelletMixture: boolean;
	products: DenormalizedProduct[];
	totalAreaHA: number;
	waterTankBottomPercentage: number;
}): {
	products: ({ formattedIncorporationOrder: number } & DenormalizedProduct)[];
	volume: number;
	water: number;
} => {
	let incorporationOrder = isPelletMixture ? 0 : 1;
	const newProducts = products
		?.sort((a, b) => a.incorporationOrder - b.incorporationOrder)
		?.map((product, i, arr) => {
			const lastOrder = arr?.[i - 1]?.incorporationOrder;
			if (lastOrder !== product.incorporationOrder) incorporationOrder++;
			return {
				...product,
				formattedIncorporationOrder: incorporationOrder
			};
		});
	const totalPhyto = computeDosesSum(newProducts, isPelletMixture) * totalAreaHA;
	const totalDebit = isPelletMixture ? totalPhyto : totalAreaHA * (debit || 0);
	const volume = totalDebit + (totalDebit * (waterTankBottomPercentage || 0)) / 100;
	const water = volume - totalPhyto;
	return { products: newProducts, volume, water };
};
