export enum SetupError {
	defaultFarmWithoutFields = "defaultFarmWithoutFields",
	equipmentMissing = "equipmentMissing",
	needUpdateVersion = "needUpdateVersion",
	noDefaultFarm = "noDefaultFarm",
	noNetwork = "noNetwork",
	noPlan = "noPlan",
	noWeather = "noWeather",
	ongoingMaintenance = "ongoingMaintenance"
}
