import { Crop, DenormalizedField, DenormalizedProduct, Nozzle, Sprayer, Tank, Target, User } from "@hygo/shared/models";
import { convertToHa } from "@hygo/shared/utils";
import styled from "styled-components";

import EquipmentSection from "./EquipmentSection";
import FieldsSection from "./FieldsSection";
import IrrigationSection from "./IrrigationSection";
import MapSection from "./MapSection";
import NotesSection from "./NotesSection";
import ProductsSection from "./ProductsSection";
import TargetSection from "./TargetSection";
import TaskSkeleton from "./TaskSkeleton";

interface AccordionDetailsProps {
	crops: Crop[];
	debit: number;
	endTime: string;
	fields: DenormalizedField[];
	id: number;
	isTask: boolean;
	loading: boolean;
	notes: string;
	nozzle: Nozzle;
	onDelete: (taskId: number) => void;
	products: DenormalizedProduct[];
	quantity: number;
	selectedFields: DenormalizedField[];
	sprayer: Sprayer;
	startTime: string;
	tankIndications: Tank;
	targets: Target[];
	user: User;
}

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const BottomSection = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 24px;
`;

const SubWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 24px;
`;

const AccordionDetails = ({
	crops,
	debit,
	endTime,
	fields,
	id,
	isTask,
	loading,
	notes,
	nozzle,
	onDelete,
	products,
	quantity,
	selectedFields,
	sprayer,
	startTime,
	tankIndications,
	targets,
	user
}: AccordionDetailsProps): JSX.Element => {
	const totalAreaHA = convertToHa(fields?.reduce((r, f) => r + (f.fieldArea || f.area), 0));

	return (
		<Wrapper>
			{loading ? (
				<TaskSkeleton />
			) : (
				<>
					<FieldsSection
						crops={crops}
						fields={fields}
						isTask={isTask}
						onDelete={() => onDelete(id)}
						selectedFields={selectedFields}
						tankIndications={tankIndications}
						windSpeedUnity={user?.windSpeedUnity}
					/>

					<BottomSection>
						<SubWrapper>
							<MapSection crops={crops} fields={fields} user={user} />
						</SubWrapper>
						<SubWrapper>
							{isTask && (
								<ProductsSection
									debit={debit}
									isPelletMixture={tankIndications?.configuration?.pelletMixture}
									products={products}
									totalAreaHA={totalAreaHA}
									waterTankBottomPercentage={user?.equipments?.waterTankBottomPercentage}
								/>
							)}
							{!isTask && (
								<IrrigationSection
									countryCode={user?.countryCode}
									endTime={endTime}
									quantity={quantity}
									startTime={startTime}
								/>
							)}
						</SubWrapper>
						{isTask && (
							<SubWrapper>
								<EquipmentSection
									debit={debit}
									isPelletMixture={tankIndications?.configuration?.pelletMixture}
									nozzle={nozzle}
									sprayer={sprayer}
								/>
								{isTask && targets?.length > 0 && <TargetSection targets={targets} />}
								{isTask && <NotesSection notes={notes} />}
							</SubWrapper>
						)}
					</BottomSection>
				</>
			)}
		</Wrapper>
	);
};

export default AccordionDetails;
