import styled from "styled-components";

const Wrapper = styled.div<{ $backgroundColor: string; $duration: number; $show: boolean }>`
	visibility: ${(props) => (props.$show ? "visible" : "hidden")};
	background-color: ${(props) => props.$backgroundColor};
	padding: 8px 16px;
	color: var(--white-100);
	text-align: center;
	border-radius: 4px;
	position: fixed;
	z-index: 10;
	right: 100px;
	bottom: 30px;
	animation: ${(props) => (props.$show ? `fadein 0.5s, fadeout 0.5s ${props.$duration - 0.5}s` : "unset")};

	@keyframes fadein {
		from {
			bottom: 0;
			opacity: 0;
		}
		to {
			bottom: 30px;
			opacity: 1;
		}
	}

	@keyframes fadeout {
		from {
			bottom: 30px;
			opacity: 1;
		}
		to {
			bottom: 0;
			opacity: 0;
		}
	}
`;

const Text = styled.h4`
	white-space: pre-wrap;
`;

interface SnackbarProps {
	backgroundColor: string;
	duration: number;
	message: string;
	show: boolean;
}

const Snackbar = ({ backgroundColor, duration, message, show }: SnackbarProps): JSX.Element => {
	return (
		<Wrapper $backgroundColor={backgroundColor} $duration={duration ? duration / 1000 : undefined} $show={show}>
			<Text>{message}</Text>
		</Wrapper>
	);
};

export default Snackbar;
