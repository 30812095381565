import { MagnifyingGlass } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import TextInput from "../TextInput";
import { Input } from "../TextInput/TextInput";

const StyledSearchInput = styled(TextInput)<{ isLightTheme: boolean }>`
	${(props) =>
		!props.isLightTheme &&
		css`
			${Input} {
				background: var(--white-10);
				border-color: var(--white-50);
				color: var(--white-100);
				&::placeholder {
					color: var(--white-50);
				}
			}
		`}
`;

interface SearchInputProps {
	name: string;
	onCustomChange: (value: string) => void;
	theme: "dark" | "light";
}

const SearchInput = ({ name, onCustomChange, theme }: SearchInputProps): JSX.Element => {
	const { t } = useTranslation();
	const methods = useForm();
	const isLightTheme = theme === "light";

	return (
		<FormProvider {...methods}>
			<StyledSearchInput
				autoFocus
				isLightTheme={isLightTheme}
				name={name}
				onCustomChange={onCustomChange}
				placeholder={t("components.searchInput.placeholder")}
				placeholderIcon={{
					icon: (
						<MagnifyingGlass
							fill={isLightTheme ? COLORS.NIGHT[50] : COLORS.WHITE[50]}
							height={20}
							width={20}
						/>
					),
					position: "left"
				}}
			/>
		</FormProvider>
	);
};

export default SearchInput;
