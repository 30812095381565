import { ProductStatus } from "@hygo/shared/models";

export const computeModulationAverage = (
	products: { initialDosePerHA: number; modulationStatus: ProductStatus; realDosePerHA: number }[]
): number => {
	const modulableProducts = products
		?.filter((p) => p.modulationStatus === ProductStatus.MODULABLE)
		?.map((p) => p.realDosePerHA / (p.initialDosePerHA || p.realDosePerHA));
	const modulation = modulableProducts?.length
		? 100 - (modulableProducts?.reduce((prev, curr) => prev + curr, 0) / modulableProducts?.length) * 100
		: 0;
	return modulation >= 0 ? modulation : 0;
};
