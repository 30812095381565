import { COLORS } from "@hygo/shared/utils";
import { FC, SVGProps } from "react";
import styled from "styled-components";

import Button from "../Button";

interface EmptyStateProps {
	btnIcon?: FC<SVGProps<SVGSVGElement>>;
	btnText?: string;
	description: string;
	grey?: boolean;
	illustration: JSX.Element;
	onClick?: () => void;
	title: string;
}

const StateButton = styled(Button)`
	margin-top: 16px;
`;

const Wrapper = styled.div<{ $grey: boolean }>`
	color: ${(props) => (props.$grey ? "var(--night-50)" : "var(--night-100)")};
	gap: 16px;
	display: flex;
	align-items: center;
`;

const EmptyState = ({
	btnIcon,
	btnText,
	description,
	grey,
	illustration,
	onClick,
	title
}: EmptyStateProps): JSX.Element => {
	return (
		<Wrapper $grey={grey}>
			{illustration}
			<div>
				<h2>{title}</h2>
				<h5>{description}</h5>
				{onClick && (
					<StateButton color={COLORS.LAKE} fillIcon Icon={btnIcon} onClick={onClick} text={btnText} />
				)}
			</div>
		</Wrapper>
	);
};

export default EmptyState;
