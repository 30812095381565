import { addonDetails, countryMapping, featureDetails, oadDetails } from "@hygo/shared/constants";
import { Country, Feature, Plan } from "@hygo/shared/models";
import { MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import AccordionCard from "./AccordionCard";
import DownloadApp from "./DownloadApp";
import EquipmentCard from "./EquipmentCard";

interface PlanDetailsProps {
	actualPlan: Plan;
	countryCode: Country;
	plan: Plan;
	planDetailsRef: MutableRefObject<HTMLDivElement>;
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;

	overflow: auto;
`;

const Header = styled.div`
	padding: 16px 0;
	border-radius: 8px;
	background-color: var(--smoke-100);
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const Price = styled.h2`
	color: var(--grass-100);
`;

const Iframe = styled.iframe`
	border: none;
	border-radius: 8px 0 0 8px;
`;

const ReviewWrapper = styled.div`
	padding: 16px;
	background-color: var(--smoke-100);
	display: flex;
	border-radius: 0 8px 8px 0;
	align-items: center;
	justify-content: center;
`;

const PriceUnit = styled.span`
	color: var(--night-100);
	font-family: "Nunito Bold";
	font-size: var(--14px);
`;

const Title = styled.h2<{ $color: string }>`
	span {
		color: ${(props) => props.$color};
	}
`;

const SectionTitle = styled.h2`
	margin-bottom: 16px;
`;

const Subtitle = styled.h4`
	color: var(--lake-100);
`;

const Cards = styled.div`
	display: grid;
	gap: 8px;
	grid-template-columns: 1fr 1fr;
`;

const YoutubeCards = styled(Cards)`
	height: 200px;
`;

const PlanDetails = ({ countryCode, plan, planDetailsRef }: PlanDetailsProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Wrapper ref={planDetailsRef}>
			<Header>
				<Title $color={plan.color}>
					HYGO <span>{t(`plans.${plan.i18nId}.name`)}</span>
				</Title>
				<Subtitle>{t(`plans.${plan.i18nId}.description`)}</Subtitle>
				{(plan.price || plan.price === 0) && (
					<Price>
						{plan.price} {t("units.euro")}
						<PriceUnit>/{t("units.year")}</PriceUnit>
					</Price>
				)}
			</Header>
			{plan.youtubeId && countryMapping[countryCode].planPresentation.video && (
				<YoutubeCards>
					<Iframe
						allow="fullscreen"
						height="100%"
						id="player"
						src={`https://www.youtube.com/embed/${plan.youtubeId}?&enablejsapi=1`}
						title="Youtube Player"
						width="100%"
					/>
					<ReviewWrapper>
						<h2>{t(`plans.${plan.i18nId}.videoSubline`)}</h2>
					</ReviewWrapper>
				</YoutubeCards>
			)}
			{!plan.features.includes(Feature.FARM_WEATHER) && <DownloadApp />}
			{plan.equipmentPack.name && (
				<div>
					<SectionTitle>{t("plans.sections.includedEquipment")}</SectionTitle>
					<EquipmentCard equipment={plan.equipmentPack} />
				</div>
			)}
			<div>
				<SectionTitle>{t("plans.sections.includedFeatures")}</SectionTitle>
				<Cards>
					{plan.features
						.filter((f) => !!featureDetails[f])
						.map((f) => {
							return (
								<AccordionCard
									body={t(`plans.features.${f}.description`)}
									image={featureDetails[f]}
									key={f}
									title={t(`plans.features.${f}.name`)}
								/>
							);
						})}
				</Cards>
			</div>
			{plan?.features?.includes(Feature.FARM_WEATHER) && countryMapping[countryCode].planPresentation.addons && (
				<div>
					<SectionTitle>{t("plans.sections.addons")}</SectionTitle>
					<Cards>
						{countryMapping[countryCode].planPresentation.addons.map((oad) => {
							return (
								<AccordionCard
									body={t(`oad.${oad}.description`)}
									image={addonDetails[oad]}
									key={oad}
									title={t(`oad.${oad}`)}
								/>
							);
						})}
					</Cards>
				</div>
			)}
			{plan?.features?.includes(Feature.FARM_WEATHER) && (
				<div>
					<SectionTitle>{t("plans.sections.oads")}</SectionTitle>
					<Cards>
						{countryMapping[countryCode].planPresentation.oadList.map((oad) => {
							return (
								<AccordionCard
									body={t(`oad.${oad}.description`)}
									image={oadDetails[oad]}
									key={oad}
									title={t(`oad.${oad}`)}
								/>
							);
						})}
					</Cards>
				</div>
			)}
		</Wrapper>
	);
};

export default PlanDetails;
