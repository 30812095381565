import { UserContext } from "@hygo/shared/contexts";
import { Crop, Field, RPGField } from "@hygo/shared/models";
import { CreationMode, DashboardMode } from "@hygo/web/models";
import { fitMapBounds } from "@hygo/web/utils";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
	createContext,
	createRef,
	Dispatch,
	MutableRefObject,
	RefObject,
	SetStateAction,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState
} from "react";
import { MapRef } from "react-map-gl";

interface CropsScreenContextResult {
	createdPolygonDetails: { area: number; center: number[] };
	creationMode: CreationMode;
	currentMode: DashboardMode;
	drawRef: MutableRefObject<MapboxDraw>;
	editedFieldId: number;
	fieldCoordinates: { lat: number; lon: number }[];
	fieldsRef: { [x: number]: RefObject<HTMLDivElement> };
	handleFieldSelection: (data: {
		centerMap: boolean;
		field: Field;
		overrideActiveFields: Field[];
		overrideNewSelectedFields: Field[];
		selection: boolean;
	}) => void;
	mapRef: MutableRefObject<MapRef>;
	RPGFields: RPGField[];
	selectedCrop: Crop;
	selectedFields: Field[];
	setCreatedPolygonDetails: Dispatch<SetStateAction<{ area: number; center: number[] }>>;
	setCreationMode: Dispatch<SetStateAction<CreationMode>>;
	setEditedFieldId: Dispatch<SetStateAction<number>>;
	setRPGFields: Dispatch<SetStateAction<RPGField[]>>;
	setSelectedCrop: Dispatch<SetStateAction<Crop>>;
	updateFieldCoordinates: (d: { lat: number; lon: number }[], updateRef: boolean) => void;
	updateMode: (mode: DashboardMode) => void;
}

export const CropsScreenContext = createContext({} as CropsScreenContextResult);
interface CropsScreenProviderProps {
	children: JSX.Element | JSX.Element[];
}

const CropsScreenProvider = ({ children }: CropsScreenProviderProps): JSX.Element => {
	const [selectedFields, setSelectedFields] = useState<CropsScreenContextResult["selectedFields"]>([]);
	const [selectedCrop, setSelectedCrop] = useState<CropsScreenContextResult["selectedCrop"]>();
	const [RPGFields, setRPGFields] = useState<CropsScreenContextResult["RPGFields"]>([]);
	const [fieldCoordinates, setFieldCoordinates] = useState<CropsScreenContextResult["fieldCoordinates"]>([]);
	const [creationMode, setCreationMode] = useState<CropsScreenContextResult["creationMode"]>(CreationMode.RPG);
	const [currentMode, setCurrentMode] = useState<CropsScreenContextResult["currentMode"]>(DashboardMode.FIELD_LIST);
	const [editedFieldId, setEditedFieldId] = useState<CropsScreenContextResult["editedFieldId"]>(null);
	const [createdPolygonDetails, setCreatedPolygonDetails] =
		useState<CropsScreenContextResult["createdPolygonDetails"]>(null);
	const mapRef = useRef<MapRef>();
	const drawRef = useRef<MapboxDraw>();

	const { activeFields, user } = useContext(UserContext);

	const fieldsRef: CropsScreenContextResult["fieldsRef"] = activeFields?.reduce(
		(acc: { [x: number]: RefObject<HTMLDivElement> }, value) => {
			acc[value.id] = createRef();
			return acc;
		},
		{}
	);

	const scrollToSelectedField = useCallback(
		(fieldId: number): void => {
			if (fieldId && fieldsRef?.[fieldId].current) {
				fieldsRef[fieldId].current.scrollIntoView({
					behavior: "smooth",
					block: "nearest"
				});
			}
		},
		[fieldsRef]
	);

	const updateFieldCoordinates: CropsScreenContextResult["updateFieldCoordinates"] = (coords, updateRef) => {
		setFieldCoordinates(coords);
		if (!updateRef) return;

		if (coords?.length) {
			drawRef.current.set({
				features: [
					{
						geometry: {
							coordinates: [coords?.map((c) => [c.lon, c.lat])],
							type: "Polygon"
						},
						id: "polygon",
						properties: {},
						type: "Feature"
					}
				],

				type: "FeatureCollection"
			});
		} else {
			drawRef.current.deleteAll();
			setCreatedPolygonDetails(null);
		}
	};

	const updateMode: CropsScreenContextResult["updateMode"] = useCallback(
		(mode) => {
			setCurrentMode(mode);
			if (mode === DashboardMode.CROPS_LIST || mode === DashboardMode.FIELD_LIST) {
				drawRef.current.deleteAll();
				drawRef.current.changeMode("static");
				setCreatedPolygonDetails(null);
			} else if (mode === DashboardMode.NEW_FIELD) {
				drawRef.current.changeMode(creationMode === CreationMode.DRAWING ? "draw_polygon" : "static");
			}
		},
		[creationMode]
	);

	const handleFieldSelection: CropsScreenContextResult["handleFieldSelection"] = useCallback(
		({ centerMap, field, overrideActiveFields, overrideNewSelectedFields, selection }) => {
			const isSelectedField = selectedFields?.find((selected) => selected.id === field?.id);
			let result;
			if (!selection) {
				result = [field];
				scrollToSelectedField(field?.id);
			} else if (isSelectedField) {
				result = selectedFields?.filter((fd) => fd.id !== field?.id);
			} else {
				result = [...selectedFields.filter((fd) => fd.id !== field?.id), field];
				scrollToSelectedField(field?.id);
			}
			const newSelectedFields = overrideNewSelectedFields || result;
			const newActiveFields = (overrideActiveFields || activeFields)?.filter((f) => !f.deleted);
			setSelectedFields(newSelectedFields);
			setTimeout(() => {
				if (mapRef?.current && centerMap)
					fitMapBounds({
						fields:
							newSelectedFields?.filter((c) => c.coordinates?.length > 0)?.length > 0
								? newSelectedFields
								: newActiveFields,
						mapRef,
						userLocation: user?.location
					});
			}, 0);
		},
		[activeFields, selectedFields, scrollToSelectedField, user]
	);

	const value = useMemo(
		() => ({
			createdPolygonDetails,
			creationMode,
			currentMode,
			drawRef,
			editedFieldId,
			fieldCoordinates,
			fieldsRef,
			handleFieldSelection,
			mapRef,
			RPGFields,
			selectedCrop,
			selectedFields,
			setCreatedPolygonDetails,
			setCreationMode,
			setEditedFieldId,
			setRPGFields,
			setSelectedCrop,
			updateFieldCoordinates,
			updateMode
		}),
		[
			RPGFields,
			creationMode,
			selectedFields,
			fieldCoordinates,
			handleFieldSelection,
			updateMode,
			fieldsRef,
			createdPolygonDetails,
			selectedCrop,
			editedFieldId,
			currentMode
		]
	);

	return <CropsScreenContext.Provider value={value}>{children}</CropsScreenContext.Provider>;
};

export default CropsScreenProvider;
