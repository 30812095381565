import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { Checkmark } from "@hygo/shared/icons";
import { Feature, Field, FieldsManagerEvents } from "@hygo/shared/models";
import { addDays, COLORS, convertCoords, getStartOfDayAsJSDate, hexToRGBA, substractDays } from "@hygo/shared/utils";
import { CropsScreenContext } from "@hygo/web/contexts";
import { DashboardMode } from "@hygo/web/models";
import {
	Button,
	CropSelector,
	DatePicker,
	InputTip,
	ManagerEmptyState,
	ParcelSVG,
	TextInput
} from "@hygo/web/ui-components";
import * as turf from "@turf/turf";
import { useFeature } from "flagged";
import { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;

const StyledInputTip = styled(InputTip)`
	margin-bottom: 8px;
`;

const Ctas = styled.div`
	padding: 16px;
	background-color: var(--white-100);
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

const SubWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 16px;
	overflow: hidden;
`;

const FormWrapper = styled.div`
	background-color: var(--white-100);
	padding: 16px;
	border-radius: 4px;
	flex: 1;
	overflow: auto;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const Row = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 16px;
`;

const InputTipText = styled.h5`
	flex: 1;
	white-space: pre-line;
`;

const Input = styled(TextInput)`
	flex: 1;
`;

const NewFieldCard = (): JSX.Element => {
	const { createField } = useApi();
	const { t } = useTranslation();
	const {
		fieldCoordinates,
		handleFieldSelection,
		selectedFields: initialSelectedFields,
		updateFieldCoordinates,
		updateMode
	} = useContext(CropsScreenContext);
	const [loading, setLoading] = useState<boolean>(false);
	const { activeFields, crops, defaultFarm, loadFields, user } = useContext(UserContext);
	const { formatFields, logAnalyticEvent } = useContext(AmplitudeContext);
	const line = fieldCoordinates?.length >= 4 && turf.lineString(fieldCoordinates?.map((c) => [c.lon, c.lat]));
	const isShapeValid = turf.booleanValid(line);
	const methods = useForm({
		mode: "onChange"
	});
	const {
		formState: { isValid }
	} = methods;

	const hasPotatoesOnly = useFeature(Feature.POTATOES_ONLY);

	const defaultValues = useMemo(() => {
		return {
			crop: null,
			harvestDate: null,
			name: null,
			sowingDate: null
		};
	}, []);

	const onCreateField = async ({
		crop,
		harvestDate,
		name,
		sowingDate
	}: {
		crop: string;
		harvestDate: Date;
		name: string;
		sowingDate: Date;
	}): Promise<void> => {
		setLoading(true);

		const response = await createField({
			coordinates: fieldCoordinates,
			cropId: parseInt(crop, 10),
			farmId: defaultFarm.id,
			harvestDate,
			name,
			sowingDate
		});
		const fetchedFields = await loadFields(false, defaultFarm?.id);
		const newCreatedField = fetchedFields?.find((f) => f.id === response?.id);
		logAnalyticEvent(FieldsManagerEvents.createFields, {
			fields: formatFields([newCreatedField], crops)
		});
		setLoading(false);
		goBackToFieldsList({
			fields: fetchedFields,
			selectedFields: [newCreatedField]
		});
	};

	const goBackToFieldsList = ({ fields, selectedFields }: { fields: Field[]; selectedFields: Field[] }): void => {
		updateFieldCoordinates([], true);
		handleFieldSelection({
			centerMap: true,
			field: null,
			overrideActiveFields: fields,
			overrideNewSelectedFields: selectedFields,
			selection: true
		});
		updateMode(DashboardMode.FIELD_LIST);
	};

	const minimumHarvestDate = methods.watch()?.sowingDate
		? addDays(getStartOfDayAsJSDate(new Date(methods.watch()?.sowingDate)), 1)
		: null;
	const maximumSowingDateDate = methods.watch()?.harvestDate
		? substractDays(getStartOfDayAsJSDate(new Date(methods.watch()?.harvestDate)), 1)
		: null;
	const cropId = methods.watch().crop;
	const crop = crops?.find((c) => c.id === parseInt(cropId, 10));

	useEffect(() => {
		methods.reset(defaultValues);
	}, [methods, defaultValues]);

	return (
		<Wrapper>
			<SubWrapper>
				{isShapeValid ? (
					<>
						<StyledInputTip palette={isShapeValid && isValid ? COLORS.GRASS : COLORS.BEACH}>
							<InputTipText>
								{isShapeValid && isValid
									? t("screens.crops.newFieldCard.inputTip.valid")
									: t("screens.crops.newFieldCard.inputTip.invalid")}
							</InputTipText>
						</StyledInputTip>
						<FormWrapper>
							<FormProvider {...methods}>
								<Form autoComplete="off">
									<Row>
										<ParcelSVG
											fill={hexToRGBA(crop?.color, 0.5, COLORS.LAKE[50])}
											height={40}
											path={convertCoords(fieldCoordinates)}
											stroke={hexToRGBA(crop?.color, 1, COLORS.LAKE[100])}
											width={40}
										/>

										<Input
											label={t("inputs.fieldName.label")}
											name="name"
											rules={{
												required: {
													message: t("inputs.fieldName.errors.required"),
													value: true
												}
											}}
										/>
									</Row>

									<CropSelector
										control={methods.control}
										crops={crops}
										hasPotatoesOnly={!!hasPotatoesOnly}
									/>
									<DatePicker
										control={methods.control}
										countryCode={user?.countryCode}
										dateFormat="dd/MM/yyyy"
										label={t("inputs.fieldPhases.sowingDate.label")}
										maxDate={maximumSowingDateDate}
										name="sowingDate"
										placeholder={t("inputs.day.placeholder")}
									/>

									<DatePicker
										control={methods.control}
										countryCode={user?.countryCode}
										dateFormat="dd/MM/yyyy"
										label={t("inputs.fieldPhases.harvestDate.label")}
										minDate={minimumHarvestDate}
										name="harvestDate"
										placeholder={t("inputs.day.placeholder")}
									/>
									<InputTip palette={COLORS.DISABLED} withBorder={false}>
										<InputTipText>
											{t("screens.crops.newFieldCard.inputTip.fieldPhases")}
										</InputTipText>
									</InputTip>
								</Form>
							</FormProvider>
						</FormWrapper>
					</>
				) : (
					<ManagerEmptyState
						asset={
							<img
								alt={t("screens.crops.newFieldCard.emptyState.title")}
								src="/assets/images/crops/field-creation.png"
								width={320}
							/>
						}
						subtitle={t("screens.crops.newFieldCard.emptyState.description")}
						title={t("screens.crops.newFieldCard.emptyState.title")}
					/>
				)}
			</SubWrapper>
			<Ctas>
				<Button
					color={COLORS.SMOKE}
					inverted
					onClick={() => goBackToFieldsList({ fields: activeFields, selectedFields: initialSelectedFields })}
					text={t("button.cancel")}
				/>
				<Button
					color={COLORS.LAKE}
					disabled={!isValid || !isShapeValid}
					fillIcon
					Icon={Checkmark}
					loading={loading}
					onClick={methods.handleSubmit(onCreateField)}
					text={t("button.save")}
				/>
			</Ctas>
		</Wrapper>
	);
};

export default NewFieldCard;
