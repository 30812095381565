import styled from "styled-components";

const Text = styled.h5`
	color: var(--lake-100);
	width: fit-content;
	cursor: pointer;
	&:hover {
		color: var(--lake-hover);
	}
`;

interface SimpleButtonProps {
	className?: string;
	onClick?: () => void;
	text: string;
}

const SimpleButton = ({ className, onClick, text }: SimpleButtonProps): JSX.Element => {
	return (
		<Text className={className} onClick={onClick}>
			{text}
		</Text>
	);
};

export default SimpleButton;
