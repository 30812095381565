import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { ImportEvents } from "@hygo/shared/models";
import { OADContext } from "@hygo/web/contexts";
import { useImport } from "@hygo/web/hooks";
import { ImportedField, OADKeyEnum, OADStepEnum } from "@hygo/web/models";
import { AmbiguousCropModal, ConfirmationModal } from "@hygo/web/ui-components";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ImportTelepacLinkingScreen from "./ImportTelepacLinkingScreen";

const ImportTelepacLinkingContainer = (): JSX.Element => {
	const { getEdiFields, importEdiFields, uploadEdiFile } = useApi();
	const { activeFields, crops, defaultFarm } = useContext(UserContext);

	const { geofoliaOnboardingStep, setStepCookie } = useContext(OADContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const [fileId, setFileId] = useState<string>();
	const navigate = useNavigate();
	const location = useLocation();
	const isGeofoliaFile = location?.state?.isGeofolia;

	const importCallback = async (): Promise<{
		createdFieldIds: number[];
		updatedFieldIds: number[];
	}> => {
		logAnalyticEvent(ImportEvents.importFromFieldsLinking, {
			importCrops: cropSynchronize,
			isGeofolia: isGeofoliaFile
		});
		const response = await importEdiFields({
			cropSynchronize,
			farmId: defaultFarm.id,
			fields: linking
				?.filter((l) => l.selected)
				?.map((l) => ({ externalRef: l.importedFieldId, fieldId: l.fieldId })),
			fileId,
			geofolia: isGeofoliaFile
		});
		if (!geofoliaOnboardingStep)
			setStepCookie({ key: OADKeyEnum.GEOFOLIA_ONBOARDING_STEP, value: OADStepEnum.SHOW_ONBOARDING_CROPS });
		return response;
	};

	const fetchImportCallback = useCallback(async (): Promise<ImportedField[]> => {
		try {
			const fetchedFileId = await uploadEdiFile(location?.state?.inputFile);
			const fetchedImportFields = await getEdiFields({
				fileId: fetchedFileId,
				geofolia: location?.state?.isGeofolia
			});
			setFileId(fetchedFileId);
			return fetchedImportFields?.map((sf) => ({ ...sf, id: sf.externalRef }));
		} catch (e) {
			const errorCode = e?.response?.data?.code;
			if (
				errorCode === "severalFarmInFile" ||
				errorCode === "fileTypeUnknown" ||
				errorCode === "emptyFile" ||
				errorCode === "wrongAgroEdiFormat"
			) {
				navigate("/import/telepac", { replace: true, state: { errorCode } });
				return;
			}
			throw e;
		}
	}, [uploadEdiFile, getEdiFields, location, navigate]);

	const {
		backFromError,
		cropSynchronize,
		error,
		fieldsToImport,
		goBack,
		goToDashboard,
		importFields,
		initImportLinking,
		linking,
		loading,
		loadingImportedFields,
		onChange,
		onClickCheckbox,
		retryImport,
		setCropSynchronize,
		success
	} = useImport({
		AmbiguousCropModal,
		ConfirmationModal,
		fetchImportCallback,
		importCallback
	});

	useEffect(() => {
		if (linking) return;
		if (location?.state?.inputFile) initImportLinking();
		else navigate("/import/telepac");
	}, [location, navigate, initImportLinking, linking]);

	return (
		<ImportTelepacLinkingScreen
			backFromError={backFromError}
			crops={crops}
			cropSynchronize={cropSynchronize}
			defaultFarm={defaultFarm}
			error={error}
			fields={activeFields}
			fieldsToImport={fieldsToImport}
			goBack={goBack}
			goToDashboard={goToDashboard}
			isGeofoliaFile={isGeofoliaFile}
			linking={linking}
			loading={loading}
			loadingImportedFields={loadingImportedFields}
			onChange={onChange}
			onClickCheckbox={onClickCheckbox}
			onClickCropSynchronize={setCropSynchronize}
			onSubmit={importFields}
			retryImport={retryImport}
			success={success}
		/>
	);
};

export default ImportTelepacLinkingContainer;
