import { useApi } from "@hygo/shared/api";
import { RPGField, SnackbarType } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { convertToHa } from "@hygo/shared/utils";
import * as turf from "@turf/turf";
import { t } from "i18next";
import { useContext } from "react";

interface useRPGProps {
	fieldCoordinates: { lat: number; lon: number }[];
	RPGFields: RPGField[];
}

interface useRPGResult {
	area: number;
	center: number[];
	loadRPGFields: (data: { latitude: number; longitude: number }) => Promise<RPGField[]>;
	selectRPGField: (isAlreadyUsed: boolean, callback: () => void) => void;
}

export const useRPG = ({ fieldCoordinates, RPGFields }: useRPGProps): useRPGResult => {
	const { showSnackbar } = useContext(SnackbarContext);

	const { getRPGFields } = useApi();
	const polygon = fieldCoordinates?.length >= 4 && turf.polygon([fieldCoordinates?.map((c) => [c.lon, c.lat])]);
	const area = polygon && convertToHa(turf.area(polygon));
	const center = polygon && turf.centerOfMass(polygon)?.geometry?.coordinates;
	const loadRPGFields: useRPGResult["loadRPGFields"] = async ({ latitude, longitude }) => {
		const fetchedRPG = await getRPGFields({
			latitude,
			longitude
		});
		const totalRPG = [...fetchedRPG, ...RPGFields].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
		return totalRPG;
	};

	const selectRPGField: useRPGResult["selectRPGField"] = (isAlreadyUsed, callback) => {
		if (isAlreadyUsed) showSnackbar(t("snackbar.fieldAlreadyUsed.success"), SnackbarType.SUCCESS);
		else callback();
	};

	return { area, center, loadRPGFields, selectRPGField };
};
