import { AmbiguousCropWebModalPropsType } from "@hygo/shared/modals";
import { COLORS } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CropIcon from "../../CropIcon";
import BaseModal from "../BaseModal";

const Subtitle = styled.h4`
	margin-bottom: 16px;
	color: var(--night-50);
	text-align: center;
	&:last-child {
		margin-bottom: 0;
	}
`;

const CropWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
	gap: 8px;
`;

const AmbiguousCropModal = ({ crops, hideModal, modalVisible }: AmbiguousCropWebModalPropsType): JSX.Element => {
	const { t } = useTranslation();

	return (
		<BaseModal
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnText={t("button.understood")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			title={t("modals.ambiguousCrop.title")}
		>
			<Subtitle>{t("modals.ambiguousCrop.body")}</Subtitle>
			<>
				{crops.map((crop) => {
					return (
						<CropWrapper key={crop.id}>
							<CropIcon crop={crop} fill={COLORS.NIGHT[100]} height={20} width={20} />
							<h4>{crop.name}</h4>
						</CropWrapper>
					);
				})}
			</>
			<Subtitle>{t("modals.ambiguousCrop.subline")}</Subtitle>
		</BaseModal>
	);
};
export default AmbiguousCropModal;
