import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { Change, Settings } from "@hygo/shared/icons";
import { SmagManagementModalPropsType } from "@hygo/shared/modals";
import { COLORS } from "@hygo/shared/utils";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ButtonCluster from "../../ButtonCluster";
import MultiActionsButton from "../../MultiActionsButton";
import BaseModal from "../BaseModal";

const Subtitle = styled.h4`
	text-align: center;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const SmagManagementModal = ({ hideModal, modalVisible, onGoToImport }: SmagManagementModalPropsType): JSX.Element => {
	const { t } = useTranslation();

	const { fetchUser, user } = useContext(UserContext);
	const { deleteSmagToken } = useApi();

	const goToImportSmag = (): void => {
		onGoToImport();
		hideModal();
	};

	const logout = async (): Promise<void> => {
		await deleteSmagToken();
		await fetchUser();
		hideModal();
	};

	return (
		<BaseModal
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnText={t("button.close")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onConfirm={hideModal}
			title={t("modals.smagManagement.title")}
		>
			<Wrapper>
				<Subtitle>{t("modals.smagManagement.subtitle", { firstName: user.firstName })}</Subtitle>
				<MultiActionsButton>
					<ButtonCluster
						chevronColor={COLORS.LAKE[100]}
						Icon={<Change fill={COLORS.LAKE[100]} height={20} width={20} />}
						label={t("modals.smagManagement.ctas.importFields")}
						onClick={goToImportSmag}
					/>
					<ButtonCluster
						chevronColor={COLORS.GASPACHO[100]}
						Icon={<Settings fill={COLORS.GASPACHO[100]} height={20} width={20} />}
						label={t("modals.smagManagement.ctas.logout")}
						onClick={logout}
					/>
				</MultiActionsButton>
				<p>{t("modals.smagManagement.body")}</p>
			</Wrapper>
		</BaseModal>
	);
};

export default SmagManagementModal;
