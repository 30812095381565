export enum MileosStatus {
	A_IN_SYNC = "IN_SYNC",
	B_NO_MEASURES = "NO_MEASURES",
	B_NO_STATUS = "NO_STATUS",
	C_RISK = "RISK",
	D_PROTECTED = "PROTECTED",
	E_VIGILANCE = "VIGILANCE",
	F_NO_RISK = "NO_RISK",
	G_NOT_ACHIEVED = "NOT_ACHIEVED",
	H_END_OF_CAMPAIGN = "END_OF_CAMPAIGN"
}
