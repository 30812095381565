import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { RecycleCrop } from "@hygo/shared/icons";
import { Crop, FieldsManagerEvents, SnackbarType } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { COLORS, denormalizeData } from "@hygo/shared/utils";
import { CropsScreenContext } from "@hygo/web/contexts";
import { DashboardMode } from "@hygo/web/models";
import { Button, CropIcon, InputTip, Loader, SearchInput } from "@hygo/web/ui-components";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;

const Header = styled.div`
	background-color: var(--white-100);
	padding: 16px;
	border-radius: 4px;
`;

const HeaderTitle = styled.h5`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 10px;
`;

const SubWrapper = styled.div`
	flex: 1;
	overflow: hidden;
	margin: 0 16px;
	display: flex;
	flex-direction: column;
`;

const CropsWrapper = styled.div`
	flex: 1;
	overflow: auto;
`;

const CropItem = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	background-color: var(--white-100);
	padding: 16px;
	cursor: pointer;
	margin: 8px 0;
	&:hover {
		background-color: var(--night-10);
	}
`;

const Ctas = styled.div`
	background-color: var(--white-100);
	padding: 16px 30px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const StyledLoader = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const LoaderWrapper = styled.div`
	background-color: var(--white-100);
	width: 100%;
	margin: 0 26px;
	padding: 32px 18px;
	border-radius: 16px;
`;

const CropsListCard = (): JSX.Element => {
	const { patchFields } = useApi();
	const [loading, setLoading] = useState<boolean>(false);
	const { handleFieldSelection, selectedFields, updateMode } = useContext(CropsScreenContext);
	const { t } = useTranslation();
	const { showSnackbar } = useContext(SnackbarContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { crops, defaultFarm, loadFields } = useContext(UserContext);
	const [searchedCrops, setSearchedCrops] = useState<Crop[]>(crops);

	const onClickCrop = async (crop: Crop): Promise<void> => {
		try {
			setLoading(true);
			const response = await patchFields({
				farmId: defaultFarm.id,
				fields: selectedFields.map((field) => ({ cropId: crop.id, id: field.id }))
			});
			const fetchedFields = await loadFields(false, defaultFarm?.id);
			const newSelectedFields = denormalizeData({
				data: fetchedFields,
				usages: [...response.updatedFieldIds, ...response.createdFieldIds]
			});
			updateMode(DashboardMode.FIELD_LIST);
			handleFieldSelection({
				centerMap: true,
				field: null,
				overrideActiveFields: fetchedFields,
				overrideNewSelectedFields: newSelectedFields,
				selection: true
			});
			logAnalyticEvent(FieldsManagerEvents.updateFieldsCrops, { crop: crop.name });
			showSnackbar(t("snackbar.updateCrops.success"), SnackbarType.SUCCESS);
			setLoading(false);
		} catch (e) {
			showSnackbar(t("snackbar.updateCrops.error"), SnackbarType.ERROR);
			throw e;
		}
	};

	const onCancel = (): void => {
		handleFieldSelection({
			centerMap: true,
			field: null,
			overrideActiveFields: null,
			overrideNewSelectedFields: [],
			selection: true
		});
		updateMode(DashboardMode.FIELD_LIST);
	};

	const search = (cropName: string): void => {
		const filteredAndSortedCrops = crops?.filter((crop) =>
			cropName?.length >= 1 ? crop.name.toLowerCase().includes(cropName?.toLowerCase()) : crop
		);

		setSearchedCrops(filteredAndSortedCrops);
	};

	return loading ? (
		<StyledLoader>
			<LoaderWrapper>
				<Loader message={t("screens.dashboard.cropsListCard.loaderMessage")} />
			</LoaderWrapper>
		</StyledLoader>
	) : (
		<Wrapper>
			<SubWrapper>
				<Header>
					<HeaderTitle>
						<RecycleCrop fill={COLORS.NIGHT[100]} height={20} width={20} />

						{t("screens.dashboard.cropsListCard.selectedFields", { count: selectedFields?.length })}
					</HeaderTitle>

					<SearchInput name="crop" onCustomChange={search} theme="light" />
				</Header>
				<CropsWrapper>
					{searchedCrops &&
						searchedCrops.map((crop) => {
							return (
								<CropItem
									key={crop.id}
									onClick={() => onClickCrop(crop)}
									onKeyDown={() => onClickCrop(crop)}
									role="button"
									tabIndex={0}
								>
									<CropIcon crop={crop} fill={COLORS.NIGHT[100]} />
									<h4>{crop?.name}</h4>
								</CropItem>
							);
						})}
				</CropsWrapper>
			</SubWrapper>
			<Ctas>
				<InputTip palette={COLORS.DISABLED} withBorder={false}>
					<ColoredCardText>{t("screens.dashboard.cropsListCard.explicabilityCrop")}</ColoredCardText>
				</InputTip>
				<Button
					color={COLORS.SMOKE}
					inverted
					onClick={onCancel}
					text={t("screens.dashboard.cropsListCard.cancelSelection")}
				/>
			</Ctas>
		</Wrapper>
	);
};
export default CropsListCard;
