import { MutableRefObject, useEffect, useState } from "react";
import { MapRef } from "react-map-gl";

interface FixMapboxMapProps {
	children: JSX.Element | JSX.Element[];
	mapRef: MutableRefObject<MapRef>;
}

const FixMapboxMap = ({ children, mapRef }: FixMapboxMapProps): JSX.Element | JSX.Element[] => {
	const [canRender, setCanRender] = useState(false);

	useEffect(() => {
		const listener = (): void => {
			setCanRender(true);
			map?.getCanvas()?.setAttribute("data-sl", "canvas-mq");
		};

		const map = mapRef.current;

		map?.on("load", listener);
		return () => {
			map?.off("load", listener);
		};
	}, [mapRef]);

	return canRender && children;
};

export default FixMapboxMap;
