import { BaseSkeleton } from "@hygo/web/ui-components";
import styled from "styled-components";

const Left = styled(BaseSkeleton)`
	flex: 2;
`;

const Right = styled(BaseSkeleton)`
	flex: 3;
`;

const Skeleton = (): JSX.Element => {
	return (
		<>
			<Left />
			<Right />
		</>
	);
};

export default Skeleton;
