import { COLORS } from "@hygo/shared/utils";
import {
	Button,
	ContainerWithBgImage,
	Divider,
	FormCard,
	Loader,
	SimpleButton,
	TextInput
} from "@hygo/web/ui-components";
import { FormProvider } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { SigninScreenProps } from "./screen.types";

const Title = styled.h1`
	margin-bottom: 32px;
	text-align: center;
	span {
		color: var(--lake-100);
	}
`;

const Link = styled(SimpleButton)`
	margin: 8px 0;
`;

const Version = styled.p`
	text-align: center;
	margin-top: 32px;
`;

const InputWrapper = styled.div`
	margin-bottom: 8px;
`;

const SignInScreen = ({
	loading,
	methods,
	onClickReset,
	onClickSignUp,
	onSubmit,
	passwordShown,
	PasswordVisibilityIcon,
	rules,
	togglePasswordVisibility,
	version
}: SigninScreenProps): JSX.Element => {
	const { t } = useTranslation();
	const {
		formState: { isValid }
	} = methods;

	return (
		<ContainerWithBgImage backgroundImage={"/assets/images/bg.jpg"}>
			<FormCard>
				<Trans i18nKey="screens.signin.title">
					<Title>
						Welcome to
						<span>Alvie</span>
					</Title>
				</Trans>
				{loading ? (
					<Loader />
				) : (
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<InputWrapper>
								<TextInput
									autoComplete="username"
									label={t("inputs.email.label")}
									name="email"
									rules={rules.email}
								/>
							</InputWrapper>
							<InputWrapper>
								<TextInput
									autoComplete="current-password"
									label={t("inputs.password.label")}
									name="password"
									placeholderIcon={{
										icon: <div onClick={togglePasswordVisibility}>{PasswordVisibilityIcon}</div>,
										position: "right"
									}}
									rules={rules.password}
									type={passwordShown ? "text" : "password"}
								/>
							</InputWrapper>
							<Link onClick={onClickReset} text={t("screens.signin.passwordForgotten")} />
							<Button color={COLORS.LAKE} disabled={!isValid} isSubmitBtn text={t("button.login")} />
							<Divider text={t("screens.signin.dividerText")} />
							<Button
								color={COLORS.LAKE}
								onClick={onClickSignUp}
								outlined
								text={t("screens.signin.createAccountBtn")}
							/>
						</form>
						{version && <Version>HYGO v.{version}</Version>}
					</FormProvider>
				)}
			</FormCard>
		</ContainerWithBgImage>
	);
};

export default SignInScreen;
