import { Country, Language } from "@hygo/shared/models";
import i18next from "i18next";
import { capitalize } from "lodash";

const MINUTES_TO_MS = 60 * 1000;
const HOURS_TO_MS = 60 * MINUTES_TO_MS;

type TimeLocale = "fr-CA" | `${string}-${Country}`;

export const addHours = (date: Date, i: number): Date => new Date(date.getTime() + i * HOURS_TO_MS);
export const addMinutes = (date: Date, i: number): Date => {
	if (!date) return;
	return new Date(date.getTime() + i * MINUTES_TO_MS);
};
export const substractMinutes = (date: Date, i: number): Date => {
	if (!date) return;
	return new Date(date.getTime() - i * MINUTES_TO_MS);
};
export const substractHours = (date: Date, i: number): Date => new Date(date.getTime() - i * HOURS_TO_MS);

export const substractMonth = (date: Date, i: number): Date => {
	if (!date) return;
	return new Date(date.setMonth(date.getMonth() - i));
};

export const addMonth = (date: Date, i: number): Date => {
	if (!date) return;
	return new Date(date.setMonth(date.getMonth() + i));
};

export const nowWithoutSeconds = (): Date => new Date(new Date().setSeconds(0, 0));

export const addDays = (date: Date, i: number): Date => new Date(new Date(date).setDate(date.getDate() + i));

export const substractDays = (date: Date, i: number): Date => new Date(new Date(date).setDate(date.getDate() - i));

export const getXNextDays = (i: number, startDate: Date): string[] => {
	const date = new Date(startDate);
	date.setHours(0, 0, 0, 0);
	return Array.from({ length: i }).map((_, index) => addDays(date, index).toISOString());
};

export const computeSlotSize = ({ endTime, startTime }: { endTime: Date; startTime: Date }): number =>
	Math.abs(endTime?.getTime() - startTime?.getTime()) / HOURS_TO_MS;

export const formatDateToLocale = (date: Date, locale: TimeLocale, withYear = true): string => {
	return new Intl.DateTimeFormat(locale, {
		day: "2-digit",
		month: "2-digit",
		year: withYear ? "numeric" : undefined
	}).format(date);
};

export const formatMonthFromDate = (date: Date, locale: TimeLocale, withYear = true): string =>
	new Intl.DateTimeFormat(locale, {
		month: "long",
		year: withYear ? "numeric" : undefined
	}).format(date);

export const formatJSDateInHours = (date: Date): string => date.getHours().toString().padStart(2, "0");
export const formatJSDateInMinutes = (date: Date): string => date.getMinutes().toString().padStart(2, "0");

export const convertStringDateToJSDate = (date: string): Date => {
	const splittedDate = date.split("-").map((v) => parseInt(v, 10));
	return new Date(splittedDate[0], splittedDate[1] - 1, splittedDate[2]);
};

export const formatJSDateInHoursAndMinutes = (date: Date): string =>
	`${formatJSDateInHours(date)}H${formatJSDateInMinutes(date)}`;

export const isBetweenDates = ({ date, endAt, startAt }: { date: Date; endAt: Date; startAt: Date }): boolean => {
	return (
		getStartOfDayAsJSDate(date).getTime() >= startAt.getTime() &&
		getStartOfDayAsJSDate(date).getTime() <= endAt.getTime()
	);
};

export const differenceBetweenDates = (date1: Date, date2: Date): number => {
	const tsDifference = date1.getTime() - date2.getTime();
	return Math.floor(tsDifference / (1000 * 60 * 60 * 24));
};
export const formatJSDateInDaysName = (date: Date, locale: TimeLocale): string =>
	date.toLocaleString(locale, { weekday: "short" });

export const getStartOfDayAsJSDate = (date: Date): Date => {
	if (!date) return;
	return new Date(new Date(date).setHours(0, 0, 0, 0));
};
export const getEndOfDayAsJSDate = (date: Date): Date => new Date(new Date(date).setHours(24, 0, 0, 0));

export const formatTimestampAsTitle = (
	timestamp: Date,
	locale: TimeLocale,
	options: Intl.DateTimeFormatOptions = {
		day: "numeric",
		month: "short",
		weekday: "long"
	}
): string => {
	if (!timestamp) return "";
	const currentDate = new Date();

	const isToday =
		timestamp.getDate() === currentDate.getDate() &&
		timestamp.getMonth() === currentDate.getMonth() &&
		timestamp.getFullYear() === currentDate.getFullYear();

	if (isToday) return i18next.t("time.today");
	const formattedDate = timestamp.toLocaleString(locale, options);
	return locale?.includes(Language.fr) ? capitalize(formattedDate) : formattedDate;
};

export const transformDate = (date: Date, value: number, method: (x: number) => number): string => {
	const ms = 1000 * 60 * value;
	const roundedDate = new Date(method(date.getTime() / ms) * ms);
	return formatJSDateInHoursAndMinutes(roundedDate);
};

export const validateDates = ({
	locale,
	maximumDate,
	minimumDate,
	mode,
	value
}: {
	locale: TimeLocale;
	maximumDate: Date;
	minimumDate: Date;
	mode: "date" | "time";
	value: Date;
}): boolean | string => {
	const getErrorMessage = (): string => {
		const maximumDateFormatted = formatDateToLocale(maximumDate, locale);
		const minimumDateFormatted = formatDateToLocale(minimumDate, locale);

		if (minimumDate && maximumDate)
			return i18next.t("inputs.date.errors.intervalDate", {
				interpolation: { escapeValue: false },
				maxDate:
					mode === "date"
						? maximumDateFormatted
						: `${maximumDateFormatted} - ${formatJSDateInHoursAndMinutes(maximumDate)}`,
				minDate:
					mode === "date"
						? minimumDateFormatted
						: `${minimumDateFormatted} - ${formatJSDateInHoursAndMinutes(minimumDate)}`
			});
		if (maximumDate)
			return i18next.t("inputs.date.errors.maxDate", {
				interpolation: { escapeValue: false },
				maxDate:
					mode === "date"
						? maximumDateFormatted
						: `${maximumDateFormatted} - ${formatJSDateInHoursAndMinutes(maximumDate)}`
			});
		if (minimumDate)
			return i18next.t("inputs.date.errors.minDate", {
				interpolation: { escapeValue: false },
				minDate:
					mode === "date"
						? minimumDateFormatted
						: `${minimumDateFormatted} - ${formatJSDateInHoursAndMinutes(minimumDate)}`
			});
	};

	return (
		!value ||
		(minimumDate &&
			maximumDate &&
			value?.getTime() >= minimumDate.getTime() &&
			value?.getTime() <= maximumDate.getTime()) ||
		(minimumDate && !maximumDate && value?.getTime() >= minimumDate.getTime()) ||
		(maximumDate && !minimumDate && value?.getTime() <= maximumDate.getTime()) ||
		(!maximumDate && !minimumDate) ||
		getErrorMessage()
	);
};
