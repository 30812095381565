import styled from "styled-components";

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;

const Title = styled.h4`
	margin-top: 32px;
`;

interface LoaderProps {
	className?: string;
	message?: string;
}
const Loader = ({ className, message }: LoaderProps): JSX.Element => {
	return (
		<Wrapper className={className}>
			<img alt="loading" height={80} src={"/assets/images/spinner.gif"} width={80} />
			{message && <Title>{message}</Title>}
		</Wrapper>
	);
};

export default Loader;
