import styled, { css } from "styled-components";

interface InputTipProps {
	children: JSX.Element | JSX.Element[];
	className?: string;
	onClick?: () => void;
	palette: Record<number, string>;
	withBorder?: boolean;
}

const Wrapper = styled.div<{ $backgroundColor: string; $borderColor: string }>`
	background-color: ${(props) => props.$backgroundColor};
	border-radius: 4px;
	color: var(--night-100);
	padding: 8px;
	border: ${(props) => (props.$borderColor ? `1px solid ${props.$borderColor}` : null)};
	${(props) =>
		props.onClick &&
		css`
			&:hover {
				cursor: pointer;
			}
		`}
`;

const InputTip = ({ children, className, onClick, palette, withBorder = true }: InputTipProps): JSX.Element => {
	return (
		<Wrapper
			$backgroundColor={palette[10]}
			$borderColor={withBorder && palette[100]}
			className={className}
			onClick={onClick}
		>
			{children}
		</Wrapper>
	);
};

export default InputTip;
