import { equipmentPackDetails } from "@hygo/shared/constants";
import { EquipmentPlan } from "@hygo/shared/models";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
	padding: 16px;
	background-color: var(--lake-10);
	border: 1px solid var(--lake-100);
	display: flex;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
`;

const Subtitle = styled.h4`
	color: var(--lake-100);
`;

const Image = styled.img`
	width: 60px;
`;

const Body = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

interface EquipmentCardProps {
	equipment: {
		name: EquipmentPlan;
		price: number;
	};
}

const EquipmentCard = ({ equipment }: EquipmentCardProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<Image alt={t(`plans.equipments.${equipment.name}.name`)} src={equipmentPackDetails[equipment.name]} />
			<Body>
				<h2>{t(`plans.equipments.${equipment.name}.name`)}</h2>
				<Subtitle>
					{equipment.price
						? `${t("plans.sections.includedEquipment.pricePerStation", { price: equipment.price })}`
						: t("plans.sections.includedEquipment.inOffer")}
				</Subtitle>
				<h5>{t(`plans.equipments.${equipment.name}.description`)}</h5>
			</Body>
		</Wrapper>
	);
};

export default EquipmentCard;
