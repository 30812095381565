import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { Calendar, Close, EditCrop, Trash } from "@hygo/shared/icons";
import { ModalsContext } from "@hygo/shared/modals";
import { Feature, Field, FieldsManagerEvents, SnackbarType } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { COLORS, denormalizeData } from "@hygo/shared/utils";
import { CropsScreenContext } from "@hygo/web/contexts";
import { DashboardMode } from "@hygo/web/models";
import { ButtonCluster, FieldPhasesModal, InputTip, MultiActionsButton } from "@hygo/web/ui-components";
import { useFeature } from "flagged";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface BulkActionsProps {
	selectedFields: Field[];
}

const Wrapper = styled.div`
	background-color: var(--white-100);
	border-radius: 4px;
	z-index: 1;
	width: 320px;
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 16px;
	top: 16px;
	left: 16px;
	padding: 16px 24px;
`;

const Cross = styled(Close)`
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 10px;
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const BulkActions = ({ selectedFields }: BulkActionsProps): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);
	const { formatFields, logAnalyticEvent } = useContext(AmplitudeContext);
	const { crops, defaultFarm, loadFields } = useContext(UserContext);
	const { showModal } = useContext(ModalsContext);
	const { handleFieldSelection, updateMode } = useContext(CropsScreenContext);
	const { showSnackbar } = useContext(SnackbarContext);
	const { deleteFields, patchFields } = useApi();
	const hasPotatoesOnly = useFeature(Feature.POTATOES_ONLY);
	const { t } = useTranslation();

	const hasSelectedCheckedSmagFields = selectedFields?.some((f) => !f.needCheck && f.smagCropZoneUid);
	const disableCropUpdate = !!hasPotatoesOnly || hasSelectedCheckedSmagFields;

	const onClickShowCrops = (): void => {
		logAnalyticEvent(FieldsManagerEvents.validateFieldsCrops, { fields: formatFields(selectedFields, crops) });
		updateMode(DashboardMode.CROPS_LIST);
	};

	const onDeleteFields = async (): Promise<void> => {
		setLoading(true);
		try {
			await deleteFields({ farmId: defaultFarm.id, fieldIds: selectedFields?.map((f) => f.id) });
			const fetchedFields = await loadFields(false, defaultFarm?.id);
			handleFieldSelection({
				centerMap: true,
				field: null,
				overrideActiveFields: fetchedFields,
				overrideNewSelectedFields: [],
				selection: true
			});
			updateMode(DashboardMode.FIELD_LIST);
			logAnalyticEvent(FieldsManagerEvents.deleteField, { fields: formatFields(selectedFields, crops) });
			showSnackbar(t("snackbar.deleteMultipleFields.success"), SnackbarType.SUCCESS);
		} catch (e) {
			showSnackbar(t("snackbar.deleteMultipleFields.error"), SnackbarType.ERROR);
			throw e;
		} finally {
			setLoading(false);
		}
	};

	const onUpdatePhases = (): void => {
		showModal(FieldPhasesModal, {
			fields: selectedFields,
			onSubmit: async (value) => {
				setLoading(true);
				try {
					const response = await patchFields({
						farmId: defaultFarm.id,
						fields: value?.ids?.map((id) => ({
							harvestDate: value?.harvestDate,
							id,
							sowingDate: value?.sowingDate
						}))
					});
					const fetchedFields = await loadFields(false, defaultFarm?.id);
					const newSelectedFields = denormalizeData({
						data: fetchedFields,
						usages: [...response.updatedFieldIds, ...response.createdFieldIds]
					});
					handleFieldSelection({
						centerMap: true,
						field: null,
						overrideActiveFields: fetchedFields,
						overrideNewSelectedFields: newSelectedFields,
						selection: true
					});
					showSnackbar(t("snackbar.updateMultiplePhases.success"), SnackbarType.SUCCESS);
				} catch (e) {
					showSnackbar(t("snackbar.updateMultiplePhases.error"), SnackbarType.ERROR);
					throw e;
				} finally {
					setLoading(false);
				}
			}
		});
	};

	const onClose = (): void => {
		handleFieldSelection({
			centerMap: true,
			field: null,
			overrideActiveFields: null,
			overrideNewSelectedFields: [],
			selection: true
		});
		updateMode(DashboardMode.FIELD_LIST);
	};

	return (
		<Wrapper>
			{!loading && <Cross height={20} onClick={onClose} width={20} />}
			<h4>{t("screens.dashboard.cropsListCard.selectedFields", { count: selectedFields?.length })}</h4>
			<MultiActionsButton>
				<ButtonCluster
					chevronColor={disableCropUpdate || loading ? COLORS.NIGHT[50] : COLORS.LAKE[100]}
					disabled={disableCropUpdate || loading}
					hoverColor={COLORS.LAKE[100]}
					Icon={
						<EditCrop
							fill={disableCropUpdate || loading ? COLORS.NIGHT[50] : COLORS.LAKE[100]}
							height={20}
							width={20}
						/>
					}
					label={t("screens.crops.bulkActions.updateCrops")}
					onClick={onClickShowCrops}
				/>
				<ButtonCluster
					chevronColor={loading ? COLORS.NIGHT[50] : COLORS.LAKE[100]}
					disabled={loading}
					hoverColor={COLORS.LAKE[100]}
					Icon={<Calendar fill={loading ? COLORS.NIGHT[50] : COLORS.LAKE[100]} height={20} width={20} />}
					label={t("screens.crops.bulkActions.updatePhases")}
					onClick={onUpdatePhases}
				/>
				<ButtonCluster
					chevronColor={loading ? COLORS.NIGHT[50] : COLORS.GASPACHO[100]}
					disabled={loading}
					hoverColor={COLORS.GASPACHO[100]}
					Icon={<Trash fill={loading ? COLORS.NIGHT[50] : COLORS.GASPACHO[100]} height={20} width={20} />}
					label={t("screens.crops.bulkActions.deleteFields")}
					onClick={onDeleteFields}
				/>
			</MultiActionsButton>
			{disableCropUpdate && (
				<InputTip palette={COLORS.DISABLED} withBorder={false}>
					<ColoredCardText>{t("screens.crops.bulkActions.inputTip")}</ColoredCardText>
				</InputTip>
			)}
		</Wrapper>
	);
};

export default BulkActions;
