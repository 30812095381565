import { useSprayer } from "@hygo/shared/feature-equipment";
import { SprayerModalPropsType } from "@hygo/shared/modals";
import { Feature } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { useFeature } from "flagged";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import InputTip from "../../InputTip";
import TextInput from "../../TextInput";
import BaseModal from "../BaseModal";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const StyledInputTip = styled(InputTip)`
	margin-top: 8px;
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const SprayerModal = ({ hideModal, modalVisible, sprayer }: SprayerModalPropsType): JSX.Element => {
	const { t } = useTranslation();
	const { handleSprayerErrors, methods, onCreateOrUpdate, rules } = useSprayer({ sprayer });

	const hasWeatherDevice = useFeature(Feature.WEATHER_DEVICE);
	const [loading, setLoading] = useState<boolean>(false);

	const onConfirm = async (values: { gpsBarcode: number; name: string; weatherBarcode: string }): Promise<void> => {
		try {
			setLoading(true);
			await onCreateOrUpdate({ ...sprayer, ...values });
			hideModal();
		} catch (e) {
			handleSprayerErrors(e);
		} finally {
			setLoading(false);
		}
	};
	const {
		formState: { isValid }
	} = methods;
	return (
		<BaseModal
			cancelBtnText={t("button.cancel")}
			closeAfterConfirm={false}
			colorConfirmBtn={COLORS.LAKE}
			confirmBtnDisabled={!isValid}
			confirmBtnLoading={loading}
			confirmBtnText={t("button.save")}
			hideModal={hideModal}
			modalVisible={modalVisible}
			onCancel={hideModal}
			onConfirm={methods.handleSubmit(onConfirm)}
			title={t("modals.sprayer.title")}
		>
			<FormProvider {...methods}>
				<Wrapper>
					<div>
						<TextInput
							label={t("inputs.sprayerName.label")}
							name="name"
							placeholder={t("inputs.sprayerName.placeholder")}
							rules={rules.name}
						/>
					</div>

					<div>
						<TextInput
							label={t("inputs.gpsBarcode.label")}
							name="gpsBarcode"
							placeholder="Ex : 866022039942250"
							rules={rules.gpsBarcode}
						/>
						<StyledInputTip palette={COLORS.DISABLED} withBorder={false}>
							<ColoredCardText>{t("inputs.gpsBarcode.inputTip")}</ColoredCardText>
						</StyledInputTip>
					</div>
					{hasWeatherDevice && (
						<div>
							<TextInput
								label={t("inputs.weatherBarcode.label")}
								name="weatherBarcode"
								placeholder="Ex : 0C2AB56"
								rules={rules.weatherBarcode}
							/>
							<StyledInputTip palette={COLORS.DISABLED} withBorder={false}>
								<ColoredCardText>{t("inputs.weatherBarcode.inputTip")}</ColoredCardText>
							</StyledInputTip>
						</div>
					)}
				</Wrapper>
			</FormProvider>
		</BaseModal>
	);
};
export default SprayerModal;
