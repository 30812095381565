import { Calendar, WaterFill } from "@hygo/shared/icons";
import { Country } from "@hygo/shared/models";
import { COLORS, formatDateToLocale, formatJSDateInHours } from "@hygo/shared/utils";
import { ButtonCluster, MultiActionsButton } from "@hygo/web/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Section from "./Section";

interface IrrigationSectionProps {
	countryCode: Country;
	endTime: string;
	quantity: number;
	startTime: string;
}

const InputsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const IrrigationSection = ({ countryCode, endTime, quantity, startTime }: IrrigationSectionProps): JSX.Element => {
	const { i18n, t } = useTranslation();

	return (
		<Section Icon={<WaterFill fill={COLORS.LAKE[100]} height={20} width={20} />} title={t("glossary.details")}>
			<InputsWrapper>
				<MultiActionsButton>
					<ButtonCluster
						disabled
						Icon={<Calendar fill={COLORS.LAKE[100]} height={20} width={20} />}
						label={t("inputs.startTime.label")}
						onClick={() => null}
						value={`${formatDateToLocale(new Date(startTime), `${i18n.resolvedLanguage}-${countryCode}`)} - ${formatJSDateInHours(new Date(startTime))}h`}
					/>
					<ButtonCluster
						disabled
						Icon={<Calendar fill={COLORS.LAKE[100]} height={20} width={20} />}
						label={t("inputs.endTime.label")}
						onClick={() => null}
						value={`${formatDateToLocale(new Date(endTime), `${i18n.resolvedLanguage}-${countryCode}`)} - ${formatJSDateInHours(new Date(endTime))}h`}
					/>
				</MultiActionsButton>
				<MultiActionsButton>
					<ButtonCluster
						disabled
						Icon={<WaterFill fill={COLORS.LAKE[100]} height={20} width={20} />}
						label={t("inputs.quantity.label")}
						onClick={() => null}
						value={`${quantity} ${t("units.millimeters")}`}
					/>
				</MultiActionsButton>
			</InputsWrapper>
		</Section>
	);
};

export default IrrigationSection;
