import { ArrowLeft, Parcelles, Warning } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import {
	Button,
	EmptyState,
	FarmSelector,
	FormCard,
	FormHeader,
	InputTip,
	SimpleButton,
	SwitchButton
} from "@hygo/web/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ImportDropzone from "./components/ImportDropzone";
import { ImportTelepacScreenProps } from "./screen.types";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	background: var(--gradient-background-2);
`;

const StyledInputTip = styled(InputTip)`
	margin-top: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
`;

const Card = styled(FormCard)`
	box-shadow: 0px 6px 60px rgba(0, 83, 94, 0.05);
`;

const InputTipText = styled.h5`
	flex: 1;
	white-space: pre-line;
`;

const FlowWrapper = styled.div`
	margin-top: 32px;
`;

const Link = styled(SimpleButton)`
	margin: 8px 0;
`;

const GeofoliaImg = styled.img`
	width: 20px;
	height: 20px;
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const SwitchWrapper = styled.div`
	margin-top: 16px;
	background: var(--smoke-100);
	border: 0.5px solid var(--night-10);
	padding: 8px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const SwitchLabelWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const DropZone = styled(ImportDropzone)`
	margin-top: 16px;
`;

const StyledButton = styled(Button)`
	margin-top: 16px;
`;

const ImportTelepacScreen = ({
	defaultFarm,
	enableButton,
	errorCode,
	farms,
	geofoliaAuth,
	goBack,
	goToDashboard,
	goToFieldsLinking,
	handleChangeDropzone,
	inputFile,
	isGeofoliaFile,
	onClickHelp,
	setGeofoliaAuth,
	updateDefaultFarm
}: ImportTelepacScreenProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			{defaultFarm ? (
				<Card>
					<FormHeader backIcon={<ArrowLeft />} onGoBack={goBack} title={t("screens.importTelepac.title")} />

					<FlowWrapper>
						<FarmSelector
							crudActions={false}
							defaultFarm={defaultFarm}
							farms={farms}
							updateDefaultFarm={updateDefaultFarm}
						/>
						<StyledInputTip palette={COLORS.DISABLED} withBorder={false}>
							<ColoredCardText>{t("screens.importTelepac.inputTip.1")}</ColoredCardText>
						</StyledInputTip>
						<DropZone file={inputFile} onChange={handleChangeDropzone} />
						<Link onClick={onClickHelp} text={t("screens.importTelepac.helperLink")} />
						{isGeofoliaFile && (
							<>
								<SwitchWrapper>
									<SwitchLabelWrapper>
										<GeofoliaImg alt="geofolia" src={`/assets/images/oad/geofolia.png`} />
										<h5>{t("screens.importTelepac.switchBtn.label")}</h5>
									</SwitchLabelWrapper>

									<SwitchButton
										name="geofoliaAuth"
										onChange={(e) => setGeofoliaAuth(e.target.checked)}
										value={geofoliaAuth}
									/>
								</SwitchWrapper>
								<StyledInputTip palette={COLORS.DISABLED} withBorder={false}>
									<ColoredCardText>{t("screens.importTelepac.inputTip.2")}</ColoredCardText>
								</StyledInputTip>
							</>
						)}
						<StyledButton
							color={COLORS.LAKE}
							disabled={!enableButton}
							onClick={goToFieldsLinking}
							text={t("screens.import.importBtn")}
						/>
						{errorCode && (
							<StyledInputTip palette={COLORS.BEACH}>
								<Warning fill={COLORS.BEACH[100]} height={20} width={20} />
								<InputTipText>{t(`screens.importTelepac.${errorCode}.inputTip`)}</InputTipText>
							</StyledInputTip>
						)}
					</FlowWrapper>
				</Card>
			) : (
				<EmptyState
					btnIcon={Parcelles}
					btnText={t("components.emptyState.noDefaultFarm.btn")}
					description={t("components.emptyState.noDefaultFarm.description")}
					illustration={<img alt="ferme" src={"/assets/images/coop.png"} width={100} />}
					onClick={goToDashboard}
					title={t("components.emptyState.noDefaultFarm.title")}
				/>
			)}
		</Wrapper>
	);
};

export default ImportTelepacScreen;
