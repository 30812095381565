import { Crop, DenormalizedField, User } from "@hygo/shared/models";
import { COLORS, computeColorFromConditions, hexToRGBA, HORIZONTAL_PADDING } from "@hygo/shared/utils";
import "mapbox-gl/dist/mapbox-gl.css";
import { FixMapboxMap } from "@hygo/web/ui-components";
import { fitMapBounds } from "@hygo/web/utils";
import * as turf from "@turf/turf";
import { useRef } from "react";
import Map, { Layer, MapRef, Source } from "react-map-gl";
import styled from "styled-components";

interface MapSectionProps {
	crops: Crop[];
	fields: DenormalizedField[];
	user: User;
}

const Wrapper = styled.section`
	flex: 1;
	border-radius: 4px;
	overflow: hidden;
`;

const MapSection = ({ crops, fields, user }: MapSectionProps): JSX.Element => {
	const mapRef = useRef<MapRef>();

	const onLoad = (): void => {
		fitMapBounds({ fields: fields, mapRef, userLocation: user?.location });
	};

	return (
		<Wrapper>
			<Map
				attributionControl={false}
				mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
				onLoad={onLoad}
				padding={{
					bottom: HORIZONTAL_PADDING,
					left: HORIZONTAL_PADDING,
					right: HORIZONTAL_PADDING,
					top: HORIZONTAL_PADDING
				}}
				ref={mapRef}
				reuseMaps
			>
				<FixMapboxMap mapRef={mapRef}>
					<Source
						data={{
							features: fields?.flatMap((field) => {
								const polygon = turf.polygon([field.coordinates.map((c) => [c.lon, c.lat])]);
								const crop = crops?.find((c) => c.id === field?.cropId);
								const line = turf.lineString(field.coordinates.map((c) => [c.lon, c.lat]));
								return [
									{
										...polygon,
										id: "polygon",
										properties: {
											fillColor: computeColorFromConditions(
												field?.realCondition,
												"FIELD",
												hexToRGBA(crop?.color, 0.5, COLORS.WHITE[50])
											)
										},
										type: "Feature"
									},
									{
										...line,
										id: "line",
										properties: {
											lineColor: computeColorFromConditions(
												field?.realCondition,
												"SLOT",
												hexToRGBA(crop?.color, 1, COLORS.WHITE[100])
											)
										},
										type: "Feature"
									},
									{
										geometry: {
											coordinates: turf.center(polygon)?.geometry?.coordinates,
											type: "Point"
										},
										id: "marker",
										properties: {
											name: field.name
										},
										type: "Feature"
									}
								];
							}),
							type: "FeatureCollection"
						}}
						id="polygons"
						type="geojson"
					>
						<Layer
							filter={["==", "$type", "Polygon"]}
							id="inside"
							paint={{
								"fill-color": ["get", "fillColor"]
							}}
							source="polygons"
							type="fill"
						/>
						<Layer
							filter={["==", "$type", "LineString"]}
							id="border"
							paint={{
								"line-color": ["get", "lineColor"],
								"line-offset": 2.5,
								"line-width": 5
							}}
							source="polygons"
							type="line"
						/>

						<Layer
							filter={["==", "$type", "Point"]}
							id="marker"
							layout={{
								"text-field": ["get", "name"],
								"text-font": ["Rubik Medium"],
								"text-size": 14
							}}
							minzoom={12}
							paint={{
								"text-color": COLORS.WHITE[100],

								"text-halo-color": COLORS.BLACK[100],
								"text-halo-width": 1
							}}
							source="polygons"
							type="symbol"
						/>
					</Source>
				</FixMapboxMap>
			</Map>
		</Wrapper>
	);
};
export default MapSection;
