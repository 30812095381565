import { AmplitudeContext } from "@hygo/shared/amplitude";
import { Idea, Pencil, Touch } from "@hygo/shared/icons";
import { FieldsManagerEvents } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { CropsScreenContext } from "@hygo/web/contexts";
import { CreationMode } from "@hygo/web/models";
import { Button, InputTip } from "@hygo/web/ui-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
	left: 0;
	z-index: 10;
	position: absolute;
	top: 25px;
	width: 100%;
	pointer-events: none;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 16px;
`;

const InputTipText = styled.h5`
	color: var(--white-100);
	flex: 1;
`;

const SwitchWrapper = styled.div`
	gap: 16px;
	display: flex;
	justify-content: center;
`;

const StyledButton = styled(Button)`
	pointer-events: auto;
`;

const StyledInputTip = styled(InputTip)`
	background-color: var(--night-90);
	width: 350px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
`;

const CreationModeSwitch = (): JSX.Element => {
	const { t } = useTranslation();
	const { createdPolygonDetails, creationMode, drawRef, fieldCoordinates, setCreationMode, updateFieldCoordinates } =
		useContext(CropsScreenContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);

	const toggleMode = (mode: CreationMode): void => {
		updateFieldCoordinates([], true);
		setCreationMode(mode);
		if (mode === CreationMode.DRAWING) {
			drawRef.current.changeMode("draw_polygon");
			logAnalyticEvent(FieldsManagerEvents.clickDraw);
		} else {
			drawRef.current.changeMode("static");
			logAnalyticEvent(FieldsManagerEvents.clickRPG);
		}
	};

	const isRpg = creationMode === CreationMode.RPG;

	return (
		<Wrapper>
			<SwitchWrapper>
				<StyledButton
					color={isRpg ? COLORS.NIGHT : COLORS.SMOKE}
					fillIcon
					Icon={Touch}
					inverted={!isRpg}
					onClick={() => !isRpg && toggleMode(CreationMode.RPG)}
					text={t("button.select")}
					width="fit-content"
				/>
				<StyledButton
					color={isRpg ? COLORS.SMOKE : COLORS.NIGHT}
					fillIcon
					Icon={Pencil}
					inverted={isRpg}
					onClick={() => isRpg && toggleMode(CreationMode.DRAWING)}
					text={t("button.draw")}
					width="fit-content"
				/>
			</SwitchWrapper>
			{!!createdPolygonDetails?.area && !!createdPolygonDetails?.center && !fieldCoordinates?.length && (
				<StyledInputTip palette={COLORS.NIGHT} withBorder={false}>
					<Idea fill={COLORS.WHITE[100]} height={20} width={20} />
					<InputTipText>{t("screens.crops.creationModeSwitch.inputTip")}</InputTipText>
				</StyledInputTip>
			)}
		</Wrapper>
	);
};
export default CreationModeSwitch;
