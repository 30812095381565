import { AmplitudeContext } from "@hygo/shared/amplitude";
import { UserContext } from "@hygo/shared/contexts";
import { usePlan } from "@hygo/shared/feature-plan";
import { DiscoverLandingEvents } from "@hygo/shared/models";
import { useChargebee } from "@hygo/web/hooks";
import { useContext, useEffect, useRef, useState } from "react";

import PricingScreen from "./PricingScreen";
import { PricingScreenProps } from "./screen.types";

const PricingContainer = (): JSX.Element => {
	const { actualPlan, getTryHygoLabel, loading, onSubmit, otherPlans, recommendedPlan } = usePlan();
	const { openChargebeePortal } = useChargebee();
	const { logAnalyticEvent } = useContext(AmplitudeContext);

	const { user } = useContext(UserContext);

	const [selectedPlan, setSelectedPlan] = useState<PricingScreenProps["selectedPlan"]>();

	const planDetailsRef: PricingScreenProps["planDetailsRef"] = useRef();

	const onClick: PricingScreenProps["onClick"] = (plan) => {
		setSelectedPlan(plan);
		logAnalyticEvent(DiscoverLandingEvents.clickOnPlan, { planId: plan.id });
		setTimeout(() => {
			planDetailsRef.current.scrollTo({ behavior: "smooth", top: 0 });
		}, 0);
	};

	const alreadyInterested = !!user?.premiumRequestDate;

	useEffect(() => {
		setSelectedPlan(actualPlan);
	}, [actualPlan]);

	return (
		<PricingScreen
			actualPlan={actualPlan}
			alreadyInterested={alreadyInterested}
			countryCode={user?.countryCode}
			getTryHygoLabel={getTryHygoLabel}
			loading={loading}
			onClick={onClick}
			onSubmit={onSubmit}
			openChargebeePortal={openChargebeePortal}
			otherPlans={otherPlans}
			planDetailsRef={planDetailsRef}
			recommendedPlan={recommendedPlan}
			selectedPlan={selectedPlan}
		/>
	);
};

export default PricingContainer;
