import _ from "lodash";

import { getStartOfDayAsJSDate } from "./time";

interface ObjectGroupedByDateAndSortedType<T> {
	data: T[];
	title: string;
}

export const convertObjectToSortedArray = <T>(
	array: T[],
	getDate: (obj: T) => Date
): ObjectGroupedByDateAndSortedType<T>[] => {
	const object = _.groupBy(array, (item) => getStartOfDayAsJSDate(getDate(item)).toISOString());
	const objectToSortedArray = Object.keys(object)
		?.map((title) => ({
			data: object[title],
			title
		}))
		?.sort((day, nextDay) => {
			return new Date(nextDay.title).getTime() - new Date(day.title).getTime();
		});
	return objectToSortedArray.map((taskGroup) => ({
		data: taskGroup.data.sort((hour, nextHour) => getDate(nextHour).getTime() - getDate(hour).getTime()),
		title: taskGroup.title
	}));
};
