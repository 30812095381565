import { UserContext } from "@hygo/shared/contexts";
import { useImport } from "@hygo/web/hooks";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import ImportTelepacScreen from "./ImportTelepacScreen";
import { ImportTelepacScreenProps } from "./screen.types";

const ImportTelepacContainer = (): JSX.Element => {
	const [inputFile, setInputFile] = useState<ImportTelepacScreenProps["inputFile"]>();
	const [geofoliaAuth, setGeofoliaAuth] = useState<ImportTelepacScreenProps["geofoliaAuth"]>(true);
	const location = useLocation();
	const { i18n } = useTranslation();

	const { defaultFarm, farms, updateDefaultFarm } = useContext(UserContext);
	const errorCode = location?.state?.errorCode;

	const splittedFile = inputFile?.name?.split(".");
	const fileExtension = splittedFile?.[splittedFile?.length - 1];
	const { goBackToImportLanding, goToDashboard } = useImport({});

	const goToFieldsLinking: ImportTelepacScreenProps["goToFieldsLinking"] = () => {
		navigate("linking", {
			state: { inputFile, isGeofolia: isGeofoliaFile && geofoliaAuth }
		});
	};

	const navigate = useNavigate();

	const handleChangeDropzone: ImportTelepacScreenProps["handleChangeDropzone"] = (file) => {
		navigate(location.pathname, { replace: true });
		setInputFile(file);
	};

	const onClickHelp: ImportTelepacScreenProps["onClickHelp"] = () => {
		window.open(
			`https://support.alvie.fr/${i18n.resolvedLanguage}/articles/8711883-exportez-votre-assolement-depuis-telepac-et-ajoutez-le-dans-hygo`,
			"_blank"
		);
	};

	const onUpdateDefaultFarm: ImportTelepacScreenProps["updateDefaultFarm"] = async (farmId) => {
		await updateDefaultFarm(farmId);
	};

	const isGeofoliaFile = fileExtension === "edi" || fileExtension === "dap";

	return (
		<ImportTelepacScreen
			defaultFarm={defaultFarm}
			enableButton={Boolean(inputFile)}
			errorCode={errorCode}
			farms={farms}
			geofoliaAuth={geofoliaAuth}
			goBack={goBackToImportLanding}
			goToDashboard={goToDashboard}
			goToFieldsLinking={goToFieldsLinking}
			handleChangeDropzone={handleChangeDropzone}
			inputFile={inputFile}
			isGeofoliaFile={isGeofoliaFile}
			onClickHelp={onClickHelp}
			setGeofoliaAuth={setGeofoliaAuth}
			updateDefaultFarm={onUpdateDefaultFarm}
		/>
	);
};

export default ImportTelepacContainer;
