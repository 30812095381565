import { Farm } from "@hygo/shared/models";
import styled from "styled-components";

import FarmSelector from "../FarmSelector";

interface ManagerProps {
	children: JSX.Element | JSX.Element[];
	className?: string;
	createFarm: (data: { name: string }) => Promise<{ id: number }>;
	defaultFarm: Farm;
	deleteFarm?: (farmId: number) => Promise<void>;
	farms: Farm[];
	loadFarms: () => Promise<Farm[]>;
	showFarmSelector?: boolean;
	updateDefaultFarm: (farmId: number, newFarms: Farm[], handleNewFields: boolean) => Promise<void>;
	updateFarm?: (farm: Farm) => Promise<void>;
	withCrudActions?: boolean;
}

const Wrapper = styled.div`
	position: relative;
	background: var(--smoke-100);
	width: 390px;
	flex: 0 0 390px;
	border-top: 1px solid var(--night-10);
	border-right: 1px solid var(--night-10);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
`;

const FarmSelectorWrapper = styled.div`
	margin: 16px 16px 8px 16px;
`;

const Manager = ({
	children,
	className,
	createFarm,
	defaultFarm,
	deleteFarm,
	farms,
	loadFarms,
	showFarmSelector = true,
	updateDefaultFarm,
	updateFarm,
	withCrudActions = true
}: ManagerProps): JSX.Element => {
	return (
		<Wrapper className={className}>
			{showFarmSelector && (
				<FarmSelectorWrapper>
					<FarmSelector
						createFarm={createFarm}
						crudActions={withCrudActions}
						defaultFarm={defaultFarm}
						deleteFarm={deleteFarm}
						farms={farms}
						loadFarms={loadFarms}
						updateDefaultFarm={updateDefaultFarm}
						updateFarm={updateFarm}
					/>
				</FarmSelectorWrapper>
			)}

			{children}
		</Wrapper>
	);
};

export default Manager;
