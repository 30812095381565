import { Error, Loader } from "@hygo/web/ui-components";

import { HygoRedirectScreenProps } from "./screen.types";

const HygoRedirectScreen = ({ isMobile, loading }: HygoRedirectScreenProps): JSX.Element => {
	if (loading) {
		return <Loader />;
	}

	if (!isMobile) {
		return <Error type={449} />;
	}
};

export default HygoRedirectScreen;
