import { convertToFloat } from "./convertToFloat";

export const svgMinifier = (initialPath: string): { minifiedPath: string; viewport: string } => {
	const values = initialPath
		.split("L")
		.join(" ")
		.split("M")
		.join(" ")
		.split("Z")
		.join("")
		.split(" ")
		.filter((el) => el !== "")
		.map((el) => convertToFloat(el));
	let Xs = values.filter((_, index) => index % 2 === 0);
	let Ys = values.filter((_, index) => index % 2 !== 0);
	const minX = Math.min(...Xs);
	const maxX = Math.max(...Xs);
	const minY = Math.min(...Ys);
	const maxY = Math.max(...Ys);
	const isLambertCoordinates = Math.max(maxX, maxY) > 100 ? 1 : 0;
	const ratio = isLambertCoordinates ? 1 : 10000;
	Xs = Xs.map((el) => (el - minX) * ratio);
	Ys = Ys.map((el) => (el - minY) * ratio);

	const minifiedPath = `M ${Xs[0]} ${Ys[0]} L ${Xs.slice(1)
		.map((el, index) => `${el} ${Ys.slice(1)[index]} `)
		.join(" ")}Z`;
	const viewportXmax = Math.max(maxX - minX, maxY - minY) * ratio;
	const viewportYmax = viewportXmax;
	const viewportXmin = maxX - minX > maxY - minY ? 0 : ((maxX - minX - (maxY - minY)) * ratio) / 2;
	const viewportYmin = maxY - minY > maxX - minX ? 0 : ((maxY - minY - (maxX - minX)) * ratio) / 2;
	let viewport = "";
	if (
		!Number.isNaN(viewportXmin) &&
		!Number.isNaN(viewportXmax) &&
		!Number.isNaN(viewportYmin) &&
		!Number.isNaN(viewportYmax)
	)
		viewport = `${viewportXmin} ${viewportYmin} ${viewportXmax} ${viewportYmax}`;

	return { minifiedPath, viewport };
};
