import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { countryMapping } from "@hygo/shared/constants";
import { UserContext } from "@hygo/shared/contexts";
import { useCoops } from "@hygo/shared/hooks";
import { Coop, Country, Language, NewUser, SignupEvents, SnackbarType } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { emailRegEx } from "@hygo/shared/utils";
import { parsePhoneNumber } from "libphonenumber-js/max";
import { useContext, useEffect, useMemo, useState } from "react";
import { RegisterOptions, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { usePasswordShown } from "./usePasswordShown";

export interface SignUpFormInputs extends Omit<NewUser, "coopId"> {
	coop: string;
	googleAutocomplete: string;
	phoneCountrySelector: Country;
}

interface useSignupResult {
	activeStep: number;
	businessSectors: string[];
	coops: Coop[];
	createNewUser: (values: SignUpFormInputs) => Promise<void>;
	goNextStep: () => Promise<void>;
	goPreviousStep: () => void;
	isButtonDisabled: boolean;
	loading: boolean;
	methods: UseFormReturn<SignUpFormInputs>;
	onChangeCountry: (countryCode: Country) => void;
	onEditPhoneCountrySelector: (v: Country) => void;
	passwordShown: boolean;
	PasswordVisibilityIcon: JSX.Element;
	rules: Record<keyof SignUpFormInputs, RegisterOptions>;
	togglePasswordVisibility: () => void;
}

interface useSignupProps {
	defaultCountry: Country;
	language: Language;
}

const steps: {
	[key: number]: (keyof SignUpFormInputs)[];
} = {
	1: ["countryCode", "googleAutocomplete", "businessSector"],
	2: ["email", "principalPhone", "password"],
	3: ["firstName", "lastName", "coop"]
};

export const useSignup = ({ defaultCountry, language }: useSignupProps): useSignupResult => {
	const { checkToken, createUser, getBusinessSectors } = useApi();
	const { t } = useTranslation();
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { showSnackbar } = useContext(SnackbarContext);
	const { login } = useContext(UserContext);
	const { coops } = useCoops({});
	const [businessSectors, setBusinessSectors] = useState<string[]>([]);

	const defaultValues = useMemo(
		() => ({
			businessSector: null,
			coop: null,
			countryCode: defaultCountry,
			email: null,
			firstName: null,
			googleAutocomplete: null,
			language,
			lastName: null,
			location: null,
			password: null,
			phoneCountrySelector: defaultCountry,
			principalPhone: null,
			utms: null
		}),
		[defaultCountry, language]
	);
	const methods = useForm({ defaultValues, mode: "all", shouldFocusError: false });
	const {
		formState: { isValid }
	} = methods;
	const { passwordShown, PasswordVisibilityIcon, togglePasswordVisibility } = usePasswordShown({
		hasError: !!methods.formState.errors?.password
	});

	const [activeStep, setActiveStep] = useState<useSignupResult["activeStep"]>(1);
	const [loading, setLoading] = useState<useSignupResult["loading"]>(false);

	const goNextStep: useSignupResult["goNextStep"] = async () => {
		logAnalyticEvent(SignupEvents.goNextStep, { currentStep: activeStep });
		const isStepValid = await methods.trigger(steps[activeStep]);
		if (isStepValid) setActiveStep((s) => s + 1);
	};
	const goPreviousStep: useSignupResult["goPreviousStep"] = async () => {
		logAnalyticEvent(SignupEvents.goBack, { currentStep: activeStep });
		setActiveStep((s) => s - 1);
	};

	const findAssociatedStep = (input: keyof SignUpFormInputs): number =>
		parseInt(
			Object.keys(steps)?.find((k) => steps[parseInt(k, 10)].includes(input)),
			10
		);

	const onChangeCountry: useSignupResult["onChangeCountry"] = (countryCode) => {
		logAnalyticEvent(SignupEvents.selectCountry, { value: countryCode });
		if (countryCode === methods.getValues("countryCode")) return;
		methods.setValue("countryCode", countryCode, { shouldValidate: true });
		setTimeout(() => {
			methods.setValue("googleAutocomplete", null);
		}, 0);
		methods.setValue("principalPhone", null, { shouldValidate: true });
		methods.setValue("coop", null, { shouldValidate: true });
		methods.setValue("location", null, { shouldValidate: true });
		methods.setValue("phoneCountrySelector", countryCode, { shouldValidate: true });
	};

	const onEditPhoneCountrySelector: useSignupResult["onEditPhoneCountrySelector"] = (value) => {
		logAnalyticEvent(SignupEvents.selectPhoneCountry, { value });
		methods.setValue("phoneCountrySelector", value, { shouldDirty: true, shouldValidate: true });
		methods.setValue("principalPhone", null, { shouldValidate: false });
	};

	const isButtonDisabled =
		activeStep === 3 ? !isValid : steps[activeStep].some((input) => !!methods.formState.errors[input]);

	useEffect(() => {
		methods.reset(defaultValues);
	}, [methods, defaultValues]);

	const rules: useSignupResult["rules"] = {
		businessSector: {
			required: {
				message: t("inputs.businessSector.errors.required"),
				value: true
			}
		},
		coop: undefined,
		countryCode: {
			required: {
				message: t("inputs.country.errors.required"),
				value: true
			}
		},
		email: {
			pattern: {
				message: t("inputs.email.errors.invalid"),
				value: emailRegEx
			},
			required: {
				message: t("inputs.email.errors.required"),
				value: true
			}
		},
		firstName: {
			maxLength: {
				message: t("inputs.firstName.errors.invalid"),
				value: 40
			},
			minLength: {
				message: t("inputs.firstName.errors.invalid"),
				value: 2
			},
			required: {
				message: t("inputs.firstName.errors.required"),
				value: true
			}
		},
		googleAutocomplete: {
			required: {
				message: t("inputs.location.errors.required"),
				value: true
			}
		},
		language: undefined,
		lastName: {
			maxLength: {
				message: t("inputs.lastName.errors.invalid"),
				value: 40
			},
			minLength: {
				message: t("inputs.lastName.errors.invalid"),
				value: 2
			},
			required: {
				message: t("inputs.lastName.errors.required"),
				value: true
			}
		},
		location: undefined,
		password: {
			required: {
				message: t("inputs.password.errors.required"),
				value: true
			},
			validate: (value: string) => {
				return (
					(value.toLowerCase() !== value &&
						value.toUpperCase() !== value &&
						/\d/.test(value) &&
						value.length >= 8 &&
						value.length <= 40) ||
					t("inputs.password.errors.invalid")
				);
			}
		},
		phoneCountrySelector: undefined,
		principalPhone: undefined,
		utms: undefined
	};

	const createNewUser: useSignupResult["createNewUser"] = async (formValues) => {
		setLoading(true);
		logAnalyticEvent(SignupEvents.createAccount);
		if (!formValues.location) {
			methods.setError("googleAutocomplete", {
				message: t("inputs.location.errors.notFindable"),
				type: "custom"
			});
			setActiveStep(findAssociatedStep("googleAutocomplete"));
			setLoading(false);
			return;
		}

		const values = {
			businessSector: formValues.businessSector,
			coopId: parseInt(formValues.coop, 10) || null,
			countryCode: formValues.countryCode,
			email: formValues.email,
			firstName: formValues.firstName,
			language: formValues.language,
			lastName: formValues.lastName,
			location: formValues.location,
			password: formValues.password,
			principalPhone: parsePhoneNumber(
				`+${countryMapping[formValues.phoneCountrySelector].phone.callingCode}${formValues.principalPhone}`,
				{
					extract: false
				}
			).format("E.164"),
			utms: formValues.utms || null
		};
		try {
			await createUser(values);
			const token = await checkToken({ email: values.email, password: values.password });
			if (token) await login(token);
			showSnackbar(t("snackbar.signUp.success"), SnackbarType.SUCCESS);
		} catch (e) {
			if (e?.response?.data?.code === "alreadyExistUser") {
				methods.setError("email", {
					message: t("inputs.email.errors.alreadyUsed"),
					type: "custom"
				});
				setActiveStep(findAssociatedStep("email"));
			} else if (
				e?.response?.data?.code === "invalidEmailAdress" ||
				e?.response?.data?.code === "wrongEmailFormatForTest"
			) {
				methods.setError("email", {
					message: t("inputs.email.errors.invalid"),
					type: "custom"
				});
				setActiveStep(findAssociatedStep("email"));
			} else if (e?.response?.data?.code === "invalidFirstName") {
				methods.setError("firstName", {
					message: t("inputs.firstName.errors.invalid"),
					type: "custom"
				});
				setActiveStep(findAssociatedStep("firstName"));
			} else if (e?.response?.data?.code === "invalidLastName") {
				methods.setError("lastName", {
					message: t("inputs.lastName.errors.invalid"),
					type: "custom"
				});
				setActiveStep(findAssociatedStep("lastName"));
			} else if (e?.response?.data?.code === "wrongPhoneNumberFormat") {
				methods.setError("principalPhone", {
					message: t("inputs.principalPhone.errors.invalid"),
					type: "custom"
				});
				setActiveStep(findAssociatedStep("principalPhone"));
			} else if (e?.response?.data?.code === "zipCodeNotFound") {
				methods.setError("googleAutocomplete", {
					message: t("inputs.location.errors.notFindable"),
					type: "custom"
				});
				setActiveStep(findAssociatedStep("googleAutocomplete"));
			} else {
				showSnackbar(t("screens.signUp.serverError"), SnackbarType.ERROR);
				throw e;
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const loadBusinessSectors = async (): Promise<void> => {
			const fetchedBusinessSectors = await getBusinessSectors();
			setBusinessSectors(fetchedBusinessSectors);
		};
		loadBusinessSectors();
	}, [getBusinessSectors]);

	return {
		activeStep,
		businessSectors,
		coops,
		createNewUser,
		goNextStep,
		goPreviousStep,
		isButtonDisabled,
		loading,
		methods,
		onChangeCountry,
		onEditPhoneCountrySelector,
		passwordShown,
		PasswordVisibilityIcon,
		rules,
		togglePasswordVisibility
	};
};
